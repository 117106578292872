<div class="container">
  <div class="page-info  page-info--dashboard">
    <h2 class="page-info__title">Admin Dashboard</h2>

    <div class="page-info__data">
      <div class="page-info__data-item"><span>{{organizationCount | async}}</span> Organizations</div>
      <div class="page-info__data-item"><span>{{userCount | async}}</span> Users</div>
    </div>
  </div>

  <!--<div *ngIf="loading" class="k-loading-image"></div>-->

  <div class="content-area  content-area--dashboard">
    <div class="layout layout--stretch">
      <div class="layout__item  u-1/1  u-1/3@md  u-1/2@lg  u-1/3@xxl">
        <kendo-card [width]="'100%'">
          <kendo-card-header class="k-hbox">
            <h1 kendoCardTitle>Activity</h1>
            <p kendoCardSubtitle>Organizations and Vendor Signups</p>
          </kendo-card-header>
          <kendo-card-body>
            <div class="layout layout--stretch layout--two-donuts">
              <div class="layout__item  u-1/2  u-1/1@md  u-1/2@lg">
                <!-- Organizations donut -->
                <kendo-chart>
                  <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                  <ng-template kendoChartDonutCenterTemplate>
                    <h3>80</h3> Organizations
                  </ng-template>
                  <kendo-chart-series>
                    <kendo-chart-series-defaults type="donut">
                      <kendo-chart-series-defaults-labels
                        font="10px Avenir Next LT Pro, sans-serif"
                        background="none"
                        color="#fff">
                      </kendo-chart-series-defaults-labels>
                    </kendo-chart-series-defaults>
                    <kendo-chart-series-item [data]="vendorCountData" [startAngle]="150"
                                            categoryField="kind" field="share"
                                            colorField="valueColor">
                      <kendo-chart-series-item-labels [content]="donutLabelContent">
                      </kendo-chart-series-item-labels>
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
                <!-- /Organizations donut -->
              </div>
              <div class="layout__item  u-1/2  u-1/1@md  u-1/2@lg">
                <!-- Users donut -->
                <kendo-chart>
                  <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                  <ng-template kendoChartDonutCenterTemplate>
                    <h3>115</h3> Users
                  </ng-template>
                  <kendo-chart-series>
                    <kendo-chart-series-defaults type="donut">
                      <kendo-chart-series-defaults-labels
                        font="10px Avenir Next LT Pro, sans-serif"
                        background="none"
                        color="#fff">
                      </kendo-chart-series-defaults-labels>
                    </kendo-chart-series-defaults>
                    <kendo-chart-series-item [data]="userCountData"
                                            categoryField="kind" field="share"
                                            colorField="valueColor">
                      <kendo-chart-series-item-labels [content]="donutLabelContent">
                      </kendo-chart-series-item-labels>
                    </kendo-chart-series-item>
                  </kendo-chart-series>
                </kendo-chart>
                <!-- /Users donut -->
              </div>
            </div>
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="layout__item  u-1/1  u-2/3@md  u-1/2@lg  u-2/3@xxl">
        <kendo-card [width]="'100%'">
          <kendo-card-header class="k-hbox">
            <h1 kendoCardTitle>Usage Statistics</h1>
            <p kendoCardSubtitle>Active Vendors and Users per Day</p>
          </kendo-card-header>
          <kendo-card-body>
            <!-- User and Org logins over time -->
            <kendo-chart>
              <kendo-chart-legend position="bottom"
                                  orientation="horizontal"
                                  labels="{ font: 10pt 'Avenir Next LT Pro', sans-serif }"></kendo-chart-legend>
              <kendo-chart-tooltip format="{0}"
                                  font="10pt Avenir Next LT Pro, sans-serif"
                                  background="none"
                                  color="#000000"
              ></kendo-chart-tooltip>
              <kendo-chart-axis-defaults [line]="{ color: '#2962FF' }">
                <kendo-chart-axis-defaults-labels font="10pt Avenir Next LT Pro, sans-serif">
                </kendo-chart-axis-defaults-labels>
              </kendo-chart-axis-defaults>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                  [categories]="['Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Yesterday', 'Today']">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-series>
                <kendo-chart-series-defaults type="area">
                </kendo-chart-series-defaults>
                <kendo-chart-series-item [line]="{ style: 'step' }" [color]="'#29B6F6'"
                                        [name]="'Organizations'" [data]="[23, 76, 10, 12, 40, 100, 98]">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="line" [line]="{ style: 'smooth' }" [color]="'#006064'"
                                        [name]="'Users'" [data]="[56, 140, 195, 46, 123, 78, 95]">
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
            <!-- /User and Org logins over time -->
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="layout__item  u-1/1  u-1/2@md">
        <kendo-card [width]="'100%'">
          <kendo-card-header class="k-hbox">
            <h1 kendoCardTitle>Orders and RFQs</h1>
            <p kendoCardSubtitle>In the last week</p>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Orders and RFQ over time -->
            <kendo-chart>
              <kendo-chart-legend position="bottom"
                                  orientation="horizontal"
                                  labels="{ font: 10pt 'Avenir Next LT Pro', sans-serif }"></kendo-chart-legend>
              <kendo-chart-tooltip format="{0}"
                                  font="10pt Avenir Next LT Pro, sans-serif"
                                  background="none"
                                  color="#000000"
              ></kendo-chart-tooltip>
              <kendo-chart-axis-defaults [line]="{ color: '#2962FF' }">
                <kendo-chart-axis-defaults-labels font="10pt Avenir Next LT Pro, sans-serif">
                </kendo-chart-axis-defaults-labels>
              </kendo-chart-axis-defaults>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                  [categories]="['Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Yesterday', 'Today']">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-series>
                <kendo-chart-series-defaults type="area">
                  <kendo-chart-series-defaults-labels
                    font="10pt Avenir Next LT Pro, sans-serif"
                    background="none"
                    color="#fff">
                  </kendo-chart-series-defaults-labels>
                </kendo-chart-series-defaults>
                <kendo-chart-series-item [name]="'RFQs'"
                                        [color]="'#BBDEFB'"
                                        [stack]="true"
                                        [data]="[123, 276, 310, 212, 240, 156, 98]">
                </kendo-chart-series-item>
                <kendo-chart-series-item [name]="'Orders'"
                                        [color]="'#1565C0'"
                                        [data]="[165, 210, 287, 144, 190, 167, 212]">
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
            <!-- /Orders and RFQ over time -->
          </kendo-card-body>
        </kendo-card>
      </div>
      <div class="layout__item  u-1/1  u-1/2@md">
        <kendo-card [width]="'100%'">
          <kendo-card-header class="k-hbox">
            <h1 kendoCardTitle>Messages Sent/Received</h1>
            <p kendoCardSubtitle>In the last week</p>
          </kendo-card-header>
          <kendo-card-body>
            <!-- Messages over time -->
            <kendo-chart>
              <kendo-chart-legend position="bottom"
                                  orientation="horizontal"
                                  labels="{ font: 10pt 'Avenir Next LT Pro', sans-serif }"></kendo-chart-legend>
              <kendo-chart-tooltip format="{0}"
                                  font="10pt Avenir Next LT Pro, sans-serif"
                                  background="none"
                                  color="#000000"
              ></kendo-chart-tooltip>
              <kendo-chart-axis-defaults [line]="{ color: '#AA00FF' }">
                <kendo-chart-axis-defaults-labels font="10pt Avenir Next LT Pro, sans-serif">
                </kendo-chart-axis-defaults-labels>
              </kendo-chart-axis-defaults>
              <kendo-chart-category-axis>
                <kendo-chart-category-axis-item
                  [categories]="['Friday', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Yesterday', 'Today']">
                </kendo-chart-category-axis-item>
              </kendo-chart-category-axis>
              <kendo-chart-series>
                <kendo-chart-series-defaults type="area">
                  <kendo-chart-series-defaults-labels
                    font="10pt Avenir Next LT Pro, sans-serif"
                    background="none"
                    color="#fff">
                  </kendo-chart-series-defaults-labels>
                </kendo-chart-series-defaults>
                <kendo-chart-series-item [name]="'Sent'"
                                        [color]="'#CE93DB'"
                                        [stack]="true"
                                        [data]="[56, 140, 195, 46, 123, 78, 95]">
                </kendo-chart-series-item>
                <kendo-chart-series-item [name]="'Received'"
                                        [color]="'#6A1B9A'"
                                        [data]="[165, 210, 287, 144, 190, 167, 212]">
                </kendo-chart-series-item>
              </kendo-chart-series>
            </kendo-chart>
            <!-- /Messages over time -->
          </kendo-card-body>
        </kendo-card>
      </div>
    </div>
  </div>
</div>
