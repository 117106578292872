/**
 * src/app/modules/user-space-admin/user-space-admin.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSpaceAdminRoutingModule } from './user-space-admin-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {ChartModule, SparklineModule} from "@progress/kendo-angular-charts";
import {AvatarModule, CardModule, LayoutModule, TabStripModule} from "@progress/kendo-angular-layout";
import {GridModule} from "@progress/kendo-angular-grid";
import {ButtonModule, ButtonsModule, DropDownButtonModule} from "@progress/kendo-angular-buttons";
import {ListViewModule} from "@progress/kendo-angular-listview";
import * as fromUserSpaceAdmin from './state/userSpaceAdmin.reducer';
import * as fromNotifications from '../notifications/state/notifications.reducer';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {UserSpaceAdminEffects} from "./state/userSpaceAdmin.effects";
import {NotificationsEffects} from "../notifications/state/notifications.effects";
import {ConvertChatMessagesToMessagesPipe} from "./pipes/convert-chat-messages-to-messages.pipe";
import {BadgeModule} from '@progress/kendo-angular-indicators';
import {PopupModule} from '@progress/kendo-angular-popup';
import {ButtonGroupModule} from "@progress/kendo-angular-buttons";
import { BrowserModule } from '@angular/platform-browser';
import {TextBoxModule} from "@progress/kendo-angular-inputs";
import {OrdersOverTimeComponent} from "./components/dashboard/order-over-time/orders-over-time.component";
import {SharedModule} from "../shared/shared.module";
import {GraphAverageValuePerQuantityPipe} from "./pipes/graph-average-value-per-quantity.pipe";
import {GraphCategoriesPipe} from "./pipes/graph-categories.pipe";
import {GraphQuantityPipe} from "./pipes/graph-quantity.pipe";
import {GraphTotalPipe} from "./pipes/graph-total.pipe";
import {GraphReversePipe} from "./pipes/graph-reverse.pipe";
import {DropDownListModule} from "@progress/kendo-angular-dropdowns";
import {RemoveSelectedPipe} from "./pipes/remove-selected.pipe";
import {FilterCustomersPipe} from "./pipes/filter-customers.pipe";
import {CustomerDataPointPipe} from "./pipes/customer-data-point.pipe";
import {GridsterModule} from "angular-gridster2";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogModule} from "@progress/kendo-angular-dialog";
import {LabelModule} from "@progress/kendo-angular-label";
import {RemoveReadonlyPipe} from "./pipes/remove-readonly.pipe";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [DashboardComponent,
    ConvertChatMessagesToMessagesPipe,
    OrdersOverTimeComponent,
    GraphAverageValuePerQuantityPipe,
    GraphCategoriesPipe,
    GraphQuantityPipe,
    GraphTotalPipe,
    GraphReversePipe,
    RemoveSelectedPipe,
    FilterCustomersPipe,
    CustomerDataPointPipe,
    RemoveReadonlyPipe
  ],

  imports: [
    CommonModule,
    UserSpaceAdminRoutingModule,
    ChartModule,
    TabStripModule,
    GridModule,
    SparklineModule,
    ButtonModule,
    DialogModule,
    LabelModule,
    CardModule,
    ListViewModule,
    AvatarModule,
    BadgeModule,
    PopupModule,
    ButtonGroupModule,
    TextBoxModule,
    StoreModule.forFeature(fromUserSpaceAdmin.userSpaceAdminFeatureKey, fromUserSpaceAdmin.reducer),
    StoreModule.forFeature(fromNotifications.notificationsFeatureKey, fromNotifications.reducer),
    EffectsModule.forFeature([UserSpaceAdminEffects, NotificationsEffects]),
    DropDownButtonModule,
    SharedModule,
    DropDownListModule,
    GridsterModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    ButtonsModule,
  ],
  exports: [
    RemoveSelectedPipe
  ],
  providers: [
    ConvertChatMessagesToMessagesPipe,
    GraphAverageValuePerQuantityPipe,
    GraphCategoriesPipe,
    GraphQuantityPipe,
    GraphTotalPipe,
    GraphReversePipe,
    RemoveSelectedPipe,
    FilterCustomersPipe,
    CustomerDataPointPipe,
    RemoveReadonlyPipe
  ]
})
export class UserSpaceAdminModule { }
