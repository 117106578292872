/**
 * src/app/modules/organizations/organizations-routing.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileComponent} from './components/profile/profile.component';
import {StateResolver} from '../shared/services/state.resolver';
import {userLoginEditSubjectGetAction, userOrganizationEditSubjectGetAction} from './state/organizations.actions';
import {EditUserComponent} from './components/profile/edit-user/edit-user.component';
import {IsAuthenticatedGuardService} from "../authentication/services/is-authenticated-guard.service";

const routes: Routes = [
  {
    path: 'profile',
    canActivate: [IsAuthenticatedGuardService],
    children: [
      {
        path: '',
        component: ProfileComponent,
        resolve: { state: StateResolver },
        data: {
          actions: [
            { action: userOrganizationEditSubjectGetAction }
          ]
        }
      },
      {
        path: 'users/:id',
        component: EditUserComponent,
        resolve: { state: StateResolver },
        data: {
          actions: [
            { action: userLoginEditSubjectGetAction, keys: { id: 'id'} }
          ]
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrganizationsRoutingModule { }
