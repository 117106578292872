/**
 * src/app/modules/authentication/services/authentication.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../shared/models/user';
import { HttpClient } from '@angular/common/http';
import {messageDto} from '../../shared/models/message.dto';
import {Organizations} from '../../organizations/models/organizations';
import {Successful} from '../../shared/models/successful';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Send credentials and attempt to authenticate.
   * @param username
   * @param password
   * @param remember_me
   * @param uuid
   */
  public login(username: string, password: string, remember_me: boolean, uuid?: string): Observable<User> {
    return this.httpClient.post<User>(`/api/authentication/login`, {username: username, password: password, remember_me: remember_me, uuid: uuid});
  }

  /**
   * Logout of the system.
   */
  public logout(): Observable<messageDto<string>> {
    return this.httpClient.get<messageDto<string>>(`/api/authentication/logout`)
  }

  /**
   * Send request to reset password.
   * @param username
   */
  public forgotPassword(username: string): Observable<messageDto<string>> {
    return this.httpClient.post<messageDto<string>>(`/api/authentication/forgot-password`, {username: username});
  }

  /**
   * Send request to set password.
   * @param token
   * @param password
   */
  public setPassword(token: string, password: string): Observable<messageDto<string>> {
    return this.httpClient.post<messageDto<string>>(`/api/authentication/set-password`, {token: token, password: password});
  }

  /**
   * Send request to sign up a configured organization. Request returns the initial user signed up.
   * @param   organizaton         Organization
   *
   * @returns messageDto<string>
   */
  public createOrganization(organization: Organizations): Observable<messageDto<string>> {
    return this.httpClient.post<messageDto<string>>(`/api/authentication/sign-up`, organization);
  }

  /**
   * Checking exists email.
   */
  public checkEmailExists(email: string): Observable<Successful> {
    return this.httpClient.post<Successful>('/api/authentication/check/email', {email});
  }

  /**
   * Send request to verify email
   * @param   organizaton         Organization
   *
   * @returns messageDto<string>
   */
  public verifyEmail(uuid: string): Observable<messageDto<string>> {
    return this.httpClient.post<messageDto<string>>(`/api/authentication/verify-email/${uuid}`, {});
  }

  /**
   * Re-send request to verify email
   * @param   userEmail         User Email
   *
   * @returns messageDto<string>
   */
  public resendVerifyEmail(userEmail: string): Observable<messageDto<string>> {
    return this.httpClient.post<messageDto<string>>(`/api/authentication/resend-verify-email/${userEmail}`, {});
  }

}
