/**
 * src/app/modules/authentication/services/order-manager-guard.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from "@ngrx/store";
import {take} from "rxjs/operators";
import {selectOrganization} from "../../../organizations/state/organizations.selector";
import {AppState} from "../../../../app.module";

@Injectable()
export class OrderManagerGuardService implements CanActivate {

  constructor(private store: Store<AppState>, public router: Router) {
  }

  async canActivate(): Promise<boolean> {
    const org = await this.store.select(selectOrganization).pipe(take(1)).toPromise();
    if (org?.plan?.order_manager) {
      return true;
    }
    this.router.navigate(['/../dashboard']);
    return false;
  }

}
