/**
 * src/app/modules/orders/state/orders.reducer.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2020
 * @since 12/20
 */

import {Action, createReducer, on} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query/dist/npm/state';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {
  countApprovedOrdersSubjectChangeAction,
  countNewOrdersSubjectChangeAction,
  countRejectedOrdersSubjectChangeAction,
  defaultPlanSubjectChangeAction,
  orderAccept,
  orderAcceptDialog,
  orderAcceptSuccess,
  orderAddChatMessage,
  orderFreightTrackingDialog,
  orderReject,
  orderRejectDialog,
  orderRejectSuccess,
  orderResetAction,
  ordersListPagerAddNameFilterAction,
  ordersListPagerAddStatusFilterAction,
  ordersListPagerChangeAction,
  ordersListPagerResetNameFilterAction,
  ordersListPagerResetStatusFilterAction,
  orderStatusUpdateAction,
  orderSubjectChangeAction,
  orderUpdateFreightTracking,
  orderUpdateFreightTrackingSuccess,
  requestPaymentDialog, requestPaymentOptionsSuccess,
  unlinkedOrderSubjectGetAction
} from './orders.actions';
import {Orders} from '../models/orders';
import {ChatMessages} from '../../notifications/models/ChatMessages';
import {logoutSuccessAction} from '../../authentication/state/authentication.actions';
import {MediaMessage} from '../../shared/models/mediaMessage';
import {Message} from '@progress/kendo-angular-conversational-ui';
import {OrderStatus} from '../models/order-status.enum';
import {Plans} from '../../organizations/models/plans';
import {SystemMessage} from '../../shared/models/systemMessage';

export const ordersFeatureKey = 'orders';

export class OrdersState  {
  currentOrder: Orders | null;
  orderDetails: {
    freightTrackingDialogOpen: boolean;
    rejectDialogOpen: boolean;
    acceptDialogOpen: boolean;
    requestPaymentDialogOpen: boolean;
    freightTrackingProcessing: boolean;
    rejectProcessing: boolean;
    acceptProcessing: boolean;
    requestPaymentProcessing: boolean;
  };
  list: {
    pager: KendoDataState
    searchFilter: FilterDescriptor | null;
    statusFilter: FilterDescriptor | null;
  };
  newOrderCount: number;
  approvedOrderCount: number;
  rejectedOrderCount: number;
  defaultPlan: Plans | null;
  uuid: string | null;
}

export const initialState: OrdersState = {
  currentOrder: null,
  orderDetails: {
    freightTrackingDialogOpen: false,
    rejectDialogOpen: false,
    acceptDialogOpen: false,
    requestPaymentDialogOpen: false,
    freightTrackingProcessing: false,
    rejectProcessing: false,
    acceptProcessing: false,
    requestPaymentProcessing: false
  },
  list : {
    pager: {
      sort: [{dir: 'asc', field: 'order_name'}],
      filter: null,
      take: 10,
      skip: 0,
    },
    searchFilter: null,
    statusFilter: null
  },
  newOrderCount: 0,
  approvedOrderCount: 0,
  rejectedOrderCount: 0,
  defaultPlan: null,
  uuid: null
};

const ordersReducer = createReducer(
  initialState,
  on(ordersListPagerChangeAction,
    (state: OrdersState, listPager: KendoDataState) => ({...state, list: {...state.list, pager: listPager} })),
  // tslint:disable-next-line:max-line-length
  on(ordersListPagerAddNameFilterAction, (state: OrdersState, searchFilter: FilterDescriptor) => ({...state, list: { ...state.list, searchFilter, pager: {...state.list.pager, skip: 0} } })),
  on(ordersListPagerResetNameFilterAction, (state: OrdersState) => ({...state, list: { ...state.list, searchFilter: null } })),
  // tslint:disable-next-line:max-line-length
  on(ordersListPagerAddStatusFilterAction, (state: OrdersState, statusFilter: FilterDescriptor) =>
    ({...state, list: { ...state.list, statusFilter, pager: {...state.list.pager, skip: 0} }  })
  ),
  on(ordersListPagerResetStatusFilterAction, (state: OrdersState) =>
    ({...state, list: { ...state.list, statusFilter: null, pager: {...state.list.pager, skip: 0} } })
  ),
  on(orderResetAction, (state: OrdersState) => ({...state, currentOrder: null})),
  on(orderSubjectChangeAction, (state: OrdersState, data: {order: Orders, skipMessages?: boolean}) => {
    if (data.skipMessages) {
      return {...state, currentOrder: {...data.order, chatMessages: state.currentOrder.chatMessages}};
    }

    return {...state, currentOrder: data.order};
  }),
  // tslint:disable-next-line:max-line-length
  on(orderAddChatMessage, (state: OrdersState, message: ChatMessages) => {
    const chatMessages = new Map<number, ChatMessages>();
    if (state.currentOrder && state.currentOrder.id === message.order.id) {
      let m: Message;
      if (message.is_media === true) {
        m = {text: message.message, containsMedia: true, author: {id: (message.originator === true) ? 1 : 0},
          timestamp: message.created_at} as MediaMessage;
      } else if (message.is_system_message === true) {
        m = {text: message.message, isSystemMessage: true, author: { id: 1 }, timestamp: message.created_at} as SystemMessage;
      } else {
        m = {text: message.message, author: {id: (message.originator === true) ? 1 : 0}, timestamp: message.created_at} as Message;
      }
      state.currentOrder.chatMessages.forEach((m) => chatMessages.set(m.id, m));
      chatMessages.set(message.id, message);
      return {...state, currentOrder: { ...state.currentOrder, chatMessages: Array.from(chatMessages.values()) } };
    } else {
      return state;
    }
  }),
  on(orderFreightTrackingDialog, (state: OrdersState, data: {isOpen: boolean}) =>
    ({ ...state, orderDetails: { ...state.orderDetails, freightTrackingDialogOpen: data.isOpen, freightTrackingProcessing: false } })
  ),
  on(orderRejectDialog, (state: OrdersState, data: {isOpen: boolean}) =>
    ({ ...state, orderDetails: { ...state.orderDetails, rejectDialogOpen: data.isOpen, rejectProcessing: false } })
  ),
  on(orderAcceptDialog, (state: OrdersState, data: {isOpen: boolean}) =>
    ({ ...state, orderDetails: { ...state.orderDetails, acceptDialogOpen: data.isOpen, acceptProcessing: false } })
  ),
  on(requestPaymentDialog, (state: OrdersState, data: {isOpen: boolean}) =>
    ({ ...state, orderDetails: { ...state.orderDetails, requestPaymentDialogOpen: data.isOpen, requestPaymentProcessing: false } })
  ),
  on(orderUpdateFreightTrackingSuccess, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, freightTrackingProcessing: false } })
  ),
  on(orderRejectSuccess, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, rejectProcessing: false } })
  ),
  on(orderAcceptSuccess, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, acceptProcessing: false } })
  ),
  on(requestPaymentOptionsSuccess, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, requestPaymentProcessing: false } })
  ),
  on(orderUpdateFreightTracking, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, freightTrackingProcessing: true } })
  ),
  on(orderReject, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, rejectProcessing: true } })
  ),
  on(orderAccept, (state: OrdersState, data) =>
    ({ ...state, orderDetails: { ...state.orderDetails, acceptProcessing: true } })
  ),
  on(logoutSuccessAction, (state: OrdersState) => ({...state, ...initialState})),
  on(defaultPlanSubjectChangeAction, (state: OrdersState, defaultPlan: { defaultPlan: Plans }) => ({...state, defaultPlan: defaultPlan.defaultPlan})),
  on(unlinkedOrderSubjectGetAction, (state: OrdersState, uuid: { uuid: string }) => ({...state, uuid: uuid.uuid})),
  on(countNewOrdersSubjectChangeAction, (state: OrdersState, newOrderCount: { newOrderCount: number }) => ({...state, newOrderCount: newOrderCount.newOrderCount})),
  on(countApprovedOrdersSubjectChangeAction, (state: OrdersState, approvedOrderCount: { approvedOrderCount: number }) => ({...state, approvedOrderCount: approvedOrderCount.approvedOrderCount})),
  on(countRejectedOrdersSubjectChangeAction, (state: OrdersState, rejectedOrderCount: { rejectedOrderCount: number }) => ({...state, rejectedOrderCount: rejectedOrderCount.rejectedOrderCount})),
  on(orderStatusUpdateAction, (state: OrdersState, status: { orderId: number | string, status: OrderStatus }) => {
     if (state.currentOrder && state.currentOrder.id === status.orderId) {
       return {...state, currentOrder: { ...state.currentOrder, status: status.status }};
     }
     return state;
  }),
);

export function reducer(state: OrdersState | undefined, action: Action) {
  return ordersReducer(state, action);

}
