/**
 * src/app/modules/shared/state/shared.effects.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  countApprovedOrdersGetAction,
  countApprovedOrdersSubjectChangeAction,
  countNewOrdersGetAction,
  countNewOrdersSubjectChangeAction,
  countRejectedOrdersGetAction,
  countRejectedOrdersSubjectChangeAction,
  defaultPlanGetAction,
  defaultPlanSubjectChangeAction,
  orderAccept,
  orderAcceptDialog,
  orderAcceptSuccess,
  orderFreightTrackingDialog,
  orderReject,
  orderRejectDialog,
  orderRejectSuccess,
  orderSubjectChangeAction,
  orderSubjectGetAction,
  orderUpdateFreightTracking,
  orderUpdateFreightTrackingSuccess, requestPaymentDialog, requestPaymentOptionsSuccess, sendPaymentOptions,
  unlinkedOrderSubjectGetAction
} from './orders.actions';
import {OrdersService} from '../services/orders.service';
import {ToastrService} from 'ngx-toastr';
import {Orders} from '../models/orders';
import {CountService} from '../../organizations/services/count.service';
import {FreightTracking} from '../../notifications/models/FreightTracking';
import {HttpErrorResponse} from '@angular/common/http';
import {RejectOrder} from '../../notifications/models/RejectOrder';
import {AcceptOrder} from '../../notifications/models/AcceptOrder';
import {loginSetOrderAction, loginSetUuidAction} from '../../authentication/state/authentication.actions';
import {HandleErrorService} from "../../shared/services/handle-error.service";
import {DefaultPlanService} from "../services/default-plan.service";
import {UnlinkedOrdersService} from "../services/unlinked-orders.service";
import {changeHeaderAction} from "../../shared/state/shared.actions";
import {RequestPaymentOptions} from "../../notifications/models/RequestPaymentOptions";

@Injectable()
export class OrdersEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private ordersService: OrdersService,
    private toastrService: ToastrService,
    private countService: CountService,
    private defaultPlanService: DefaultPlanService,
    private errorHandler: HandleErrorService,
    private unlinkedOrderService: UnlinkedOrdersService
  ) {}


  getOrder$ = createEffect(() =>
    this.actions.pipe(
      ofType(orderSubjectGetAction.type),
      switchMap((props: any) => {

          return this.ordersService.getOne(props.id).pipe(take(1), map(o => orderSubjectChangeAction({order: o})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving this order');
              return of(orderSubjectChangeAction({order: new Orders()}));
            }));
        }

      )
    )
  );

  getUnlinkedOrder$ = createEffect(() =>
    this.actions.pipe(
      ofType(unlinkedOrderSubjectGetAction.type),
      switchMap((props: any) => {
          return this.unlinkedOrderService.getOne(props.uuid).pipe(take(1), map(o => orderSubjectChangeAction({order: o})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving this order');
              return of(orderSubjectChangeAction({order: new Orders()}));
            }));
        }
      )
    )
  );

  setUnlinkedHeader$ = createEffect(() =>
    this.actions.pipe(
      ofType(unlinkedOrderSubjectGetAction.type),
      switchMap(() => {
          return of(changeHeaderAction({header: 'unlinked'}));
        }
      )
    )
  );

  updateFreightTracking$ = createEffect(() => this.actions.pipe(
    ofType(orderUpdateFreightTracking.type),
    switchMap((props: FreightTracking) => this.ordersService.updateFreightTracking(props).pipe(
      take(1),
      tap(() => this.toastrService.success('You have successfully updated the tracking information for that order.')),
    )),
    switchMap(o => [
      orderSubjectChangeAction({order: o, skipMessages: true}),
      orderFreightTrackingDialog({isOpen: false}),
      orderUpdateFreightTrackingSuccess({status: true}),
    ]),
    catchError((err: HttpErrorResponse) => {
      this.toastrService.error(err.message);
      return of(orderFreightTrackingDialog({isOpen: true}));
    }),
  ));


  rejectOrder$ = createEffect(() => this.actions.pipe(
    ofType(orderReject.type),
    switchMap((props: RejectOrder) => this.ordersService.rejectOrder(props).pipe(
      take(1),
      tap((o) => {
        if (props && props.reason && props.reason.length > 0) {
          this.ordersService.messageOrder(o, props.reason, false).subscribe();
        }
      }),
      tap(() => this.toastrService.success('Order has been successfully rejected.')),
    )),
    switchMap(o => [
      orderSubjectChangeAction({order: o, skipMessages: true}),
      orderRejectDialog({isOpen: false}),
      orderRejectSuccess({status: true}),
    ]),
    catchError((err: HttpErrorResponse) => {
      this.toastrService.error(err.message, 'Order could not be rejected at this time.');
      return of(orderRejectDialog({isOpen: true}));
    }),
  ));


  acceptOrder$ = createEffect(() => this.actions.pipe(
    ofType(orderAccept.type),
    switchMap((props: AcceptOrder) => this.ordersService.acceptOrder(props).pipe(
      take(1),
      tap(() => this.toastrService.success('Order has been successfully accepted.'))
    )),
    switchMap(o => [
      orderSubjectChangeAction({order: o, skipMessages: true}),
      orderAcceptDialog({isOpen: false}),
      orderAcceptSuccess({status: true}),
    ]),
    catchError((err: HttpErrorResponse) => {
      this.toastrService.error(err.message, 'Order could not be accepted at this time.');
      return of(orderAcceptDialog({isOpen: true}));
    }),
  ));

  requestPaymentOptions$ = createEffect(() => this.actions.pipe(
    ofType(sendPaymentOptions.type),
    switchMap((props: RequestPaymentOptions) => this.ordersService.sendPaymentOptions(props).pipe(
      take(1),
      tap(() => this.toastrService.success('Payment info has been successfully requested.'))
    )),
    switchMap(o => [
      orderSubjectChangeAction({order: o, skipMessages: true}),
      requestPaymentDialog({isOpen: false}),
      requestPaymentOptionsSuccess({status: true}),
    ]),
    catchError((err: HttpErrorResponse) => {
      this.toastrService.error(err.message, 'Payment info could not be sent at this time.');
      return of(requestPaymentDialog({isOpen: true}));
    }),
  ));

  getDefaultPlan = createEffect(() =>
    this.actions.pipe(
      ofType(defaultPlanGetAction.type),
      switchMap((props: any) => {
          return this.defaultPlanService.getDefaultPlan().pipe(take(1), map(p => defaultPlanSubjectChangeAction({defaultPlan: p})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the default plan');
              return of(defaultPlanSubjectChangeAction({defaultPlan: null}));
            }));
        }
      )
    )
  );

  getNewOrderCount = createEffect(() =>
    this.actions.pipe(
      ofType(countNewOrdersGetAction.type),
      switchMap((props: any) => {
          return this.countService.getNewOrderCount().pipe(take(1), map(c => countNewOrdersSubjectChangeAction({newOrderCount: c})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the count of new orders');
              return of(countNewOrdersSubjectChangeAction({newOrderCount: 0}));
            }));
        }

      )
    )
  );

  getApprovedOrderCount = createEffect(() =>
    this.actions.pipe(
      ofType(countApprovedOrdersGetAction.type),
      switchMap((props: any) => {
          return this.countService.getApprovedOrderCount().pipe(take(1), map(c => countApprovedOrdersSubjectChangeAction({approvedOrderCount: c})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the count of approved orders');
              return of(countApprovedOrdersSubjectChangeAction({approvedOrderCount: 0}));
            }));
        }

      )
    )
  );

  getRejectedOrderCount = createEffect(() =>
    this.actions.pipe(
      ofType(countRejectedOrdersGetAction.type),
      switchMap((props: any) => {
          return this.countService.getRejectedOrderCount().pipe(take(1), map(c => countRejectedOrdersSubjectChangeAction({rejectedOrderCount: c})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the count of rejected orders');
              return of(countRejectedOrdersSubjectChangeAction({rejectedOrderCount: 0}));
            }));
        }

      )
    )
  );


  setOrderOnLogin = createEffect(() =>
    this.actions.pipe(
      ofType(loginSetOrderAction.type),
      tap((props: { orderId: number }) => {
        // this.router.navigate(['orders', props.orderId]); // this will allow us to navigate to an order on login
      })
    ), {dispatch: false}
  );

  setUuidOnLogin = createEffect(() =>
    this.actions.pipe(
      ofType(loginSetUuidAction.type),
      tap((props: { uuid: string }) => {
        // this.router.navigate(['orders', props.orderId]); // this will allow us to navigate to an order on login
      })
    ), {dispatch: false}
  );


}
