/**
 * src/app/modules/user-space-admin/state/userSpaceAdmin.effects.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 02/17/2021
 * @copyright DesignersAxis, LLC, 2020
 *
 */
import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {
  countEmployeesGetAction,
  countEmployeesSubjectChangeAction,
  countLocationsGetAction,
  countLocationsSubjectChangeAction,
  customerResetAction,
  customersMatchSelectionLogicAction,
  customersSetSubjectAction,
  customerSubjectChangeAction, dashboardConfigurationSaveAction, dashboardConfigurationSetAction,
  filtersSubjectChangeAction,
  initialLoadSubjectAction,
  salesCodeResetAction,
  salesCodesMatchSelectionLogicAction,
  salesCodesSetSubjectAction,
  salesCodeSubjectChangeAction,
  setCustomerInNewListAction,
  setOrderSummaryAction, setSalesCodeInNewListAction,
  timeframeSubjectChangeAction,
  widgetSubjectChangeAction,
} from "./userSpaceAdmin.actions";
import {catchError, map, switchMap, take, tap} from "rxjs/operators";
import {of, pipe} from "rxjs";
import {OrdersService} from "../../orders/services/orders.service";
import {OrganizationsService} from "../../organizations/services/organizations.service";
import {UsersService} from "../../organizations/services/users.service";
import {CountService} from "../../organizations/services/count.service";
import {FiltersInterface} from "../models/filters.interface";
import {HandleErrorService} from "../../shared/services/handle-error.service";
import {DashboardService} from "../services/dashboard.service";
import {DashboardConfiguration} from "../models/dashboardConfiguration";
import {configurationListUpdateAction, loginSetOrderAction} from "../../authentication/state/authentication.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.module";

@Injectable()
export class UserSpaceAdminEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private organizationsService: OrganizationsService,
    private toastrService: ToastrService,
    private userService: UsersService,
    private ordersService: OrdersService,
    private countService: CountService,
    private errorHandler: HandleErrorService,
    private dashboardService: DashboardService,
    private store: Store<AppState>
  ) {}

  onResetCustomer = createEffect(() =>
    this.actions.pipe(
      ofType(customerResetAction.type),
      switchMap((filters: FiltersInterface) => {
        const f = Object.assign({}, filters);
        f.customer = '';
        return of(filtersSubjectChangeAction(f));
        }
      )
    ));

  onResetSalesCode = createEffect(() =>
    this.actions.pipe(
      ofType(salesCodeResetAction.type),
      switchMap((filters: FiltersInterface) => {
        const f = Object.assign({}, filters);
        f.salesCode = '';
        return of(filtersSubjectChangeAction(f));
        }
      )
    ));

  onWidgetChange = createEffect(() =>
    this.actions.pipe(
      ofType(widgetSubjectChangeAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(filtersSubjectChangeAction(filters));
        }
      )
    ));

  onFilterChange = createEffect(() =>
    this.actions.pipe(
      ofType(filtersSubjectChangeAction.type),
      switchMap((filters: FiltersInterface) => {
          return this.ordersService.getOrderVolumeSummary(filters.customer, filters.salesCode, filters.timeframe).pipe(switchMap(data => {
              return of(setOrderSummaryAction({ordersSummary: data}));
            })
            , catchError((e) => {
                this.toastrService.error('There was a server error retrieving this login.');
                return of(setOrderSummaryAction({ordersSummary: []}));
              }
            )
          )
        }
      )
    ));

  onTimeframeChange = createEffect(() =>
    this.actions.pipe(
      ofType(timeframeSubjectChangeAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(customersMatchSelectionLogicAction(filters));
        }
      )
    ));

  onSalesCodeChange = createEffect(() =>
    this.actions.pipe(
      ofType(salesCodeSubjectChangeAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(customersMatchSelectionLogicAction(filters));
        }
      )
    ));

  onCustomerChange = createEffect(() =>
    this.actions.pipe(
      ofType(customerSubjectChangeAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(salesCodesMatchSelectionLogicAction(filters));
        }
      )
    ));

  onCustomerSet = createEffect(() =>
    this.actions.pipe(
      ofType(setCustomerInNewListAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(filtersSubjectChangeAction(filters));
        }
      )
    ));

  onCustomersMatchSelectionLogicAction = createEffect(() =>
    this.actions.pipe(
      ofType(customersMatchSelectionLogicAction.type),
      switchMap((filters: FiltersInterface) => {
        // customersSetSubjectAction, filterSubjectChangeAction OR customerResetAction
        return this.ordersService.getTopCustomersSummary(filters.salesCode, filters.timeframe).pipe(switchMap(data => {
            let selectedCustomerExistsInNewList = false;
            for (const k of data) {
              if (k.label === filters.customer) {
                selectedCustomerExistsInNewList = true;
              }
            }
            const f = Object.assign({}, filters);
            if (!selectedCustomerExistsInNewList) {
              f.customer = '';
            }
            return of(setCustomerInNewListAction({...f, ...{ topCustomers: data } }));
          })
          , catchError((e) => {
              this.toastrService.error('There was a server error retrieving this login.');
              return of(customersSetSubjectAction({topCustomers: []}));
            }
          )
        );
        }
      )
    ));

  onSalesCodeSet = createEffect(() =>
    this.actions.pipe(
      ofType(setSalesCodeInNewListAction.type),
      switchMap((filters: FiltersInterface) => {
          return of(filtersSubjectChangeAction(filters));
        }
      )
    ));

  onSalesCodesMatchSelectionLogicAction = createEffect(() =>
    this.actions.pipe(
      ofType(salesCodesMatchSelectionLogicAction.type),
      switchMap((filters: FiltersInterface) => {
          return this.ordersService.getSalescodes(filters.customer).pipe(switchMap(data => {
              let selectedSalesCodeExistsInNewList = false;
              for (const k of data) {
                if (k === filters.salesCode) {
                  selectedSalesCodeExistsInNewList = true;
                }
              }
              const f = Object.assign({}, filters);
              if (!selectedSalesCodeExistsInNewList) {
                f.salesCode = '';
              }
              return of(setSalesCodeInNewListAction({...f, ...{ salesCodes: data } }));
            })
            , catchError((e) => {
                this.toastrService.error('There was a server error retrieving this login.');
                return of(customerResetAction(filters));
              }
            )
          );
        }
      )
    ));

  onInitialLoad = createEffect(() =>
    this.actions.pipe(
      ofType(initialLoadSubjectAction.type),
      switchMap((filters: FiltersInterface) => {
          return this.ordersService.getTopCustomersSummary(filters.salesCode, filters.timeframe).pipe(switchMap(data => {
            return of(customersSetSubjectAction({topCustomers: data}));

            // not returning sales codes right now
            // return this.ordersService.getSalescodes(filters.customer).pipe(switchMap(s => {
            //   return of(salesCodesSetSubjectAction({salesCodes: s}));
            //   // return of(filtersSubjectChangeAction(filters));
            // }));

            })
            , catchError((e) => {
                this.errorHandler.handleError(e, 'There was a server error retrieving this login.');
                return of(customersSetSubjectAction({topCustomers: []}));
              }
            )
          )
        }
      )
    ));

  getLocationCount = createEffect(() =>
    this.actions.pipe(
      ofType(countLocationsGetAction.type),
      switchMap((props: any) => {
          return this.countService.getLocationCount().pipe(take(1), map(c => countLocationsSubjectChangeAction({locationCount: c})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the count of locations');
              return of(countLocationsSubjectChangeAction({locationCount: 0}));
            }));
        }

      )
    )
  );

  getEmployeeCount = createEffect(() =>
    this.actions.pipe(
      ofType(countEmployeesGetAction.type),
      switchMap((props: any) => {
          return this.countService.getEmployeeCount().pipe(take(1), map(c => countEmployeesSubjectChangeAction({employeeCount: c})),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving the count of employees');
              return of(countEmployeesSubjectChangeAction({employeeCount: 0}));
            }));
        }

      )
    )
  );

  saveTemplate$ = createEffect(() =>
    this.actions.pipe(
      ofType(dashboardConfigurationSaveAction.type),
      switchMap((data: { config: DashboardConfiguration} ) => {
          return this.dashboardService.save(data.config).pipe(switchMap(res => {
              this.store.dispatch(dashboardConfigurationSetAction({config: res}));
              this.toastrService.success('Successfully saved template.');
              return of(configurationListUpdateAction());
            })
            , catchError(e => {
                this.toastrService.error('There was a server error updating your available templates.');
                return of(configurationListUpdateAction());
              }
            )
          );
        }
      )
    ));


}
