/**
 * src/app/modules/orders/orders-routing.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ListComponent} from './components/list/list.component';
import {StateResolver} from '../shared/services/state.resolver';
import {OrderDetailsComponent} from './components/order-details/order-details.component';
import {
  countApprovedOrdersGetAction,
  countNewOrdersGetAction,
  countRejectedOrdersGetAction, defaultPlanGetAction,
  orderSubjectGetAction, unlinkedOrderSubjectGetAction
} from './state/orders.actions';
import {IsAuthenticatedGuardService} from '../authentication/services/is-authenticated-guard.service';
import {OrderManagerGuardService} from '../authentication/services/featureGuards/order-manager-guard.service';
import {userOrganizationEditSubjectGetAction} from '../organizations/state/organizations.actions';

const routes: Routes = [
  {
    path: 'orders',
    canActivate: [IsAuthenticatedGuardService, OrderManagerGuardService],
    children: [
      {
        path: '',
        component: ListComponent,
        resolve: { state: StateResolver },
        data: {
          actions: [
            { action: countNewOrdersGetAction},
            { action: countApprovedOrdersGetAction},
            { action: countRejectedOrdersGetAction},
            { action: userOrganizationEditSubjectGetAction},
          ]
        }
      }
    ]
  },
  {
    path: 'orders/:id',
    canActivate: [IsAuthenticatedGuardService, OrderManagerGuardService],
    component: OrderDetailsComponent,
    resolve: {state: StateResolver},
    data: {
      actions: [
        {action: orderSubjectGetAction, keys: {id: 'id'}}
      ]
    }
  },
  {
    path: 'shared/:uuid',
    component: OrderDetailsComponent,
    resolve: {state: StateResolver},
    data: {
      actions: [
        { action: defaultPlanGetAction },
        { action: unlinkedOrderSubjectGetAction, keys: {uuid: 'uuid'} }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
