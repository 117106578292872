<div class="header-holder" *ngIf="showMenu">
  <header class="header">
    <section class="header__section">
      <a [routerLink]="[(org | async)?.plan?.dashboard ? 'dashboard' : ((org | async)?.plan?.order_manager ? 'orders' : 'profile')]">
        <img src="/assets/icons/temp-logo.svg" class="logo" alt="">
      </a>
    </section>
    <section class="header__section header__section--nav">
      <nav *ngIf="isVendorActive" class="main-navigation" role="navigation">
        <ul>
          <li *ngIf="(org | async)?.plan?.dashboard" class="nav-item" [routerLink]="['dashboard']" routerLinkActive="active">
            Dashboard
          </li>
<!--          <li class="nav-item">-->
<!--            Designers-->
<!--          </li>-->
          <li class="nav-item" *ngIf="(org | async)?.plan?.order_manager" [routerLink]="['orders']" routerLinkActive="active">
            Orders
          </li>
        </ul>
        <ul>
          <li class="nav-item" *ngxPermissionsOnly="['system_admin']">
<!--            <div (click)="changeHeader()" [routerLink]="[(organizationPlan | async).plan?.dashboard ? 'system-admin/dashboard' : 'system-admin/organizations']">System Admin</div>-->
            <div (click)="changeHeader()" [routerLink]="['system-admin/dashboard']">System Admin</div>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['system_admin', 'vendor_admin']" [routerLink]="['/profile']">
            Profile
          </li>
          <li class="nav-item" (click)="logout()">
            Logout
          </li>
        </ul>
      </nav>
    </section>
    <section class="header__section header__section--user-actions">
      <!-- start notifications dropdown -->
      <vp-notifications-dropdown *ngIf="(org | async)?.plan?.notifications"></vp-notifications-dropdown>
      <!-- end notifications dropdown -->
    </section>
  </header>
</div>
