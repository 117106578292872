/**
 * src/modules/shared/pipes/graph-categories.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 02/17/2020
 */
import {Pipe, PipeTransform} from '@angular/core';
import {OrdersSummary} from "../../orders/models/orders-summary";

@Pipe({
  name: 'graphCategories'
})
export class GraphCategoriesPipe implements PipeTransform {

  transform(summary: OrdersSummary[]): string[] {
    if (!summary) {
      return [];
    }
    const output: string[] = [];
    for (const o of summary) {
      output.push(o.label);
    }
    return output;
  }

}
