/**
 * src/app/modules/authentication/state/authentication.actions.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createAction, props} from '@ngrx/store';
import {Credentials} from '../models/credentials';
import {User} from '../../shared/models/user';
import {DashboardConfiguration} from "../../user-space-admin/models/dashboardConfiguration";

export const loginAction = createAction('[Auth] Login', props<{ credentials: Credentials, uuid?: string, orderId?: number }>());
export const loginSuccessAction = createAction('[Auth] Login Success', props<User>());
export const loginFailureAction = createAction('[Auth] Login Failure', props<any>());
export const logoutAction = createAction('[Auth] Logout');
export const logoutSuccessAction = createAction('[Auth] Logout Success');
export const logoutFailureAction = createAction('[Auth] Logout failure');
export const loginUpdateAction = createAction('[Auth] Login Update', props<User>());

export const loginSetOrderAction = createAction('[Auth Order] Set Order Id for redirect', props<{ orderId: number }>());
export const loginSetUuidAction = createAction('[Auth Uuid] Set uuid for VP Match event', props<{ uuid: string }>());

export const configurationListUpdateAction = createAction('[Configuration] Prompts an update of the list of available dashboard configurations');
export const configurationListSetAction = createAction('[Configuration] Updates the list of available dashboard configurations', props<{ configurations: DashboardConfiguration[] }>());
