/**
 * src/modules/shared/pipes/remove-selected.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/11/2021
 */
import {Pipe, PipeTransform} from '@angular/core';
import {FiltersInterface} from "../models/filters.interface";

@Pipe({
  name: 'removeSelected'
})
export class RemoveSelectedPipe implements PipeTransform {

  transform(v: any, filters: FiltersInterface, key: string, dropdownKey: string): any[] {
    if (!v) {
      return [];
    }
    if (!filters || !filters[key]) {
      return v;
    }
    const output: any[] = [];
    for (const o of v) {
      if (!(o && o[dropdownKey] && o[dropdownKey] === filters[key])) {
        output.push(o);
      }
    }
    return output;
  }

}
