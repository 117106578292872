/**
 * src/app/modules/shared/services/state.resolver.ts
 * Resolve data via dispatching redux actions.
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.module';

@Injectable()
export class StateResolver implements Resolve<any> {

  constructor(private store: Store<AppState>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    if (route.data && route.data.actions) {
      for (const action of route.data.actions) {

        if (action.keys) {
            const args = {};
            for (const k in action.keys) {
              if (action.keys.hasOwnProperty(k) && route.params.hasOwnProperty(action.keys[k])) {
              args[k] = route.params[action.keys[k]];
              }
            }
            this.store.dispatch(action.action(args));
          } else {
            this.store.dispatch(action.action());
          }

      }
    }

    return of('noop');
  }

}
