/**
 * src/app/modules/authentication/state/authentication.effects.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthenticationService} from '../services/authentication.service';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Socket} from 'ngx-socket-io';
import {
  configurationListSetAction,
  configurationListUpdateAction,
  loginAction,
  loginFailureAction,
  loginSetOrderAction, loginSetUuidAction,
  loginSuccessAction,
  logoutSuccessAction
} from './authentication.actions';
import {Credentials} from '../models/credentials';
import {User} from '../../shared/models/user';
import {Role} from '../../shared/models/roles.enum';
import {AppState} from '../../../app.module';
import {changeHeaderAction} from '../../shared/state/shared.actions';
import {Store} from '@ngrx/store';
import {userOrganizationEditSubjectChangeAction} from '../../organizations/state/organizations.actions';
import {orderResetAction} from '../../orders/state/orders.actions';
import {ToastrService} from 'ngx-toastr';
import {DashboardConfiguration} from "../../user-space-admin/models/dashboardConfiguration";
import {dashboardConfigurationSetAction} from "../../user-space-admin/state/userSpaceAdmin.actions";
import {DashboardService} from "../../user-space-admin/services/dashboard.service";

@Injectable()
export class AuthenticationEffects {

  constructor(
    private socket: Socket,
    private actions: Actions,
    private authenticationService: AuthenticationService,
    private router: Router,
    private toastrService: ToastrService,
    private dashboardService: DashboardService,
    private store: Store<AppState>
  ) {}

  login$ = createEffect(() =>
    this.actions.pipe(
      ofType(loginAction.type),
      switchMap((action: { credentials: Credentials, orderId: number, uuid: string }) => {
          return this.authenticationService.login(action.credentials.username, action.credentials.password, action.credentials.remember_me, action.uuid).pipe(
            switchMap(user => [
              loginSetOrderAction({ orderId: action.orderId}),
              loginSetUuidAction({ uuid: action.uuid}),
              loginSuccessAction(user),
            ]),
            catchError(error =>
              of(loginFailureAction(error)))
          );
        }
      )
    )
  );

  loginFailure$ = createEffect(() =>
    this.actions.pipe(
      ofType(loginFailureAction),
      tap((err) => {
        if (err.error?.email) {
          this.toastrService.error(err.error.message, '', {
            enableHtml: true,
            timeOut: 10000,
          });
        } else {
          this.toastrService.error(err.error.message);
        }
      })
    ), {dispatch: false}
  );

  loginSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(loginSuccessAction),
      tap((u: User) => {

        this.store.dispatch(orderResetAction());

        // update organization.
        this.store.dispatch(userOrganizationEditSubjectChangeAction(u.organizations));

        if (u.role.indexOf(Role.SystemAdmin) !== -1) {
          this.store.dispatch(changeHeaderAction({header: 'admin'}));
          this.router.navigate(['/system-admin/dashboard']);
        } else {
          this.store.dispatch(changeHeaderAction({header: 'user'}));
          this.router.navigate(['/dashboard']);
        }

        this.socket.disconnect();
        this.socket.connect();
      })
      ), {dispatch: false}
  );

  logoutSuccess$ = createEffect(() =>
    this.actions.pipe(
      ofType(logoutSuccessAction),
      tap(() => {
        this.router.navigate(['/login']);
        this.socket.disconnect();
      })
    ), {dispatch: false}
  );

  updateDashboardConfigurations$ = createEffect(() =>
    this.actions.pipe(
      ofType(configurationListUpdateAction),
      switchMap(() => {
        return this.dashboardService.getCollection().pipe(switchMap(res => {
          return of(configurationListSetAction({configurations: res}));
        }), catchError(e => {
          this.toastrService.error('There was a server error retrieving templates');
          return of(configurationListSetAction({configurations: []}));
        }));
      })
    ), {dispatch: true}
  );


}
