/**
 * Based on order and organization, determine if you already own the order you are viewing
 *
 * src/app/modules/orders/pipes/does-order-belong-to-organization.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 07/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import {Orders} from '../../models/orders';
import {Organizations} from '../../../organizations/models/organizations';

@Pipe({
  name: 'doesOrderBelongToOrganization'
})
export class DoesOrderBelongToOrganizationPipe implements PipeTransform {
  transform(order: Orders, organization: Organizations): boolean {
    if (!order || !organization) {
      return false;
    }
    return order.organization?.id && organization.id && order.organization.id === organization.id;
  }
}
