/**
 * src/app/modules/orders/services/default-plan.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 7/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Plans} from "../../organizations/models/plans";

@Injectable({
  providedIn: 'root'
})
export class DefaultPlanService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get a single organization in the vendor portal.
   */
  public getDefaultPlan(): Observable<Plans> {
    return this.httpClient.get<Plans>(`/api/plans/default`);
  }

}
