<div class="container">
  <form class="form-holder" [formGroup]="vendorForm" autocomplete="nope">
    <div class="page-info  page-info--dashboard">
      <h2 class="page-info__title"><a [routerLink]="['/system-admin', 'organizations']">Organizations</a> / Edit Organization</h2>

    </div>

    <div class="content-area">

      <ng-container *ngIf="organization | async as $org">
      <div class="details-header" [class]="{ 'details-header--logo': false }">
        <div class="form-group avatar-container">
          <div class="form-control" kendoTooltip filter=".tooltip-enabled" position="bottom">
            <span *ngIf="vendorForm.get('logo_url')?.value?.length > 0" class="material-icons remove-attachment-icon tooltip-enabled"
                  title="Your avatar when you chat with your customers" (click)="removeLogo('logo_url')">delete</span>
            <label *ngIf="vendorForm" class="details-header__logo-container tooltip-enabled" title="Your avatar when you chat with your customers">
              <ng-container [ngSwitch]="vendorForm.get('logo_url')?.value?.length > 0">
                <input type="file" (change)="onLogoUpload($event)" accept="image/png, image/jpeg">
                <kendo-avatar *ngSwitchCase="true"
                              [imageSrc]="vendorForm.get('logo_url')?.value"
                              class="kendo-avatar">
                </kendo-avatar>
                <ng-container *ngSwitchDefault>
                  <div *ngIf="logoUploading" class="k-icon k-i-loading k-icon-lg"></div>
                  <div *ngIf="!logoUploading" class="details-header__logo-placeholder">
                    <i class="material-icons md-24">add</i>
                  </div>
                </ng-container>
              </ng-container>
            </label>
          </div>
        </div>

<!--      <label class="details-header__logo-container">-->
<!--        <input type="file" (change)="onFileUpload($event)" accept="image/png, image/jpeg">-->

<!--        <ng-container [ngSwitch]="$org.logo_url && $org.logo_url.length > 0">-->

<!--          <kendo-avatar *ngSwitchCase="true"-->
<!--                        width="100px"-->
<!--                        height="100px"-->
<!--                        shape="rounded"-->
<!--                        [imageSrc]="f.get('logo_url').value || $org.logo_url"-->
<!--                        class="org-logo">-->
<!--          </kendo-avatar>-->

<!--          <div class="details-header__logo-placeholder" *ngSwitchDefault>-->
<!--            <div class="plus">+</div>-->
<!--            <div>Upload logo</div>-->
<!--          </div>-->

<!--        </ng-container>-->

<!--      </label>-->

          <div class="details-header__text">

            <ng-container *ngIf="organization | async as $org">

              <ng-container [ngSwitch]="$org.name && $org.name.length > 0">
                <h1 *ngSwitchCase="true">{{ $org.name }}</h1>
                <h1 class="no-name" *ngSwitchDefault>New Organization</h1>
              </ng-container>

            </ng-container>

      </div>

      <div class="details-header__active-switch">
        <kendo-label text="Active"></kendo-label>
        <kendo-switch
                      formControlName="active"
                      onLabel="Yes"
                      offLabel="No"></kendo-switch>
      </div>
    </div>
      <kendo-tabstrip (tabSelect)="onTab($event)">
      <kendo-tabstrip-tab [title]="'Details'" [selected]="(organizationEdit | async).tab === 'details' || !(hasOrganization | async)" (click)="onTab('details')">
        <ng-template kendoTabContent>

            <div class="content-area  content-area--edit-details">

              <div class="form-groups-container">
                <div class="layout layout--stretch">
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <div class="form-group">
                      <h2 class="form-group-header">General Info
                        <!--          <div *ngIf="!vendor" class="k-icon k-i-check-circle" [class.success]="formGroupsState.generalInfoGroupValid">-->
                        <!--          </div>-->
                      </h2>
                      <div class="form-control">
                        <kendo-label for="vendor-name" text="Organization Name"></kendo-label>
                        <input kendoTextBox id="vendor-name" type="text" formControlName="name" autocomplete="nope">
                        <!--          <p *ngIf="formErrors.vendor_name" class="error-message">{{formErrors.vendor_name}}</p>-->
                      </div>
                      <div class="form-control">
                        <kendo-label for="contact-first-name" text="Contact First Name"></kendo-label>
                        <input kendoTextBox id="contact-first-name" type="text" formControlName="contact_first_name" autocomplete="nope">
                        <!--          <p *ngIf="formErrors.contact_first_name" class="error-message">{{formErrors.contact_first_name}}</p>-->
                      </div>
                      <div class="form-control">
                        <kendo-label for="contact-last-name" text="Contact Last Name"></kendo-label>
                        <input kendoTextBox id="contact-last-name" type="text" formControlName="contact_last_name" autocomplete="nope">
                        <!--          <p *ngIf="formErrors.contact_last_name" class="error-message">{{formErrors.contact_last_name}}</p>-->
                      </div>
                    </div>
                  </div>
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <ng-container formGroupName="primaryLocation">
                      <div class="form-group">
                        <h2 class="form-group-header">
                          Address
                          <!--          <div *ngIf="!vendor" class="k-icon k-i-check-circle" [class.success]="formGroupsState.addressGroupValid"></div>-->
                        </h2>

                        <div class="form-control">
                          <kendo-label for="address" text="Street"></kendo-label>
                          <input ngx-google-places-autocomplete name="address"
                            class="k-textbox"
                            autocomplete="nope"
                            formControlName="address"
                            placeholder="Street"
                            (onAddressChange)="handleAddressChange($event)">
                        </div>

                        <div class="layout">
                          <div class="layout__item u-1/2">
                            <div class="form-control">
                              <kendo-label for="city" text="City"></kendo-label>
                              <input kendoTextBox id="city" type="text" formControlName="city" autocomplete="nope">
                              <!--          <p *ngIf="formErrors.city" class="error-message">{{formErrors.city}}</p>-->
                            </div>
                          </div>
                          <div class="layout__item u-1/2">
                            <div class="form-control">
                              <kendo-label for="state" text="State/Province"></kendo-label>
                              <input kendoTextBox id="state" type="text" formControlName="province" autocomplete="nope">
                              <!--            <p *ngIf="formErrors.state" class="error-message">{{formErrors.state}}</p>-->
                            </div>
                          </div>
                        </div>

                        <div class="layout">
                          <div class="layout__item u-1/2">
                            <div class="form-control">
                              <kendo-label for="post-code" text="Zip/Postcode"></kendo-label>
                              <input kendoTextBox id="post-code" type="text" formControlName="postal_code" autocomplete="nope">
                              <!--            <p *ngIf="formErrors.post_code" class="error-message">{{formErrors.post_code}}</p>-->
                            </div>
                          </div>
                          <div class="layout__item u-1/2">
                            <div class="form-control">
                              <kendo-label for="country" text="Country"></kendo-label>
                              <input kendoTextBox id="country" type="text" formControlName="country" autocomplete="nope">
                              <!--          <p *ngIf="formErrors.country" class="error-message">{{formErrors.country}}</p>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <div class="form-group">
                      <h2 class="form-group-header">Contact
                        <!--          <div *ngIf="!vendor" class="k-icon k-i-check-circle" [class.success]="formGroupsState.contactGroupValid"></div>-->
                      </h2>

                      <div class="form-control">
                        <kendo-label for="web-site" text="Website"></kendo-label>
                        <input kendoTextBox id="web-site" type="text" formControlName="contact_website" autocomplete="nope"
                          pattern="([hH][tT][tT][pP][sS]?:\/\/)?[\w-]+(\.[\w-]+)+(\/([^\/]+\/?)*)?"
                          placeholder="Website">
                      </div>

                      <kendo-formfield class="form-control">
                        <kendo-label for="email" text="Email"></kendo-label>
                        <input kendoTextBox id="email" type="email" formControlName="contact_email" autocomplete="nope">
                        <kendo-formerror *ngIf="vendorForm.controls.contact_email.errors?.error">
                          {{vendorForm.controls.contact_email.errors?.error}}
                        </kendo-formerror>
                      </kendo-formfield>

                      <div class="form-control">
                        <kendo-label for="phone" [optional]="false" text="Phone"></kendo-label>
                        <input kendoTextBox id="phone" type="text" formControlName="contact_number" autocomplete="nope" [mask]="mask">

                      </div>
                    </div>
                  </div>
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <div class="form-group">
                      <h2 class="form-group-header">Additional Info
                        <!--          <div *ngIf="!vendor" class="k-icon k-i-check-circle" [class.success]="formGroupsState.additionalInfoGroupValid">-->
                        <!--          </div>-->
                      </h2>

                      <div class="form-control">
                        <kendo-label for="web-site" text="Organization Type"></kendo-label>
                        <ul class="k-radio-list">
                          <li class="k-radio-item">
                            <input name="kind" type="radio" #kindVendor kendoRadioButton formControlName="kind" value="vendor">
                            <kendo-label class="k-radio-label" [for]="kindVendor" text="Vendor"></kendo-label>
                          </li>
                          <li class="k-radio-item">
                            <input name="kind" type="radio" #kindManufacturer kendoRadioButton formControlName="kind" value="manufacturer">
                            <kendo-label class="k-radio-label" [for]="kindManufacturer" text="Manufacturer"></kendo-label>
                          </li>
                          <li class="k-radio-item">
                            <input name="kind" type="radio" #kindShowroom kendoRadioButton formControlName="kind" value="showroom">
                            <kendo-label class="k-radio-label" [for]="kindShowroom" text="Showroom"></kendo-label>
                          </li>
                        </ul>
                      </div>

                      <div class="form-control">
                        <kendo-label for="plan-dropdown" text="Plan"></kendo-label>
                        <kendo-dropdownlist
                          id="plan-dropdown"
                          *ngIf="(plans | async)"
                          [data]="plans | async | removeSelectedItem:selectedPlan:'name'"
                          [textField]="'name'"
                          [valueField]="'id'"
                          [defaultItem]="selectedPlan"
                          (selectionChange)="onPlanChanged($event)"
                          formControlName="plan">
                        </kendo-dropdownlist>
                      </div>

                    </div>

                  </div>
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <div class="form-group">
                      <h2 class="form-group-header">Directory Cover Photo</h2>
                      <div class="form-control" kendoTooltip filter=".tooltip-enabled" position="bottom">
                        <span *ngIf="vendorForm.get('cover_photo_url')?.value?.length > 0" class="material-icons remove-attachment-icon" (click)="removeLogo('cover_photo_url')">cancel</span>
                        <label *ngIf="vendorForm" class="details-header__logo-container tooltip-enabled" title="Your cover photo on the Studio Designer Vendor Directory">
                          <ng-container [ngSwitch]="vendorForm.get('cover_photo_url')?.value?.length > 0">
                            <input type="file" (change)="onCoverPhotoUpload($event)" accept="image/png, image/jpeg">
                            <kendo-avatar *ngSwitchCase="true"
                                          width="170px"
                                          height="120px"
                                          [imageSrc]="vendorForm.get('cover_photo_url')?.value"
                                          class="kendo-avatar">
                            </kendo-avatar>
                            <div class="details-header__logo-placeholder cover_photo-placeholder" *ngSwitchDefault>
                              <div *ngIf="coverPhotoUploading" class="k-icon k-i-loading k-icon-xl"></div>
                              <ng-container *ngIf="!coverPhotoUploading">
                                <div class="plus">+</div>
                                <div>Upload Cover Photo</div>
                              </ng-container>
                            </div>
                          </ng-container>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


    </ng-template>
  </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [disabled]="!(hasOrganization | async)" [title]="'Logins'" [selected]="(organizationEdit | async).tab === 'logins' && (hasOrganization | async)">
        <ng-template kendoTabContent>
          <div class="content-area  content-area--org-details">

            <div class="grid-controls">
              <div class="item-actions">
                <button kendoButton [routerLink]="['users', 'new']">Add New Login</button>
              </div>
            </div>

            <kendo-grid [data]="$org.users"
                      [resizable]="true"
                      scrollable="none"
          >

              <kendo-grid-column field="logo" title="" width="60"
                                [filterable]="false" [sortable]="false">
                <ng-template kendoGridCellTemplate let-user>
                  <kendo-avatar [shape]="'circle'"
                                [initials]="user.first_name"></kendo-avatar>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="first_name" title="First Name" resizable="false">
              <ng-template kendoGridCellTemplate let-user>
                {{user.first_name}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="last_name" title="Last Name" resizable="false">
              <ng-template kendoGridCellTemplate let-user>
                {{user.last_name}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="email" title="Email" resizable="false">
              <ng-template kendoGridCellTemplate let-user>
                {{user.email}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="status" title="Status" resizable="false">
              <ng-template kendoGridCellTemplate let-user>
                <kendo-badge-container>
                  <kendo-badge [themeColor]="(user.status.indexOf('active') !== -1) ? 'success' : 'light'"
                              [shape]="'rectangle'"
                              [size]="'medium'"
                              [position]="'inside'"
                              [align]="{ horizontal: 'start', vertical: 'top' }">
                    {{(user.status.indexOf('active') !== -1) ? 'ACTIVE' : 'INACTIVE'}}
                  </kendo-badge>
                </kendo-badge-container>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Actions" width="170"
                              [minResizableWidth]="170"
                              [filterable]="'false'" [sortable]="false"
                              class="k-grid-column--center">
              <ng-template kendoGridCellTemplate let-user>
                <kendo-buttongroup>
                  <button kendoButton [primary]="true" class="table-actions-btn"
                          (click)="loginAs(user.id)">Login as</button>
                  <button kendoButton [primary]="true" class="table-actions-btn"
                          [routerLink]="['/system-admin', 'organizations', (organization | async).id, 'users', user.id]">Edit</button>
                </kendo-buttongroup>

              </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
              <div class="content-area  content-area--no-content">
                <p>This organization has no logins yet. Why not create one?</p>
                <button kendoButton
                        primary="true" [routerLink]="['users', 'new']">Add New Login</button>
              </div>
            </ng-template>

          </kendo-grid>
          </div>
        </ng-template>

      </kendo-tabstrip-tab>
    </kendo-tabstrip>
        <div class="form-buttons">
          <div class="layout">
            <div class="layout__item  u-1/2">
              <button kendoButton type="button" (click)="onCancel()">
                Cancel
              </button>
            </div>
            <div class="layout__item  u-1/2  layout--right">
              <button kendoButton [primary]="true" (click)="onFormSubmit()">
                <span *ngIf="loading" class="k-i-loading"></span>
                Save Organization
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
