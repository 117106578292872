<section class="header__section header__section--user-actions">
  <div class="notifications" #notifications
       (click)="toggleDropdown()">
    <div class="icon"></div>
    <kendo-badge-container>
      <kendo-badge *ngIf="(unreadCount | async) > 0"
        [position]="'edge'" [themeColor]="'success'"
        [align]="{ vertical: 'top', horizontal: 'end' }"
        [shape]="'circle'"
      >
        {{ unreadCount | async }}
      </kendo-badge>
    </kendo-badge-container>
  </div>
  <!-- start notifications dropdown -->
  <kendo-popup [anchor]="notifications" *ngIf="showDropdown" class="notifications-list">
    <ng-container *ngIf="chatMessages | async as messages">
      <kendo-listview [data]="messages" [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'">
        <ng-template kendoListViewHeaderTemplate>
          <div class="notifications-list__header">Notifications</div>
        </ng-template>
        <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
          <div class="notifications-list__notification">
            <kendo-avatar
              [shape]="'circle'"
              [width]="40"
              [height]="40"
              [imageSrc]="dataItem.order.designer.logo"
            >
            </kendo-avatar>
            <div class="notification__details">
              <div class="fn">{{ dataItem.order.designer.name }}</div>
              <div class="date">{{ dataItem.created_at | date: 'MM/dd/yyyy' }}</div>
              <div *ngIf="dataItem.is_media" [title]="dataItem.message | extractFileName" class="message">{{ dataItem.message | extractFileName | truncateFileName:38: 60}}</div>
              <div *ngIf="!dataItem.is_media" [title]="dataItem.message" class="message">{{ (dataItem.alternate_message || dataItem.message) | truncateFileName:38:60 }}</div>
            </div>
            <div class="notification__status">
              <kendo-badge-container>
                <kendo-badge [position]="'inside'"
                             [fill]="'solid'"
                             [shape]="'rectangle'"
                             [size]="'large'"
                             [themeColor]="'success'"
                             [align]="{ vertical: 'top', horizontal: 'end' }">
                  {{ dataItem.status }}
                </kendo-badge>
              </kendo-badge-container>
            </div>
            <div class="notification__view" *ngIf="(organization | async)?.plan?.order_manager">
              <a class="action-grid-btn k-button k-button-primary" kendoButton
                 (click)="viewOrder(dataItem)">View</a>
            </div>
          </div>
        </ng-template>
        <ng-template kendoListViewFooterTemplate>
          <div class="notifications-list__footer">
          <div class="item-actions">
            <button kendoButton (click)="markAllAsRead()">Mark all as read</button>
            <button kendoButton [primary]="true" (click)="viewAllNotifications()">View All Notifications</button>
          </div>
          </div>
        </ng-template>
      </kendo-listview>
    </ng-container>
  </kendo-popup>
  <!-- end notifications dropdown -->
</section>
