/**
 * src/app/modules/orders/services/image.service.ts
 *
 * @author Aaron Romero <aaron@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2021
 * @since 03/29
 *
 */

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UploadResult} from '../models/upload-result';


@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private httpClient: HttpClient) { }

  getFile(url: string): Observable<Blob> {
    return this.httpClient.get(url, {responseType: 'blob'});
  }

  uploadFile(file: File, orderId?: number): Observable<UploadResult> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<UploadResult>(`/api/files${orderId ? '?orderId=' + orderId : ''}`, formData);
  }

}
