/**
 * src/modules/shared/pipes/remove-readonly.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 9/21
 */
import {Pipe, PipeTransform} from '@angular/core';
import {FiltersInterface} from "../models/filters.interface";

@Pipe({
  name: 'removeReadonly'
})
export class RemoveReadonlyPipe implements PipeTransform {

  transform(v: any): any {
    if (!v) {
      return v;
    }
    return Object.assign({}, v);
  }

}
