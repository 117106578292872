/**
 * src/app/modules/orders/pipes/item-total.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 01/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';

@Pipe({
  name: 'itemTotal'
})
export class ItemTotalPipe implements PipeTransform {
  transform(price: string | currency, quantity: string | currency): currency {

    price = currency(price, {precision: 4});
    quantity = currency(quantity, {precision: 4});

    return currency(price.multiply(quantity), {precision: 2});

  }
}
