<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp">
    <div class="paper">

      <div class="sign-body">
        <form class="left-block form-holder u-1/2@sm u-1/1" [formGroup]="vendorForm">

          <h1 class="mb-5">Sign Up</h1>

          <ng-container>
            <div class="mb-5" [style.display]="signUpPage === 1 ? 'block' : 'none'">
              <kendo-formfield class="custom-formfield mb-4">
                <label for="email">Email</label>
                <input kendoTextBox id="email" type="email" formControlName="contact_email" autocomplete="email"
                       placeholder="Email" [pattern]="emailValidationPattern" required>
                <kendo-formerror *ngIf="vendorForm.controls.contact_email.errors?.required">
                  Email is required
                </kendo-formerror>
                <kendo-formerror *ngIf="vendorForm.controls.contact_email.errors?.emailExists">
                  Email already in use
                </kendo-formerror>
              </kendo-formfield>

              <kendo-formfield class="custom-formfield mb-4">
                <label for="vendor-name">Organization Name</label>
                <input kendoTextBox id="vendor-name" type="text" formControlName="name" autocomplete="organization"
                  placeholder="Your organization name">
                <kendo-formerror *ngIf="vendorForm.controls.name.errors?.nameAlreadyExists">
                  Organization Name already in use
                </kendo-formerror>
              </kendo-formfield>

              <div class="custom mb-4">
                <label for="contact-first-name">Contact First Name</label>
                <input kendoTextBox id="contact-first-name" type="text" formControlName="contact_first_name"
                  placeholder="Your first name" autocomplete="nope">
              </div>
              <div class="custom mb-4">
                <label for="contact-last-name">Contact Last Name</label>
                <input kendoTextBox id="contact-last-name" type="text" formControlName="contact_last_name"
                  autocomplete="nope" placeholder="Your last name">
              </div>
            </div>
          </ng-container>

          <ng-container formGroupName="primaryLocation">
            <div class="mb-5" [style.display]="signUpPage === 2 ? 'block' : 'none'">

              <div class="custom mb-4">
                <label for="address">Address</label>
                <input ngx-google-places-autocomplete name="address"
                  class="k-textbox"
                  autocomplete="nope"
                  formControlName="address"
                  placeholder="Street"
                  (onAddressChange)="handleAddressChange($event)">
              </div>

              <div class="layout mb-4">
                <div class="layout__item u-1/2">
                  <div class="custom">
                    <label for="city">City</label>
                    <input kendoTextBox id="city" type="text" formControlName="city" autocomplete="nope"
                      placeholder="City">
                  </div>
                </div>
                <div class="layout__item u-1/2">
                  <div class="custom">
                    <label for="state">State/Province</label>
                    <input kendoTextBox id="state" type="text" formControlName="province" autocomplete="nope"
                      placeholder="State/Province">
                  </div>
                </div>
              </div>

              <div class="layout mb-4">
                <div class="layout__item u-1/2">
                  <div class="custom">
                    <label for="post-code">Zip/Postcode</label>
                    <input kendoTextBox id="post-code" type="text" formControlName="postal_code" autocomplete="nope"
                      placeholder="Zip/Postcode">
                  </div>
                </div>
                <div class="layout__item u-1/2">
                  <div class="custom">
                    <label for="country">Country</label>
                    <input kendoTextBox id="country" type="text" formControlName="country" autocomplete="nope"
                      placeholder="Country">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


          <ng-container>
            <div class="mb-5" [style.display]="signUpPage === 3 ? 'block' : 'none'">
              <div class="custom mb-4">
                <label for="web-site">Website</label>
                <input kendoTextBox id="web-site" type="text" formControlName="contact_website" autocomplete="nope"
                  pattern="([hH][tT][tT][pP][sS]?:\/\/)?[\w-]+(\.[\w-]+)+(\/([^\/]+\/?)*)?"
                  placeholder="Website">
              </div>

              <div class="custom mb-4">
                <label for="phone">Phone</label>
                <input kendoTextBox id="phone" type="tel" formControlName="contact_number" autocomplete="off"
                  [mask]="mask"
                  [pattern]="phoneValidationPattern">
              </div>

              <div class="custom mb-4">
                <label for="password">Password</label>

                <kendo-textbox #password kendoTextBox id="password" formControlName="password" autocomplete="off"
                  placeholder="Password" [pattern]="passwordValidationPattern">
                  <ng-template kendoTextBoxSuffixTemplate>
                    <button kendoButton look="clear" [icon]="'eye'" [type]="'button'"
                      (mousedown)="togglePass(true)"
                      (mouseup)="togglePass(false)"
                      (mouseleave)="togglePass(false)">
                  </button>
                  </ng-template>
                </kendo-textbox>
                <p *ngIf="f.get('password').errors?.pattern" class="error-message">Password must have at least 8
                  characters, 1
                  uppercase letter and 1 number.</p>
              </div>
            </div>
          </ng-container>


          <div class="btns-row custom">
            <ng-container *ngIf="signUpPage === 1; else backButton"><button routerLink="/login" kendoButton
                class="btn btn-secondary">Back</button></ng-container>

            <ng-template #backButton><button kendoButton class="btn btn-secondary"
                (click)="previousPage()">Back</button></ng-template>

            <ng-container *ngIf="signUpPage === 3; else nextButton"><button kendoButton [primary]="true"
                class="btn btn-primary" [disabled]="vendorForm.invalid" (click)="onSubmit()">Next</button>
            </ng-container>

            <ng-template #nextButton><button [disabled]="isNextButtonDisabled()" kendoButton [primary]="true"
                class="btn btn-primary" (click)="nextPage()">Next</button></ng-template>

          </div>

        </form>

        <div class="w-100 d-flex flex-column right-block layout__item u-1/2">
          <h5 class="right-block__header">Welcome to The Studio Designer Vendor Portal!</h5>
          <div class="body-1">
            The fast and easy way to collaborate with designers on orders, gain insights, and discover new partners.</div>
        </div>
      </div>
      <div class="sign-footer">
        <div class="sign-footer__circle filled" [ngClass]="{'active': signUpPage === 1}"></div>
        <div class="sign-footer__line" [ngClass]="{'filled': signUpPage > 1}"></div>
        <div class="sign-footer__circle" [ngClass]="{'active': signUpPage === 2, 'filled': signUpPage > 1}"></div>
        <div class="sign-footer__line" [ngClass]="{'filled': signUpPage > 2}"></div>
        <div class="sign-footer__circle" [ngClass]="{'active': signUpPage === 3, 'filled': signUpPage > 2}"></div>
      </div>


    </div>
  </div>


</div>
