import { Pipe, PipeTransform } from '@angular/core';
import {AsYouType, CountryCode} from 'libphonenumber-js';

@Pipe({ name: 'phoneformat' })
export class PhoneFormatPipe implements PipeTransform {


  transform(value: string, countryCode: string) {
    if (!value) {
      return;
    }

    if (value.length >= 9 && value.slice(0, 1) === '1') {
      return value;
    }

    let asYouType: AsYouType;
    asYouType = new AsYouType(countryCode as CountryCode);

    return asYouType.input(value);
  }
}
