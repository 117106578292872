import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFileName'
})
export class TruncateFilenamePipe implements PipeTransform {
  transform(name: string, args: number, textLength?: number): string {
    if (!name) {
      return name;
    }
    const extension: string =
      name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
    let newName: string = name.replace('.' + extension, '');
    // If the length of fileName is less than the arg value, then return fileName unchanged
    if (name.length <= args) {
      return name;
    }
    // if not a file, truncate text if necessary
    if (!(name.includes(`.${extension}`))) {
      if (name.length > textLength) {
        name = name.substring(0, textLength) + '...';
        return name;
      } else {
        return name;
      }
    }
    newName = newName.substring(0, 15) + (name.length > args ? '...' : '');
    return newName + '.' + extension;
  }
}
