<div class="container">
  <div class="page-info  page-info--list">
    <h2 class="page-info__title">Plans ({{statistics.total}})</h2>

    <div class="page-info__data" *ngIf="!isLoadingPlans && statistics.total>0">
      <button kendoButton
              primary="true"
              routerLink="new">Add New Plan</button>
    </div>
  </div>

  <div *ngIf="isLoadingPlans" class="k-loading-image"></div>

  <div *ngIf="!isLoadingPlans" class="content-area">

    <div class="grid-content">
        <kendo-grid [loading]="isLoadingGridData"
                    [data]="gridData"
                    [resizable]="true"
                    [sortable]="sortableSettings"
                    scrollable="none"
                    [filter]="pagerState.filter"
                    [pageable]="true"
                    [sort]="pagerState.sort"
                    [pageSize]="pagerState.take"
                    [skip]="pagerState.skip"
                    reorderable="true"
                    [columnMenu]="{ filter: false }"
                    (dataStateChange)="dataStateChange($event)"
                    >

          <kendo-grid-column field="name" title="Name">
            <ng-template kendoGridCellTemplate let-plan>
              {{plan.name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Actions" width="120"
                            [minResizableWidth]="120"
                            [filterable]="'false'" [sortable]="false"
                            class="k-grid-column--center">
            <ng-template kendoGridCellTemplate let-plan>

              <kendo-buttongroup>
                <button kendoButton [routerLink]="[plan.id]" [primary]="true">Edit</button>
              </kendo-buttongroup>

            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
          </ng-template>

          <ng-template kendoGridNoRecordsTemplate>
            <div class="content-area  content-area--no-content">
              <p>No Plans. Why not create one?</p>
              <button kendoButton
                      primary="true"
                      (click)="addPlan()">Add New Plan</button>
            </div>
          </ng-template>
        </kendo-grid>
      </div>
  </div>

</div>
