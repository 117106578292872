/**
 * src/app/modules/authentication/services/is-logged-in-guard.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.module";
import {selectAuthenticatedUser, selectIsAuthenticated} from "../state/authentication.selector";
import {Observable, of} from "rxjs";
import {take} from "rxjs/operators";
import {Role} from "../../shared/models/roles.enum";
import {changeHeaderAction} from "../../shared/state/shared.actions";

@Injectable()
export class IsLoggedInGuardService implements CanActivate {

  constructor(private store: Store<AppState>, public router: Router) {
  }

  canActivate(): Observable<boolean> {
    this.store.select(selectIsAuthenticated).pipe(take(1)).subscribe(v => {
      if (v === true) {
        this.store.select(selectAuthenticatedUser).subscribe(u => {
          if (!u) {
            return of(true);
          }
          if (u.role.indexOf(Role.SystemAdmin) !== -1) {
            this.store.dispatch(changeHeaderAction({header: 'admin'}));
            this.router.navigate(['/system-admin/dashboard']);
          } else {
            this.store.dispatch(changeHeaderAction({header: 'user'}));
            this.router.navigate(['/dashboard']);
          }
        });
      }
    });
    return of(true);

  }

}
