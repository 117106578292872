/**
 * src/modules/shared/pipes/graph-quantity.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 02/17/2020
 */
import {Pipe, PipeTransform} from '@angular/core';
import {OrdersSummary} from '../../orders/models/orders-summary';
import * as currency from 'currency.js';

@Pipe({
  name: 'graphQuantity'
})
export class GraphQuantityPipe implements PipeTransform {

  transform(summary: OrdersSummary[]): currency[] {
    if (!summary) {
      return [];
    }
    const output: currency[] = [];
    for (const o of summary) {
      output.push(o.quantity);
    }
    return output;
  }

}
