import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './modules/authentication/components/login/login.component';
import {ForgotPasswordComponent} from './modules/authentication/components/forgot-password/forgot-password.component';
import {SetPasswordComponent} from './modules/authentication/components/set-password/set-password.component';
import {SignUpComponent} from './modules/authentication/components/sign-up/sign-up.component';
import {IsLoggedInGuardService} from './modules/authentication/services/is-logged-in-guard.service';
import {VerifyEmailComponent} from './modules/authentication/components/verify-email/verify-email.component';
import {IsAuthHeaderGuardService} from './modules/authentication/services/is-auth-header-guard.service';
import {ResendVerificationEmailComponent} from './modules/authentication/components/resend-verification-email/resend-verification-email.component';
import {SignUpSuccessComponent} from './modules/authentication/components/sign-up-success/sign-up-success.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login' ,
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: ForgotPasswordComponent
  },
  {
    path: 'set-password',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: SetPasswordComponent
  },
  {
    path: 'sign-up',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: SignUpComponent
  },
  {
    path: 'sign-up/success/:uuid',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: SignUpSuccessComponent,
  },
  {
    path: 'verify-email/:uuid',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: VerifyEmailComponent
  },
  {
    path: 'resend-verify-email/:uuid',
    canActivate: [IsLoggedInGuardService, IsAuthHeaderGuardService],
    component: ResendVerificationEmailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
