/**
 * src/app/modules/organizations/state/organizations.actions.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {HttpErrorResponse} from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import {Organizations} from '../models/organizations';
import {User} from '../../shared/models/user';

export const userOrganizationEditTabChangeAction = createAction('[User Organization] Profile Tab Change', props<{ tab: string }>());
export const userOrganizationEditSubjectGetAction = createAction('[User Organization] Profile Subject Get');
export const userOrganizationEditSubjectUpdateAction = createAction('[User Organization] Profile Subject Update', props<Organizations>());
export const userOrganizationEditSubjectChangeAction = createAction('[User Organization] Profile Subject Change', props<Organizations>());
export const userOrganizationEditSubjectGetFailedAction =
  createAction('[User Organization] Profile Subject Get Failed', props<{ error: HttpErrorResponse }>());
export const userOrganizationEditSubjectUpdateFailedAction =
  createAction('[User Organization] Profile Subject Update Failed', props<{ error: HttpErrorResponse }>());

export const userOrganizationEditLogoUrlSubjectUpdateAction = createAction('[User Organization] Profile Logo Url Subject Update', props<Organizations>());
export const userOrganizationEditLogoUrlSubjectUpdateFailedAction = createAction('[User Logins] Profile Logo Url Subject Update Failed', props<any>());

export const userLoginEditSubjectGetAction = createAction('[User Logins] Login Subject Get', props<{id: number}>());
export const userLoginEditSubjectUpdateAction = createAction('[User Logins] Login Subject Update', props<User>());
export const userLoginEditSubjectChangeAction = createAction('[User Logins] Login Subject Change', props<User>());
export const userLoginEditSubjectUpdateFailedAction = createAction('[User Logins] Login Subject Update Failed', props<any>());

//    Organizations
export const countOrganizationGetAction = createAction('[Count] Organization Count Subject Get');
export const countOrganizationSubjectChangeAction = createAction('[Count] Organization Count Subject Change', props<{ organizationCount: number }>());

//    Active Organizations
export const countActiveOrganizationGetAction = createAction('[Count] Active Organization Count Subject Get');
export const countActiveOrganizationSubjectChangeAction = createAction('[Count] Active Organization Count Subject Change', props<{ activeOrganizationCount: number }>());

//    Inactive Organizations
export const countInactiveOrganizationGetAction = createAction('[Count] Inactive Organization Count Subject Get');
export const countInactiveOrganizationSubjectChangeAction = createAction('[Count] Inactive Organization Count Subject Change', props<{ inactiveOrganizationCount: number }>());

//    Users
export const countUserGetAction = createAction('[Count] User Count Subject Get');
export const countUserSubjectChangeAction = createAction('[Count] User Count Subject Change', props<{ userCount: number }>());
