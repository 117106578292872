/**
 * src/app/modules/shared/directives/autofocus.directive.ts
 *
 * @author Stanislav Shamrai <stanislav@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 08/03/2021
 */

import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[autofocus]'
})

export class AutofocusDirective implements AfterViewInit {

  private _autofocus: boolean;

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      if (this._autofocus || typeof this._autofocus === 'undefined') {
        this.el.nativeElement.focus();
      }
    });
  }
  @Input() set autofocus(condition: boolean) {
    this._autofocus = condition !== false;
  }
}
