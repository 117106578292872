<div class="header-holder" *ngIf="showMenu">
  <header class="header">
    <section class="header__section">
      <div class="logo" [routerLink]="['system-admin/dashboard']"></div>
    </section>
    <section class="header__section header__section--nav">
      <nav *ngIf="isVendorActive" class="main-navigation  main-navigation--admin" role="navigation">
        <ul>
          <li class="nav-item" [routerLink]="['system-admin/dashboard']" routerLinkActive="active">
            Dashboard
          </li>
          <li class="nav-item" [routerLink]="['system-admin/organizations']" routerLinkActive="active">
            Organizations
          </li>
          <li class="nav-item" [routerLink]="['system-admin/plans']" routerLinkActive="active">
            Plans
          </li>
        </ul>
        <ul>
          <li class="nav-item" >
            <div (click)="changeHeader()" [routerLink]="['dashboard']">User View</div>
          </li>
          <li class="nav-item" (click)="logout()">
            Logout
          </li>
        </ul>
      </nav>
    </section>
  </header>
</div>
