/**
 * src/app/modules/shared/models/roles.enum.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

export enum Role {
  SystemAdmin = 'system_admin',
  VendorAdmin = 'vendor_admin',
  VendorUser = 'vendor_user',
  AdminLoginAs = 'admin_login_as',
}
