/**
 * src/app/modules/shared/models/user.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Role} from './roles.enum';
import {Status} from './statuses.enum';
import {Organizations} from '../../organizations/models/organizations';
import {DashboardConfiguration} from "../../user-space-admin/models/dashboardConfiguration";

export class User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  contact_number: string;
  timezone: string;
  pending: boolean;
  active: boolean;
  organizations: Organizations;
  role: Role[];
  status: Status[];
  dashboardConfigurations: DashboardConfiguration[];
}
