/**
 * src/app/modules/authentication/services/is-auth-header-guard.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 7/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.module";
import {Observable, of} from "rxjs";
import {changeHeaderAction} from "../../shared/state/shared.actions";
import {selectIsAuthenticated} from "../state/authentication.selector";
import {take} from "rxjs/operators";

@Injectable()
export class IsAuthHeaderGuardService implements CanActivate {

  constructor(private store: Store<AppState>) {
  }

  canActivate(): Observable<boolean> {

    this.store.select(selectIsAuthenticated).pipe(take(1)).subscribe(next => {
      if (!next) {
        this.store.dispatch(changeHeaderAction({header: ''}));
      }
    });


    return of(true);

  }

}
