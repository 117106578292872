/**
 * src/modules/users/models/phoneMasks.enum.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2021
 * @since 7/21
 *
 */

export enum PhoneMasks {
  Default = '0*',
  UsaFormatted = '(000) 000-0000'
}
