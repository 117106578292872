/**
 * src/app/modules/user-space-admin/components/dashboard/dashboard.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {Observable, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../app.module";
import {
  selectDashboardConfiguration,
  selectDashboardLoadOpen,
  selectDashboardSaveOpen,
  selectEmployeeCount,
  selectLocationCount,
} from "../../state/userSpaceAdmin.selector";
import {CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType} from "angular-gridster2";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Widget} from "../../models/widget.enum";
import {Timeframes} from "../../models/timeframes.enum";
import {DisplayEnum} from "../../models/display.enum";
import {DashboardConfiguration} from "../../models/dashboardConfiguration";
import {selectAuthenticatedUser} from "../../../authentication/state/authentication.selector";
import {filter, take} from "rxjs/operators";
import {User} from "../../../shared/models/user";
import {
  dashboardConfigurationSaveAction,
  dashboardConfigurationSetAction,
  templateLoadModalAction,
  templateSaveModalAction
} from "../../state/userSpaceAdmin.actions";

@Component({
  selector: 'vp-us-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit, OnDestroy {

  public locationCount: Observable<number>;
  public employeeCount: Observable<number>;

  public newWidgetForm: FormGroup;
  public saveForm: FormGroup;
  public loadForm: FormGroup;

  public options: GridsterConfig;
  public dashboard: Observable<DashboardConfiguration>;

  public dataOptions = [Widget.TotalValueOfOrders, Widget.AverageValueOfOrders, Widget.QuantityOfOrders];
  public timeframeOptions = [Timeframes.Lifetime, Timeframes.Year, Timeframes.Week];
  public display = [DisplayEnum.Graph, DisplayEnum.Top10];

  public templateSaveOpen: Observable<boolean>;
  public templateLoadOpen: Observable<boolean>;
  public user: Observable<User>;

  protected subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {

    this.options = {
      gridType: GridType.VerticalFixed,
      fixedRowHeight: 578,
      displayGrid: DisplayGrid.OnDragAndResize,
      pushItems: true,
      compactType: CompactType.CompactLeftAndUp,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
      setGridSize: true,
      maxCols: 3
    };

    this.dashboard = this.store.select(selectDashboardConfiguration);

    this.user = this.store.select(selectAuthenticatedUser);

    this.locationCount = this.store.select(selectLocationCount);
    this.employeeCount = this.store.select(selectEmployeeCount);

    this.newWidgetForm = this.createForm();

    this.templateSaveOpen = this.store.select(selectDashboardSaveOpen);
    this.templateLoadOpen = this.store.select(selectDashboardLoadOpen);

    this.subscriptions.push(this.templateSaveOpen.subscribe());
    this.subscriptions.push(this.templateLoadOpen.subscribe());

    this.loadForm = this.createLoadForm();

    this.subscriptions.push(this.dashboard.pipe(filter(d => !!d))
      .subscribe(d => this.saveForm = this.createSaveForm(d)));

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Create form that lets us configure a widget
   */
  public createForm(): FormGroup {
    return new FormGroup({
      widget: new FormControl(Widget.TotalValueOfOrders, [Validators.required]),
      timeframe: new FormControl(Timeframes.Lifetime, [Validators.required]),
      display: new FormControl(DisplayEnum.Graph, [Validators.required]),
    });
  }

  /**
   * Create form that lets us save a widget
   * @param config
   */
  public createSaveForm(config: DashboardConfiguration): FormGroup {
    return new FormGroup({
      name: new FormControl(config.name, [Validators.required, Validators.maxLength(255)])
    });
  }

  /**
   * Create form that lets us load a widget
   */
  public createLoadForm(): FormGroup {
    return new FormGroup({
      configuration: new FormControl(null)
    });
  }

  get s() {
    return this.saveForm;
  }

  get f() {
    return this.newWidgetForm;
  }

  /**
   * Handle when options are changed (this isnt currently configurable)
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Remove an item
   * @param $event
   * @param item
   */
  removeItem($event, item): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.dashboard.pipe(take(1)).toPromise().then(d => {
      d = Object.assign({}, d);
      d.configuration = Object.assign([], d.configuration);
      d.configuration.splice(d.configuration.indexOf(item), 1);
      this.loadConfiguration(d);
    });
  }

  /**
   * Open the load prompt
   */
  openLoadPrompt(): void {
    document.body.style.overflow = 'hidden';
    this.store.dispatch(templateLoadModalAction({isOpen: true}));
  }

  /**
   * Close the load prompt
   * @param e
   */
  closeLoadPrompt(e?: DashboardConfiguration): void {
    document.body.style.overflow = '';
    this.store.dispatch(templateLoadModalAction({isOpen: false}));
    if (e) {
      this.loadConfiguration(e);
    }
  }

  /**
   * Load a specific template
   * @param config
   */
  loadConfiguration(config: DashboardConfiguration): void {
    this.store.dispatch(dashboardConfigurationSetAction({config}));
  }

  /**
   * Open the save prompt
   */
  openSavePrompt(): void {
    document.body.style.overflow = 'hidden';
    this.store.dispatch(templateSaveModalAction({isOpen: true}));
  }

  /**
   * Close the save prompt
   * @param e
   * @param isNew
   */
  closeSavePrompt(e?: string, isNew = false): void {
    document.body.style.overflow = '';
    this.store.dispatch(templateSaveModalAction({isOpen: false}));
    if (typeof e === 'string' && e.length > 0) {
      this.dashboard.pipe(take(1)).toPromise().then(d => {
        d = Object.assign({}, d);
        d.name = e;
        if (isNew) {
          delete d.id;
        }
        this.store.dispatch(dashboardConfigurationSaveAction({config: d}));
      });
    }
  }

  /**
   * Add an item to the dashboard configuration
   * @param item
   */
  addItem(item: GridsterItem): void {
    this.dashboard.pipe(take(1)).toPromise().then(d => {
      d = Object.assign({}, d);
      d.configuration = Object.assign([], d.configuration);
      d.configuration.push(item);
      this.loadConfiguration(d);
    });
  }

  /**
   * Add a new widget using form values
   */
  addNewItem(): void {
    this.addItem({x: 0, y: 0, cols: 1, rows: 1, display: this.f.get('display').value, timeframe: this.f.get('timeframe').value, widget: this.f.get('widget').value});
  }

  /**
   * When a customer is selected in list, load their graph
   * @param customer
   * @param item
   */
  onCustomerSelected(customer: string, item: GridsterItem) {
    item = Object.assign({}, item);
    this.addItem({x: item?.x, y: item?.y, cols: 1, rows: 1, display: DisplayEnum.Graph, timeframe: item?.timeframe, widget: item?.widget, customer});
  }

}

