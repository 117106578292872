/**
 * src/app/modules/orders/models/order-status.enum.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

export enum OrderStatus {
    ShippingInProgress = 'Shipping in progress',
    DeliveringInProgress = 'Delivering in progress',
    Closed = 'Closed',
    New = 'New',
    InProgress = 'In Progress',
    Sent = 'Sent',
    Updated = 'Updated',
    Approved = 'Approved',
    Acknowledged = 'Acknowledged',
    Shipped = 'Shipped',
    Delivered = 'Delivered',
    Canceled = 'Canceled',
    Rejected = 'Rejected'
}
