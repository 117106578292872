/**
 * src/app/modules/shared/shared.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {NavigationComponent} from './components/navigation/navigation.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {HeaderComponent} from './components/header/header.component';
import {SignUpModalComponent} from './components/sign-up-modal/sign-up-modal.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FindMaskPipe} from './pipes/find-mask.pipe';
import {StateResolver} from './services/state.resolver';
import {StoreModule} from '@ngrx/store';
import * as fromShared from './state/shared.reducer';
import {NgxPermissionsModule} from 'ngx-permissions';
import {BadgeModule} from '@progress/kendo-angular-indicators';
import {PopupModule} from '@progress/kendo-angular-popup';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {ListViewModule} from '@progress/kendo-angular-listview';
import {AvatarModule, CardModule, TabStripModule} from '@progress/kendo-angular-layout';
import {CurrencyjsPipe} from './pipes/currencyjs.pipe';
import {SortByTimestampPipe} from './pipes/sort-by-timestamp.pipe';
import {LocalizeMessagesPipe} from './pipes/localize-messages.pipe';
import {LocalizeDatePipe} from './pipes/localize-date.pipe';
import {UserSpaceAdminModule} from '../user-space-admin/user-space-admin.module';
import {TruncateFilenamePipe} from './pipes/truncate-filename.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {GraphSortPipe} from './pipes/graph-sort.pipe';
import {TotalAndAverageComponent} from './components/graphs/total-and-average/total-and-average.component';
import {ChartModule} from '@progress/kendo-angular-charts';
import {NotificationsDropdownComponent} from './components/notifications-dropdown/notifications-dropdown.component';
import {AllNotificationsComponent} from './components/all-notifications/all-notifications.component';
import {TextBoxModule} from '@progress/kendo-angular-inputs';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {GridModule} from '@progress/kendo-angular-grid';
import {TruncateFilenameShortPipe} from './pipes/truncate-filename-short.pipe';
import {PhoneFormatPipe} from './pipes/phone-format.pipe';
import {ExtractFileExtensionPipe} from './pipes/extract-file-extension.pipe';
import {ExtractFilenamePipe} from './pipes/extract-filename.pipe';
import {UnlinkedHeaderComponent} from './components/unlinked-header/unlinked-header.component';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    NavigationComponent,
    BreadcrumbsComponent,
    HeaderComponent,
    FindMaskPipe,
    TruncateFilenamePipe,
    TruncatePipe,
    CurrencyjsPipe,
    SortByTimestampPipe,
    LocalizeMessagesPipe,
    LocalizeDatePipe,
    TruncateFilenameShortPipe,
    TotalAndAverageComponent,
    GraphSortPipe,
    NotificationsDropdownComponent,
    AllNotificationsComponent,
    PhoneFormatPipe,
    ExtractFileExtensionPipe,
    ExtractFilenamePipe,
    UnlinkedHeaderComponent,
    SignUpModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature(fromShared.sharedFeatureKey, fromShared.reducer),
    BadgeModule,
    PopupModule,
    ListViewModule,
    AvatarModule,
    CardModule,
    ChartModule,
    TabStripModule,
    TextBoxModule,
    ButtonsModule,
    GridModule,
    TooltipModule,
    DialogModule,
    ReactiveFormsModule,
  ],
  providers: [
    FindMaskPipe,
    StateResolver,
    SignUpModalComponent
  ],
  exports: [
    HeaderComponent,
    FindMaskPipe,
    CurrencyjsPipe,
    TruncateFilenamePipe,
    TruncatePipe,
    SortByTimestampPipe,
    LocalizeMessagesPipe,
    LocalizeDatePipe,
    TruncateFilenameShortPipe,
    PhoneFormatPipe,
    ExtractFileExtensionPipe,
    TotalAndAverageComponent,
    ExtractFilenamePipe,
    UnlinkedHeaderComponent,
    SignUpModalComponent
  ]
})
export class SharedModule {}
