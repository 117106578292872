/**
 * src/app/modules/orders/services/unlinked-orders.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 7/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TransformationsService} from '../../shared/services/transformations.service';
import {Orders} from '../models/orders';
import {FreightTracking} from "../../notifications/models/FreightTracking";
import {RejectOrder} from '../../notifications/models/RejectOrder';
import {AcceptOrder} from '../../notifications/models/AcceptOrder';

@Injectable({
  providedIn: 'root'
})
export class UnlinkedOrdersService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService) { }

  /**
   * Get a single unlinked order in the vendor portal.
   */
  public getOne(uuid: string): Observable<Orders> {
    return this.httpClient.get<Orders>(`/api/unlinkedorders/${uuid}`);
  }

  /**
   * Accept a designers order.
   */
  acceptOrder(data: AcceptOrder): Observable<Orders> {
    return this.httpClient.post<Orders>(`/api/unlinkedorders/${data.uuid}/accept`, data);
  }

  /**
   * Accept a designers order.
   */
  rejectOrder(data: RejectOrder): Observable<Orders> {
    return this.httpClient.post<Orders>(`/api/unlinkedorders/${data.uuid}/reject`, data);
  }

  /**
   * Update order freight tracking.
   */
  updateFreightTracking(data: FreightTracking): Observable<Orders> {
    return this.httpClient.post<null>(`/api/unlinkedorders/${data.uuid}/freight-tracking`, data);
  }

  /**
   * Send message to designers order.
   */
  messageOrder(uuid: string, message: string, isMedia: boolean) {
    return this.httpClient.post(`/api/unlinkedorders/${uuid}/message`, {message, isMedia});
  }

}
