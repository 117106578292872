/**
 * src/modules/shared/pipes/find-mask.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 11/23/2020
 */
import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
  name: 'findMask'
})
export class FindMaskPipe implements PipeTransform {
  transform(phone: string): string {

    // check if no phone number provided, or if the number has non-numeric characters
    if (!phone || phone.match(/[^$,.\d]/)) {
      return '0*';
    }

    if (phone.replace(/\D/g, '').length <= 10) {
      return '(000) 000-00000';
    }

    return '0*';

  }
}
