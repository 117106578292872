/**
 * src/app/modules/system-admin/components/edit-user/edit-user.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {filter, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UsersService} from '../../services/users.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {User} from '../../../shared/models/user';
import {FormsService} from '../../../shared/services/forms.service';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {selectSystemAdminOrganizationEditLogin, selectSystemAdminOrganizationEditOrganization} from '../../state/systemAdmin.selector';
import {loginEditSubjectUpdateAction} from '../../state/systemAdmin.actions';
import {Organizations} from '../../../organizations/models/organizations';
import * as momentTZ from "moment-timezone";
import {statusValidator} from "../../directives/status-validator.directive";
import {PhoneMasks} from '../../../shared/models/phoneMasks.enum';

@Component({
  selector: 'vp-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnDestroy {

  public userForm: FormGroup;
  public user = new BehaviorSubject<User>(new User());
  private subscriptions: Subscription[] = [];
  private orgId: number;
  public statuses = [{text: 'Active', value: 'active'}, {text: 'Inactive', value: 'inactive'}, {text: 'Pending', value: 'pending'}];
  public roles = [{text: 'System Admin', value: 'system_admin'},
    {text: 'Organization Admin', value: 'vendor_admin'},
    {text: 'User', value: 'vendor_user'}];

  public organizationEditLogin: Observable<User>;
  public organization: Observable<Organizations>;

  public timeZonesListSource = momentTZ.tz.names();
  public timeZonesList = [...this.timeZonesListSource];

  public mask = PhoneMasks.Default;
  private lastEmail: string;

  constructor(private userService: UsersService,
              private activatedRoute: ActivatedRoute,
              private formsService: FormsService,
              private location: Location,
              private toastrService: ToastrService,
              private router: Router,
              private store: Store<AppState>) {
      this.userForm = this.createForm();
  }

  ngOnInit(): void {

    this.organization = this.store.select(selectSystemAdminOrganizationEditOrganization);
    this.organizationEditLogin = this.store.select(selectSystemAdminOrganizationEditLogin);

    const storeOrganizationEditLogin = this.organizationEditLogin
      .subscribe((u) => {
        this.user.next(u);
      });

    this.subscriptions.push(storeOrganizationEditLogin);

    const userSubscriber = this.user.pipe(filter((u) => this.userForm !== undefined && u !== null)).subscribe((user) => {

      // reset and populate form.
      this.lastEmail = user.email;
      this.userForm.reset(user);

      // check if the url doesn't have our id in it, if missing, update with out id, this handles 'new'.
      if (!isNaN(user.id)) {
        this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
          if (params.id && params.id === 'new') {
            this.location.replaceState(`/system-admin/organizations/${user.organizations.id}/users/${user.id}`);
          }
        });
      }

    });

    this.subscriptions.push(userSubscriber);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get f() {
    return this.userForm;
  }

  handleTimeZoneFilter(search: string) {
    if (typeof search === 'string') {
      search = search.trim().toLowerCase();
    }

    if (!search) {
      return this.timeZonesList = [...this.timeZonesListSource];
    }

    this.timeZonesList = this.timeZonesListSource.filter(zone => zone.toLowerCase().includes(search));
  }

  /**
   * Get a populated model to push to backend.
   */
  getPopulatedModel(): User {

    const user = new User();

    user.id = this.f.get('id').value;
    user.first_name = this.f.get('first_name').value;
    user.last_name = this.f.get('last_name').value;
    user.contact_number = this.f.get('contact_number').value;
    user.email = this.f.get('email').value;
    user.status = this.f.get('status').value;
    user.role = this.f.get('role').value;
    user.timezone = this.f.get('timezone').value;

    return user;

  }

  onFormSubmit() {

    if (!this.f.valid) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    const populatedModel = this.getPopulatedModel();

    this.organization
      .pipe(take(1))
      .subscribe((o) => this.store.dispatch(loginEditSubjectUpdateAction({org_id: o.id, user: populatedModel})));

  }

  private createForm(): FormGroup {
    
    const emailExistsValidator = async (control: FormControl): Promise<ValidationErrors> => {
      return await this.userService.emailExistsValidator(control.value, this.lastEmail);
    }

    const f = new FormGroup({
      id: new FormControl(undefined, []),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      contact_number: new FormControl('', [Validators.maxLength(255)]),
      email: new FormControl('', [Validators.required, Validators.maxLength(255)], [emailExistsValidator]),
      status: new FormControl([], [Validators.required, statusValidator()]),
      role: new FormControl([], []),
      timezone: new FormControl('', []),
    });

    f.get('contact_number').valueChanges.subscribe((phone) => {
      const oldMask = this.mask;
      const newMask = (phone && !phone.match(/[^$,.\d]/) && phone.replace(/\D/g, '').length <= 10) ? PhoneMasks.UsaFormatted : PhoneMasks.Default;
      if (oldMask === PhoneMasks.UsaFormatted && newMask === PhoneMasks.Default && (!this.f.get('contact_number').value || this.f.get('contact_number').value.length === 0)) {
        this.f.get('contact_number').setValue('', {emitEvent: false});
      }
      this.mask = newMask;
      return phone;
    });

    return f;
  }

  cancel() {
    this.location.back();
  }

}
