/**
 * src/app/modules/system-admin/services/organizations.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ResultListDTO } from '../../shared/models/resultList.dto';
import { Organizations } from '../../organizations/models/organizations';
import { State } from '@progress/kendo-data-query';
import { TransformationsService } from '../../shared/services/transformations.service';
import { switchMap } from 'rxjs/operators';
import { Locations } from '../../shared/models/locations';
import {or} from "@progress/kendo-angular-grid/dist/es2015/utils";

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService) { }

  /**
   * Get paginated collection of organizations in the vendor portal.
   */
  public getCollection(state: State): Observable<ResultListDTO<Organizations>> {

    const params = this.transformationsService.getHttpParamsFromKendoState(state);

    params.getAll('filterValues[]')?.forEach((v, i, a) => { a[i] = encodeURIComponent(v); })

    return this.httpClient.get<ResultListDTO<Organizations>>(`/api/system-admin/organizations`, {params});

  }

  /**
   * Get a single organization in the vendor portal.
   */
  public getOne(id: number): Observable<Organizations> {
    return this.httpClient.get<Organizations>(`/api/system-admin/organizations/${id}`).pipe(switchMap((org) => {

      if (!org.primaryLocation) {
        org.primaryLocation = new Locations();
      }
      return of(org);

    }));
  }

  /**
   * Create or update an organization.
   * @param org
   */
  public save(org: Organizations): Observable<Organizations> {

    const url = '/api/system-admin/organizations';
    let r: Observable<Organizations>;

    if (org.id) {
      r =  this.httpClient.put<Organizations>(`${url}/${org.id}`, org);
    } else {
      r = this.httpClient.post<Organizations>(url, org);
    }

    return r;

  }

}
