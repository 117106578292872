/**
 * src/app/modules/orders/state/orders.actions.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createAction, props} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query/dist/npm/state';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {Orders} from '../models/orders';
import {ChatMessages} from '../../notifications/models/ChatMessages';
import {FreightTracking} from '../../notifications/models/FreightTracking';
import {RejectOrder} from '../../notifications/models/RejectOrder';
import {AcceptOrder} from '../../notifications/models/AcceptOrder';
import {OrderStatus} from "../models/order-status.enum";
import {Plans} from "../../organizations/models/plans";
import {RequestPaymentOptions} from "../../notifications/models/RequestPaymentOptions";

// organizations list
export const ordersListPagerChangeAction = createAction('[Orders] List Pager Change', props<KendoDataState>());
// tslint:disable-next-line:max-line-length
export const ordersListPagerAddNameFilterAction = createAction('[Orders] List Pager Add Text Filter', props<FilterDescriptor>());
// tslint:disable-next-line:max-line-length
export const ordersListPagerAddStatusFilterAction = createAction('[Orders] List Pager Add Status Filter', props<FilterDescriptor>());
export const ordersListPagerResetNameFilterAction = createAction('[Orders] List Pager Reset Name Filter');
export const ordersListPagerResetStatusFilterAction = createAction('[Orders] List Pager Reset Status Filter');

// unlinked orders
export const unlinkedOrderSubjectGetAction = createAction('[Orders] Unlinked Order Subject Get', props<{ uuid: string }>());

// orders edit
export const orderSubjectGetAction = createAction('[Orders] Subject Get', props<{ id: number | string }>());
export const orderSubjectChangeAction = createAction('[Orders] Subject Change', props<{order: Orders, skipMessages?: boolean}>());

export const orderResetAction = createAction('[Orders] Reset');
export const orderSubjectUpdateAction = createAction('[Orders] Subject Update', props<Orders>());
export const orderAddChatMessage = createAction('[Orders] Add Chat Message', props<ChatMessages>());
export const orderFreightTrackingDialog = createAction('[Orders] Update Freight Tracking Dialog', props<{isOpen: boolean}>());
export const orderRejectDialog = createAction('[Orders] Reject Dialog', props<{isOpen: boolean}>());
export const orderAcceptDialog = createAction('[Orders] Accept Dialog', props<{isOpen: boolean}>());
export const orderUpdateFreightTracking = createAction('[Orders] Update Freight Tracking', props<FreightTracking>());
export const orderUpdateFreightTrackingSuccess = createAction('[Orders] Update Freight Tracking Success', props<{ status: boolean }>());
export const orderReject = createAction('[Orders] Reject', props<RejectOrder>());
export const orderRejectSuccess = createAction('[Orders] Reject Success', props<{ status: boolean }>());
export const orderAccept = createAction('[Orders] Accept', props<AcceptOrder>());
export const orderAcceptSuccess = createAction('[Orders] Accept Success', props<{ status: boolean }>());
export const orderStatusUpdateAction = createAction('[Orders] When Order is Updated, update only the status', props<{ orderId: number | string, status: OrderStatus }>());

export const defaultPlanGetAction = createAction('[Default Plan] Default Plan Subject Get');
export const defaultPlanSubjectChangeAction = createAction('[Default Plan] Default Plan Subject Change', props<{ defaultPlan: Plans }>());

//    New Orders
export const countNewOrdersGetAction = createAction('[Count] New Order Count Subject Get');
export const countNewOrdersSubjectChangeAction = createAction('[Count] New Order Count Subject Change', props<{ newOrderCount: number }>());

//    Approved Orders
export const countApprovedOrdersGetAction = createAction('[Count] Approved Order Count Subject Get');
export const countApprovedOrdersSubjectChangeAction = createAction('[Count] Approved Order Count Subject Change', props<{ approvedOrderCount: number }>());

//    Rejected Orders
export const countRejectedOrdersGetAction = createAction('[Count] Rejected Order Count Subject Get');
export const countRejectedOrdersSubjectChangeAction = createAction('[Count] Rejected Order Count Subject Change', props<{ rejectedOrderCount: number }>());

// Payment Options
export const requestPaymentDialog = createAction('[Payment Options] Request Payment Dialog', props<{isOpen: boolean}>());
export const sendPaymentOptions = createAction('[Payment Options] Send Payment Options', props<RequestPaymentOptions>());
export const requestPaymentOptionsSuccess = createAction('[Payment Options] Request Payment Success', props<{ status: boolean }>());



