/**
 * src/app/modules/organizations/state/organizations.selector.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */


import {organizationsFeatureKey, OrganizationsState} from './organizations.reducer';
import {createSelector} from '@ngrx/store';

export const selectOrganizationsState = (state: any) => state[organizationsFeatureKey];

export const selectOrganization = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.organizations
);

export const selectOrganizationProfileTab = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.profileTab
);

export const selectLoginEditSubject = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.user
);

export const selectOrganizationCount = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.organizationCount
);

export const selectActiveOrganizationCount = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.activeOrganizationCount
);

export const selectInactiveOrganizationCount = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.inactiveOrganizationCount
);

export const selectUserCount = createSelector(
  selectOrganizationsState,
  (state: OrganizationsState) => state.userCount
);
