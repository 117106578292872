<kendo-card [width]="'100%'">
  <kendo-card-header class="k-hbox">
    <h1 kendoCardTitle>{{ title }}</h1>
    <p kendoCardSubtitle>{{ subtitle }}</p>
  </kendo-card-header>
  <kendo-card-body>
    <!-- Ave Orders -->
    <kendo-chart>
      <kendo-chart-legend position="bottom"
                          orientation="horizontal"
                          labels="{ font: 10px 'Avenir Next LT Pro', sans-serif }"></kendo-chart-legend>
      <kendo-chart-tooltip [format]="entitiesLabel"
                            font="10pt Avenir Next LT Pro, sans-serif"
                           background="none"
                           color="#000000"
      ></kendo-chart-tooltip>
      <kendo-chart-axis-defaults [line]="{ color: 'white' }">
        <kendo-chart-axis-defaults-labels font="10px Avenir Next LT Pro, sans-serif" [format]="axisLabelFormat">
        </kendo-chart-axis-defaults-labels>
      </kendo-chart-axis-defaults>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="categories"
                                        [majorGridLines]="{color: '#F7F8FA'}"
                                        [labels]=" {color: '#B5BEC6' }">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-value-axis>
        <kendo-chart-value-axis-item [majorGridLines]="{visible: false}"
                                      [labels]="{color: '#B5BEC6', font: '10px Avenir Next LT Pro, sans-serif'}">
        </kendo-chart-value-axis-item>
      </kendo-chart-value-axis>
      <kendo-chart-series>
        <kendo-chart-series-defaults type="area">
        </kendo-chart-series-defaults>
        <kendo-chart-series-item *ngIf="lineData && lineData.length > 0" [type]="'line'" [line]="{ style: 'smooth' }"
                                 [color]="lineColor"
                                 [name]="lineLabel"
                                 [labels]="lineSeriesLabels"

                                 [data]="lineData">
        </kendo-chart-series-item>
        <kendo-chart-series-item [line]="{ style: 'step' }" [color]="barColor"
                                 [name]="barLabel"
                                 [data]="barData"
                                 [labels]="barSeriesLables"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
    <!-- /Ave Orders -->
  </kendo-card-body>
</kendo-card>
