<div class="sign-up-modal-wrapper">
  <kendo-dialog *ngIf="opened" (close)="closeModal()" [minWidth]="250" [width]="hasInfo ? 814 : 407">
    <div class="dialog-container">
      <div [formGroup]="loginForm" class="sign-up-modal-left layout__item" [ngClass]="hasInfo ? 'u-1/2' : 'u-1/1'">
        <h2 class="title mb-3">Sign In</h2>
        <div class="subtitle mb-5">To fully engage with our design community.</div>

        <div class="mb-5">
          <div class="custom mb-3">
            <label for="email">Email</label>
            <input kendoTextBox formControlName="username" id="username" type="text" autocomplete="nope" placeholder="your@email.com">
            <p *ngIf="loginForm.dirty && (f.controls.username?.errors?.required || f.controls.username?.errors?.username)" class="error-message">Invalid email address</p>
          </div>
          <div class="custom mb-3">
            <label for="vendor-name">Password</label>
            <input kendoTextBox formControlName="password" id="password" type="password" autocomplete="nope" placeholder="password">
            <p *ngIf="loginForm.dirty && f.controls.password?.errors?.required" class="error-message">Password is missing</p>
          </div>
          <p *ngIf="f.invalid && errorMessage"> {{errorMessage}}</p>
          <div class="custom mb-3 forgot-block">
            <a class="btn-link" routerLink="/forgot-password" routerLinkActive="active">
              Forgot the password?
            </a>
          </div>
        </div>
        <div class="btns-row mb-5 custom">
          <button kendoButton look="outline" [primary]="true" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
          <button kendoButton [primary]="true" class="btn btn-primary" [disabled]="loginForm.invalid || loading" (click)="onSubmit()">Log In</button>
        </div>
        <div class="footer-row custom">
          <span class="label">Do not have an account?</span> <a (click)="signUp.emit()" href="javascript:void(0)" class="btn-link text-accent">Sign Up</a>
        </div>

      </div>
      <div class="w-100 d-flex flex-column sign-up-modal-right layout__item u-1/2" *ngIf="hasInfo">
        <h5 class="sign-up-modal-right__header">Communicate with our design community</h5>
        <div class="body-1">Now that we’ve launched the vendor portal, you can use Studio Designer to receive and communicate orders. Get started in minutes with our simple Sign Up.</div>
      </div>
    </div>
  </kendo-dialog>
</div>
