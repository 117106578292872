/**
 * src/app/modules/system-admin/components/dashboard/dashboard.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { Component, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { geometry, Group, Layout, Text } from '@progress/kendo-drawing';
import { ChartComponent, RenderEvent, SeriesVisualArgs } from '@progress/kendo-angular-charts';

import 'hammerjs';
import {Point} from '@progress/kendo-drawing/geometry';
import {Observable} from 'rxjs';
import {
  selectOrganizationCount, selectUserCount
} from '../../../organizations/state/organizations.selector';
import {ToastrService} from 'ngx-toastr';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';

@Component({
  selector: 'vp-sa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // counts
  public organizationCount: Observable<number>;
  public userCount: Observable<number>;

  public vendorCountData: any[] = [{
    kind: 'Active', share: 0.75, valueColor: '#43A047'
  }, {
    kind: 'Inactive', share: 0.25, valueColor: '#1B5E20'
  }];

  public userCountData: any[] = [{
    kind: 'Active', share: 0.53, valueColor: '#EC407A'
  }, {
    kind: 'Inactive', share: 0.47, valueColor: '#880E4F'
  }];

  public donutLabelContent(e: any): string {
    return e.category + '\n ' + (e.value * 100) + '%';
  }

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.organizationCount = this.store.select(selectOrganizationCount);
    this.userCount = this.store.select(selectUserCount);
  }

}
