  <div class="grid-controls">
    <div class="item-actions">
      <button kendoButton [routerLink]="['users', 'new']">Add New Login</button>
    </div>
  </div>

  <ng-container *ngIf="(organizationProfile | async) as $org">
  <kendo-grid
    [data]="$org.users"
    [resizable]="true"
              scrollable="none">

    <kendo-grid-column field="logo" title="" width="60"
                       [filterable]="false" [sortable]="false">
      <ng-template kendoGridCellTemplate let-user>
        <kendo-avatar [shape]="'circle'"
                      [initials]="user.first_name"></kendo-avatar>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="first_name" title="First Name" resizable="false">
      <ng-template kendoGridCellTemplate let-user>
        {{user.first_name}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="last_name" title="Last Name" resizable="false">
      <ng-template kendoGridCellTemplate let-user>
        {{user.last_name}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="email" title="Email" resizable="false">
      <ng-template kendoGridCellTemplate let-user>
        {{user.email}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="status" title="Status" resizable="false">
      <ng-template kendoGridCellTemplate let-user>
        <kendo-badge-container>
          <kendo-badge [themeColor]="(user.status.indexOf('active') !== -1) ? 'success' : 'light'"
                       [shape]="'rectangle'"
                       [size]="'medium'"
                       [position]="'inside'"
                       [align]="{ horizontal: 'start', vertical: 'top' }">
            {{(user.status.indexOf('active') !== -1) ? 'ACTIVE' : 'INACTIVE'}}
          </kendo-badge>
        </kendo-badge-container>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Actions" width="150"
                       [minResizableWidth]="150"
                       [filterable]="'false'" [sortable]="false"
                       class="k-grid-column--center">
      <ng-template kendoGridCellTemplate let-user>
        <kendo-buttongroup>
          <button kendoButton [primary]="true" [routerLink]="['users', user.id]">Edit</button>
        </kendo-buttongroup>

      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      <div class="content-area  content-area--no-content">
        <p>This organization has no logins yet. Why not create one?</p>
        <button kendoButton
                primary="true" [routerLink]="['users', 'new']">Add New Login</button>
      </div>
    </ng-template>

  </kendo-grid>

  </ng-container>
