/**
 * src/app/modules/authentication/authentication.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthenticationRoutingModule} from './authentication-routing.module';
import {LoginComponent} from './components/login/login.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {CheckBoxModule, FormFieldModule, TextBoxModule} from '@progress/kendo-angular-inputs';
import {ReactiveFormsModule} from '@angular/forms';
import {Store, StoreModule} from '@ngrx/store';
import * as fromAuthentication from './state/authentication.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from './state/authentication.effects';
import {SetPasswordComponent} from './components/set-password/set-password.component';
import {PasswordStrengthMeterModule} from 'angular-password-strength-meter';
import {IsAuthenticatedGuardService} from "./services/is-authenticated-guard.service";
import {SignUpComponent} from "./components/sign-up/sign-up.component";
import {AvatarModule} from "@progress/kendo-angular-layout";
import {LabelModule} from "@progress/kendo-angular-label";
import {SharedModule} from "../shared/shared.module";
import {FindMaskPipe} from "./pipes/find-mask.pipe";
import {NgxMaskModule} from "ngx-mask";
import {IsLoggedInGuardService} from "./services/is-logged-in-guard.service";
import {DashboardGuardService} from "./services/featureGuards/dashboard-guard.service";
import {NotificationsGuardService} from "./services/featureGuards/notifications-guard.service";
import {OrderManagerGuardService} from "./services/featureGuards/order-manager-guard.service";
import {WsService} from "../wsockets/services/ws/ws.service";
import {AppState} from "../../app.module";
import {filter} from "rxjs/operators";
import {logoutSuccessAction} from "./state/authentication.actions";
import {VerifyEmailComponent} from "./components/verify-email/verify-email.component";
import {IsAuthHeaderGuardService} from "./services/is-auth-header-guard.service";
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {MapToolsService} from '../shared/services/map-tools.service';
import {ResendVerificationEmailComponent} from './components/resend-verification-email/resend-verification-email.component';
import {SignUpSuccessComponent} from './components/sign-up-success/sign-up-success.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, SetPasswordComponent, SignUpComponent, FindMaskPipe,
  VerifyEmailComponent, ResendVerificationEmailComponent, SignUpSuccessComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    ButtonsModule,
    TextBoxModule,
    CheckBoxModule,
    StoreModule.forFeature(fromAuthentication.authenticationFeatureKey, fromAuthentication.reducer),
    EffectsModule.forFeature([AuthenticationEffects]),
    PasswordStrengthMeterModule,
    AvatarModule,
    LabelModule,
    SharedModule,
    NgxMaskModule,
    GooglePlaceModule,
    FormFieldModule,
  ],
  providers: [IsAuthenticatedGuardService, IsLoggedInGuardService, DashboardGuardService, NotificationsGuardService,
    OrderManagerGuardService, IsAuthHeaderGuardService, MapToolsService]
})
export class AuthenticationModule {

  constructor(private wsService: WsService, private store: Store<AppState>) {

    wsService.$logoutEvents.pipe(filter(m => m.name === 'USER_LOGOUT')).subscribe(
      x => {
        this.store.dispatch(logoutSuccessAction());
      }
    );

  }

}
