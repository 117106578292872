import { Injectable } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HandleErrorService {

  constructor(private toastrService: ToastrService, public router: Router) { }

  // error: HttpErrorResponse
  handleError(error: HttpErrorResponse, message?: string) {
    if (error && error.status === 401) {
      this.router.navigate(['/login']);
    } else {
      this.toastrService.error(message ? message : `Not authenticated`);
    }
  }


}
