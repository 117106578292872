/**
 * src/app/modules/user-space-admin/state/userSpaceAdmin.actions.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 02/17/2021
 * @copyright DesignersAxis, LLC, 2020
 *
 */
import {createAction, props} from '@ngrx/store';
import {OrdersSummary} from '../../orders/models/orders-summary';
import {Timeframes} from '../models/timeframes.enum';
import {Widget} from '../models/widget.enum';
import {FiltersInterface} from "../models/filters.interface";
import {Observable} from "rxjs";
import {DashboardConfiguration} from "../models/dashboardConfiguration";

// filtersSubjectChangeAction - get customers
// filtersSubjectChangeAction (this does not use customer) - order summary
export const customerResetAction = createAction('[Customer] Reset Customer', props<FiltersInterface>()); // this triggers filtersSubjectChangeAction
export const filtersSubjectChangeAction = createAction('[Dashboard Filters] Draw new dashboard based on up to date filters', props<FiltersInterface>()); // sets values in reducer, triggers get order summary
// getCustomers()
export const setOrderSummaryAction = createAction('[Order Summary]', props<{ordersSummary: OrdersSummary[]}>()); // sets the graph data in reducer

export const timeframeSubjectChangeAction = createAction('[Timeframe] Change Timeframe value', props<FiltersInterface>()); // set timeframe in reducer, determine next step in effects
export const salesCodeSubjectChangeAction = createAction('[Sales Code] Change Sales Code value', props<FiltersInterface>()); // set sales code in reducer, determine next step in effects
export const customerSubjectChangeAction = createAction('[Customer] Sets a new customer', props<FiltersInterface>()); // set customer in reducer, determine next step in effects
export const salesCodeResetAction = createAction('[Sales Code] Reset value', props<FiltersInterface>()); // unsets sales code in reducer, then runs filterSubjectChangeAction

// widgetSubjectChangeAction - this only changes the widget value, which we subscribe to
export const widgetSubjectChangeAction = createAction('[Widget] Change Widget value', props<FiltersInterface>()); // set widget in reducer, filtersSubjectChangeAction

export const customersMatchSelectionLogicAction = createAction('[Customers] Determines whether to reset customer selection depending on if the value is within the new customers list', props<FiltersInterface>());
export const setCustomerInNewListAction = createAction('[Customers] Sets a customer on a list', props<FiltersInterface & { topCustomers: OrdersSummary[] }>());

export const setSalesCodeInNewListAction = createAction('[Sales Codes] Sets a sales code on a list', props<FiltersInterface & { salesCodes: string[] }>());

export const salesCodesMatchSelectionLogicAction = createAction('[Sales Codes] Determines whether to reset sales code selection depending on if the value is within the new sales codes list', props<FiltersInterface>());
export const customersSetSubjectAction = createAction('[Customers] Sets list of customers', props<{ topCustomers: OrdersSummary[] }>());
export const salesCodesSetSubjectAction = createAction('[Sales Codes] Sets list of sales codes', props<{ salesCodes: string[] }>());

export const initialLoadSubjectAction = createAction('[Dashboard] This will populate our initial filters then draw data', props<FiltersInterface>());
export const filtersSubjectSetAction = createAction('[Dashboard] This will populate our initial filters then draw data', props<FiltersInterface>());

// dashboard configurations
export const dashboardConfigurationSetAction = createAction('[Dashboard configuration] Set the config', props<{config: DashboardConfiguration}>());
export const dashboardConfigurationSaveAction = createAction('[Dashboard configuration] Save the config', props<{config: DashboardConfiguration}>());
export const templateSaveModalAction = createAction('[Dashboard Configuration] Open the save modal action', props<{isOpen: boolean}>());
export const templateLoadModalAction = createAction('[Dashboard Configuration] Open the load modal action', props<{isOpen: boolean}>());

// COUNTS:
//    Locations
export const countLocationsGetAction = createAction('[Count] Location Count Subject Get');
export const countLocationsSubjectChangeAction = createAction('[Count] Location Count Subject Change', props<{ locationCount: number }>());

//    Employees
export const countEmployeesGetAction = createAction('[Count] Employee Count Subject Get');
export const countEmployeesSubjectChangeAction = createAction('[Count] Employee Count Subject Change', props<{ employeeCount: number }>());
