/**
 * src/app/modules/organizations/services/organizations.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Organizations} from '../models/organizations';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get the organization, which doubles as the organizational profile.
   */
  public getOne(): Observable<Organizations> {
    return this.httpClient.get<Organizations>(`/api/profile`);
  }

  /**
   * Create or update an organization.
   */
  public save(org: Organizations): Observable<Organizations> {

    const url = '/api/profile';
    return this.httpClient.put<Organizations>(url, org);

  }


}
