import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vp-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  /**
   * Dummy locations data as an example
   */

  locations: any[] = [
    {
      id: 1,
      name: 'Chicago Warehouse',
      address: '1060 W Addison, Chicago IL 12345',
      email: 'chicago@studiodesigner.io',
      type: 'warehouse'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}




