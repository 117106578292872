/**
 * Can the user view the update-tracking button
 *
 * src/app/modules/orders/pipes/update-tracking.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 07/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import {Orders} from '../../models/orders';
import {Organizations} from '../../../organizations/models/organizations';
import {OrderStatus} from "../../models/order-status.enum";

@Pipe({
  name: 'updateTracking'
})
export class UpdateTrackingPipe implements PipeTransform {
  transform(order: Orders, organization: Organizations): boolean {
    if (!order || !organization) {
      return false;
    }
    return order.status !== OrderStatus.Canceled && order.id && organization.plan?.update_tracking_number;
  }
}
