/**
 * src/app/modules/authentication/authentication-routing.module.ts
 *
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {authenticationFeatureKey, AuthenticationState} from './modules/authentication/state/authentication.reducer';
import {UserSpaceAdminModule} from './modules/user-space-admin/user-space-admin.module';
import {SharedModule} from './modules/shared/shared.module';
import {OrdersModule} from './modules/orders/orders.module';
import {SystemAdminModule} from './modules/system-admin/system-admin.module';
import {NgxPermissionsModule} from 'ngx-permissions';
import {localStorageSync} from 'ngrx-store-localstorage';
import {ToastrModule} from 'ngx-toastr';
import {systemAdminFeatureKey, SystemAdminState} from './modules/system-admin/state/systemAdmin.reducer';
import {AuthInterceptor} from './modules/authentication/services/authentication.interceptor';
import {NgxMaskModule} from 'ngx-mask';
import {sharedFeatureKey} from './modules/shared/state/shared.reducer';
import {OrganizationsModule} from './modules/organizations/organizations.module';
import {organizationsFeatureKey} from './modules/organizations/state/organizations.reducer';
import {ordersFeatureKey} from './modules/orders/state/orders.reducer';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {WsocketsModule} from './modules/wsockets/wsockets.module';
import {WsService} from './modules/wsockets/services/ws/ws.service';
import {userSpaceAdminFeatureKey} from './modules/user-space-admin/state/userSpaceAdmin.reducer';
import {notificationsFeatureKey} from './modules/notifications/state/notifications.reducer';
import {IntlModule} from '@progress/kendo-angular-intl';
import {LabelModule} from '@progress/kendo-angular-label';
import {DateInputsModule} from '@progress/kendo-angular-dateinputs';

export interface AppState {
  authentication: AuthenticationState;
  systemAdmin: SystemAdminState;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [authenticationFeatureKey,
      systemAdminFeatureKey,
      sharedFeatureKey,
      notificationsFeatureKey,
      organizationsFeatureKey,
      ordersFeatureKey,
      userSpaceAdminFeatureKey],
    storageKeySerializer: (key) => `sd_vp_${key}`,
    rehydrate: true
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

const config: SocketIoConfig = { url: '', options: {} };

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot(),
    AppRoutingModule,
    AuthenticationModule,
    UserSpaceAdminModule,
    OrdersModule,
    SystemAdminModule,
    OrganizationsModule,
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot({
      validation: false,
    }),
    SocketIoModule.forRoot(config),
    WsocketsModule,
    IntlModule,
    LabelModule,
    DateInputsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ws: WsService) {
  }
}
