/**
 * src/app/modules/organizations/pipes/remove-selected-item.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 06/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';

@Pipe({
  name: 'removeSelectedItem'
})
export class RemoveSelectedItemPipe implements PipeTransform {
  transform(items: any[], selected: any, key: any): any[] {

    if (!items || !selected || !key) {
      return items;
    }

    return items.filter(item => item[key] !== selected[key]);

  }
}
