/**
 * src/app/modules/wsockets/wsockets.module.ts
 *
 * @since 1/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {WsService} from './services/ws/ws.service';

@NgModule({
  declarations: [],
  providers: [WsService],
  imports: [
    CommonModule,
  ]
})
export class WsocketsModule { }
