/**
 * src/app/modules/shared/state/shared.reducer.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2020
 * @since 12/20
 */

import {Action, createReducer, on} from '@ngrx/store';
import {changeHeaderAction} from './shared.actions';
import {logoutSuccessAction} from '../../authentication/state/authentication.actions';
import {OrganizationsState} from '../../organizations/state/organizations.reducer';

export const sharedFeatureKey = 'shared';

export interface SharedState  {
  header: string;
}

export const initialState: SharedState = {
  header: 'user'
};

const sharedReducer = createReducer(
  initialState,
  on(logoutSuccessAction, (state: SharedState) => ({...state, ...initialState})),
  on(changeHeaderAction, (state: SharedState, {header}) => ({...state, header})),
);

export function reducer(state: SharedState | undefined, action: Action) {
  return sharedReducer(state, action);

}
