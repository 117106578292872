<kendo-listview
  [data]="locations"
  [containerClass]="'k-d-flex k-flex-col k-flex-nowrap locations-list'">
  <ng-template kendoListViewHeaderTemplate>
    <div class="locations-list__header">
      1 Additional Location
    </div>
  </ng-template>
  <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
    <div class="locations-list__location  vcard">
      <kendo-avatar
        [shape]="'circle'"
        [width]="40"
        [height]="40"
        [initials]="dataItem.name">
      </kendo-avatar>
      <div class="location__details">
        <div class="fn">{{ dataItem.name }}</div>
        <div class="email">{{ dataItem.email }}</div>
        <div class="adr">{{ dataItem.address }}</div>
      </div>
      <div class="location__type">
        <kendo-badge-container>
          <kendo-badge [position]="'inside'"
                       [fill]="'solid'"
                       [shape]="'rectangle'"
                       [size]="'large'"
                       [themeColor]="'success'"
                       [align]="{ vertical: 'top', horizontal: 'end' }">
            {{ dataItem.type }}
          </kendo-badge>
        </kendo-badge-container>
      </div>
      <div class="location__edit">
        <button class="action-grid-btn" primary="true" kendoButton>Edit</button>
      </div>
    </div>
  </ng-template>
  <ng-template kendoListViewFooterTemplate>
    <div class="locations-list__footer">
      <button kendoButton>Add New Location</button>
    </div>
  </ng-template>
</kendo-listview>
