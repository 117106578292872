<div class="container">
  <ng-container *ngIf="order | async as $order">
    <div class="page-info">
      <h2 class="page-info__title">
        <ng-container *ngIf="(organization | async) && ( order | async )|doesOrderBelongToOrganization:(organization | async)"><a href="javascript:void(0)" (click)="routeToOrders()">Orders</a> / </ng-container>Order # {{$order.order_name}}
           <kendo-badge-container *ngIf="$order.status === orderStatuses.New">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'"
                        [shape]="'rectangle'" [fill]="'solid'"
                        [themeColor]="'warning'">New</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.Approved">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'"
                        [shape]="'rectangle'" [fill]="'solid'"
                        [themeColor]="'success'">Accepted</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.Rejected">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'"
                        [shape]="'rectangle'" [fill]="'solid'"
                        [themeColor]="'error'">Rejected</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.Canceled">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'"
                        [shape]="'rectangle'" [fill]="'solid'"
                        [themeColor]="'error'">Canceled</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.InProgress || getOrderStatus">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'" [shape]="'rectangle'"
                        [fill]="'solid'"
                        [themeColor]="'light'">In Progress</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.Delivered">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                        [position]="'inside'"
                        [shape]="'rectangle'" [fill]="'solid'"
                        [themeColor]="'success'">Delivered</kendo-badge>
          </kendo-badge-container>
          <kendo-badge-container *ngIf="$order.status === orderStatuses.Shipped">
            <kendo-badge [align]="{ vertical: 'top', horizontal: 'start' }"
                          [position]="'inside'"
                          [shape]="'rectangle'" [fill]="'solid'"
                          class="shipped-badge">Shipped</kendo-badge>
          </kendo-badge-container>
      </h2>
      <div class="page-info__data">

        <button kendoButton primary="true"
                *ngIf="($order.status !== orderStatuses.Approved) && (((header | async) === 'unlinked' && (order | async)?.published) || ( (organization | async) && (( order | async ) | acceptOrder: (organization | async)) && ( order | async )|doesOrderBelongToOrganization:(organization | async)))"
                (click)="acceptOrder()">
          <span *ngIf="isMobile()" class="k-icon k-i-check"></span>
          <ng-container *ngIf="!isMobile()">Acknowledge</ng-container>
        </button>
        <button kendoButton
                *ngIf="($order.status !== orderStatuses.Rejected) && (((header | async) === 'unlinked' && (order | async)?.published) || ( (organization | async) && (( order | async ) | rejectOrder: (organization | async)) && ( order | async )|doesOrderBelongToOrganization:(organization | async)))"
                (click)="rejectOrder()">
<!--          <span *ngIf="isMobile()" class="k-icon k-i-close"></span>-->
          <ng-container>Reject Order</ng-container>
        </button>
        <button kendoButton
                *ngIf="(
                ($order.status === orderStatuses.Approved || $order.status === orderStatuses.Delivered || $order.status === orderStatuses.Shipped)
                && $order.status !== orderStatuses.Rejected) && (((header | async) === 'unlinked' && (order | async)?.published) || ( (organization | async) &&( order | async ) | updateTracking: (organization | async)))"
                (click)="openFreightTrackingDialog()">
          <ng-container >Update Tracking</ng-container>
        </button>

        <button kendoButton
                *ngIf="((organization | async)?.plan?.payments && paymentAllowedOrderStatuses.includes($order.status) &&
                (((header | async) === 'unlinked' && (order | async)?.published) || ((organization | async) && (order | async) | doesOrderBelongToOrganization:(organization | async))))"
                (click)="requestPaymentOpen()">
          <ng-container >Request Payment</ng-container>
        </button>
      </div>
    </div>
    <div class="content-area k-d-flex k-justify-content-center">
          <div class="order-preview papper" [ngClass]="{'animated-hide': !isOrderShown}">
            <div class="h-entry">
              <header class="order-preview__top">
                <div class="order-from p-author h-card">
                  <div class="column">
                    <h1 class="p-name">{{$order.designer?.name}}</h1>
                    <p class="p-adr h-adr">
                      <span class="p-street-address">{{$order.designer?.address?.address}}</span>
                      <span class="p-extended-address">{{$order.designer?.address?.address_ext}}</span>
                      <span class="p-locality">{{$order.designer?.address?.city}}</span>
                      <span class="p-region">{{$order.designer?.address?.province}}</span>
                      <span class="p-postal-code">{{$order.designer?.address?.postal_code}}</span>
                      <span class="p-country-name">{{$order.designer?.address?.country}}</span>
                    </p>
                    <p class="phone-etc">
                      <span class="tel">
                        <span class="type">Phone:</span>
                        <span class="value">{{$order.designer?.address?.phone | phoneformat: 'US'}}</span>
                      </span>
                      <span class="tel">
                        <span class="type">Fax:</span>
                        <span class="value">{{$order.designer?.address?.fax}}</span>
                      </span>
                      <span class="u-email" *ngIf="$order.designer?.email">
                        <span class="type">Email</span>
                        <a class="value" href="{{$order.designer?.email}}">{{$order.designer?.email}}</a>
                      </span>
                    </p>
                  </div>
                  <div *ngIf="$order.designer?.logo" class="column">
                    <img class="u-logo"
                          [src]="$order.designer?.logo"
                          alt="{{$order.designer?.name}} Logo" />
                  </div>
                </div>

                <h2 class="order-preview__title p-name"><span class="p-category">Order</span>
                  <span>{{$order.order_name}}</span></h2>
              </header>
              <div class="order__to">
                <div class="column h-card">
                  <ng-container *ngIf="$order.to">
                    <p class="p-name">To: {{$order.to?.name}}</p>
                    <p class="p-adr h-adr">
                      <span class="p-street-address">{{$order.to?.address}}</span>
                      <span class="p-locality">{{$order.to?.city}}, </span>
                      <span class="p-region">{{$order.to?.province}}, </span>
                      <span class="p-postal-code">{{$order.to?.postal_code}}</span>
                      <span class="p-country-name">{{$order.to?.country}}</span>
                    </p>
                    <div class="shipping_phone-fax-container">
                      <span class="tel" *ngIf="$order.attention">
                        <span class="type">Attention:</span>
                        <br>
                        <span class="type">{{$order.attention}}</span>
                      </span>
                    </div>
                    <div class="shipping_phone-fax-container">
                      <span class="tel" *ngIf="$order.to?.phone">
                        <span class="type">Phone:</span>
                        <span class="value">{{$order.to?.phone | phoneformat: 'US'}}</span>
                      </span>
<!--                      <span class="u-email" *ngIf="$org?.contact_email">-->
<!--                        <span class="type">Email</span>-->
<!--                        <a class="value" href="mailto:{{$org?.contact_email}}">{{$org?.contact_email}}</a>-->
<!--                      </span>-->
                    </div>
                  </ng-container>

                  <p class="p-name">Ship to: {{$order.ship_to?.name}}</p>
                  <p class="p-adr h-adr">
                    <span class="p-street-address">{{$order.ship_to?.address}}</span>
                    <span class="p-street-address" *ngIf="$order.ship_to?.address_ext">{{$order.ship_to?.address_ext}}</span>
                    <span class="p-locality">{{$order.ship_to?.city}}, </span>
                    <span class="p-region">{{$order.ship_to?.province}}, </span>
                    <span class="p-postal-code">{{$order.ship_to?.postal_code}}</span>
                    <span class="p-country-name">{{$order.ship_to?.country}}</span>
                  </p>
                  <div class="shipping_phone-fax-container">
                    <span class="tel" *ngIf="$order.ship_to?.phone">
                      <span class="type">Phone:</span>
                      <span class="value">{{$order.ship_to?.phone | phoneformat: 'US'}}</span>
                    </span>
                    <span class="tel" *ngIf="$order.ship_to?.fax">
                      <span class="type">Fax:</span>
                      <span class="value">{{$order.ship_to?.fax}}</span>
                    </span>
                  </div>
                </div>
                <div class="column">
                  <div class="dt-published">
                    <span class="type">Order Date</span>
                    <span class="value">{{$order.ordered_at | localizedate: (vpUser | async)?.timezone | date:dateFormat}}</span>
                  </div>
                  <div class="dt-updated">
                    <span class="type">Ship Via</span>
                    <span class="value">{{$order.ship_via}}</span>
                  </div>
                  <div class="dt-updated">
                    <span class="type">Terms</span>
                    <span class="value">{{$order.payment_terms}}</span>
                  </div>
                  <!--                <div class="client-name">-->
                  <!--                  <span class="type">Client</span>-->
                  <!--                  <span class="value">{{$order.client?.name}}</span>-->
                  <!--                </div>-->
                </div>
              </div>
              <p class="p-summary"><span class="type">Sidemark</span> {{$order.side_mark}}</p>

              <div class="e-content">
                <table class="item-table">
                  <thead class="item-table__head">
                    <tr>
                      <th colspan="2">Items</th> <!-- this is where the image is -->
                      <th></th>
                      <th colspan="2"></th>
                    </tr>
                  </thead>

                  <tbody class="item-table__body">
                    <ng-container *ngFor="let $item of $order.items; let i=index">
                      <tr class="h-product">
                        <td>
                          <div class="u-photo">
                            <img *ngIf="$item.primary_image"
                                  class="u-photo"
                                  [ngClass]="{'clickable': ($item | imageOccurrences) > 0 || ($item | documentOccurrences) > 0}"
                                  src="{{$item.primary_image}}"
                                  alt=""
                                  (click)="viewAttachments($item)" />
                              <span *ngIf="(($item | imageOccurrences) > 0 || ($item | documentOccurrences) > 0) && $item.primary_image"
                                class="material-icons zoom-icon" (click)="viewAttachments($item)">zoom_in</span>
                            <div *ngIf="!$item.primary_image" class="u-empty-photo"></div>
                          </div>
                        </td>
                        <td class="desc" [ngClass]="{'no-item-number-component': !$item.item_number && !$item.component}">
                          <div>
                            <a>
                              {{$item.item_number}}
                              <ng-container *ngIf="$item.component"> - {{$item.component}}</ng-container></a>
                          </div>

                          <p>{{$item.short_description}}</p>
                          <div class="e-description">
                            <p>{{$item.long_description}}</p>
                          </div>
                          <!-- determine if there are any attachments, which will allow a click function to be called, opening the modal -->
                          <div *ngIf="(($item | imageOccurrences) > 0 || ($item | documentOccurrences) > 0) && ($item.primary_image || $item.files.length > 0)"
                            class="e-description attachment-link-details" (click)="viewAttachments($item)">
                            <p>View&nbsp;<ng-container *ngIf="($item | documentOccurrences) > 0">{{ ($item | documentOccurrences) }} file<ng-container *ngIf="($item | documentOccurrences) !== 1">s</ng-container>
                              </ng-container>
                              <!-- if order has both images and documents -->
                              <ng-container *ngIf="($item | documentOccurrences) > 0 && ($item | imageOccurrences) > 0">&nbsp;and&nbsp;</ng-container>
                              <ng-container *ngIf="($item | imageOccurrences) > 0">{{ ($item | imageOccurrences) }}&nbsp;image<ng-container *ngIf="($item | imageOccurrences) !== 1">s</ng-container></ng-container>
                            </p>
                          </div>
                        </td>

                        <td>
                          <table class="item-table-details" style="width: 250px;">
                            <tbody class="item-table-details__body">
                              <tr>
                                <td style="width: 125px;">
                                  <label>Quantity</label>
                                </td>
                                <td style="width: 50px;">{{$item.quantity}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Unit</label>
                                </td>
                                <td>{{$item.unit}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Unit Price</label>
                                </td>
                                <td>{{$item.unit_cost | currencyjs}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Total Cost</label>
                                </td>
                                <td>{{$item.total_cost | currencyjs}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Other Costs</label>
                                </td>
                                <td>{{$item.other_cost | currencyjs}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Sales Tax</label>
                                </td>
                                <td>{{$item.sales_tax | currencyjs}}</td>
                              </tr>
                              <tr>
                                <td>
                                  <label>Total</label>
                                </td>
                                <td>{{$item.total_purchase | currencyjs}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="!$order.items || $order.items.length === 0">
                      <tr class="h-product">
                        <td>
                          <div class="u-photo"></div>
                        </td>
                        <td class="desc no-item-number-component">
                          <div></div>
                          <p></p>
                          <div class="e-description">
                            <p></p>
                          </div>
                        </td>
                        <td>
                          <table class="item-table-details" style="width: 250px;">
                            <tbody class="item-table-details__body">
                            <tr>
                              <td style="width: 125px;">
                                <label>Quantity</label>
                              </td>
                              <td style="width: 50px;">{{ 0 | currencyjs }}</td>
                            </tr>
                            <tr>
                              <td>
                                <label>Unit Price</label>
                              </td>
                              <td>{{0 | currencyjs}}</td>
                            </tr>
                            <tr>
                              <td>
                                <label>Total Cost</label>
                              </td>
                              <td>{{0 | currencyjs}}</td>
                            </tr>
                            <tr>
                              <td>
                                <label>Other Costs</label>
                              </td>
                              <td>{{0 | currencyjs}}</td>
                            </tr>
                            <tr>
                              <td>
                                <label>Sales Tax</label>
                              </td>
                              <td>{{0 | currencyjs}}</td>
                            </tr>
                            <tr>
                              <td>
                                <label>Total</label>
                              </td>
                              <td>{{0 | currencyjs}}</td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>

                  <tfoot class="item-table__foot">
                    <td></td>
                    <td></td>
                    <td>
                      <table style="width: 250px;">
                        <tbody class="item-table-details__body">
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Sub Total</label>
                            </th>
                            <td colspan="2" style="width: 50px;">{{$order.cost | currencyjs}}</td>
                          </tr>
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Other Costs</label>
                            </th>
                            <td colspan="2" style="width: 50px;">{{$order.other_cost | currencyjs}}</td>
                          </tr>
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Sales Tax</label>
                            </th>
                            <td colspan="2" style="width: 50px;">{{$order.sales_tax | currencyjs}}</td>
                          </tr>
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Total</label>
                            </th>
                            <td colspan="2" style="width: 50px;">{{$order.total_price | currencyjs}}</td>
                          </tr>
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Deposit Requested</label>
                            </th>
                            <td colspan="2" style="width: 50px;">{{$order.purchase_deposit | currencyjs}}</td>
                          </tr>
                          <!--                <tr>-->
                          <!--                  <th colspan="3">Deposit Requested</th>-->
                          <!--                  <td>$1,200.00</td>-->
                          <!--                </tr>-->
                          <!--                <tr>-->
                          <!--                  <th colspan="3">Discount</th>-->
                          <!--                  <td>$0.00</td>-->
                          <!--                </tr>-->
                          <!--                <tr>-->
                          <!--                  <th colspan="3">Payment Applied</th>-->
                          <!--                  <td>$0.00</td>-->
                          <!--                </tr>-->
                          <!--                <tr>-->
                          <!--                  <th colspan="3">Balance:</th>-->
                          <!--                  <td>$1,200.00</td>-->
                          <!--                </tr>-->
                          <tr>
                            <th colspan="3" style="width: 125px;">
                              <label>Currency</label>
                            </th>
                            <td colspan="2" style="width: 50px;">USD</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tfoot>
                </table>
                <ng-container *ngIf="$order.special_instructions">
                  <h3 class="notes-header">Special Instructions</h3>
                  <p class="notes-text">{{$order.special_instructions}}</p>
                </ng-container>
                <ng-container *ngIf="$order.terms">
                  <h3 class="notes-header">Terms</h3>
                  <p class="notes-text">{{$order.terms}}</p>
                </ng-container>
              </div>

              <div class="footer-address">
                <span>{{$order.designer?.name}}</span>
                <span class="p-street-address">{{$order.designer?.address?.address}}, </span>
                <span class="p-extended-address">{{$order.designer?.address?.address_ext}}, </span>
                <span class="p-locality">{{$order.designer?.address?.city}}, </span>
                <span class="p-region">{{$order.designer?.address?.province}}, </span>
                <span class="p-postal-code">{{$order.designer?.address?.postal_code}}</span>
                <span class="p-country-name">{{$order.designer?.address?.country}}</span>
                <span class="tel">
                  <span class="type">Phone: </span>
                  <span class="value">{{$order.designer?.address?.phone}}</span>
                </span>
                <span class="u-email" *ngIf="$order.designer?.email">
                  <span class="type">Email: </span>
                  <a class="value" href="{{$order.designer?.email}}">{{$order.designer?.email}}</a>
                </span>
              </div>

            </div>
          </div>
        <div
          class="papper chat-anchor"
          [ngClass]="{'animated-hide': isOrderShown}"
          *ngIf="((header | async) !== 'unlinked' && (organization | async) && (( order | async ) | doesOrderBelongToOrganization:(organization | async)))">
          <vp-order-chat [order]="order" [vpUser]="vpUser" (isInProgressOrder)="checkInProgressOrderStatus($event)"></vp-order-chat>
      </div>
    </div>

    <kendo-dialog *ngIf="acceptDialogOpen | async" #approveDialog title="Accept Order"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (click)="determineCloseModal($event)"
                  (close)="closeAcceptDialog()">
      <div class="dialog-content">
        <form class="form-holder" [formGroup]="orderAcceptForm" (ngSubmit)="sendAccept()">
          <p>To accept this order, assign an Order Number, Date and Name</p>
          <div class="form-group">
            <div class="form-control">
              <div class="layout layout--stretch">
                <div class="layout__item  u-2/5">
                  <kendo-label for="order_number" text="Order Number" class="k-label-inline"></kendo-label>
                </div>
                <div class="layout__item  u-3/5">
                  <input kendoTextBox id="order_number" type="text" formControlName="vendor_invoice_id" [autofocus] (keyup.enter)="autoFocusFormEnter($event, 1)">
                </div>
              </div>
            </div>
            <kendo-formfield class="form-control">
              <div class="layout layout--stretch">
                <kendo-label class="layout__item u-2/5 k-label-inline k-text-end" for="order_date" text="Date"></kendo-label>
                <kendo-datepicker class="layout__item u-3/5" #accept_order_date formControlName="accept_order_date"
                  [calendarType]="calendarType" [(value)]="acceptOrderDateValue" [format]="dateFormat" [min]="minDate" [max]="acceptMaxDate" (keyup.enter)="autoFocusFormEnter($event, 2)"></kendo-datepicker>
              </div>
              <kendo-formerror class="k-text-end">Invalid Date</kendo-formerror>
            </kendo-formfield>
            <div class="form-control">
              <div class="layout layout--stretch">
                <div class="layout__item  u-2/5">
                  <kendo-label for="order_name" text="Your Name" class="k-label-inline"></kendo-label>
                </div>
                <div class="layout__item  u-3/5">

                  <input kendoTextBox id="order_name" type="text" [ngModel]="(vpUser | async).first_name + ' ' + (vpUser | async).last_name" formControlName="order_accepted_name"
                         (keyup.enter)="autoFocusFormEnter($event, 3)">

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closeAcceptDialog()">Cancel</button>

        <button kendoButton primary="true"
                [disabled]="(acceptProcessing | async) || !(orderAcceptForm.valid)"
                (click)="sendAccept()"
                #send_accept_btn>Accept Order</button>

      </kendo-dialog-actions>
    </kendo-dialog>

  <!-- Request Payments Dialog-->

    <kendo-dialog *ngIf="requestPaymentDialogOpen | async" title="Request Payment"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (click)="determineCloseModal($event, 'payment_options')"
                  (close)="closePaymentOptionsDialog()">
      <div class="dialog-content">
        <form class="form-holder" [formGroup]="requestPaymentForm" (ngSubmit)="sendPaymentOptions()">
          <p>Please provide a requested amount and a payment option.</p>
          <div class="form-group">
            <div class="form-control">
              <div class="layout layout--stretch">
                <div class="layout__item  u-2/5">
                  <kendo-label for="requested_payment_amount" text="Requested Amount:" class="k-label-inline"></kendo-label>
                </div>
                <div class="layout__item  u-3/5">
                  <input kendoTextBox id="requested_payment_amount" type="text" formControlName="requested_payment_amount" [autofocus]>
                </div>
              </div>
            </div>
            <div class="layout__item  u-3/5">
              <kendo-label text="Payment Options:" class="k-label-inline"></kendo-label>
            </div>
            <div class="payment-buttons-container">
              <div class="ach-btn-container">
                <span class="material-icons icon-action-grey600-ic_account_balance_grey600_24dp ach-icon">account_balance</span>
                <button [ngClass]="{'payment-button-focus': selectedPaymentACH}" class="ach-btn" type="button" (click)="autoFocusPaymentOptions('ACH')">
                  ACH
                </button>
              </div>
              <div class="credit-card-btn-container">
                <span class="material-icons icon-ic_credit_card_black_24dp credit-card-icon">credit_card</span>
                <button [ngClass]="{'payment-button-focus': selectedPaymentCreditCard}" class="credit-card-btn" type="button" (click)="autoFocusPaymentOptions('Credit Card')">
                  Credit Card
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closePaymentOptionsDialog()">Cancel</button>

        <button kendoButton primary="true"
                [disabled]="requestPaymentProcessing | async"
                (click)="sendPaymentOptions()">Request Payment Info</button>

      </kendo-dialog-actions>
    </kendo-dialog>

    <kendo-dialog *ngIf="adjustDialogOpen" #adjustDialog title="Request Changes"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (close)="closeAdjustDialog()">
      <p>Let the designer know about changes this order needs before it can be accepted. Complete all fields.</p>
      <div class="form-group">
        <div class="form-control">
          <kendo-label for="order_changes" text="Requested Changes"></kendo-label>
          <textarea kendoTextArea id="order_changes" autoSize="true"></textarea>
        </div>
        <div class="form-control">
          <kendo-label for="order_change_name" text="Your Name"></kendo-label>
          <input kendoTextBox id="order_change_name" type="text">
        </div>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closeAdjustDialog()">Cancel</button>
        <button kendoButton primary="true" (click)="closeAdjustDialog()">Send Request</button>
      </kendo-dialog-actions>
    </kendo-dialog>

    <kendo-dialog *ngIf="rejectDialogOpen | async" #rejectDialog title="Reject Order"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (close)="closeRejectDialog()">
      <div class="form-group" [formGroup]="orderRejectForm">
        <kendo-formfield class="form-control">
          <kendo-label for="reject_order_date" text="Date"></kendo-label>
          <kendo-datepicker class="u-3/5" formControlName="reject_order_date"
            [calendarType]="calendarType" [(value)]="rejectOrderDateValue" [format]="dateFormat" [min]="minDate"
                            (keyup.enter)="autoFocusEnterReject(1)" #reject_order_date></kendo-datepicker>
          <kendo-formerror class="k-text-end u-3/5">Invalid Date</kendo-formerror>
        </kendo-formfield>
        <div class="form-control">
          <kendo-label for="reject_order_name" text="Your Name"></kendo-label>

          <input kendoTextBox id="reject_order_name" type="text" [ngModel]="(vpUser | async).first_name + ' ' + (vpUser | async).last_name" formControlName="order_rejected_name"
                 (keyup.enter)="autoFocusEnterReject(2)" #reject_order_name_btn>
        </div>
        <p>Optionally, Let the designer know why this order was rejected.</p>
        <div class="form-control">
          <kendo-label for="reject_order_reason" text="Reason for rejection"></kendo-label>
          <textarea kendoTextArea id="reject_order_reason" autoSize="true" formControlName="reason" [rows]="4" [maxLength]="255" [autofocus]></textarea>
        </div>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closeRejectDialog()">Cancel</button>

        <button kendoButton primary="true"
                [disabled]="rejectProcessing | async"
                (click)="sendRejection()"
                #send_reject_btn>Send Rejection</button>

      </kendo-dialog-actions>
    </kendo-dialog>

    <!--  Dialog to View/Download attachments to a corresponding Order-->
    <kendo-dialog *ngIf="attachmentsDialogOpen" title="Images & Documents"
                  [width]="970" [minWidth]="550"
                  [maxHeight]="'70vh'"
                  (click)="determineCloseModal($event, 'attachments')"
                  (close)="closeAttachmentsDialog()">
      <div class="dialog-content-attachments">

        <!-- Displaying Primary Image -->
        <div *ngIf="viewItem.primary_image" class="primary-image-container" (click)="download(viewItem.primary_image)">
          <img class="image-files" [title]="viewItem.primary_image" [src]="viewItem.primary_image">
          <span class="material-icons file-download-icon">file_download</span>
        </div>

        <!-- Image List-->
        <ng-container *ngFor="let $files of viewItem.files; let i=index">
          <!-- Show Image files if they exist-->
          <div *ngIf="$files.type === 'image'" class="images-content-container" (click)="download($files.url, $files.name)">
            <img class="image-files" [title]="$files?.url" [src]="$files?.url">
            <span class="material-icons file-download-icon">file_download</span>
          </div>
        </ng-container>

        <!-- Document List-->
        <div *ngIf="(viewItem | documentOccurrences) > 0" class="documents-content-container">
          <ng-container *ngFor="let $files of viewItem.files; let i=index">
            <div *ngIf="$files.type === 'document'" class="document-details" (click)="download($files.url, $files.name)">
              <span class="material-icons description-icon-document">description</span>
              <div class="file-info">
                <p class="document-description" [title]="$files.description">{{$files.description | truncate:20}}</p>
                <p class="document-file-name" [title]="$files.name"> {{$files.name | truncateFileName: 35}}</p>
              </div>
              <span class="material-icons file-download-icon-document">file_download</span>
            </div>
          </ng-container>
        </div>

      </div>

    </kendo-dialog>

  </ng-container>

  <kendo-dialog *ngIf="freightTrackingDialogOpen | async" #freightTrackingDialog title="Freight Tracking"
                [width]="600" [minWidth]="450"
                [maxHeight]="'70vh'"
                (close)="closeFreightTrackingDialog()">
    <div class="dialog-content">
      <form class="form-holder" [formGroup]="freightTrackingForm" (ngSubmit)="updateFreightTracking()">
        <div class="form-groups-container">
          <div class="form-group">
            <kendo-formfield class="form-control">
              <kendo-label for="tracking_number" text="Freight Tracking #"></kendo-label>
              <input kendoTextBox id="tracking_number" [maxLength]="75" type="text" formControlName="tracking_number" [autofocus] (keyup.enter)="autoFocusFormEnter($event, 1)">
            </kendo-formfield>
            <kendo-formfield class="form-control">
              <kendo-label for="est_ship_date" text="Estimated Ship Date"></kendo-label>
              <kendo-datepicker class="u-3/5" id="est_ship_date" formControlName="est_ship_date"
                                [calendarType]="calendarType" [format]="dateFormat" [min]="minDate" (keyup.enter)="autoFocusFormEnter($event, 2)"></kendo-datepicker>
              <kendo-formerror class="k-text-end u-3/5">Invalid Date</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="form-control">
              <kendo-label for="date_shipped" text="Date Shipped"></kendo-label>
              <kendo-datepicker class="u-3/5" id="date_shipped" formControlName="date_shipped"
                                [calendarType]="calendarType" [format]="dateFormat" [min]="minDate" (keyup.enter)="autoFocusFormEnter($event, 3)"></kendo-datepicker>
              <kendo-formerror class="k-text-end u-3/5">Invalid Date</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="form-control">
              <kendo-label for="delivered" text="Delivered"></kendo-label>
              <kendo-datepicker class="u-3/5" id="delivered" formControlName="delivered"
                                [calendarType]="calendarType" [format]="dateFormat" [min]="minDate" (keyup.enter)="autoFocusFormEnter($event, 4)"></kendo-datepicker>
              <kendo-formerror class="k-text-end u-3/5">Invalid Date</kendo-formerror>
            </kendo-formfield>
            <!--          <div class="form-control">-->
            <!--            <div class="layout layout&#45;&#45;stretch">-->
            <!--              <div class="layout__item  u-2/5">-->
            <!--                <kendo-label for="shipping_provider" text="Shipping Provider" class="k-label-inline"></kendo-label>-->
            <!--              </div>-->
            <!--              <div class="layout__item  u-3/5">-->
            <!--                <input kendoTextBox id="shipping_provider"  [maxLength]="75" type="text" formControlName="shipping_provider"/>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          <div class="form-control">-->
            <!--            <div class="layout layout&#45;&#45;stretch">-->
            <!--              <div class="layout__item  u-2/5">-->
            <!--                <kendo-label for="your_name" text="Your Name" class="k-label-inline"></kendo-label>-->
            <!--              </div>-->
            <!--              <div class="layout__item  u-3/5">-->
            <!--                <input kendoTextBox id="your_name"  [maxLength]="75" type="text" formControlName="your_name">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
      </form>
    </div>
    <kendo-dialog-actions layout="stretched">
      <button kendoButton (click)="closeFreightTrackingDialog()">Cancel</button>

      <button kendoButton primary="true"
              [disabled]="freightTrackingProcessing | async"
              (click)="updateFreightTracking()"
              #update_freight_tracking_btn>Update Tracking #</button>

    </kendo-dialog-actions>
  </kendo-dialog>
  <vp-sign-up-modal [uuid]="uuid | async" (signUp)="signUp()"></vp-sign-up-modal>
  <div>
    <button type="button" *ngIf="isOrderShown" class="swipe-button chat" (click)="responsiveBlocksToggle()">Chat</button>
    <button type="button" *ngIf="!isOrderShown" class="swipe-button orders" (click)="responsiveBlocksToggle()">Order</button>
  </div>
</div>
