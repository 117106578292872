<ng-container *ngIf="(organizationProfile | async) as $org">
  <h5>Payment access settings</h5>
  <div class="payment-option-details" kendoTooltip filter=".tooltip-enabled" position="top">
    <img src="../../../../../../assets/Images/stripe.svg" alt="" class="stripe-img" />
    <a [href]="stripeLoginUrl" class="stripe-link">
      <button kendoButton
              [primary]="true"
              class="btn btn-primary">
        {{$org.stripe_status === stripeStatus.NotCreated ? 'Create/Connect Stripe account' : 'Review connected Stripe account'}}
      </button>
    </a>
    <a *ngIf="$org.stripe_status !== stripeStatus.NotCreated" class="stripe-link" href="https://dashboard.stripe.com/" target="_blank">
      <button kendoButton class="btn btn-secondary">
        Dashboard
      </button>
    </a>
    <div [ngSwitch]="$org.stripe_status" class="stripe-state-info">
      <ng-container *ngSwitchCase="stripeStatus.Verification">
<!--        <span class="payment-option-status-text"></span>-->
        <span class="icon-info k-icon k-i-information tooltip-enabled"
              title="Your account is under review"></span>
      </ng-container>
      <ng-container *ngSwitchCase="stripeStatus.NotValid">
        <span class="icon-info k-icon k-i-warning tooltip-enabled"
              title="Log in to your stripe account and correct or fill in the details"></span>
      </ng-container>
      <ng-container *ngSwitchCase="stripeStatus.Valid">
        <span class="icon-info k-icon k-i-check tooltip-enabled"
              title="Your account is active"></span>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <span class="icon-info k-icon k-i-information tooltip-enabled"
              title="Create your account to receive Stripe payments"></span>
      </ng-container>
    </div>
    <a href="https://knowledge.studiodesigner.com/" target="_blank"
       class="k-icon k-i-question tooltip-enabled"
       title="Read more about Stripe payments"></a>
<!--    <a href="javascript:void(0);"-->
<!--       class="k-icon k-i-close tooltip-enabled"-->
<!--       title="Unlink Stripe account"></a>-->
  </div>
  <br><br>

  <form class="form-holder" [formGroup]="vendorForm" *ngIf="$org.stripe_status === stripeStatus.Valid">
    <ul class="k-checkbox-list">
      <li class="k-checkbox-item">
        <input type="checkbox" kendoCheckBox formControlName="allow_credit_card"/>
        <kendo-label class="k-checkbox-label"
                     text="Allow Credit Card"></kendo-label>
      </li>
      <li class="k-checkbox-item">
        <input type="checkbox" kendoCheckBox formControlName="allow_ach"/>
        <kendo-label class="k-checkbox-label"
                     text="Allow ACH"></kendo-label>
      </li>
    </ul>
  </form>
</ng-container>
