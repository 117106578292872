/**
 * src/app/modules/system-admin/state/systemAdmin.selector.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createSelector} from '@ngrx/store';
import {OrganizationEdit, systemAdminFeatureKey, SystemAdminState} from './systemAdmin.reducer';
import {CompositeFilterDescriptor, State as KendoDataState} from '@progress/kendo-data-query';

export const selectSystemAdminState = (state: any) => state[systemAdminFeatureKey];

export const selectSystemAdminOrganizationListPager = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.organizationList.pager
);

export const selectSystemAdminOrganizationListSearchFilter = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.organizationList.searchFilter
);

export const selectSystemAdminOrganizationListStatusFilter = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.organizationList.statusFilter
);

export const selectSystemAdminOrganizationListPagerWithFilters = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => {
    const pager: KendoDataState = {...state.organizationList.pager };

    if (state.organizationList.statusFilter !== null || state.organizationList.searchFilter !== null) {
      pager.filter = {
        logic: 'and',
        filters: []};
    }

    if (state.organizationList.statusFilter !== null) {
      pager.filter.filters.push(state.organizationList.statusFilter);
    }

    if (state.organizationList.searchFilter !== null) {
      pager.filter.filters.push(state.organizationList.searchFilter);
    }

    return pager;
  }
);

export const selectSystemAdminOrganizationEdit = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.organizationEdit
);

export const selectSystemAdminOrganizationEditOrganization = createSelector(
  selectSystemAdminOrganizationEdit,
  (state: OrganizationEdit) => state.organization
);

export const selectSystemAdminOrganizationHasOrganization = createSelector(
  selectSystemAdminOrganizationEdit,
  (state: OrganizationEdit) => state.organization?.id !== undefined
);

export const selectSystemAdminOrganizationEditLogin = createSelector(
  selectSystemAdminOrganizationEdit,
  (state: OrganizationEdit) => state.currentUser
);

// plans
export const selectSystemAdminPlanListSearchFilter = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.planList.searchFilter
);

export const selectSystemAdminPlanListPagerWithFilters = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => {
    const pager: KendoDataState = {...state.planList.pager };

    if (state.planList.statusFilter !== null || state.planList.searchFilter !== null) {
      pager.filter = {
        logic: 'and',
        filters: []};
    }

    if (state.planList.statusFilter !== null) {
      pager.filter.filters.push(state.planList.statusFilter);
    }

    if (state.planList.searchFilter !== null) {
      pager.filter.filters.push(state.planList.searchFilter);
    }

    return pager;
  }
);

export const selectSystemAdminPlanEdit = createSelector(
  selectSystemAdminState,
  (state: SystemAdminState) => state.planEdit
);

// export const selectSystemAdminPlanEditPlan = createSelector(
//   selectSystemAdminPlanEdit,
//   (state: OrganizationEdit) => state.plan
// );
