/**
 * src/modules/shared/pipes/graph-total.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/11/2021
 */
import {Pipe, PipeTransform} from '@angular/core';
import {OrdersSummary} from '../../orders/models/orders-summary';
import * as currency from 'currency.js';

@Pipe({
  name: 'graphTotal'
})
export class GraphTotalPipe implements PipeTransform {

  transform(summary: OrdersSummary[]): currency[] {
    if (!summary) {
      return [];
    }
    const output: currency[] = [];
    for (const o of summary) {
      output.push(o.value);
    }
    return output;
  }

}
