<!--<div class="filters-block layout__item">-->
<!--  <div class="papper">-->
<!--    <h5>Filters</h5>-->
<!--<div class="grid-controls">-->

<!--  <div id="widget-filter" class="dashboard-filters">-->
<!--        <p class="label">Category</p>-->
<!--    <kendo-dropdownlist-->
<!--                        id="widget-dropdown"-->
<!--                        *ngIf="(widgets | async) && (filters | async)"-->
<!--                        [data]="widgets | async | removeSelected:(filters | async):'widget':'widget'"-->
<!--                        [defaultItem]="{text: ((filters | async)?.widget ? (filters | async)?.widget : WIDGET_ENUM.TotalValueOfOrders), widget: ((filters | async)?.widget ? (filters | async)?.widget : WIDGET_ENUM.TotalValueOfOrders)}"-->
<!--                        [textField]="'text'"-->
<!--                        [valueField]="'widget'"-->
<!--                        (selectionChange)="onWidgetChanged($event.widget)">-->
<!--    </kendo-dropdownlist>-->
<!--  </div>-->

<!--  <div id="timeframe-filter" class="dashboard-filters">-->
<!--        <p class="label">Timeframe</p>-->
<!--    <kendo-dropdownlist-->
<!--                        id="timeframes-dropdown"-->
<!--                        *ngIf="timeframes | async"-->
<!--                        [data]="timeframes | async | removeSelected:(filters | async):'timeframe':'timeFrame'"-->
<!--                        [defaultItem]="{text: ((filters | async)?.timeframe ? (filters | async)?.timeframe : TIMEFRAMES_ENUM.Year), timeFrame: ((filters | async)?.timeframe ? (filters | async)?.timeframe : TIMEFRAMES_ENUM.Year)}"-->
<!--                        [textField]="'text'"-->
<!--                        [valueField]="'timeFrame'"-->
<!--                        (selectionChange)="onTimeframeChanged($event.timeFrame)">-->
<!--    </kendo-dropdownlist>-->
<!--  </div>-->
<!--</div>-->
<!--  </div>-->
<!--</div>-->
<div *ngIf="!loaded" class="k-i-loading"></div>
<ng-container *ngIf="loaded && display === DISPLAY_ENUM.Top10 && data | async as $customers">
  <div class="layout--flush layout__item">
      <div class="papper dashboard-list">
      <kendo-card [width]="'100%'">
        <kendo-card-header class="k-hbox">
          <div>
            <h5 kendoCardTitle>Top Customers</h5>
            <p kendoCardSubtitle>{{ widget }}</p>
          </div>
        </kendo-card-header>
        <kendo-card-body>
          <ng-container *ngFor="let customer of $customers | filterCustomers:widget">
            <div id="row-content" class="dashboard-list__customer-item bold">
              <div class="dashboard-list__customer-item bold">
                <a class="dashboard-list__customer-item bold" (click)="onCustomerChanged(customer.label)">
                  <div>
                    <span class="k-mr-3">{{ customer | customerDataPoint:widget }}</span>
                    <kendo-avatar class="k-mr-3" [initials]="customer.label" [width]="'32px'" [height]="'32px'" [shape]="'circle'"></kendo-avatar>
                    {{ customer.label }}
                  </div>
                  <span class="dashboard-list__select">Select</span>
                </a>
              </div>
            </div>
          </ng-container>
          <div class="dashboard-list__empty" *ngIf="loaded && !((data | async)?.length > 0)">No Data</div>
        </kendo-card-body>
      </kendo-card>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="display === DISPLAY_ENUM.Graph">
  <ng-container *ngIf="loaded && data | async | graphReverse as $ordersSummary">
  <div class="layout__item">
    <div class="papper" *ngIf="loaded">
    <vp-total-and-average-graph *ngIf="widget === WIDGET_ENUM.TotalValueOfOrders"
                                [title]="(customer ? ( customer | titlecase) : 'All Customers')"
                                [subtitle]="WIDGET_ENUM.TotalValueOfOrders"
                                [axisLabelFormat]="'$##,#'"
                                [lineSeriesLabels]="{format: 'c'}"
                                [lineColor]="'#F06292'"
                                [lineLabel]="WIDGET_ENUM.TotalValueOfOrders + ' over ' + timeframe"
                                [lineData]="$ordersSummary | graphTotal"
                                [categories]="$ordersSummary | graphCategories"
                                [entitiesLabel]="'${0}'"
    ></vp-total-and-average-graph>
    <vp-total-and-average-graph *ngIf="widget === WIDGET_ENUM.AverageValueOfOrders"
                                [title]="(customer ? ( customer | titlecase) : 'All Customers')"
                                [subtitle]="WIDGET_ENUM.AverageValueOfOrders"
                                [lineColor]="'#16A085'"
                                [axisLabelFormat]="'$##,#'"
                                [lineSeriesLabels]="{format: 'c'}"
                                [lineLabel]="WIDGET_ENUM.AverageValueOfOrders + ' over ' + timeframe"
                                [lineData]="$ordersSummary | graphAverageValuePerQuantity"
                                [categories]="$ordersSummary | graphCategories"
                                [entitiesLabel]="'${0} per order'"
    ></vp-total-and-average-graph>
    <vp-total-and-average-graph *ngIf="widget === WIDGET_ENUM.QuantityOfOrders"
                                [title]="(customer ? ( customer | titlecase) : 'All Customers')"
                                [subtitle]="WIDGET_ENUM.QuantityOfOrders"
                                [lineColor]="'#FFC300'"
                                [lineLabel]="WIDGET_ENUM.QuantityOfOrders + ' over ' + timeframe"
                                [lineData]="$ordersSummary | graphQuantity"
                                [categories]="$ordersSummary | graphCategories"
                                [entitiesLabel]="'{0} orders'"
    ></vp-total-and-average-graph>
    </div>
  </div>
  </ng-container>
</ng-container>
