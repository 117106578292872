/**
 * src/app/modules/system-admin/system-admin.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SystemAdminRoutingModule} from './system-admin-routing.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {HeaderComponent} from "./components/header/header.component";
import {ListOrganizationsComponent} from "./components/list-organization/list-organizations.component";
import {DropDownListModule, MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {ButtonGroupModule, ButtonModule} from "@progress/kendo-angular-buttons";
import {
  CheckBoxModule,
  FormFieldModule,
  RadioButtonModule,
  SliderModule,
  SwitchModule,
  TextBoxModule
} from "@progress/kendo-angular-inputs";
import {GridModule} from "@progress/kendo-angular-grid";
import {DialogModule} from "@progress/kendo-angular-dialog";
import {OrganizationsService} from "./services/organizations.service";
import {StoreModule} from "@ngrx/store";
import * as fromSystemAdmin from "./state/systemAdmin.reducer";
import {LayoutModule, TabStripModule} from "@progress/kendo-angular-layout";
import {ChartsModule} from "@progress/kendo-angular-charts";
import {EditOrganizationComponent} from "./components/edit-organization/edit-organization.component";
import {NgxMaskModule} from "ngx-mask";
import {ReactiveFormsModule} from "@angular/forms";
import {LabelModule} from "@progress/kendo-angular-label";
import {FormsService} from "../shared/services/forms.service";
import {IndicatorsModule} from "@progress/kendo-angular-indicators";
import { EditUserComponent } from './components/edit-user/edit-user.component';
import {SharedModule} from '../shared/shared.module';
import {StateResolver} from '../shared/services/state.resolver';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffects} from '../authentication/state/authentication.effects';
import {SystemAdminEffects} from './state/systemAdmin.effects';
import {EditPlanComponent} from "./components/edit-plan/edit-plan.component";
import {ListPlansComponent} from "./components/list-plan/list-plans.component";
import {OrganizationsModule} from "../organizations/organizations.module";
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {MapToolsService} from '../shared/services/map-tools.service';

@NgModule({
  declarations: [DashboardComponent, HeaderComponent, ListOrganizationsComponent, EditOrganizationComponent, ListPlansComponent, EditPlanComponent, EditUserComponent],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SystemAdminRoutingModule,
    DropDownListModule,
    ButtonModule,
    TextBoxModule,
    GridModule,
    CheckBoxModule,
    SwitchModule,
    DialogModule,
    ButtonGroupModule,
    SliderModule,
    StoreModule.forFeature(fromSystemAdmin.systemAdminFeatureKey, fromSystemAdmin.reducer),
    EffectsModule.forFeature([SystemAdminEffects]),
    LayoutModule,
    ChartsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    LabelModule,
    TabStripModule,
    IndicatorsModule,
    MultiSelectModule,
    SharedModule,
    NgxMaskModule,
    OrganizationsModule,
    TooltipModule,
    GooglePlaceModule,
    TooltipModule,
    FormFieldModule,
  ],
  providers: [OrganizationsService, FormsService, MapToolsService]
})
export class SystemAdminModule { }
