/**
 * src/app/modules/shared/components/graphs/total-and-average/total-and-average.component.ts
 *
 * @author John Eubank <John@studiodesigner.com>
 * @since 05/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */
import {Component, Input} from "@angular/core";
import {SeriesLabels} from "@progress/kendo-angular-charts";

@Component({
  selector: 'vp-total-and-average-graph',
  templateUrl: './total-and-average.component.html',
  styleUrls: ['./total-and-average.component.scss']
})
export class TotalAndAverageComponent {

  @Input() title: string;
  @Input() subtitle: string;

  @Input() axisLabelFormat: string; // $##,#
  @Input() lineSeriesLabels: SeriesLabels; // {format: 'c'}
  @Input() barSeriesLables: SeriesLabels; // {format: 'c'}

  @Input() barColor = '#006064';
  @Input() barLabel: string;
  @Input() barData: any[];

  @Input() lineColor = '#F06292';
  @Input() lineLabel: string;
  @Input() lineData: any[];

  @Input() categories: any[];

  @Input() entitiesLabel: string;

  public loading = false;

  constructor() {
  }

}
