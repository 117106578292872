import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFileExtension'
})
export class ExtractFileExtensionPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    let getFileExtension = value.split(/[#?]/)[0].split('.').pop().trim();

    if (getFileExtension.includes('xl')) {
      getFileExtension = 'excel';
    }

    if (getFileExtension.includes('doc')) {
      getFileExtension = 'word';
    }

    return getFileExtension;
  }
}
