/**
 * src/app/modules/orders/pipes/item-total.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 01/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';
import {Orders} from "../models/orders";
import {Items} from "../models/items";

@Pipe({
  name: 'documentOccurrences'
})
export class DocumentOccurrencesPipe implements PipeTransform {
  transform(item: Items): number {
    return item.files ? item.files.filter((doc) => (doc.type === 'document')).length : 0;
  }
}
