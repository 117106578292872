/**
 * src/app/modules/system-admin/components/list-organization/list-organization.component.ts
 *
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {ToastrService} from 'ngx-toastr';
import {SwitchComponent} from '@progress/kendo-angular-inputs';
import {FilterDescriptor, State} from '@progress/kendo-data-query';
import {Observable, Subscription} from 'rxjs';
import {Status} from '../../../shared/models/statuses.enum';
import {OrganizationsService} from '../../services/organizations.service';
import {filter, take} from 'rxjs/operators';
import {ResultListDTO} from '../../../shared/models/resultList.dto';
import {Organizations} from '../../../organizations/models/organizations';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {
  selectSystemAdminOrganizationListPagerWithFilters,
  selectSystemAdminOrganizationListSearchFilter,
  selectSystemAdminOrganizationListStatusFilter
} from '../../state/systemAdmin.selector';
import {
  organizationEditSubjectChangeAction,
  organizationEditSubjectGetAction,
  organizationEditTabChangeAction,
  organizationListPagerAddNameFilterAction,
  organizationListPagerAddStatusFilterAction,
  organizationListPagerChangeAction,
  organizationListPagerResetNameFilterAction,
  organizationListPagerResetStatusFilterAction
} from '../../state/systemAdmin.actions';
import {Router} from '@angular/router';
import {
  selectActiveOrganizationCount,
  selectInactiveOrganizationCount
} from '../../../organizations/state/organizations.selector';
import {selectIsAuthenticated} from '../../../authentication/state/authentication.selector';
import {HandleErrorService} from '../../../shared/services/handle-error.service';

@Component({
  selector: 'vp-system-admin-organizations-list',
  templateUrl: './list-organizations.component.html',
  styleUrls: ['./list-organizations.component.scss']
})
export class ListOrganizationsComponent implements OnInit, OnDestroy {
  public isLoadingVendors: boolean;
  public isLoadingGridData: boolean;
  public gridData: GridDataResult | ResultListDTO<Organizations>;
  public VendorStatus = Status;
  public confirmChangeVendorStatusDialogOpened: boolean;
  public vendorForChangeActiveStatus: '';
  public switchToVendorStatus: Status;

  // list-organization page statistics.
  public statistics = { total: 0 };

  // default page sizes.
  public pageSizes = [10, 25, 50];

  // sort settings for kendo grid.
  public sortableSettings = { allowUnsort: true,  mode: 'multi' };

  // the state of the kendo pager.
  public pagerState: State;

  // default item drop down items for filters.
  public changeStatusMenuItemsDefault = { text: 'Show All', status: 'show_all' };

  // drop down items for filters.
  public changeStatusMenuItems  = [
    { text: 'Show All', status: 'show_all' },
    { text: 'Active', status: true },
    { text: 'Inactive', status: false }
  ];

  // external filters.
  public searchFilterSelect: Observable<FilterDescriptor>;
  public statusFilterSelect: Observable<FilterDescriptor>;

  public selectedDropdownStatus: any;

  // counts
  public activeOrganizationCount: Observable<number>;
  public inactiveOrganizationCount: Observable<number>;

  private subscriptions: Subscription[] = [];

  constructor(
    private organizationsService: OrganizationsService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private errorHandler: HandleErrorService,
    private router: Router
  ) {}

  ngOnInit(): void {

    const pagerSubscription = this.store.select(selectSystemAdminOrganizationListPagerWithFilters)
      .pipe(filter(err => err !== null)).subscribe((pagerState: State) => {

        this.store.select(selectIsAuthenticated).pipe(take(1), filter(v => v)).subscribe(() => {
          this.organizationsService.getCollection(pagerState).pipe(take(1)).subscribe(r => {
              this.gridData = r;
              this.statistics.total = r.total;
            },
            (err) => {

              this.gridData = null;
              this.statistics.total = 0;
              this.errorHandler.handleError(err, 'Unable to get organizations from the server.');

            });


          this.pagerState = {...pagerState, filter: null};

          this.isLoadingGridData = false;
          this.isLoadingVendors = false;
        });

      });

    this.searchFilterSelect = this.store.select(selectSystemAdminOrganizationListSearchFilter);
    this.statusFilterSelect = this.store.select(selectSystemAdminOrganizationListStatusFilter);

    const statusSubscriber = this.statusFilterSelect.subscribe((status) => {

      if (status === null) {
        this.selectedDropdownStatus = this.changeStatusMenuItems[0];
      } else {

        this.selectedDropdownStatus = this.changeStatusMenuItems[this.changeStatusMenuItems
          .indexOf(this.changeStatusMenuItems
          .filter(value => value.status === status.value)
          .pop())];

      }

    });

    this.activeOrganizationCount = this.store.select(selectActiveOrganizationCount);
    this.inactiveOrganizationCount = this.store.select(selectInactiveOrganizationCount);

    this.subscriptions.push(pagerSubscription);
    this.subscriptions.push(statusSubscriber);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Add text search filter.
   * @param $event
   */
  onSearchInput($event) {

    if ($event === null) {
      this.store.dispatch(organizationListPagerResetNameFilterAction());
      return;
    }

    // tslint:disable-next-line:no-shadowed-variable
    let filter: FilterDescriptor;

    filter = {
        field: 'name',
        operator: 'contains',
        value: $event,
        ignoreCase: true
    };

    this.store.dispatch(organizationListPagerAddNameFilterAction(filter));

  }

  /**
   * Add status filter.
   * @param $event
   */
  onStatusFiltersListChanged($event) {

    if ($event.status === 'show_all') {
      this.store.dispatch(organizationListPagerResetStatusFilterAction());
      return;
    }

    // tslint:disable-next-line:no-shadowed-variable
    let filter: FilterDescriptor = null;

    filter = {
        field: 'active',
        operator: 'eq',
        value: $event.status,
        ignoreCase: true
      };

    this.store.dispatch(organizationListPagerAddStatusFilterAction(filter));

  }

  addOrganization() {
    this.store.dispatch(organizationEditTabChangeAction({tab: 'details'}));
    this.store.dispatch(organizationEditSubjectChangeAction(new Organizations()));
    this.router.navigate(['/system-admin/organizations/details']);
  }

  editOrganization(id: any) {
    this.store.dispatch(organizationEditTabChangeAction({tab: 'details'}));
    this.store.dispatch(organizationEditSubjectGetAction({id}));
    this.router.navigate(['/system-admin/organizations/' + id]);
  }

  onDialogClose() {
  }

  changeVendorStatus() {
  }

  onVendorStatusChange(switchToVendorActive: boolean, vendor: any, switcher: SwitchComponent) {
  }

  /**
   * Dispatch a data state change.
   * @param state
   */
  dataStateChange(state: DataStateChangeEvent) {
    this.store.dispatch(organizationListPagerChangeAction(state));
  }

}
