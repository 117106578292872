import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FormsService} from '../../../shared/services/forms.service';
import {AuthenticationService} from '../../services/authentication.service';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'vp-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {

  public successVerifiedEmail = false;
  public errorVerifiedEmail = false;
  public error: string;

  public form: FormGroup;

  public emailValidationPattern: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.form = this.createForm();

    // if the uuid is passed to the login component, let's ensure that its passed along with the request
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      if (params && params.uuid) {
        this.verifyEmail(params.uuid);
      }
    });
  }

  verifyEmail(uuid: string): void {
    this.authenticationService.verifyEmail(uuid).subscribe(next => {
      this.successVerifiedEmail = true;
    }, err => {
      this.errorVerifiedEmail = true;
      this.error = err.error.message || '';
    });
  }

  private createForm() {

    return this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });

  }

  onSubmit() {
    if (!this.form.valid) {
      this.formsService.markFormGroupAsTouched(this.form);
      return;
    }
    this.router.navigate(['/resend-verify-email', this.form.get('email').value]);
  }

}
