/**
 * Based on order, determine if you are viewing an unlinked order
 *
 * src/app/modules/orders/pipes/is-unlinked.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 07/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import {Orders} from "../../models/orders";

@Pipe({
  name: 'isUnlinked'
})
export class IsUnlinkedPipe implements PipeTransform {
  transform(order: Orders): boolean {
    if (!order) {
      return;
    }
    return !!order.id;
  }
}
