/**
 * src/app/modules/system-admin/state/systemAdmin.reducer.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2020
 * @since 10/20
 */

import {Action, createReducer, on} from '@ngrx/store';
import {FilterDescriptor, State as KendoDataState} from '@progress/kendo-data-query';
import {
  loginEditSubjectChangeAction,
  organizationEditSubjectChangeAction,
  organizationEditTabChangeAction,
  organizationListPagerAddNameFilterAction,
  organizationListPagerAddStatusFilterAction,
  organizationListPagerChangeAction,
  organizationListPagerResetNameFilterAction,
  organizationListPagerResetStatusFilterAction, planEditSubjectChangeAction,
  planListPagerAddNameFilterAction,
  planListPagerChangeAction,
  planListPagerResetNameFilterAction
} from './systemAdmin.actions';
import {Organizations} from '../../organizations/models/organizations';
import {User} from '../../shared/models/user';
import {logoutSuccessAction} from '../../authentication/state/authentication.actions';
import {OrganizationsState} from "../../organizations/state/organizations.reducer";
import {Plans} from "../../organizations/models/plans";

export const systemAdminFeatureKey = 'system_admin';

export interface OrganizationListState {
  pager: KendoDataState;
  searchFilter: FilterDescriptor;
  statusFilter: FilterDescriptor;
}

export interface PlanListState {
  pager: KendoDataState;
  searchFilter: FilterDescriptor;
  statusFilter: FilterDescriptor;
}

export interface PlanEdit {
  plan: Plans;
}

export interface OrganizationEdit {
  tab: string;
  organization: Organizations;
  currentUser: User;
}

export interface SystemAdminState  {
  organizationList: OrganizationListState;
  organizationEdit: OrganizationEdit;
  planList: PlanListState;
  planEdit: PlanEdit;
}

export const initialState: SystemAdminState = {
  organizationList: {
    searchFilter: null,
    statusFilter: null,
    pager: {
      sort: [{dir: 'asc', field: 'name'}],
      filter: null,
      take: 10,
      skip: 0,
    }
  },
  organizationEdit: {
    organization: null,
    currentUser: null,
    tab: 'details'
  },
  planList: {
    searchFilter: null,
    statusFilter: null,
    pager: {
      sort: [{dir: 'asc', field: 'name'}],
      filter: null,
      take: 10,
      skip: 0,
    }
  },
  planEdit: {
    plan: null,
  },
};

const systemAdminReducer = createReducer(
  initialState,
  on(logoutSuccessAction, (state: SystemAdminState) => ({...state, ...initialState})),
  on(organizationListPagerChangeAction,
    (state: SystemAdminState, pager: KendoDataState) => ({...state, organizationList: { ...state.organizationList, pager: pager } })),
  on(organizationListPagerAddNameFilterAction, (state: SystemAdminState, filter: FilterDescriptor) =>
    ({...state, organizationList: { ...state.organizationList, searchFilter: filter, pager: {...state.organizationList.pager, skip: 0} } })
  ),
  on(organizationListPagerResetNameFilterAction, (state: SystemAdminState) =>
    ({...state, organizationList: { ...state.organizationList, searchFilter: null, pager: {...state.organizationList.pager, skip: 0} } })
  ),

  on(planListPagerChangeAction,
    (state: SystemAdminState, pager: KendoDataState) => ({...state, planList: { ...state.planList, pager: pager } })),
  on(planListPagerAddNameFilterAction, (state: SystemAdminState, filter: FilterDescriptor) =>
    ({...state, organizationList: { ...state.planList, searchFilter: filter, pager: {...state.planList.pager, skip: 0} } })
  ),
  on(planListPagerResetNameFilterAction, (state: SystemAdminState) =>
    ({...state, organizationList: { ...state.planList, searchFilter: null, pager: {...state.planList.pager, skip: 0} } })
  ),

  on(organizationListPagerAddStatusFilterAction, (state: SystemAdminState, filter: FilterDescriptor) => ({...state, organizationList: { ...state.organizationList, statusFilter: filter } })),
  on(organizationListPagerResetStatusFilterAction, (state: SystemAdminState) => ({...state, organizationList: { ...state.organizationList, statusFilter: null } })),

  on(organizationEditSubjectChangeAction, (state: SystemAdminState, org: Organizations) => ({...state, organizationEdit: { ...state.organizationEdit, organization: {...org} }})),

  on(planEditSubjectChangeAction, (state: SystemAdminState, plan: Plans) => ({...state, planEdit: { ...state.planEdit, plan: {...plan} }})),

  on(organizationEditTabChangeAction, (state: SystemAdminState, {tab}) => ({...state, organizationEdit: { ...state.organizationEdit, tab }})),
  on(loginEditSubjectChangeAction, (state: SystemAdminState, u: User) => ({...state, organizationEdit: { ...state.organizationEdit, currentUser: {...u} }})),
  on(logoutSuccessAction, (state: SystemAdminState) => ({...state, ...initialState})),
);

export function reducer(state: SystemAdminState | undefined, action: Action) {
  return systemAdminReducer(state, action);

}
