/**
 * src/app/modules/notifications/state/notifications.reducer.ts
 *
 * @author John Eubankzz <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2021
 * @since 03/21
 */

import {Action, createReducer, on} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query/dist/npm/state';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {ChatMessages} from '../../notifications/models/ChatMessages';
import {
  notificationsListPagerChangeAction,
  notificationsPagerAddNameFilterAction,
  notificationsPagerResetNameFilterAction,
  notificationsTabChangeAction,
  previewNotificationsListSubjectChangeAction,
  unreadNotificationCountSubjectChangeAction,
} from "./notifications.actions";

export const notificationsFeatureKey = 'notifications';

export class NotificationsState  {
  previewNotificationsList: ChatMessages[];
  list: {
    pager: KendoDataState
    searchFilter: FilterDescriptor | null;
    statusFilter: FilterDescriptor | null;
  };
  tab: 'all' | 'read' | 'unread';
  unreadCount: number;
}

export const initialState: NotificationsState = {
  previewNotificationsList: [],
  list : {
    pager: {
      sort: [{dir: 'desc', field: 'created_at'}],
      filter: null,
      take: 10,
      skip: 0,
    },
    searchFilter: null,
    statusFilter: null
  },
  tab: 'all',
  unreadCount: 0
};

const notificationsReducer = createReducer(
  initialState,
  on(previewNotificationsListSubjectChangeAction,
    (state: NotificationsState, previewNotificationsList: { previewNotificationsList: ChatMessages[] }) => ({...state, previewNotificationsList: previewNotificationsList.previewNotificationsList})
  ),
  on(
    notificationsListPagerChangeAction,
    (state: NotificationsState, listPager: KendoDataState) => ({...state, list: {...state.list, pager: listPager} })
  ),
  on(
    notificationsPagerAddNameFilterAction,
    (state: NotificationsState, searchFilter: FilterDescriptor) => ({...state, list: { ...state.list, searchFilter, pager: {...state.list.pager, skip: 0} } })
  ),
  on(
    notificationsPagerResetNameFilterAction,
    (state: NotificationsState) => ({...state, list: { ...state.list, searchFilter: null } })
  ),
  on(
    notificationsTabChangeAction,
    (state: NotificationsState, {tab}) => ({...state,  tab: tab, list: {
        pager: {
          sort: [{dir: 'desc', field: 'created_at'}],
          filter: null,
          take: 10,
          skip: 0,
        } as KendoDataState,
        searchFilter: null,
        statusFilter: null
      } })
  ),
  on(
    unreadNotificationCountSubjectChangeAction,
    (state: NotificationsState, {count}) => ({...state, unreadCount: count})
  )
);

export function reducer(state: NotificationsState | undefined, action: Action) {
  return notificationsReducer(state, action);

}
