/**
 * src/app/modules/orders/services/orders.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResultListDTO} from '../../shared/models/resultList.dto';
import {State} from '@progress/kendo-data-query';
import {TransformationsService} from '../../shared/services/transformations.service';
import {Orders} from '../models/orders';
import * as currency from 'currency.js';
import {OrdersSummary} from "../models/orders-summary";
import {FreightTracking} from "../../notifications/models/FreightTracking";
import {RejectOrder} from '../../notifications/models/RejectOrder';
import {AcceptOrder} from '../../notifications/models/AcceptOrder';
import {Timeframes} from "../../user-space-admin/models/timeframes.enum";
import {map} from 'rxjs/operators';
import {UnlinkedOrdersService} from "./unlinked-orders.service";
import {RequestPaymentOptions} from "../../notifications/models/RequestPaymentOptions";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService, private unlinkedOrdersService: UnlinkedOrdersService) { }

  /**
   * Get paginated collection of orders in the vendor portal.
   */
  public getCollection(state: State): Observable<ResultListDTO<Orders[]>> {

    const params = this.transformationsService.getHttpParamsFromKendoState(state);

    return this.httpClient.get<ResultListDTO<Orders[]>>(`/api/orders`, {params});

  }

  /**
   * Get a single organization in the vendor portal.
   */
  public getOne(id: number): Observable<Orders> {
    return this.httpClient.get<Orders>(`/api/orders/${id}`).pipe(map(orders => {
      orders.chatMessages?.forEach(m => m.created_at = new Date(m.created_at));
      return orders;
    }));
    // .pipe(switchMap(this.transformCurrency.bind(this)));
  }

  /**
   * Gets the summary of orders' quantity and total value by month
   */
  public getOrderVolumeSummary(customer?: string, salesCode?: string, timeframe?: Timeframes | ''): Observable<OrdersSummary[]> {
    let opts = '';
    opts += `${customer ? `customer=${customer}&` : ''}`;
    opts += `${salesCode ? `salesCode=${salesCode}&` : ''}`;
    opts += `${timeframe && timeframe.length ? `timeframe=${timeframe}&` : ''}`;
    return this.httpClient.get<OrdersSummary[]>(`/api/summary/ordervolume?${opts}`);
  }

  /**
   * Gets a list of the top customers for an organization (filterable by sales code + timeframe)
   */
  public getTopCustomersSummary(salesCode?: string, timeframe?: Timeframes | ''): Observable<OrdersSummary[]> {
    let opts = '';
    opts += `${salesCode ? `salesCode=${salesCode}&` : ''}`;
    opts += `${timeframe && timeframe.length ? `timeframe=${timeframe}&` : ''}`;
    return this.httpClient.get<OrdersSummary[]>(`/api/summary/topcustomers?${opts}`);
  }

  /**
   * Gets the list of customers
   */
  public getCustomers(): Observable<string[]> {
    return this.httpClient.get<string[]>(`/api/summaryfilters/customers`);
  }

  /**
   * Gets the list of sales codes, potentially filterable by customer
   */
  public getSalescodes(customer?: string): Observable<string[]> {
    return this.httpClient.get<string[]>(`/api/summaryfilters/salescodes${customer ? `?customer=${customer}` : ''}`);
  }

  /**
   * Transform money fields to currency js.
   */
  transformCurrency(order: Orders) {

    if (!order) {
      return;
    }

    order.total_price = currency(order.total_price);
    order.requested_amount = currency(order.requested_amount);
    order.cost = currency(order.cost);
    order.other_cost = currency(order.other_cost);
    order.sales_tax = currency(order.sales_tax);
    order.purchase_deposit = currency(order.purchase_deposit);
    order.items.forEach((i) => {
      i.quantity = currency(i.quantity);
      i.unit_cost = currency(i.unit_cost);
      i.total_cost = currency(i.total_cost);
      i.other_cost = currency(i.other_cost);
      i.sales_tax = currency(i.sales_tax);
      i.total_purchase = currency(i.total_purchase);
      i.purchase_deposit = currency(i.purchase_deposit);
    });

  }

  /**
   * Accept a designers order.
   */
  acceptOrder(data: AcceptOrder): Observable<Orders> {

    if (!data.order_id) {
      return this.unlinkedOrdersService.acceptOrder(data);
    }

    return this.httpClient.post<Orders>(`/api/orders/${data.order_id}/accept`, data);

  }

  /**
   * Send payment Options.
   */
  sendPaymentOptions(data: RequestPaymentOptions): Observable<Orders> {
    return this.httpClient.post<Orders>(`/api/orders/${data.order_id}/payment_options`, data);
  }

  /**
   * Accept a designers order.
   */
  rejectOrder(data: RejectOrder): Observable<Orders> {

    if (!data.order_id) {
      return this.unlinkedOrdersService.rejectOrder(data);
    }

    return this.httpClient.post<Orders>(`/api/orders/${data.order_id}/reject`, data);

  }

  /**
   * Update order freight tracking.
   */
  updateFreightTracking(data: FreightTracking): Observable<Orders> {

    if (!data.order_id) {
      return this.unlinkedOrdersService.updateFreightTracking(data);
    }

    return this.httpClient.post<null>(`/api/orders/${data.order_id}/freight-tracking`, data);

  }

  /**
   * Send message to designers order.
   */
  messageOrder(order: Orders, message: string, isMedia: boolean) {

    if (!order.id) {
      throw Error('no order provided');
    }

    return this.httpClient.post(`/api/orders/${order.id}/message`, {message, isMedia});

  }

}
