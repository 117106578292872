/**
 * src/app/modules/notifications/state/notifications.selector.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {createSelector} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query';
import {notificationsFeatureKey, NotificationsState} from "./notifications.reducer";

export const selectNotificationsState = (state: any) => state[notificationsFeatureKey];

// Tab
export const selectTab = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.tab
)

// Preview
export const selectPreviewNotificationsList = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.previewNotificationsList
);

// All
export const selectNotificationsList = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.list
);

export const selectNotificationsListPager = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.list.pager
);

export const selectNotificationsListSearchFilter = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.list.searchFilter
);

export const selectNotificationsListPagerWithFilters = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => {

    const pager: KendoDataState = {...state.list.pager };

    if (state.list.statusFilter !== null || state.list.searchFilter !== null) {
      pager.filter = {
        logic: 'and',
        filters: []};
    }

    if (state.list.statusFilter !== null) {
      pager.filter.filters.push(state.list.statusFilter);
    }

    if (state.list.searchFilter !== null) {
      pager.filter.filters.push(state.list.searchFilter);
    }

    return pager;
  }
);

export const selectUnreadNotificationCount = createSelector(
  selectNotificationsState,
  (state: NotificationsState) => state.unreadCount
);

