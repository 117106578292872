/** A hero's name can't match the given regular expression */
import {Status} from "../../shared/models/statuses.enum";
import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export function statusValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (!control || !control.value) {
      return null; // no control, will be validated elsewhere
    }

    let active = false; // set initial values for when we search
    let inactive = false; // set initial values for when we search

    for (const status of control.value) {
      if ((status as Status) === Status.active) {
        active = true;
      } else if ((status as Status) === Status.inactive) {
        inactive = true;
      }
    }

    return active && inactive ? {activeAndInactive: {value: `Cannot have both active and inactive status`}} : null;
  };
}
