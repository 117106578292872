/**
 * src/app/modules/shared/pipes/currencyjs.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/07/2019
 */

import {Pipe, PipeTransform} from '@angular/core';
import {Message} from "@progress/kendo-angular-conversational-ui";
import * as moment from "moment";

@Pipe({
  name: 'localizemessages'
})
export class LocalizeMessagesPipe implements PipeTransform {
  transform(value: any[], timezone?: string): Message[] {

    if (!value) {
      return [];
    }

    if (!timezone) {
      return value;
    }

    return value.map((e: Message) => {
      e.timestamp = new Date(moment(e.timestamp).tz(timezone, false).format().substring(0,19));
      return e;
    })

  }
}
