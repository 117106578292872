
  <div class="header-holder">
    <header class="header">
      <section class="header__section">
        <img src="/assets/icons/temp-logo.svg" class="logo" alt="">
      </section>
      <section class="header__section header__section--nav">
        <ng-container *ngIf="(order | async)?.published">
        <nav kendoTooltip filter=".tooltip-enabled" position="bottom" class="main-navigation" role="navigation">
          <ul>
            <li class="nav-item tooltip-enabled" title="Sign Up to add this order to your account and have access to all features" (click)="signUp()">
              Create Account
            </li>
            <li class="nav-item tooltip-enabled" title="Sign In to add this order to your account and have access to all features" (click)="login()">
              Sign In
            </li>
          </ul>
        </nav>
        </ng-container>
      </section>
    </header>
    <vp-sign-up-modal [hasInfo]="false"></vp-sign-up-modal>
  </div>
