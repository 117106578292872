/**
 * Based on header, determine if you are logged in
 *
 * src/app/modules/orders/pipes/is-logged-in.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 07/21
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isLoggedIn'
})
export class IsLoggedInPipe implements PipeTransform {
  transform(header: string): boolean {
    if (!header) {
      return;
    }
    return header !== 'unlinked';
  }
}
