/**
 * src/app/modules/shared/state/shared.actions.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createAction, props} from '@ngrx/store';

export const changeHeaderAction = createAction('[Shared] Change Header', props<{ header: string }>());
