/**
 * src/app/modules/user-space-admin/state/userSpaceAdmin.reducer.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 02/17/2021
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Action, createReducer, on} from "@ngrx/store";
import {
  countEmployeesSubjectChangeAction,
  countLocationsSubjectChangeAction,
  customerResetAction,
  customersSetSubjectAction,
  customerSubjectChangeAction, dashboardConfigurationSetAction,
  filtersSubjectChangeAction,
  filtersSubjectSetAction,
  initialLoadSubjectAction,
  salesCodeResetAction,
  salesCodesSetSubjectAction,
  salesCodeSubjectChangeAction,
  setCustomerInNewListAction,
  setOrderSummaryAction,
  setSalesCodeInNewListAction, templateLoadModalAction,
  templateSaveModalAction,
  timeframeSubjectChangeAction,
  widgetSubjectChangeAction,
} from "./userSpaceAdmin.actions";
import {OrdersSummary} from "../../orders/models/orders-summary";
import {Timeframes} from "../models/timeframes.enum";
import {Widget} from "../models/widget.enum";
import {FiltersInterface} from "../models/filters.interface";
import {logoutSuccessAction} from "../../authentication/state/authentication.actions";
import {orderAcceptDialog} from "../../orders/state/orders.actions";
import {DashboardConfiguration} from "../models/dashboardConfiguration";

export const userSpaceAdminFeatureKey = 'user_space_admin';

export interface UserSpaceAdminState {
  ordersSummary: OrdersSummary[];
  topCustomers: OrdersSummary[];
  locationCount: number;
  employeeCount: number;
  filters: FiltersInterface;
  customers: string[];
  salesCodes: string[];
  timeframes: {text: string, timeFrame: Timeframes}[];
  widgets: {text: string, widget: Widget}[];
  dashboard: {
    configuration: DashboardConfiguration;
    templateSaveModalOpen: boolean;
    templateLoadModalOpen: boolean;
  };
}

export const initialState: UserSpaceAdminState = {
  ordersSummary: [],
  topCustomers: [],
  locationCount: 0,
  employeeCount: 0,
  filters: {customer: '', salesCode: '', timeframe: Timeframes.Year, widget: Widget.AverageValueOfOrders},
  customers: [],
  salesCodes: [],
  timeframes: [{
    text: `Last 7 Days`,
    timeFrame: Timeframes.Week,
  }, {
    text: `Last 30 Days`,
    timeFrame: Timeframes.Month,
  }, {
    text: `Last 4 Quarters`,
    timeFrame: Timeframes.Quarter,
  }, {
    text: `Last 12 months`,
    timeFrame: Timeframes.Year,
  }, {
    text: Timeframes.Lifetime,
    timeFrame: Timeframes.Lifetime,
  }],
  widgets: [
    {
      text: Widget.TotalValueOfOrders,
      widget: Widget.TotalValueOfOrders
    }, {
      text: Widget.QuantityOfOrders,
      widget: Widget.QuantityOfOrders
    }, {
      text: Widget.AverageValueOfOrders,
      widget: Widget.AverageValueOfOrders
    }],
  dashboard: {
    configuration: {
      name: '',
      configuration: []
    },
    templateSaveModalOpen: false,
    templateLoadModalOpen: false,
  },
};

const userSpaceAdminReducer = createReducer(
  initialState,

  on(logoutSuccessAction, (state: UserSpaceAdminState) => ({...state, ...initialState})),

  on(countLocationsSubjectChangeAction, (state: UserSpaceAdminState, locationCount: { locationCount: number }) => ({...state, locationCount: locationCount.locationCount})),
  on(countEmployeesSubjectChangeAction, (state: UserSpaceAdminState, employeeCount: { employeeCount: number }) => ({...state, employeeCount: employeeCount.employeeCount})),

  on(customerResetAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters: { customer: '', salesCode: filters.salesCode, timeframe: filters.timeframe, widget: filters.widget }})),
  on(salesCodeResetAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters: { customer: filters.customer, salesCode: '', timeframe: filters.timeframe, widget: filters.widget }})),

  on(filtersSubjectChangeAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),
  on(timeframeSubjectChangeAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),
  on(salesCodeSubjectChangeAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),
  on(widgetSubjectChangeAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),
  on(customerSubjectChangeAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),

  on(templateSaveModalAction, (state: UserSpaceAdminState, data: {isOpen: boolean}) =>
    ({ ...state, dashboard: { ...state.dashboard, templateSaveModalOpen: data.isOpen }})
  ),
  on(templateLoadModalAction, (state: UserSpaceAdminState, data: {isOpen: boolean}) =>
    ({ ...state, dashboard: { ...state.dashboard, templateLoadModalOpen: data.isOpen }})
  ),
  on(dashboardConfigurationSetAction, (state: UserSpaceAdminState, data: { config: DashboardConfiguration }) =>
    ({ ...state, dashboard: { ...state.dashboard, configuration: data.config }})
  ),

  on(filtersSubjectSetAction, (state: UserSpaceAdminState, filters: FiltersInterface) => ({...state, filters})),

  on(setSalesCodeInNewListAction, (state: UserSpaceAdminState, opts: FiltersInterface & { salesCodes: string[] }) => {
    return {...state,
      filters: {
        timeframe: opts.timeframe,
        widget: opts.widget,
        customer: opts.customer,
        salesCode: opts.salesCode
      }, salesCodes: opts.salesCodes
    };
  }),

  on(setCustomerInNewListAction, (state: UserSpaceAdminState, opts: FiltersInterface & { topCustomers: OrdersSummary[] }) => {
    return {...state,
      filters: {
        timeframe: opts.timeframe,
        widget: opts.widget,
        customer: opts.customer,
        salesCode: opts.salesCode
      }, topCustomers: opts.topCustomers
    };
  }),

  on(customersSetSubjectAction, (state: UserSpaceAdminState, topCustomers: { topCustomers: OrdersSummary[] }) => ({...state, topCustomers: topCustomers.topCustomers})),
  on(salesCodesSetSubjectAction, (state: UserSpaceAdminState, salesCodes: { salesCodes: string[] }) => {
    return { ...state, salesCodes: salesCodes.salesCodes };
  }),
  on(setOrderSummaryAction, (state: UserSpaceAdminState, orderSummary: { ordersSummary: OrdersSummary[] }) => ({...state, ordersSummary: orderSummary.ordersSummary})),
  on(initialLoadSubjectAction, (state: UserSpaceAdminState) => ({...state, timeframes: [{
      text: `Last 7 Days`,
      timeFrame: Timeframes.Week,
    }, {
      text: `Last 30 Days`,
      timeFrame: Timeframes.Month,
    }, {
      text: `Last 4 Quarters`,
      timeFrame: Timeframes.Quarter,
    }, {
      text: `Last 12 months`,
      timeFrame: Timeframes.Year,
    }, {
      text: Timeframes.Lifetime,
      timeFrame: Timeframes.Lifetime,
    }],
    widgets: [
      {
        text: Widget.TotalValueOfOrders,
        widget: Widget.TotalValueOfOrders
      }, {
        text: Widget.QuantityOfOrders,
        widget: Widget.QuantityOfOrders
      }, {
        text: Widget.AverageValueOfOrders,
        widget: Widget.AverageValueOfOrders
      }]}))
);

export function reducer(state: UserSpaceAdminState | undefined, action: Action) {
  return userSpaceAdminReducer(state, action);

}
