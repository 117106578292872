/**
 * @fileOverview Helper utilities for map and location operations.
 * src/app/services/map-tools.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>, Igor Dyukov <igor@studiodesigner.com>
 * @date 08/02/2021
 * @copyright DesignersAxis, LLC 2018
 * @requires "ngx-google-places-autocomplete"
 */
import { Injectable } from '@angular/core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';

const countries = require('../../../assets/constants/countries.json');

interface AddressComponents {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  administrative_area_level_3: string;

  country: string;
  country_code: string;
  locality: string;
  political: string;
  postal_code: string;
  postal_code_suffix: string;
  route: string;
  street_number: string;
  state_abbr: string;
}

export interface CountryInterface {
  id: number,
  name: string,
  abbreviation: string,
  states?: {
    id: number,
    name: string,
    abbreviation: string
  }[]
}

@Injectable()
export class MapToolsService {

  constructor() { }

  /**
   * Parse results google places api results.
   * @param components
   */

  /**
   *
   * @param components
   */
  public parseGooglePlacesResults(components: Address): AddressComponents {

    const parsed = {} as AddressComponents;

    components.address_components.forEach(val => {
      val.types.forEach(type => {
        switch (type) {
          case 'administrative_area_level_1':
            parsed.state_abbr = val.short_name;
            break;
          case 'country':
            parsed.country_code = val.short_name;
            break;
          default:
            parsed[type] = val.long_name;
        }
      });
    });

    // Add blank string to prevent undefined errors.
    parsed.street_number ??= '';

    // Add blank string if route is empty to prevent undefined.
    parsed.route ??= '';

    return parsed;
  }

  /**
   * Gets a list of countries
   *
   * @return {Country[]}
   */
  public getCountries(): CountryInterface[] {
    return <CountryInterface[]> <any> countries;
  }

}
