<div class="container">
  <div class="page-info  page-info--dashboard">
    <h2 class="page-info__title">Organization Profile</h2>
  </div>

  <div class="content-area">
    <div class="details-header profile-details-header">
      <div class="layout layout--stretch">
          <div class="form-group avatar-container">
            <div class="form-control" kendoTooltip filter=".tooltip-enabled" position="bottom">
              <span *ngIf="vendorForm.get('logo_url')?.value?.length > 0" class="material-icons remove-attachment-icon tooltip-enabled"
                    title="Your avatar when you chat with your customers" (click)="removeLogo('logo_url')">delete</span>
              <label *ngIf="vendorForm" class="details-header__logo-container tooltip-enabled" title="Your avatar when you chat with your customers">
                <ng-container [ngSwitch]="vendorForm.get('logo_url')?.value?.length > 0">
                  <input type="file" (change)="onLogoUpload($event)" accept="image/png, image/jpeg">
                  <kendo-avatar *ngSwitchCase="true"
                                [imageSrc]="vendorForm.get('logo_url')?.value"
                                class="kendo-avatar">
                  </kendo-avatar>
                  <ng-container *ngSwitchDefault>
                    <div *ngIf="avatarLoading" class="k-icon k-i-loading k-icon-lg"></div>
                    <div *ngIf="!avatarLoading" class="details-header__logo-placeholder">
                      <i class="material-icons md-24">add</i>
                    </div>
                  </ng-container>
                </ng-container>
              </label>
            </div>
          </div>

          <ng-container *ngIf="organizationProfile | async as $org">
            <div class="details-header__text">
              <h1>{{$org.name}}</h1>
            </div>
          </ng-container>
      </div>

    </div>

    <ng-container *ngIf="profileTab | async as $tab">

      <kendo-tabstrip (tabSelect)="onTab($event)">
        <kendo-tabstrip-tab [title]="'Details'" [selected]="$tab === 'details'">
          <ng-template kendoTabContent>
            <div class="content-area custom content-area--edit-details">
              <vp-organization-details [vendorForm]="vendorForm"></vp-organization-details>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <!--    <kendo-tabstrip-tab [title]="'Locations'" [selected]="$tab === 'locations'">-->
        <!--      <ng-template kendoTabContent>-->
        <!--        <vp-locations></vp-locations>-->
        <!--      </ng-template>-->
        <!--    </kendo-tabstrip-tab>-->

        <kendo-tabstrip-tab [title]="'Logins'" [selected]="$tab === 'logins'">
          <ng-template kendoTabContent>
            <div class="content-area custom content-area--org-details">
              <vp-logins></vp-logins>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <!--    <kendo-tabstrip-tab [title]="'Shipping'" [selected]="$tab === 'shipping'">-->
        <!--      <ng-template kendoTabContent>-->
        <!--        Shipping?-->
        <!--      </ng-template>-->
        <!--    </kendo-tabstrip-tab>-->

        <kendo-tabstrip-tab *ngIf="(organizationProfile | async)?.plan?.email_notifications" [title]="'Email Settings'" [selected]="$tab === 'email settings'">
          <ng-template kendoTabContent>
            <div class="content-area custom content-area--edit-details  content-area--edit-email">
              <vp-email-settings [vendorForm]="vendorForm"></vp-email-settings>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

        <kendo-tabstrip-tab *ngIf="(organizationProfile | async)?.plan?.payments" [title]="'Payments'" [selected]="$tab === 'payments'">
          <ng-template kendoTabContent>
            <div class="content-area custom content-area--org-details">
              <vp-payments [vendorForm]="vendorForm"></vp-payments>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>

      </kendo-tabstrip>

    </ng-container>
    <div class="form-buttons">
      <div class="layout">
        <div class="layout__item  u-1/2">
          <button kendoButton type="button" (click)="onCancel()">
            Cancel
          </button>
        </div>
        <div class="layout__item  u-1/2  layout--right">
          <button kendoButton [primary]="true" (click)="onFormSubmit()">
            Save Profile
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
