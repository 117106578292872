import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {filter, take} from "rxjs/operators";
import {loginAction} from "../../../authentication/state/authentication.actions";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../app.module";
import {selectAuthenticationErrorMessage} from "../../../authentication/state/authentication.selector";
import {HttpErrorResponse} from "@angular/common/http";
import {Subscription} from "rxjs";

@Component({
  selector: 'vp-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss']
})
export class SignUpModalComponent implements OnInit, OnDestroy {
  public opened = false;

  @Input() uuid: string;
  @Input() hasInfo = true;
  @Output() signUp = new EventEmitter<void>();

  // for login form
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public errorMessageSelectSubscription: Subscription;
  public errorMessage;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.loginForm = this.createForm();
    this.errorMessageSelectSubscription = this.store.select(selectAuthenticationErrorMessage)
      .pipe(filter(err => err !== null)).subscribe((err: HttpErrorResponse) => {

        if (err.error) {
          this.errorMessage = err.error.message;
        }

        this.loading = false;
        this.submitted = false;
      });
  }

  get f() {
    return this.loginForm;
  }

  ngOnDestroy() {
    if (this.errorMessageSelectSubscription) {
      this.errorMessageSelectSubscription.unsubscribe();
    }
  }

  onSubmit() {

    this.loading = true;
    this.submitted = true;

    if (this.f.valid) {

      this.store.dispatch(loginAction({
        credentials: {
          username: this.f.get('username').value,
          password: this.f.get('password').value,
          remember_me: false,
        },
        uuid: this.uuid
      }));

      this.closeModal();
      
    }

  }

  private createForm() {
    return this.formBuilder.group({
      username: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
      remember_me: [false],
    });
  }

  openModal(): void {
    this.opened = true;
  }

  closeModal(): void {
    this.opened = false;
  }

}
