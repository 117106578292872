<div class="container">
  <ng-container *ngIf="user | async as $user">
  <div class="page-info  page-info--edit" *ngIf="organization | async as $org">
    <h2 class="page-info__title"><a [routerLink]="['/system-admin', 'organizations', $org?.id]">{{$org?.name}}</a> / Edit User</h2>
  </div>
  </ng-container>

  <div class="content-area">
    <form class="form-holder" [formGroup]="userForm">
      <div class="content-area  content-area--edit-details">
        <div class="form-groups-container">
          <div class="layout layout--stretch">
            <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
              <div class="form-group">
                <h2 class="form-group-header">Name</h2>
                <div class="form-control">
                  <kendo-label for="first_name" text="First Name"></kendo-label>
                  <input kendoTextBox id="first_name" type="text" formControlName="first_name">
                </div>
                <div class="form-control">
                  <kendo-label for="last_name" text="Last Name"></kendo-label>
                  <input kendoTextBox id="last_name" type="text" formControlName="last_name">
                </div>
              </div>
            </div>
            <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
              <div class="form-group">
                <h2 class="form-group-header">Contact</h2>
                <kendo-formfield class="form-control">
                  <kendo-label for="email" text="Email"></kendo-label>
                  <input kendoTextBox id="email" type="email" formControlName="email">
                  <kendo-formerror *ngIf="userForm.controls.email.errors?.error">
                    {{userForm.controls.email.errors?.error}}
                  </kendo-formerror>
                </kendo-formfield>
                <div class="form-control">
                  <kendo-label for="phone" text="Phone" [optional]="'true'"></kendo-label>
                  <input kendoTextBox id="phone" type="text" formControlName="contact_number" [mask]="mask">
                </div>
              </div>
            </div>

            <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
              <div class="form-group">
                <h2 class="form-group-header">Status</h2>
                <div class="form-control">
                  <kendo-label for="status" text="Status"></kendo-label>
                  <kendo-multiselect id="status" textField="text" valueField="value" valuePrimitive="true"
                                      [data]="statuses" formControlName="status"></kendo-multiselect>
                  <p *ngIf="f.get('status').errors?.activeAndInactive?.value" class="error-message">{{ f.get('status').errors.activeAndInactive.value }}</p>
                </div>
                <div class="form-control">
                  <kendo-label for="roles" text="Roles"></kendo-label>
                  <kendo-multiselect id="roles" textField="text" valueField="value" valuePrimitive="true"
                                    [data]="roles" formControlName="role"></kendo-multiselect>
                </div>
                <div class="form-control">
                  <kendo-label for="timezone" text="Timezone"></kendo-label>
                  <kendo-dropdownlist
                    id="timezone"
                    [data]="timeZonesList"
                    [filterable]="true"
                    (filterChange)="handleTimeZoneFilter($event)"
                    formControlName="timezone"
                  ></kendo-dropdownlist>
                </div>
              </div>
            </div>
            <!--          <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">-->
            <!--            <div class="form-group">-->
            <!--              <h2 class="form-group-header">Locations?</h2>-->
            <!--              <div class="form-control">-->
            <!--                <div class="checkbox-label-container">-->
            <!--                  <kendo-label class="checkbox-label">-->
            <!--                    &lt;!&ndash;            <input kendoCheckBox type="checkbox" [value]="location.location_id"&ndash;&gt;-->
            <!--                    &lt;!&ndash;                   [checked]="checkUserAssigned(user, location)" (change)="onUserLocationCheckboxChange($event)">&ndash;&gt;-->
            <!--                    &lt;!&ndash;            {{ location.name }} {{ location.is_default ? '&nbsp;[DEFAULT]' : '' }}&ndash;&gt;-->
            <!--                  </kendo-label>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </form>

    <div class="form-buttons">
      <div class="layout">
        <div class="layout__item  u-1/2">
          <button class="black-border" kendoButton type="button" (click)="cancel()">
            Cancel
          </button>
        </div>
        <div class="layout__item  u-1/2  layout--right">
          <button kendoButton [primary]="true" (click)="onFormSubmit()">
            <!--      <span *ngIf="loading" class="k-i-loading"></span>-->
            Save User
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
