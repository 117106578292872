/**
 * src/app/modules/shared/pipes/currencyjs.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/07/2019
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';
import {Message} from "@progress/kendo-angular-conversational-ui";

@Pipe({
  name: 'sortbytimestamp'
})
export class SortByTimestampPipe implements PipeTransform {
  transform(value: any[]): Message[] {

    if (!value) {
      return [];
    }

    return value.slice().sort((a, b) => {

      // we want to hard cast the compared values to js date objects.
      if (typeof a.timestamp.getTime !== "function") {
        a.timestamp = new Date(a.timestamp);
      }
      if (typeof b.timestamp.getTime !== "function") {
        b.timestamp = new Date(b.timestamp);
      }

      return a.timestamp.getTime() - b.timestamp.getTime();
    });

  }
}
