<div class="container">
  <form class="form-holder" [formGroup]="planForm" autocomplete="nope">
    <div class="page-info  page-info--dashboard">
      <h2 class="page-info__title"><a [routerLink]="['/system-admin', 'plans']">Plans</a> / {{ (planEdit | async)?.plan?.id ? 'Edit' : 'New' }} Plan</h2>
    </div>

    <div class="content-area">

      <ng-container *ngIf="plan | async as $plan">
      <div class="details-header" [class]="{ 'details-header--logo': false }">

        </div>
            <div class="content-area  content-area--edit-details">
              <div class="form-groups-container">
                <div class="layout layout--stretch">
                  <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
                    <div class="form-group">
                      <div class="form-control">
                        <kendo-label for="plan-name" text="Plan Name"></kendo-label>
                        <input kendoTextBox id="plan-name" type="text" formControlName="name" autocomplete="nope">
<!--                        <p *ngIf="formErrors.plan_name" class="error-message">{{formErrors.plan_name}}</p>-->
                      </div>
                    </div>
                  </div>
                </div>

                <fieldset>
                  <div class="plan-container">
                    <div class="k-checkbox-list">
                      <div class="default-plan_container">
                        <div class="k-checkbox-item">
                          <input type="checkbox" kendoCheckBox formControlName="defaultPlan"/>
                          <kendo-label class="k-checkbox-label"
                                       text="Default Plan"></kendo-label>
                        </div>
                      </div>

                      <div class="plan-list_container">

                        <div class="k-pt-2 k-pb-2">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="order_manager"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Order Manager"></kendo-label>
                          </div>


                          <div class="k-ml-4">

                            <!-- Accept Order -->
                            <div class="k-checkbox-item">
                              <input type="checkbox" kendoCheckBox formControlName="accepting_orders"/>
                              <kendo-label class="k-checkbox-label"
                                           text="Accepting Orders"></kendo-label>
                            </div>

                            <div class="k-checkbox-item">
                              <input type="checkbox" kendoCheckBox formControlName="rejecting_orders"/>
                              <kendo-label class="k-checkbox-label"
                                           text="Rejecting Orders"></kendo-label>
                            </div>

                            <div class="k-checkbox-item">
                              <input type="checkbox" kendoCheckBox formControlName="update_tracking_number"/>
                              <kendo-label class="k-checkbox-label"
                                           text="Update Tracking"></kendo-label>
                            </div>

                            <div class="k-checkbox-item">
                              <input type="checkbox" kendoCheckBox formControlName="request_changes"/>
                              <kendo-label class="k-checkbox-label"
                                           text="Request Changes"></kendo-label>
                            </div>

                            <!-- DISABLING THESE OPTIONS FOR NOW -->

<!--                            <div class="k-checkbox-item">-->
<!--                              <input type="checkbox" kendoCheckBox formControlName="estimated_shipped_dates"/>-->
<!--                              <kendo-label class="k-checkbox-label"-->
<!--                                           text="Estimated Shipped Dates"></kendo-label>-->
<!--                            </div>-->
<!--                            <div class="k-checkbox-item">-->
<!--                              <input type="checkbox" kendoCheckBox formControlName="date_shipped"/>-->
<!--                              <kendo-label class="k-checkbox-label"-->
<!--                                           text="Date Shipped"></kendo-label>-->
<!--                            </div>-->

<!--                            &lt;!&ndash; Accept Order -> Acknowledge Dates &ndash;&gt;-->
<!--                            <div class="k-checkbox-item">-->
<!--                              <input type="checkbox" disabled kendoCheckBox formControlName="acknowledge_dates"/>-->
<!--                              <kendo-label class="k-checkbox-label"-->
<!--                                           text="Acknowledge Dates"></kendo-label>-->
<!--                            </div>-->

<!--                            &lt;!&ndash; Accept Order -> Vendor Order # &ndash;&gt;-->
<!--                            <div class="k-checkbox-item">-->
<!--                              <input type="checkbox" disabled kendoCheckBox formControlName="vendor_order_numbers"/>-->
<!--                              <kendo-label class="k-checkbox-label"-->
<!--                                           text="Vendor Order Numbers"></kendo-label>-->
<!--                            </div>-->

                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="email_notifications"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Email Notifications"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="multiple_locations"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Multiple Locations"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="payments"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Payments"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="dashboard"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Dashboard"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="notifications"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Notifications"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="vendor_directory"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Vendor Directory"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="permissions"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Permissions"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="oneClick"/>
                            <kendo-label class="k-checkbox-label"
                                         text="OneClick"></kendo-label>
                          </div>

                        </div>

                        <div class="k-pt-1 k-pb-1">

                          <div class="k-checkbox-item">
                            <input type="checkbox" kendoCheckBox formControlName="directoryCoverPhoto"/>
                            <kendo-label class="k-checkbox-label"
                                         text="Directory Cover Photo"></kendo-label>
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>

                </fieldset>

              </div>
            </div>

        <div class="form-buttons">
          <div class="layout">
            <div class="layout__item  u-1/2">
              <button kendoButton type="button" (click)="onCancel()">
                Cancel
              </button>
            </div>
            <div class="layout__item  u-1/2  layout--right">
              <button kendoButton [primary]="true" (click)="onFormSubmit()">
                <span *ngIf="loading" class="k-i-loading"></span>
                Save Plan
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>
