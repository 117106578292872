import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFileNameShort'
})
export class TruncateFilenameShortPipe implements PipeTransform {
  transform(name: string, args: number): string {
    if (!name || !args) {
      return name;
    }
    const extension: string = name.substring(name.lastIndexOf('.') + 1, name.length).toLowerCase();
    let newName: string = name.replace('.' + extension, '');
    // If the length of fileName is less than the arg value, then return the passed in fileName
    if (name.length <= args) {
      return name;
    }
    newName = newName.substring(0, args) + (name.length > args ? '..' : '');
    return newName + '.' + extension;
  }
}
