<div class="container">
<div class="page-info  page-info--dashboard">
  <h2 class="page-info__title">Notification Center</h2>
</div>
<div class="content-area">
<!--  <ng-container *ngIf="allNotifications | async as $allNotifications">-->
<!--    <ng-container *ngIf="readNotifications | async as $readNotifications">-->
<!--      <ng-container *ngIf="unreadNotifications | async as $unreadNotifications">-->
  <ng-container *ngIf="tab | async as $tab">
        <kendo-tabstrip (tabSelect)="onTab($event)">
          <ng-container *ngFor="let t of tabs">
            <kendo-tabstrip-tab [title]="t | titlecase" [selected]="$tab === t" (click)="onTab(t)">
              <ng-template kendoTabContent>
                <div class="content-area  content-area--edit-details">
                    <div class="grid-controls">
                      <div class="search-items">
                        <kendo-textbox (valueChange)="onSearchInput($event, t)"
                                       placeholder="Search Message Text"
                                       [value]="(notificationsSearchFilterSelect | async)?.value"
                                       [clearButton]="true">
                          <ng-template kendoTextBoxSuffixTemplate>
                            <button kendoButton look="clear" icon="zoom"></button>
                          </ng-template>
                        </kendo-textbox>
                      </div>

                      <div class="grid-actions  grid-actions--pull-left">
                        <kendo-buttongroup *ngIf="t !== 'read'">
                          <button kendoButton (click)="markAsRead(notificationsGridData.data)"
                                  [primary]="true">Mark All As Read</button>
                        </kendo-buttongroup>
                      </div>
                    </div>
                  <div class="grid-content">
                    <kendo-grid [id]="t + '-notification-list-grid'" [loading]="notificationsIsLoadingGridData" [data]="notificationsGridData" [resizable]="true"
                                [selectable]="selectableSettings" [sortable]="sortableSettings" scrollable="none" [filter]="notificationsPagerState.filter"
                                [pageable]="true" [sort]="notificationsPagerState.sort" [pageSize]="notificationsPagerState.take" [skip]="notificationsPagerState.skip"
                                reorderable="true" [columnMenu]="{ filter: false }" kendoGridSelectBy="code"
                                [selectedKeys]="notificationsSelectedKeys" (cellClick)="onRowClicked($event)" (dataStateChange)="dataStateChange($event)"
                                (pageChange)="onPageChange()">
                      <kendo-grid-column field="created_at" title="Date" width="120" [resizable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div>
                            {{dataItem.created_at | date: 'MM/dd/yyyy'}}
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column width="200"
                                         field="name" title="From">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="with-avatar-container">
                            <kendo-avatar shape="circle" [imageSrc]="dataItem.order.designer.logo ? dataItem.order.designer.logo : null"></kendo-avatar>
                            <div class="designer-info">
                              <span class="designer-company"> {{dataItem.name}}</span>
                            </div>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column [minResizableWidth]="160" [resizable]="false"
                                         field="message" title="Message">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div *ngIf="dataItem.is_media" [title]="dataItem.message | extractFileName">{{ dataItem.message | extractFileName | truncateFileName:70: 75}}</div>
                          <div *ngIf="!dataItem.is_media" [title]="dataItem.message">{{ (dataItem.alternate_message || dataItem.message) | truncateFileName:70:75 }}</div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column *ngIf="t === 'all'" title="Status" width="140" [resizable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <ng-container *ngIf="dataItem.unread">Unread</ng-container>
                          <ng-container *ngIf="!dataItem.unread">Read</ng-container>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column title="Actions" width="280" [resizable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-buttongroup>
                            <button kendoButton *ngIf="dataItem.unread" (click)="markAsRead([dataItem])">Mark as Read</button>
                          </kendo-buttongroup>
                          <kendo-buttongroup *ngIf="(organization | async)?.plan?.order_manager">
                            <button kendoButton (click)="viewOrder(dataItem)">View Order</button>
                          </kendo-buttongroup>
                        </ng-template>
                      </kendo-grid-column><ng-template kendoGridNoRecordsTemplate>
                      <div class="content-area  content-area--no-content">
                        <ng-container *ngIf="t !== 'unread'">
                          <p>No
                            <span *ngIf="t ==='read'">previously read</span>
                            <span *ngIf="t ==='unread'">unread</span>
                            messages right now.</p>
                        </ng-container>
                        <ng-container *ngIf="t === 'unread'">
                          <p>You are up to date</p>
                          <kendo-buttongroup>
                            <button kendoButton [primary]="true" (click)="moveToAllTab()">View All Notifications</button>
                          </kendo-buttongroup>
                        </ng-container>
                      </div>
                    </ng-template>
                    </kendo-grid>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </ng-container>
        </kendo-tabstrip>
  </ng-container>
<!--      </ng-container>-->
<!--    </ng-container>-->
<!--  </ng-container>-->
</div>
</div>
