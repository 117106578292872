import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() { }


  /**
   * Marks a form group as touched, for validation effects.
   * @param fg
   */
  public markFormGroupAsTouched(fg: FormGroup) {
    Object.keys(fg.controls).forEach(field => {
      const f = fg.get(field);
      if (f instanceof FormGroup) {
        this.markFormGroupAsTouched(f);
        return;
      }
      fg.get(field).markAsTouched({ onlySelf: true });
    });
  }

}
