/**
 * src/app/modules/system-admin/state/systemAdmin.effects.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  loginEditLoginAsUserAction, loginEditLoginAsUserFailedAction, loginEditLoginAsUserSuccessAction,
  loginEditSubjectChangeAction,
  loginEditSubjectGetAction,
  loginEditSubjectUpdateAction,
  loginEditSubjectUpdateFailedAction, organizationEditLogoUrlSubjectUpdateAction,
  organizationEditSubjectChangeAction,
  organizationEditSubjectGetAction,
  organizationEditSubjectUpdateAction,
  organizationEditSubjectUpdateFailedAction,
  planEditSubjectChangeAction,
  planEditSubjectGetAction, planEditSubjectUpdateAction, planEditSubjectUpdateFailedAction
} from './systemAdmin.actions';
import {OrganizationsService} from '../services/organizations.service';
import {Organizations} from '../../organizations/models/organizations';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '../services/users.service';
import {User} from '../../shared/models/user';
import {AppState} from '../../../app.module';
import {Store} from '@ngrx/store';
import {Plans} from "../../organizations/models/plans";
import {PlansService} from "../services/plans.service";
import {
  userOrganizationEditSubjectGetAction
} from "../../organizations/state/organizations.actions";
import {HttpErrorResponse} from '@angular/common/http';
import {loginSuccessAction} from '../../authentication/state/authentication.actions';
import {HandleErrorService} from "../../shared/services/handle-error.service";

@Injectable()
export class SystemAdminEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private organizationsService: OrganizationsService,
    private plansService: PlansService,
    private userService: UsersService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private errorHandler: HandleErrorService
  ) {}

  getOrganization = createEffect(() =>
    this.actions.pipe(
      ofType(organizationEditSubjectGetAction.type),
      switchMap((props: any) => {

        if (props.id === 'new') {
          return of(organizationEditSubjectChangeAction(new Organizations()));
        }

        return this.organizationsService.getOne(props.id).pipe(map(org => organizationEditSubjectChangeAction(org)),
          catchError((e) => {
            this.errorHandler.handleError(e, 'There was a server error retrieving this organization');
            return of(organizationEditSubjectChangeAction(new Organizations()));
          }));
        }

      )
    )
  );

  updateOrganization = createEffect(() =>
    this.actions.pipe(
      ofType(organizationEditSubjectUpdateAction.type),
      switchMap((updatedOrg: Organizations) => {

        return this.organizationsService.save(updatedOrg).pipe(switchMap(org => {

              let msg;
              if (!updatedOrg.id) {
                msg = `${org.name} has been successfully added.`;
              } else {
                msg = `${org.name} has been successfully updated.`;
              }

              this.toastrService.success(msg);
              this.router.navigate(['/system-admin/organizations']);

              return of(organizationEditSubjectChangeAction(org));

        }),
        catchError((err) => {

          let msg = 'There was a server error saving this organization';
          if (err.error && err.error.hasOwnProperty('statusCode') && err.error.statusCode === 409 ) {
            msg = err.error.message;
          }

          this.toastrService.error(msg);
          return of(organizationEditSubjectUpdateFailedAction(err));


        }));

        }

      )
    )
  );

  updateLogoOfOrganization$ = createEffect(() =>
    this.actions.pipe(
      ofType(organizationEditLogoUrlSubjectUpdateAction.type),
      switchMap((updatedProfile: Organizations) => {
          return this.organizationsService.save(updatedProfile).pipe(switchMap(o => {
              this.toastrService.success(`Logo updated`);
              return of();
            }),
            catchError((err) => {
              this.toastrService.error(`Could not update logo`);
              return of(organizationEditSubjectUpdateFailedAction(err));
            })
          );

        }
      )
    ));

  // plans
  getPlan = createEffect(() =>
    this.actions.pipe(
      ofType(planEditSubjectGetAction.type),
      switchMap((props: any) => {

          if (props.id === 'new') {
            return of(planEditSubjectChangeAction(new Plans()));
          }

          return this.plansService.getOne(props.id).pipe(map(plan => planEditSubjectChangeAction(plan)),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving this plan');
              return of(planEditSubjectChangeAction(new Plans()));
            }));
        }

      )
    )
  );

  updatePlan = createEffect(() =>
    this.actions.pipe(
      ofType(planEditSubjectUpdateAction.type),
      switchMap((updatedPlan: Plans) => {

          return this.plansService.save(updatedPlan).pipe(
            take(1),
            tap((plan) => this.toastrService.success((!plan.id ? `${plan.name} plan has been successfully added.` : `${plan.name} plan has been successfully updated.`))),
            switchMap(plan => [
              planEditSubjectChangeAction(plan),
              userOrganizationEditSubjectGetAction(),
            ]),
            catchError((err) => {

              let msg = 'There was a server error saving this plan';
              if (err.error && err.error.hasOwnProperty('statusCode') && err.error.statusCode === 409 ) {
                msg = err.error.message;
              }

              this.toastrService.error(msg);
              return of(planEditSubjectUpdateFailedAction(err));


            }));

        }

      )
    )
  );

  getLogin = createEffect(() =>
    this.actions.pipe(
      ofType(loginEditSubjectGetAction.type),
      switchMap(({org_id, user_id}) => {

        if (user_id === 'new') {
            return of(loginEditSubjectChangeAction(new User()));
        }

        return this.userService.getOne(org_id, user_id).pipe(map(user => loginEditSubjectChangeAction(user)),
            catchError(() => {
              this.toastrService.error('There was a server error retrieving this user');
              return of(loginEditSubjectChangeAction(new User()));
            }));
        }

      )
    )
  );


  updateLogin = createEffect(() =>
    this.actions.pipe(
      ofType(loginEditSubjectUpdateAction.type),
      // tslint:disable-next-line:no-shadowed-variable
      switchMap((props: {org_id, user: User}, i) => {

        return this.userService.save(props.org_id, props.user).pipe(switchMap(u => {
          let msg;
          if (!props.user.id) {
            msg = `${u.first_name} ${u.last_name} has been successfully added.`;
          } else {
            msg = `${u.first_name} ${u.last_name} has been successfully updated.`;
          }

          this.router.navigate([`/system-admin/organizations/${props.org_id}`]);
          this.toastrService.success(msg);

          return of(loginEditSubjectChangeAction(u));

        }),
        catchError((err) => {

          let msg = 'There was a server error saving this user';
          if (err.error && err.error.hasOwnProperty('statusCode') && err.error.statusCode === 409 ) {
            msg = err.error.message;
          }

          this.toastrService.error(msg);
          return of(loginEditSubjectUpdateFailedAction(err));

        }));

        }

      )
    )
  );


  loginAsAction$ = createEffect(() => this.actions.pipe(
    ofType(loginEditLoginAsUserAction.type),
    switchMap((props: { userId: number }) => this.userService.loginAsUser(props).pipe(
      take(1),
      tap(() => this.toastrService.success('Logged in!')),
    )),
    switchMap(user => [
      loginEditLoginAsUserSuccessAction(user),
      loginSuccessAction(user),
    ]),
    catchError((error: HttpErrorResponse) => {
      this.errorHandler.handleError(error, error.message);
      return of(loginEditLoginAsUserFailedAction({ error }));
    }),
  ));


}
