/**
 * src/app/modules/organizations/components/profile/logins/logins.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Organizations} from '../../../models/organizations';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.module';
import {selectOrganization} from '../../../state/organizations.selector';

@Component({
  selector: 'vp-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.scss']
})
export class LoginsComponent implements OnInit, OnDestroy {


  public organizationProfile: Observable<Organizations>;

  constructor(private store: Store<AppState>,
  ) { }


  ngOnInit(): void {
    this.organizationProfile = this.store.select(selectOrganization);
  }

  ngOnDestroy() {
  }

}
