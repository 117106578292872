/**
 * src/app/modules/authentication/components/login/login.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {loginAction} from '../../state/authentication.actions';
import {Subscription} from 'rxjs';
import {selectAuthenticationErrorMessage} from '../../state/authentication.selector';
import {HttpErrorResponse} from '@angular/common/http';
import {filter, take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {TextBoxComponent} from '@progress/kendo-angular-inputs';

@Component({
  selector: 'vp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild("password") public textbox: TextBoxComponent;

  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public errorMessageSelectSubscription: Subscription;
  public errorMessage;
  public emailValidationPattern: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  get f() {
    return this.loginForm;
  }

  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = "password";
  }

  ngOnInit() {
    this.loginForm = this.createForm();
    this.errorMessageSelectSubscription = this.store.select(selectAuthenticationErrorMessage)
      .pipe(filter(err => err !== null)).subscribe((err: HttpErrorResponse) => {

      if (err.error) {
        this.errorMessage = err.error.message;
      }

      this.loading = false;
      this.submitted = false;
      });

  }

  ngOnDestroy() {
    if (this.errorMessageSelectSubscription) {
      this.errorMessageSelectSubscription.unsubscribe();
    }
  }

  onSubmit() {

    this.loading = true;
    this.submitted = true;

    if (this.f.valid) {

      const props = {
        credentials: {
          username: this.f.get('username').value,
          password: this.f.get('password').value,
          remember_me: false,
        },
        uuid: undefined
      };

      // if the uuid is passed to the login component, let's ensure that its passed along with the request
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
        if (params && params.uuid) {
          props.uuid = params.uuid;
        }
      });

      this.store.dispatch(loginAction( props));

    }
  }

  private createForm() {
    return this.formBuilder.group({
      username: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
      remember_me: [false],
      uuid: ['', []],
    });
  }

  public togglePass(visible: boolean): void {
    this.textbox.input.nativeElement.type = visible ? 'text' : 'password';
  }
}
