/**
 * src/app/modules/system-admin/services/users.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../../shared/models/user';
import {UsersService as UsersServiceNotAdmin} from 'app/modules/organizations/services/users.service';
import {ValidationErrors} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private httpClient: HttpClient,
              private usersService: UsersServiceNotAdmin) { }

  /**
   * Get a single user.
   */
  public getOne(org_id: number, user_id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/system-admin/organizations/${org_id}/users/${user_id}`);
  }

  /**
   * Login as user.
   */
  public loginAsUser(data: { userId: number }): Observable<User> {
    return this.httpClient.post<User>(`/api/system-admin/login-as-user`, data);
  }

  /**
   * Create or update an organizations user..
   */
  public save(org_id: number, user: User): Observable<User> {

    const url = `/api/system-admin/organizations/${org_id}/users`;
    let r: Observable<User>;

    if (user.id) {
      r =  this.httpClient.put<User>(`${url}/${user.id}`, user);
    } else {
      r = this.httpClient.post<User>(url, user);
    }

    return r;

  }

  /**
   * email validator, checks for email presence, show message about error.
   */
  public async emailExistsValidator(email: string, lastEmail: string): Promise<ValidationErrors> {
      return this.usersService.emailExistsValidator(email, lastEmail);
  }

}
