<div class="container">
  <div class="page-info  page-info--list">
    <h2 class="page-info__title">Orders <span *ngIf="statistics.total">({{statistics.total}})</span></h2>

    <div class="page-info__data">
      <div class="page-info__data-item"><span>
          {{newOrderCount | async}}
        </span> New</div>
      <div class="page-info__data-item"><span>
          {{approvedOrderCount | async}}
        </span> Accepted</div>
      <div class="page-info__data-item"><span>
          {{rejectedOrderCount | async}}
        </span> Rejected</div>
      <div class="page-info__data-item" *ngFor="let status of OrdersByStatus">
        <span>{{status.number}}</span> {{status.label === 'In_progress'? 'In Progress' : status.label}}
      </div>
    </div>
  </div>

  <div *ngIf="isLoadingOrders" class="k-loading-image"></div>

  <div *ngIf="!isLoadingOrders" class="content-area">
    <div class="grid-controls">
      <div class="search-items">
      <kendo-textbox (valueChange)="onSearchInput($event)"
        placeholder="Search Orders"
                     [value]="(searchFilterSelect | async)?.value"
        [clearButton]="true">
          <ng-template kendoTextBoxSuffixTemplate>
            <button kendoButton look="clear" icon="zoom"></button>
          </ng-template>
        </kendo-textbox>
      </div>

      <div class="filter-items">
      <kendo-dropdownlist [data]="changeStatusMenuItems"
                          [defaultItem]="changeStatusMenuItemsDefault"
                          [textField]="'text'"
                          [valueField]="'status'"
                          [value]="selectedDropdownStatus"
          (selectionChange)="onStatusFiltersListChanged($event)">
        </kendo-dropdownlist>
      </div>
    </div>

    <div class="grid-content">
      <kendo-grid id="order-list-grid" [loading]="isLoadingGridData" [data]="gridData" [resizable]="true"
        [selectable]="selectableSettings" [sortable]="sortableSettings" scrollable="none" [filter]="pagerState.filter"
        [pageable]="true" [sort]="pagerState.sort" [pageSize]="pagerState.take" [skip]="pagerState.skip"
                reorderable="true" [columnMenu]="{ filter: false }" kendoGridSelectBy="code"
                [selectedKeys]="selectedKeys" (cellClick)="onRowClicked($event)" (dataStateChange)="dataStateChange($event)"
                (pageChange)="onPageChange()">
        <!--      <kendo-grid-checkbox-column width="40" [resizable]="'false'" [minResizableWidth]="40">-->
        <!--        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">-->
        <!--          <input kendoCheckBox type="checkbox" [checked]="selectedKeys.length && allRowsSelected"-->
        <!--                 [indeterminate]="selectedKeys.length && !allRowsSelected" (change)="selectAllRows($event)">-->
        <!--        </ng-template>-->
        <!--      </kendo-grid-checkbox-column>-->

        <kendo-grid-column field="order_name" title="Order #" width="145" [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>
              {{dataItem.order_name}}
            </div>
          </ng-template>
        </kendo-grid-column>

      <kendo-grid-column [minResizableWidth]="200"
                         field="designers.name" title="From">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="with-avatar-container">
              <!-- <kendo-avatar shape="circle" [imageSrc]="dataItem.avatar ? dataItem.avatar : null">
            </kendo-avatar> -->
              <div class="designer-info">
                <!--              <span>{{dataItem.designer.full_name}}</span>-->
                <span class="designer-company"> {{dataItem.designer?.name}}</span>
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>

      <kendo-grid-column width="120" [resizable]="false"
                         field="ordered_at" title="Date">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.ordered_at | localizedate: (vpUser | async).timezone | date:'MM/dd/yyyy'}}
          </ng-template>
        </kendo-grid-column>

      <kendo-grid-column width="100" media="(min-width: 1360px)"
                         title="Items" [filterable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.items?.length}}
          </ng-template>
        </kendo-grid-column>

      <kendo-grid-column media="(min-width: 1072px)" width="130" [minResizableWidth]="150"
                         field="total_price" title="Total">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.total_price | currencyjs}}
          </ng-template>
        </kendo-grid-column>

<!--      <kendo-grid-column media="(min-width: 1360px)" width="175"-->
<!--                         field="shipping_date" title="Shipping Date">-->
<!--        <ng-template kendoGridCellTemplate let-dataItem>-->
<!--        <span *ngIf="dataItem.shipping_date">-->
<!--&lt;!&ndash;          {{dataItem.shipping_date | date: 'MM/dd/yyyy'}}&ndash;&gt;-->
<!--        </span>-->
<!--          <span *ngIf="!dataItem.shipping_date">-->
<!--          <span style="opacity: 0.7;" class="k-icon k-i-minus k-i-kpi-trend-equal"></span>-->
<!--        </span>-->
<!--        </ng-template>-->
<!--      </kendo-grid-column>-->

        <kendo-grid-column width="150" media="(min-width: 880px)" field="status" title="Status">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="order-status-label">
              {{dataItem.status}}
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Actions" width="125" [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-buttongroup>
            <button kendoButton [routerLink]="[dataItem.id]"
                    [primary]="true">View</button>
<!--            <button kendoButton [primary]="false">Comments-->
<!--              <kendo-badge-container>-->
<!--                <kendo-badge *ngIf="dataItem.chat_unread_count && dataItem.chat_unread_count > 0">-->
<!--                  !-->
<!--                </kendo-badge>-->
<!--              </kendo-badge-container>-->
<!--            </button>-->
<!--            <button kendoButton *ngIf="!dataItem.void_disabled"-->
<!--                    (click)="openConfirmDialog(dataItem)"-->
<!--                    [primary]="false">-->
<!--              Void-->
<!--            </button>-->
            </kendo-buttongroup>
          </ng-template>
      </kendo-grid-column><ng-template kendoGridNoRecordsTemplate>
          <div class="content-area  content-area--no-content">
            <p *ngIf="!statistics.total && (!statusFilterName || statusFilterName === 'show_all')">No Orders yet!</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.New">No New Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.Approved">No Accepted Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.Rejected">No Rejected Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.InProgress">No In Progress Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.Shipped">No Shipped Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.Delivered">No Delivered Orders</p>
            <p *ngIf="!statistics.total && statusFilterName === orderStatusEnum.Canceled">No Canceled Orders</p>
          </div>
        </ng-template>
      </kendo-grid>
    </div>

<!--  <kendo-dialog *ngIf="showConfirmDialog" [width]="700" [minWidth]="370">-->
<!--    <div class="dialog-content">-->
<!--      <span>-->
<!--        The <b> {{selectedOrder.code}} </b> is going to be Voided. After that you will not be able-->
<!--        to take any actions on it but only review the details.-->
<!--      </span>-->
<!--    </div>-->
<!--    <kendo-dialog-actions layout="normal">-->
<!--      <button (click)="showConfirmDialog = false" class="custom-button white">Discard</button>-->
<!--      <button (click)="confirmClicked()" class="custom-button yellow">Confirm</button>-->
<!--    </kendo-dialog-actions>-->
<!--  </kendo-dialog>-->

  </div>
</div>
