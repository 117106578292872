/**
 * src/modules/user-space-admin/pipes/graph-sort.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 02/17/2020
 */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'graphSort'
})
export class GraphSortPipe implements PipeTransform {

  transform(months: string[]): string[] {
    const now = new Date();
    // reorder months
    for (let i = 0; i < 12; i++) {
      // send the month under test to the end of the array
      months.push(months.splice(0, 1)[0]);
      if (now.getMonth() === i) {
        break;
      }
    }
    return months;
  }

}
