/**
 * src/app/modules/organizations/services/count.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 02/25/2021
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Organizations} from '../models/organizations';

@Injectable({
  providedIn: 'root'
})
export class CountService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get the count of locations
   */
  public getLocationCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/locations`);
  }

  /**
   * Get the count of employees
   */
  public getEmployeeCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/employees`);
  }

  /**
   * Get the count of new orders
   */
  public getNewOrderCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/orders/new`);
  }

  /**
   * Get the count of approved orders
   */
  public getApprovedOrderCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/orders/approved`);
  }

  /**
   * Get the count of new orders
   */
  public getRejectedOrderCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/orders/rejected`);
  }

  /**
   * Get the count of organizations
   */
  public getOrganizationCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/organizations`);
  }

  /**
   * Get the count of active organizations
   */
  public getActiveOrganizationCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/organizations/active`);
  }

  /**
   * Get the count of inactive organizations
   */
  public getInactiveOrganizationCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/organizations/inactive`);
  }

  /**
   * Get the count of inactive organizations
   */
  public getUserCount(): Observable<number> {
    return this.httpClient.get<number>(`/api/count/users`);
  }
}
