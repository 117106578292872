<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp sign-up-success">
    <div class="paper small">
      <div class="success-container custom">
        <ng-container *ngIf="!errorVerifiedEmail && !successVerifiedEmail">
          <span class="k-icon k-i-clock mb-4"></span>
          <h2 class="mb-2">Sending email...</h2>
        </ng-container>
        <ng-container *ngIf="successVerifiedEmail">
          <span class="k-icon k-i-check mb-4"></span>
          <h2 class="mb-2">Success!</h2>
        </ng-container>
        <ng-container *ngIf="errorVerifiedEmail">
          <span class="k-icon k-i-close mb-4"></span>
          <h2 class="mb-2">Error!</h2>
        </ng-container>
        <p class="subtitle mb-2 mb-4">
          <ng-container *ngIf="errorVerifiedEmail">
            Could not resend verification email.
            {{error}}
          </ng-container>
          <ng-container *ngIf="successVerifiedEmail">
            Verification email re-sent, please check your email
          </ng-container>
        </p>
        <button kendoButton class="btn btn-primary"
                routerLink="/login">Login</button>
      </div>
    </div>
  </div>
</div>
