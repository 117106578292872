<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp">
    <div class="paper small">
      <div class="sign-body">
        <form [formGroup]="passwordForm" (submit)="onSubmit()" class="left-block">
          <h2 class="mb-20">Reset Your Password</h2>
          <p class="subtitle mb-38">Please enter a new password</p>

          <div class="custom reset-input-block">
            <label for="password">New Password</label>
            <kendo-textbox #password kendoTextBox id="password" formControlName="password" autocomplete="off"
              placeholder="Your New Password" [pattern]="passwordValidationPattern">
              <ng-template kendoTextBoxSuffixTemplate>
                <button kendoButton look="clear" [icon]="'eye'" [type]="'button'"
                  (mousedown)="togglePass(true)"
                  (mouseup)="togglePass(false)"
                  (mouseleave)="togglePass(false)">
              </button>
              </ng-template>
            </kendo-textbox>
            <p *ngIf="f.get('password').errors?.pattern" class="error-message">Password must have at least 8 characters,
              1
              uppercase letter and 1 number.</p>
            <p *ngIf="err">Invalid password reset token.</p>
          </div>
          <div class="btns-row custom">
            <button kendoButton [primary]="true" kendoButton class="btn btn-primary"
              [disabled]="passwordForm.invalid || loading">Save Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
