/**
 * Can the user view the accept order button
 *
 * src/app/modules/orders/pipes/accept-order.pipe.ts
 *
 * @author Yury Verkhovetsky <yury@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 08/21
 */

import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'vp-resend-verify-email',
  templateUrl: './resend-verification-email.component.html',
  styleUrls: ['./resend-verification-email.component.scss'],
})
export class ResendVerificationEmailComponent implements OnInit {

  public successVerifiedEmail = false;
  public errorVerifiedEmail = false;
  public error: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    // if the uuid is passed to the login component, let's ensure that its passed along with the request
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      if (params && params.uuid) {
        this.resendVerifyEmail(params.uuid);
      }
    });
  }

  resendVerifyEmail(userEmail: string): void {
    this.authenticationService.resendVerifyEmail(userEmail).subscribe(next => {
      this.successVerifiedEmail = true;
    }, err => {
      this.errorVerifiedEmail = true;
      this.error = err.error.message || '';
    });
  }

}
