/**
 * src/app/modules/organizations/models/plans.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Organizations} from "./organizations";

export class Plans {
  id: number;
  defaultPlan: boolean;
  name: string | null;
  email_notifications: boolean;
  acknowledge_dates: boolean;
  estimated_shipped_dates: boolean;
  date_shipped: boolean;
  vendor_order_numbers: boolean;
  order_manager: boolean;
  accepting_orders: boolean;
  rejecting_orders: boolean;
  update_tracking_number: boolean;
  request_changes: boolean;
  multiple_locations: boolean;
  payments: boolean;
  dashboard: boolean;
  notifications: boolean;
  vendor_directory: boolean;
  permissions: boolean;
  oneClick: boolean;
  directoryCoverPhoto: boolean;
  organizations: Organizations[] | undefined;

  /**
   * Add new features like this
   */
  // feature_X: boolean;

  constructor() {
  }

}
