/**
 * src/app/modules/authentication/state/authentication.selector.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {authenticationFeatureKey, AuthenticationState} from './authentication.reducer';
import {createSelector} from '@ngrx/store';

export const selectAuthenticationState = (state: any) => state[authenticationFeatureKey];

export const selectIsAuthenticated = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.isAuthenticated
);

export const selectAuthenticationErrorMessage = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.errorMessage
);

export const selectAuthenticatedUser = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState) => state.user
);

