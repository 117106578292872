/***
 * src/app/modules/shared/pipes/localizedate.pipe.ts
 *
 * @author Leancon Matudan <leancon@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 04/02/2021
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizedate'
})

export class LocalizeDatePipe implements PipeTransform {
  transform(value: any, timezone?: string): Date {

    if (!value) {
      return;
    }

    if (!timezone) {
      return value;
    }

    return new Date(moment(value).tz(timezone, false).format().substring(0, 19));
  }
}
