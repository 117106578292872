/**
 * src/app/modules/user-space-admin/components/notifications-dropdown/notifications-dropdown.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {Observable, Subscription} from 'rxjs';
import {ChatMessages} from '../../../notifications/models/ChatMessages';
import {
  selectPreviewNotificationsList,
  selectUnreadNotificationCount
} from '../../../notifications/state/notifications.selector';
import {
  markAllNotificationsAsReadAction,
  markNotificationsAsReadAction, notificationsTabChangeAction,
} from '../../../notifications/state/notifications.actions';
import {Router} from '@angular/router';
import {Organizations} from '../../../organizations/models/organizations';
import {selectOrganization} from '../../../organizations/state/organizations.selector';

@Component({
  selector: 'vp-notifications-dropdown',
  templateUrl: './notifications-dropdown.component.html',
  styleUrls: ['./notifications-dropdown.component.scss']
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {

  public chatMessages: Observable<ChatMessages[]>;
  public unreadCount: Observable<number>;
  public subscriptions: Subscription[] = [];
  public showDropdown = false;
  public organization: Observable<Organizations>;

  constructor(private store: Store<AppState>,
              private router: Router) { }

  ngOnInit(): void {
    this.organization = this.store.select(selectOrganization);
    this.chatMessages = this.store.select(selectPreviewNotificationsList);
    this.unreadCount = this.store.select(selectUnreadNotificationCount);
    this.subscriptions.push(this.chatMessages.subscribe());
    this.subscriptions.push(this.unreadCount.subscribe());
    this.subscriptions.push(this.organization.subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Marks a series of notifications as read
   * @param chatMessages
   */
  markAsRead(chatMessages: ChatMessages[]) {
    this.store.dispatch(markNotificationsAsReadAction({messages: chatMessages}));
  }

  /**
   * Marks all notifications as read
   */
  markAllAsRead() {
    this.store.dispatch(markAllNotificationsAsReadAction());
  }

  viewAllNotifications() {
    this.router.navigate(['system-admin', 'notifications']);
    this.showDropdown = false;
    // activate All tab
    this.store.dispatch(notificationsTabChangeAction({tab: 'all'}));
  }

  viewOrder(chatMessage: ChatMessages) {
    this.markAsRead([chatMessage]);
    this.router.navigate(['/orders', chatMessage.order.id]);
    this.showDropdown = false;
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

}
