/**
 * src/app/modules/orders/pipes/is-image.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/21
 */

import {Pipe, PipeTransform} from '@angular/core';

// @ts-ignore
const isSafari = /constructor/i.test(window.HTMLElement) || ((p) => p.toString() === '[object SafariRemoteNotification]')(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification));

@Pipe({
  name: 'isimage'
})
export class IsImagePipe implements PipeTransform {
  private reg = new RegExp(`\\.(jpeg|jpg|gif|png|bmp${isSafari ? '|tiff' : ''})$`);

  transform(url: string): boolean {
    if (!url) {
      return;
    }
    return(url.match(this.reg) != null);
  }
}
