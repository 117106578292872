<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp sign-up-success">
    <div class="paper small">
      <div class="success-container custom">
        <span class="k-icon k-i-check mb-4"></span>
        <h2 class="mb-2">
          Success!
        </h2>
        <p class="subtitle mb-2 mb-4">Account has been created, check your email for verification and follow an instructions</p>
        <div class="btns-row custom">
          <button kendoButton class="btn btn-secondary"
                  [routerLink]="['/resend-verify-email', email | async]">Resend email</button>
          <button kendoButton class="btn btn-primary"
                  routerLink="/login">Login</button>
        </div>
      </div>
    </div>
  </div>
</div>
