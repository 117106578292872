/**
 * src/app/modules/orders/models/orders.enum.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */
import {OrderStatus} from './order-status.enum';
import {Items} from './items';
import * as currency from 'currency.js';
import {Designers} from '../../designers/models/designers';
import {Address} from '../../shared/models/address';
import {ChatMessages} from '../../notifications/models/ChatMessages';
import {Organizations} from '../../organizations/models/organizations';

export class Orders {
  id: number;
  order_name: string | null;
  designer: Designers;
  to: Address;
  ship_to: Address;
  client: Address;
  account_number: string | null;
  title: string | null;
  status: OrderStatus;
  total_price: currency | null;
  cost: currency | null;
  other_cost: currency | null;
  sales_tax: currency | null;
  purchase_deposit: currency | null;
  tracking_number: string | null;
  vendor_note: string | null;
  ship_via: string | null;
  payment_terms: string | null;
  terms: string | null;
  special_instructions: string | null;
  payment_method: string | null;
  created_at!: Date | null;
  ordered_at!: Date | null;
  received_at!: Date | null;
  modified_at: Date | null;
  removed_at: Date | null;
  accepted_at: Date | null;
  rejected_at: Date | null;
  shipped_at: Date | null;
  est_ship_date: number; // 20200324 yyyymmdd
  date_shipped: number; // 20200324 yyyymmdd
  delivered: number; // 20200324 yyyymmdd
  attention: string;
  side_mark: string;
  vendor_invoice_id: string | null;
  uuid?: string;
  published?: boolean;
  allow_credit_card?: boolean;
  allow_ach?: boolean;
  requested_amount?: currency;
  items: Items[];
  chatMessages: ChatMessages[];
  organization?: Organizations;
}
