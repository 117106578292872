export function yyyymmddFromDate(date: Date | undefined): number {
  if (!date) {
    return 0;
  }

  const year = date.getFullYear() * 10000;
  const month = (date.getMonth() + 1) * 100;
  const day = date.getDate();
  return year + month + day;
}

export function yyyymmddToDate(date: number | undefined): Date | undefined {
  if (!date) {
    return;
  }

  const year = Math.floor(date / 10000);
  return new Date(
    year,
    (Math.floor(date / 100) - year * 100) - 1,
    date - Math.floor(date / 100) * 100,
  );
}
