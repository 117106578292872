/**
 * src/app/modules/authentication/components/sign-up/sign-up.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {ChangeDetectorRef, Component, OnInit, AfterViewInit, ViewEncapsulation, HostListener, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {MapToolsService} from '../../../shared/services/map-tools.service';
import {FormsService} from '../../../shared/services/forms.service';
import {take} from 'rxjs/operators';
import {Organizations} from '../../../organizations/models/organizations';
import {FileService} from '../../../orders/services/file.service';
import {Locations} from '../../../shared/models/locations';
import {ToastrService} from 'ngx-toastr';
import {PhoneMasks} from '../../../shared/models/phoneMasks.enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {UnlinkedVendorsService} from '../../../orders/services/unlinked-vendors.service';
import {UnlinkedAddress} from '../../../shared/models/unlinkedAddress';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
// import {loginAction} from '../../state/authentication.actions';
import {loginAction} from '../../state/authentication.actions';
import {TextBoxComponent} from '@progress/kendo-angular-inputs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'vp-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit, AfterViewInit {
  @ViewChild('password') public textbox: TextBoxComponent;

  public err: string;
  public passwordValidationPattern: RegExp = /^(?=\D*\d)(?=[^A-Z]*[A-Z]).{8,}$/;
  public emailValidationPattern: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  public phoneValidationPattern: RegExp = /^([0-9]{9,12})$/;
  public urlValidationPattern: RegExp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  public mask = PhoneMasks.Default;
  public signUpPage = 1;

  public vendorForm: FormGroup;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!this.vendorForm.invalid && this.signUpPage === 3) {
        this.onSubmit();
      } else if (!this.isNextButtonDisabled()) {
        this.nextPage();
      }
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private formsService: FormsService,
              private cd: ChangeDetectorRef,
              private fileService: FileService,
              private toastrService: ToastrService,
              private store: Store<AppState>,
              private unlinkedVendorsService: UnlinkedVendorsService,
              private router: Router,
              private mapTools: MapToolsService) { }

  ngOnInit(): void {
    this.vendorForm = this.createForm();
  }

  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = 'password';
  }

  get f() {
    return this.vendorForm;
  }

  /**
   * Get a populated model to push to backend.
   */
  getPopulatedModel(): Organizations {
    let website;

    const org: Organizations & { password?: string } = new Organizations();

    org.name = this.f.get('name').value;
    org.contact_first_name = this.f.get('contact_first_name').value;
    org.contact_last_name = this.f.get('contact_last_name').value;
    website = this.f.get('contact_website').value?.trim();
    org.contact_website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';
    org.contact_email = this.f.get('contact_email').value;
    org.contact_number = this.f.get('contact_number').value;
    org.kind = this.f.get('kind').value;
    org.active = this.f.get('active').value;
    org.password = this.f.get('password').value;

    org.primaryLocation = new Locations(); // this remains unused for now

    const locationId = this.f.get('primaryLocation.address').value;

    // org.primaryLocation.id = this.f.get('primaryLocation.id').value;
    org.primaryLocation.address = this.f.get('primaryLocation.address').value;
    org.primaryLocation.city = this.f.get('primaryLocation.city').value;
    org.primaryLocation.province = this.f.get('primaryLocation.province').value;
    org.primaryLocation.postal_code = this.f.get('primaryLocation.postal_code').value;
    org.primaryLocation.country = this.f.get('primaryLocation.country').value;
    website = this.f.get('primaryLocation.website').value?.trim();
    org.primaryLocation.website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';

    if (org.active === null) {
      org.active = false;
    }

    // get the uuid if it exists in the activated route, pass to prefillFromUuid method
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params && params.uuid) {
        org.uuid = params.uuid;
      }
    });

    return org;
  }

  /**
   * Create the form containing standard organization configuration with password
   */
  createForm() {
    const emailExistsValidator = async (control: FormControl): Promise<ValidationErrors> => {
      if (!control.value) {
        return null;
      }

      const checkEmail = await this.authenticationService.checkEmailExists(control.value).toPromise().then((data) => {
        if (data.successful) {
          return null;
        }
      }).catch((e: HttpErrorResponse) => {
        return {emailExists: e.error.message};
      });

      return checkEmail;
    };

    const f = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      contact_first_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      contact_last_name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      contact_website: new FormControl('', [
        Validators.maxLength(255),
        Validators.pattern(this.urlValidationPattern)
      ]),
      contact_email: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)],
        [emailExistsValidator]),
      contact_number: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      kind: new FormControl('vendor', [Validators.required]),
      active: new FormControl(true, []),
      primaryLocation: new FormGroup({
        id: new FormControl(undefined, []),
        address: new FormControl('', [
          Validators.required,
          Validators.maxLength(255)
        ]),
        city: new FormControl('', [
          Validators.required,
          Validators.maxLength(255)
        ]),
        province: new FormControl('', [
          Validators.required,
          Validators.maxLength(255)
        ]),
        postal_code: new FormControl('', [
          Validators.required,
          Validators.maxLength(255)
        ]),
        country: new FormControl('', [
          Validators.required,
          Validators.maxLength(255)
        ]),
        website: new FormControl('', [])
      }),
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ])
    });

    f.get('contact_number').valueChanges.subscribe((phone) => {
      const oldMask = this.mask;
      const newMask = (phone && !phone.match(/[^$,.\d]/) && phone.replace(/\D/g, '').length <= 10) ? PhoneMasks.UsaFormatted : PhoneMasks.Default;
      if (oldMask === PhoneMasks.UsaFormatted && newMask === PhoneMasks.Default && (!this.f.get('contact_number').value || this.f.get('contact_number').value.length === 0)) {
        this.f.get('contact_number').setValue('', {emitEvent: false});
      }
      this.mask = newMask;
      return phone;
    });

    // get the uuid if it exists in the activated route, pass to prefillFromUuid method
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params && params.uuid) {
        this.prefillFromUuid(params.uuid);
      }
    });

    return f;
  }

  /**
   * Using the UUID, which at this point we assume has been provided within the URL, get the vendor and prefill information
   * @param uuid
   */
  async prefillFromUuid(uuid: string): Promise<void> {
    this.unlinkedVendorsService.getVendorByUUID(uuid).pipe(take(1)).subscribe((info: UnlinkedAddress) => {
      this.f.get('name').setValue(info.vendor.name);
      this.f.get('primaryLocation.address').setValue(info.vendor.address);
      this.f.get('primaryLocation.city').setValue(info.vendor.city);
      this.f.get('primaryLocation.province').setValue(info.vendor.province);
      this.f.get('primaryLocation.postal_code').setValue(info.vendor.postal_code);
      this.f.get('contact_website').setValue(info.website);
      this.f.get('contact_email').setValue(info.email);
      this.f.get('contact_number').setValue(info.vendor.phone);
      this.f.get('primaryLocation.country').setValue(info.vendor.country);
    });
  }

  /**
   * On Submit action behavior
   */
  onSubmit() {

    if (!this.f.valid) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    this.authenticationService.createOrganization(this.getPopulatedModel()).pipe(take(1)).subscribe(
      (r) => {
        if (r.status === 200) {
          this.router.navigate(['/sign-up/success', this.f.get('contact_email').value]);

          return;
        }

        if (r.message === 'Organization already exists') {
          this.signUpPage = 1;
          this.cd.detectChanges();
          this.f.get('name').setErrors({nameAlreadyExists: true});
        } else if (r.message === 'Email already exists') {
          this.f.get('contact_email').setErrors({emailAlreadyExists: true});
        } else {
          this.toastrService.error(r.message);
        }

      }, (err) => {
        this.err = err.message;
      }
    );
  }

  nextPage() {
    this.signUpPage++;
  }

  previousPage() {
    this.signUpPage--;
  }

  /**
   * Upload a single image file (or Logo in Profile-Details).
   * @var event
   * @var formField
   */
  onFileUpload(event: any, formField: string) {
    // const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    // const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    // const files: FileList = target.files;
    // this.fileService.uploadFile(files[0]).subscribe((v) => {
    //   this.vendorForm.get(formField).setValue(v.url);
    // });
    return; // do nothing for now. this should likely not be allowed during the signup process (not an authorized user)
  }

  /**
   * Remove a single image file.
   */
  removeLogo(formField: string) {
    // this.vendorForm.get(formField).setValue('');
    return; // do nothing for now. this should likely not be allowed during the signup process (not an authorized user)
  }

  isNextButtonDisabled() {
    if (
      (this.f.get('name').value && !this.f.get('name').errors &&
        this.f.get('contact_first_name').value && !this.f.get('contact_first_name').errors &&
        this.f.get('contact_last_name').value && !this.f.get('contact_last_name').errors &&
        this.f.get('contact_email').value && !this.f.get('contact_email').errors &&
        this.signUpPage === 1) ||
      (this.f.get('primaryLocation.address').value && !this.f.get('primaryLocation.address').errors &&
        this.f.get('primaryLocation.city').value && !this.f.get('primaryLocation.city').errors &&
        this.f.get('primaryLocation.province').value && !this.f.get('primaryLocation.province').errors &&
        this.f.get('primaryLocation.postal_code').value && !this.f.get('primaryLocation.postal_code').errors &&
        this.f.get('primaryLocation.country').value && !this.f.get('primaryLocation.country').errors &&
        this.signUpPage === 2)
    ) {
      return false;
    }
    return true;
  }

  handleAddressChange(address: Address) {

    const parsedAddress = this.mapTools.parseGooglePlacesResults(address);

    this.f.get('primaryLocation.address').setValue(`${parsedAddress.street_number} ${parsedAddress.route}`);
    this.f.get('primaryLocation.city').setValue(parsedAddress.locality);
    this.f.get('primaryLocation.province').setValue(parsedAddress.state_abbr);
    this.f.get('primaryLocation.postal_code').setValue(parsedAddress.postal_code);
    this.f.get('primaryLocation.country').setValue(parsedAddress.country_code);
  }

  public togglePass(visible: boolean): void {
    this.textbox.input.nativeElement.type = visible ? 'text' : 'password';
  }
}
