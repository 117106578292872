/**
 * src/app/modules/shared/state/shared.selector.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createSelector} from '@ngrx/store';
import {sharedFeatureKey, SharedState} from './shared.reducer';

export const selectSharedState = (state: any) => state[sharedFeatureKey];

export const selectHeader = createSelector(
  selectSharedState,
  (state: SharedState) => state.header
);


