/**
 * src/app/modules/user-space-admin/components/all-notifications/all-notifications.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 *
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataStateChangeEvent, GridDataResult, SelectableSettings} from '@progress/kendo-angular-grid';
import {FilterDescriptor, State} from '@progress/kendo-data-query';
import {Observable, Subscription} from 'rxjs';
import {ResultListDTO} from '../../../shared/models/resultList.dto';
import {filter, take} from 'rxjs/operators';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {ToastrService} from 'ngx-toastr';
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {ChatMessages} from "../../../notifications/models/ChatMessages";
import {
  selectNotificationsListPagerWithFilters, selectNotificationsListSearchFilter,
  selectTab,
} from "../../../notifications/state/notifications.selector";
import {
  markNotificationsAsReadAction, notificationsListPagerChangeAction,
  notificationsPagerAddNameFilterAction,
  notificationsPagerResetNameFilterAction,
  notificationsTabChangeAction,
} from "../../../notifications/state/notifications.actions";
import {Router} from "@angular/router";
import {Organizations} from "../../../organizations/models/organizations";
import {selectOrganization} from "../../../organizations/state/organizations.selector";

@Component({
  selector: 'vp-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnInit, OnDestroy {

  public tab: Observable<'all' | 'read' | 'unread'>;

  public notificationsIsLoadingGridData: boolean;
  public notificationsGridData: GridDataResult | ResultListDTO<ChatMessages[]>;
  public notificationsPagerState: State;
  public notificationsSelectedKeys: number[] = [];
  public notificationsSearchFilterSelect: Observable<FilterDescriptor>;
  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple'
  };
  public sortableSettings = { allowUnsort: true,  mode: 'multi' };
  public readonly tabs: ('all' | 'read' | 'unread')[] = ['all', 'read', 'unread'];
  public organization: Observable<Organizations>;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<AppState>,
              private notificationsService: NotificationsService,
              private toastrService: ToastrService,
              private router: Router) {}

  ngOnInit() {

    this.tab = this.store.select(selectTab);

    this.organization = this.store.select(selectOrganization);

    const notificationsPagerSubscription = this.store.select(selectNotificationsListPagerWithFilters)
      .pipe(filter(err => err !== null)).subscribe((pagerState: State) => {
        this.store.select(selectTab).pipe(take(1)).subscribe((tab: 'all' | 'unread' | 'read') => {
          this.notificationsPagerState = { filter: null} ;
          this.notificationsService.getNotifications(pagerState, tab).pipe(take(1)).subscribe(r => {
            this.notificationsGridData = r;
          }, (err) => {
            this.notificationsGridData = null;
            this.toastrService.error('Unable to get chat messages.');
          });
          this.notificationsPagerState = {...pagerState, filter: null} ;
          this.notificationsIsLoadingGridData = false;
        });
      });

    this.notificationsSearchFilterSelect = this.store.select(selectNotificationsListSearchFilter);

    this.subscriptions.push(this.tab.subscribe());
    this.subscriptions.push(this.organization.subscribe());
    this.subscriptions.push(notificationsPagerSubscription);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectAllRows($event) {}
  toggleSearchInputVisibility() {}
  onRowClicked($event) {}
  onPageChange() {}
  viewOrderWithChat($event) {}
  openConfirmDialog($event) {}

  /**
   * Add text search filter.
   * @param $event
   * @param tab
   */
  onSearchInput($event, tab: 'all' | 'read' | 'unread') {

    // tslint:disable-next-line:no-shadowed-variable
    let filter: FilterDescriptor;

    filter = {
      field: 'message',
      operator: 'contains',
      value: $event,
      ignoreCase: true
    };

    // this.store.dispatch(notificationsPagerResetNameFilterAction());
    if ($event === null) {
      this.store.dispatch(notificationsPagerResetNameFilterAction());
      return;
    }
    this.store.dispatch(notificationsPagerAddNameFilterAction(filter));

  }

  /**
   * Dispatch a data state change.
   */
  dataStateChange(state: DataStateChangeEvent) {
      this.store.dispatch(notificationsListPagerChangeAction(state));
  }

  /**
   * Called when a tab is changed.
   */
  onTab(tab: any) {
    this.store.dispatch(notificationsTabChangeAction({tab: tab.title.toLowerCase()}));
  }

  moveToAllTab() {
    this.store.dispatch(notificationsTabChangeAction({tab: 'all'}));
  }

  viewOrder(message: ChatMessages) {
    this.markAsRead([message]);
    this.router.navigate(['/orders', message.order.id])
  }

  markAsRead(messages: ChatMessages[]) {
    const unread: ChatMessages[] = [];
    for (const m of messages) {
      if (m.unread) {
        unread.push(m);
      }
    }
    if (unread.length > 0) {
      this.store.dispatch(markNotificationsAsReadAction({messages: messages}));
    }
  }

}
