/**
 * src/app/modules/orders/components/order-details/order-details.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @author Alan Moore <alan@studiodesigner.com>
 *
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import {Message, SendMessageEvent, User} from '@progress/kendo-angular-conversational-ui';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {selectOrderChat, selectUuid} from '../../state/orders.selector';
import {from, Observable} from 'rxjs';
import {Orders} from '../../models/orders';
import {concatMap, map, take, tap} from 'rxjs/operators';
import {OrdersService} from '../../services/orders.service';
import {ChatMessages} from '../../../notifications/models/ChatMessages';
import {User as VpUser} from '../../../shared/models/user';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {FileService} from '../../services/file.service';
import {Attachment} from '../../../shared/models/attachment';
import {MediaMessage} from '../../../shared/models/mediaMessage';
import {Organizations} from "../../../organizations/models/organizations";
import {selectOrganization} from "../../../organizations/state/organizations.selector";
import {ActivatedRoute} from "@angular/router";
import {UnlinkedOrdersService} from "../../services/unlinked-orders.service";
import {SystemMessage} from "../../../shared/models/systemMessage";
import { v4 } from 'uuid';

const bot: User = {
  id: 0
};

const user: User = {
  id: 1,
};

@Component({
  selector: 'vp-order-chat',
  templateUrl: './order-chat.component.html',
  styleUrls: ['./order-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderChatComponent implements OnInit, AfterViewInit {

  @Input() public order: Observable<Orders>;
  @Input() public vpUser: Observable<VpUser>;
  @Input() public uuid: Observable<string>;
  @Output() public isInProgressOrder = new EventEmitter<boolean>();

  public readonly user: User = user;
  public readonly bot: User = bot;
  public chatMessages: Observable<MediaMessage[]>;
  public attachments: Attachment[] = [];
  private storedMessage = '';
  public organization: Observable<Organizations>;
  // this is a list of uuids (not related to any entities) -- if there are any uploads in progress, user cannot send message
  private currentUploadingUUIDs: string[] = [];

  constructor(private store: Store<AppState>,
              private ordersService: OrdersService,
              private unlinkedOrdersService: UnlinkedOrdersService,
              private http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private toastrService: ToastrService,
              private fileInput: ElementRef,
              private fileService: FileService) { }

  ngOnInit(): void {

    // if order has no id, never set organization
    this.order.pipe(take(1)).toPromise().then((order) => {
      if (order.id) {
        this.organization = this.store.select(selectOrganization);
      }
    });

    this.chatMessages = this.store.select(selectOrderChat).pipe(map((x: ChatMessages[]) => {
      if (!x) {
        return [];
      }
      const messages = [];
      for (const m of x) {
        if (m.is_media === true) {
          messages.push({text: m.message, containsMedia: true, author: {id: (m.originator === true) ? 1 : 0}, timestamp: new Date(m.created_at)} as MediaMessage);
        } else if (m.is_system_message) {
          messages.push({text: m.message, isSystemMessage: true, author: {id: (m.originator === true) ? 1 : 0}, timestamp: new Date(m.created_at)} as SystemMessage);
        } else {
          messages.push({text: m.message, author: {id: (m.originator === true) ? 1 : 0}, timestamp: new Date(m.created_at)} as Message);
        }
      }
      return messages;
    }));

    this.chatMessages.subscribe(() => setTimeout(() => {
      const chat = document.querySelector('vp-order-chat div[kendochatscrollanchor]');
      if (chat) {
        chat.scrollTop = chat.scrollHeight;
      }
    }, 0));
  }

  ngAfterViewInit(): void {
    // because we cannot directly modify the kendo chat template, we must inject this block of code before the arrow
    const messageBoxElement = document.getElementsByClassName('k-message-box');
    const attachmentIconSpan = document.createElement('ng-container');
    attachmentIconSpan.className = `attachment-media-icon`;
    attachmentIconSpan.innerHTML =
      `<label for="upload-selection" class="upload-label"><span class="k-icon k-i-attachment"></span></label>
      <input #fileInput type="file" id="upload-selection" (change)="uploadAttachment($event)"/>`;
    messageBoxElement[0].appendChild(attachmentIconSpan);
    this.fileInput.nativeElement.querySelector('#upload-selection')
      .addEventListener('change', this.uploadAttachment.bind(this));
    const sendMessageButtons = document.getElementsByClassName('k-button-send');
    if (sendMessageButtons && sendMessageButtons.length === 1) {
      sendMessageButtons[0].addEventListener('click', this.sendAttachmentOnly.bind(this));
    }
  }

  /**
   * Attempt to upload the attachment, removes the attachment on failure
   * @param event
   */
  public uploadAttachment(event): void {
    const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const files: FileList = target.files;
    const file = files[0];

    // push a random uuid to show a file upload
    const uuid = v4();
    this.attachments.push({
      url: '',
      isImage: file.type.substr(0, 5) === 'image',
      name: file.name,
      content: file,
      type: file.type,
      uuid
    });

    this.currentUploadingUUIDs.push(uuid);

    this.order.pipe(take(1)).subscribe(order => {

      // we want to assign the url property of the attachment
      this.fileService.uploadFile(file, order.id).subscribe((v) => {
        for (const a of this.attachments) {
          if (a.uuid === uuid && a.isImage === (file.type.substr(0, 5) === 'image')) {
            a.url = v.url;
          }
        }
        (document.getElementById('upload-selection') as HTMLInputElement).value = null;
        if (this.currentUploadingUUIDs.indexOf(uuid) > -1) {
          this.currentUploadingUUIDs.splice(this.currentUploadingUUIDs.indexOf(uuid), 1);
        }
      }, (e) => {
        this.removeAttachment(undefined, uuid);
        if (this.currentUploadingUUIDs.indexOf(uuid) > -1) {
          this.currentUploadingUUIDs.splice(this.currentUploadingUUIDs.indexOf(uuid), 1);
        }
      });
    });

  }

  /**
   * Remove an attachment from previewed icons
   *
   * @param url string
   * @param uuid string
   */
  public removeAttachment(url?: string, uuid?: string): void {
    uuid ? this.removeAttachmentByUuid(uuid) : this.removeAttachmentByUrl(url);
  }

  /**
   * Remove an attachment from previewed icons by uuid
   *
   * @param uuid string
   */
  public removeAttachmentByUuid(uuid: string): void {
    for (let i = 0; i < this.attachments.length; i++) {
      if (this.attachments[i].uuid === uuid) {
        this.attachments.splice(i, 1);
        return;
      }
    }
  }

  /**
   * Remove an attachment from previewed icons by url
   *
   * @param url string
   */
  public removeAttachmentByUrl(url: string): void {
    for (let i = 0; i < this.attachments.length; i++) {
      if (this.attachments[i].url === url) {
        this.attachments.splice(i, 1);
        return;
      }
    }
  }

  public sendAttachmentOnly() {
    const messageInputs = document.getElementsByClassName('k-input');
    if (this.storedMessage === '' && this.attachments.length > 0) {
      this.sendMessage();
    }
  }

  public async sendMessage(e?: SendMessageEvent): Promise<void> {

    if (this.organization) {
      const org = await this.organization.pipe(take(1)).toPromise();
      if (org?.plan?.request_changes !== true) {
        return; // organization is NOT allowed to send messages of any type
      }
    }

    // store the message so we can reliably determine elsewhere whether we want to send attachments only
    if (e && e.message && e.message.text) {
      this.storedMessage = e.message.text;
    }

    this.order.pipe(take(1)).subscribe(o => {

      const messages: MediaMessage[] = [];
      if (e && e.message && e.message.text) {
        messages.push({author: {id: 1}, text: e.message.text, containsMedia: false});
      }

      // only send the attachments if there are none currently uploading
      let attachmentsStillUploading = this.currentUploadingUUIDs.length > 0;
      if (!attachmentsStillUploading) {
        for (const message of this.attachments) {
          messages.push({author: {id: 1}, text: message.url, containsMedia: true});
        }
      }

      let index = 0;
      if (o.id) {
        from(messages).pipe(
          concatMap(m => this.ordersService.messageOrder(o, m.text, m.containsMedia).
          pipe(tap(v => index = index + 1)))).subscribe(v => {
            if (!attachmentsStillUploading) {
              this.attachments = [];
            }
          this.storedMessage = '';
        });
      } else {
        this.store.select(selectUuid).pipe(take(1)).subscribe(uuid => {
          from(messages).pipe(
            concatMap(m => this.unlinkedOrdersService.messageOrder(uuid, m.text, m.containsMedia).
            pipe(tap(v => index = index + 1)))).subscribe(v => {
            this.attachments = [];
            this.storedMessage = '';
          });
        });

      }


    });
  }

  private populateMessage(order: Orders, message: MediaMessage): ChatMessages {
    return {
      order,
      name: '',
      message: message.text,
      created_at: new Date(),
      originator: true,
      unread: true,
      is_media: message.containsMedia
    };
  }

}
