/**
 * src/app/modules/system-admin/state/systemAdmin.actions.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createAction, props} from '@ngrx/store';
import {FilterDescriptor, State as KendoDataState} from '@progress/kendo-data-query';
import {Organizations} from '../../organizations/models/organizations';
import {User} from '../../shared/models/user';
import {Plans} from "../../organizations/models/plans";
import {HttpErrorResponse} from '@angular/common/http';

// organizations list
export const organizationListPagerChangeAction = createAction('[System Admin] Organization List Pager Change', props<KendoDataState>());
// tslint:disable-next-line:max-line-length
export const organizationListPagerAddNameFilterAction = createAction('[System Admin] Organization List Pager Add Name Filter', props<FilterDescriptor>());
// tslint:disable-next-line:max-line-length
export const organizationListPagerAddStatusFilterAction = createAction('[System Admin] Organization List Pager Add Status Filter', props<FilterDescriptor>());
export const organizationListPagerResetNameFilterAction = createAction('[System Admin] Organization List Pager Reset Name Filter');
export const organizationListPagerResetStatusFilterAction = createAction('[System Admin] Organization List Pager Reset Status Filter');

// organization edit
export const organizationEditTabChangeAction = createAction('[System Admin] Organization Tab Change', props<{ tab: string }>());
export const organizationEditSubjectGetAction = createAction('[System Admin] Organization Subject Get', props<{ id: number | string }>());
export const organizationEditSubjectUpdateAction = createAction('[System Admin] Organization Subject Update', props<Organizations>());
export const organizationEditSubjectUpdateFailedAction = createAction('[System Admin] Organization Subject Update Failed', props<any>());
export const organizationEditSubjectChangeAction = createAction('[System Admin] Organization Subject Change', props<Organizations>());

// for updating logo
export const organizationEditLogoUrlSubjectUpdateAction = createAction('[System Admin] Profile Logo Url Subject Update', props<Organizations>());

// plans list
export const planListPagerChangeAction = createAction('[System Admin] Plan List Pager Change', props<KendoDataState>());
// tslint:disable-next-line:max-line-length
export const planListPagerAddNameFilterAction = createAction('[System Admin] Plan List Pager Add Name Filter', props<FilterDescriptor>());
// tslint:disable-next-line:max-line-length
export const planListPagerAddStatusFilterAction = createAction('[System Admin] Plan List Pager Add Status Filter', props<FilterDescriptor>());
export const planListPagerResetNameFilterAction = createAction('[System Admin] Plan List Pager Reset Name Filter');
export const planListPagerResetStatusFilterAction = createAction('[System Admin] Plan List Pager Reset Status Filter');

// plan edit
export const planEditSubjectGetAction = createAction('[System Admin] Plan Subject Get', props<{ id: number | string }>());
export const planEditSubjectUpdateAction = createAction('[System Admin] Plan Subject Update', props<Plans>());
export const planEditSubjectUpdateFailedAction = createAction('[System Admin] Plan Subject Update Failed', props<any>());
export const planEditSubjectChangeAction = createAction('[System Admin] Plan Subject Change', props<Plans>());

// login edit
// tslint:disable-next-line:max-line-length
export const loginEditSubjectGetAction = createAction('[System Admin] Login Subject Get', props<{ org_id: number | string, user_id: number | string }>());
export const loginEditSubjectUpdateAction = createAction('[System Admin] Login Subject Update', props<{org_id: number, user: User}>());
export const loginEditSubjectChangeAction = createAction('[System Admin] Login Subject Change', props<User>());
export const loginEditSubjectUpdateFailedAction = createAction('[System Admin] Login Subject Update Failed', props<any>());

export const loginEditLoginAsUserAction = createAction('[System Admin] Login As User', props<{ userId: number }>());
export const loginEditLoginAsUserFailedAction = createAction('[System Admin] Login As User Failed', props<{ error: HttpErrorResponse }>());
export const loginEditLoginAsUserSuccessAction = createAction('[System Admin] Login As User Success', props<User>());
