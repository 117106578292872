import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FormsService} from "../../../shared/services/forms.service";
import {AuthenticationService} from "../../services/authentication.service";
import {take} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'vp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordForm: FormGroup;
  public formErrors: any;
  public loading = false;
  public successfullyReset = false;
  private validationMessages: any;

  public emailValidationPattern: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (!this.forgotPasswordForm.invalid) {
        this.onSubmit();
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private formsService: FormsService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.createForm();
  }

  onSubmit() {

    if (!this.f.valid) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

     this.loading = true;

    this.authenticationService.forgotPassword(this.f.get('email').value).pipe(take(1)).subscribe((r) => {

      this.loading = false;
      this.successfullyReset = true;

    }, (err) => {
      this.toastrService.error("Unable to submit your request at this time, please try again later.");
      this.loading = false;
      this.successfullyReset = false;

    });

  }

  goToLoginPage() {
  }

  get f() {
    return this.forgotPasswordForm
  }

  private createForm() {

    return this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });

  }

}
