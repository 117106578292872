/**
 * src/app/modules/organizations/components/profile/payments/payments.component.ts
 *
 * @author Peter Korotkiy <darkfoxs96@gmail.com>
 * @since 08/26
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, Input} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.module';
import {selectOrganization} from '../../../state/organizations.selector';
import {StripeStatusEnum} from '../../../models/stripeStatus.enum';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'vp-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {

  @Input() vendorForm: FormGroup;

  public organizationProfile = this.store.select(selectOrganization);
  public stripeStatus = StripeStatusEnum;
  public stripeLoginUrl: SafeUrl;

  constructor(
    private store: Store<AppState>,
    private dom: DomSanitizer,
  ) {
    const returnUrl = encodeURIComponent(location.href);
    this.stripeLoginUrl = this.dom.bypassSecurityTrustUrl(`/api/profile/stripe/connect?returnUrl=${returnUrl}`);
  }

}
