<ng-container *ngIf="(header | async) === 'user'">
  <vp-header></vp-header>
</ng-container>

<ng-container *ngIf="(header | async) === 'admin'">
  <vp-system-admin-header></vp-system-admin-header>
</ng-container>
<ng-container *ngIf="(header | async) === 'unlinked'">
  <vp-unlinked-header></vp-unlinked-header>
</ng-container>

<router-outlet></router-outlet>
