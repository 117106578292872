/**
 * src/app/modules/orders/components/list/list.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @author Alan Moore <alan@studiodesigner.com>
 *
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataStateChangeEvent, GridDataResult, SelectableSettings} from '@progress/kendo-angular-grid';
import {FilterDescriptor, State} from '@progress/kendo-data-query';
import {Observable, Subscription} from 'rxjs';
import {OrderStatus} from '../../models/order-status.enum';
import {ResultListDTO} from '../../../shared/models/resultList.dto';
import {filter, map, take} from 'rxjs/operators';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {
  selectApprovedOrderCount,
  selectNewOrderCount,
  selectOrdersListPagerWithFilters,
  selectOrdersListSearchFilter,
  selectOrdersListStatusFilter,
  selectRejectedOrderCount
} from '../../state/orders.selector';
import {
  ordersListPagerAddNameFilterAction,
  ordersListPagerAddStatusFilterAction,
  ordersListPagerChangeAction,
  ordersListPagerResetNameFilterAction,
  ordersListPagerResetStatusFilterAction
} from '../../state/orders.actions';
import {OrdersService} from '../../services/orders.service';
import {ToastrService} from 'ngx-toastr';
import {Orders} from '../../models/orders';
import {selectAuthenticatedUser, selectIsAuthenticated} from '../../../authentication/state/authentication.selector';
import {User as VpUser} from '../../../shared/models/user';
import {HandleErrorService} from '../../../shared/services/handle-error.service';

@Component({
  selector: 'vp-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {

  constructor(private store: Store<AppState>,
              private ordersService: OrdersService,
              private toastrService: ToastrService,
              private errorHandler: HandleErrorService) {}

  public isLoadingOrders: boolean;
  public isLoadingGridData: boolean;
  statusEnum = OrderStatus;
  public OrdersByStatus: {
    number: number,
    label: string
  }[] = [];
  public selectedKeys: number[] = [];
  public Orders: any = [];
  public searchInputVisible: boolean;
  public vpUser: Observable<VpUser>;

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple'
  };

  public gridData: GridDataResult | ResultListDTO<Orders[]>;

  // list-organization page statistics.
  public statistics = { total: 0 };

  // default page sizes.
  public pageSizes = [10, 25, 50];

  // sort settings for kendo grid.
  public sortableSettings = { allowUnsort: true,  mode: 'multi' };

  // the state of the kendo pager.
  public pagerState: State;

  // default item drop down items for filters.
  public changeStatusMenuItemsDefault = { text: 'Show All', status: 'show_all' };

  public changeStatusMenuItems = [
    { text: 'New Orders', status: OrderStatus.New },
    { text: 'Accepted', status: OrderStatus.Approved },
    { text: 'In Progress', status: OrderStatus.InProgress },
    { text: 'Shipped', status: OrderStatus.Shipped },
    { text: 'Delivered', status: OrderStatus.Delivered },
    { text: 'Canceled', status: OrderStatus.Canceled },
    { text: 'Rejected', status: OrderStatus.Rejected },
  ];

  public readonly orderStatusEnum = OrderStatus;
  public statusFilterName: string;

  public allRowsSelected = false;

  // external filters.
  public searchFilterSelect: Observable<FilterDescriptor>;
  public statusFilterSelect: Observable<FilterDescriptor>;
  private subscriptions: Subscription[] = [];
  public selectedDropdownStatus: any;

  // counts
  public newOrderCount: Observable<number>;
  public approvedOrderCount: Observable<number>;
  public rejectedOrderCount: Observable<number>;

  ngOnInit() {

    const pagerSubscription = this.store.select(selectOrdersListPagerWithFilters)
      .pipe(filter(err => err !== null)).subscribe((pagerState: State) => {

        this.store.select(selectIsAuthenticated).pipe(take(1), filter(v => v)).subscribe(() => {
          this.pagerState = { filter: null} ;

          this.ordersService.getCollection(pagerState).pipe(take(1)).subscribe(r => {

              if (pagerState.filter) {
                this.statusFilterName = pagerState.filter.filters[0]['value'];
              }

              if (r.data && r.data.length > 0) {
                r.data.forEach(this.ordersService.transformCurrency);
              }

              r.data.map(orders => {
                if (orders.status === 'Approved') {
                  orders.status = 'Accepted' as OrderStatus;
                }
              });

              this.gridData = r;
              this.statistics.total = r.total;
            },
            (err) => {

              this.gridData = null;
              this.statistics.total = 0;
              this.errorHandler.handleError(err, 'Unable to get orders from the server.');

            });

          this.pagerState = {...pagerState, filter: null} ;

          this.isLoadingGridData = false;
        });
      });

    this.searchFilterSelect = this.store.select(selectOrdersListSearchFilter);
    this.statusFilterSelect = this.store.select(selectOrdersListStatusFilter);

    const statusSubscriber = this.statusFilterSelect.subscribe((status) => {

      if (status === null) {
        this.selectedDropdownStatus = this.changeStatusMenuItemsDefault;
      } else {

        this.selectedDropdownStatus = this.changeStatusMenuItems[this.changeStatusMenuItems
          .indexOf(this.changeStatusMenuItems.filter(value => value.status === status.value).pop())];

      }

    });

    this.newOrderCount = this.store.select(selectNewOrderCount);
    this.approvedOrderCount = this.store.select(selectApprovedOrderCount);
    this.rejectedOrderCount = this.store.select(selectRejectedOrderCount);
    this.vpUser = this.store.select(selectAuthenticatedUser).pipe(map((u: VpUser) => {
      return u;
    }));

    this.subscriptions.push(pagerSubscription);
    this.subscriptions.push(statusSubscriber);
    this.subscriptions.push(this.vpUser.subscribe());

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectAllRows($event) {}
  toggleSearchInputVisibility() {}
  onRowClicked($event) {}
  onPageChange() {}
  viewOrderWithChat($event) {}
  openConfirmDialog($event) {}

  /**
   * Add text search filter.
   * @param $event
   */
  onSearchInput($event) {

    // this.store.dispatch(ordersListPagerResetNameFilterAction());

    if ($event === null) {
      this.store.dispatch(ordersListPagerResetNameFilterAction());
      return;
    }

    // tslint:disable-next-line:no-shadowed-variable
    let filter: FilterDescriptor;

    filter = {
      field: 'search',
      operator: 'is',
      value: $event,
      ignoreCase: true
    };

    this.store.dispatch(ordersListPagerAddNameFilterAction(filter));

  }

  /**
   * Add status filter.
   */
  onStatusFiltersListChanged($event) {

    this.statusFilterName = $event.status;

    if ($event.status === 'show_all') {
      this.store.dispatch(ordersListPagerResetStatusFilterAction());
      return;
    }

    // tslint:disable-next-line:no-shadowed-variable
    let filter: FilterDescriptor = null;

    filter = {
      field: 'status',
      operator: 'eq',
      value: $event.status,
      ignoreCase: true
    };

    this.store.dispatch(ordersListPagerAddStatusFilterAction(filter));

  }

  /**
   * Dispatch a data state change.
   */
  dataStateChange(state: DataStateChangeEvent) {
    this.store.dispatch(ordersListPagerChangeAction(state));
  }

}
