/**
 * src/app/modules/organizations/state/organizations.reducer.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Action, createReducer, on} from '@ngrx/store';
import {Organizations} from '../models/organizations';
import {
  countActiveOrganizationSubjectChangeAction,
  countInactiveOrganizationSubjectChangeAction, countOrganizationSubjectChangeAction, countUserSubjectChangeAction,
  userLoginEditSubjectChangeAction,
  userLoginEditSubjectUpdateAction,
  userOrganizationEditSubjectChangeAction,
  userOrganizationEditTabChangeAction
} from './organizations.actions';
import {logoutSuccessAction} from '../../authentication/state/authentication.actions';
import {AuthenticationState} from '../../authentication/state/authentication.reducer';
import {organizationEditTabChangeAction} from '../../system-admin/state/systemAdmin.actions';
import {SystemAdminState} from '../../system-admin/state/systemAdmin.reducer';
import {User} from '../../shared/models/user';
import {OrdersState} from '../../orders/state/orders.reducer';

export const organizationsFeatureKey = 'organizations';

export interface OrganizationsState {
  organizations: Organizations | null;
  profileTab: string;
  user: User;
  organizationCount: number;
  activeOrganizationCount: number;
  inactiveOrganizationCount: number;
  userCount: number;
}

export const initialState: OrganizationsState = {
  organizations: null,
  profileTab: 'details',
  user: null,
  organizationCount: 0,
  activeOrganizationCount: 0,
  inactiveOrganizationCount: 0,
  userCount: 0,
};

const organizationsReducer = createReducer(
  initialState,
  on(logoutSuccessAction, (state: OrganizationsState) => ({...state, ...initialState})),
  on(userOrganizationEditSubjectChangeAction, (state: OrganizationsState, organizations: Organizations) => ({...state, organizations})),
  on(userOrganizationEditTabChangeAction, (state: OrganizationsState, {tab}) => ({...state,  profileTab: tab })),
  on(userLoginEditSubjectChangeAction, (state: OrganizationsState, user: User) => ({...state, user})),
  on(countOrganizationSubjectChangeAction,
    (state: OrganizationsState, organizationCount: { organizationCount: number }) =>
    ({...state, organizationCount: organizationCount.organizationCount})),
  on(countActiveOrganizationSubjectChangeAction,
    (state: OrganizationsState, activeOrganizationCount: { activeOrganizationCount: number }) =>
    ({...state, activeOrganizationCount: activeOrganizationCount.activeOrganizationCount})),
  on(countInactiveOrganizationSubjectChangeAction,
    (state: OrganizationsState, inactiveOrganizationCount: { inactiveOrganizationCount: number }) =>
      ({...state, inactiveOrganizationCount: inactiveOrganizationCount.inactiveOrganizationCount})),
  on(countUserSubjectChangeAction, (state: OrganizationsState, userCount: { userCount: number }) =>
    ({...state, userCount: userCount.userCount})),
);

export function reducer(state: OrganizationsState | undefined, action: Action) {
  return organizationsReducer(state, action);

}
