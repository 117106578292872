import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../app.module";
import {Observable, Subscription} from "rxjs";
import {selectIsAuthenticated} from "../../../authentication/state/authentication.selector";
import {take} from "rxjs/operators";
import {logoutSuccessAction} from "../../../authentication/state/authentication.actions";
import {AuthenticationService} from "../../../authentication/services/authentication.service";
import {ToastrService} from "ngx-toastr";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {changeHeaderAction} from '../../../shared/state/shared.actions';
import {PlanEdit} from "../../state/systemAdmin.reducer";
import {selectSystemAdminPlanEdit} from "../../state/systemAdmin.selector";

@Component({
  selector: 'vp-system-admin-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public isVendorActive = true;
  public showMenu = false;
  public subscription: Subscription;
  public organizationPlan: Observable<PlanEdit>;

  constructor(private store: Store<AppState>,
              private authenticationService: AuthenticationService,
              private toastrService: ToastrService,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.organizationPlan = this.store.select(selectSystemAdminPlanEdit);
    this.subscription = this.store.select(selectIsAuthenticated).subscribe((isAuthenticated: boolean) => {
      this.showMenu = isAuthenticated;
    });

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.authenticationService.logout().pipe(take(1)).subscribe(() => {
      this.store.dispatch(logoutSuccessAction());
    }, (err) => {
      this.toastrService.error("Unable to successfully log you out.");
    });
  }

  changeHeader() {
    this.store.dispatch(changeHeaderAction({header: 'user'}));
  }

}
