/**
 * src/app/modules/organizations/organizations.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LabelModule} from '@progress/kendo-angular-label';
import {OrganizationsRoutingModule} from './organizations-routing.module';
import {DetailsComponent} from './components/profile/details/details.component';
import {LocationsComponent} from './components/profile/locations/locations.component';
import {LoginsComponent} from './components/profile/logins/logins.component';
import {ShippingComponent} from './components/shipping/shipping.component';
import {EmailSettingsComponent} from './components/profile/email-settings/email-settings.component';
import {ProfileComponent} from './components/profile/profile.component';
import {AvatarModule, TabStripModule} from '@progress/kendo-angular-layout';
import {CheckBoxModule, RadioButtonModule, TextBoxModule} from '@progress/kendo-angular-inputs';
import {NgxMaskModule} from 'ngx-mask';
import {ButtonModule, ButtonsModule} from '@progress/kendo-angular-buttons';
import {FilterMenuModule, GridModule} from '@progress/kendo-angular-grid';
import {DropDownListModule, MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {BadgeModule} from '@progress/kendo-angular-indicators';
import {StoreModule} from '@ngrx/store';
import * as fromOrganizations from './state/organizations.reducer';
import {EffectsModule} from '@ngrx/effects';
import {OrganizationsEffects} from './state/organizations.effects';
import {ListViewModule} from '@progress/kendo-angular-listview';
import {EditUserComponent} from './components/profile/edit-user/edit-user.component';
import {SharedModule} from '../shared/shared.module';
import * as fromAuthentication from '../authentication/state/authentication.reducer';
import {AuthenticationEffects} from '../authentication/state/authentication.effects';
import {RemoveSelectedItemPipe} from './pipes/remove-selected-item.pipe';
import {TooltipModule} from '@progress/kendo-angular-tooltip';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {MapToolsService} from '../shared/services/map-tools.service';
import {PaymentsComponent} from './components/profile/payments/payments.component';

@NgModule({
  declarations: [DetailsComponent,
    LocationsComponent,
    LoginsComponent,
    ShippingComponent,
    EmailSettingsComponent,
    ProfileComponent,
    EditUserComponent,
    PaymentsComponent,
    RemoveSelectedItemPipe
  ],
  imports: [
    CommonModule,
    LabelModule,
    OrganizationsRoutingModule,
    TabStripModule,
    TextBoxModule,
    NgxMaskModule,
    CheckBoxModule,
    ButtonModule,
    GridModule,
    DropDownListModule,
    AvatarModule,
    BadgeModule,
    ButtonsModule,
    RadioButtonModule,
    StoreModule.forFeature(fromOrganizations.organizationsFeatureKey, fromOrganizations.reducer),
    StoreModule.forFeature(fromAuthentication.authenticationFeatureKey, fromAuthentication.reducer),
    EffectsModule.forFeature([OrganizationsEffects, AuthenticationEffects]),
    FilterMenuModule,
    ListViewModule,
    SharedModule,
    MultiSelectModule,
    GooglePlaceModule,
    TooltipModule,
  ],
  exports: [
    RemoveSelectedItemPipe
  ],
  providers: [
    RemoveSelectedItemPipe,
    MapToolsService,
  ]
})
export class OrganizationsModule { }
