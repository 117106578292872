/**
 * src/app/modules/orders/services/unlinked-vendors.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 7/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UnlinkedAddress} from '../../shared/models/unlinkedAddress';

@Injectable({
  providedIn: 'root'
})
export class UnlinkedVendorsService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get the vendor information prefilled by the designer when they shared the order
   */
  public getVendorByUUID(uuid: string): Observable<UnlinkedAddress> {
    return this.httpClient.get<UnlinkedAddress>(`/api/unlinkedvendors/${uuid}`);
  }

}
