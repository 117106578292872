/**
 * src/app/modules/shared/models/statuses.enum.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

export enum Status {
  active = 'active',
  inactive = 'inactive',
  pending = 'pending'
}
