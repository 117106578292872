/**
 * src/app/modules/user-space-admin/components/dashboard/orders-over-time/orders-over-time.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 05/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
// @ts-ignore
import {AppState} from '../../../../app.module';
import {OrdersSummary} from '../../../../orders/models/orders-summary';
import {OrdersService} from '../../../../orders/services/orders.service';
import {Timeframes} from "../../../models/timeframes.enum";
import {Widget} from "../../../models/widget.enum";
import {DisplayEnum} from "../../../models/display.enum";


@Component({
  selector: 'vp-orders-over-time',
  templateUrl: './orders-over-time.component.html',
  styleUrls: ['./orders-over-time.component.scss']
})

export class OrdersOverTimeComponent implements OnInit {

  @Input() widget: Widget;
  @Input() timeframe: Timeframes;
  @Input() display: DisplayEnum;
  @Input() customer: string;
  @Output() customerSelected = new EventEmitter<string>();

  public data: Observable<OrdersSummary[]>;

  public readonly WIDGET_ENUM = Widget;
  public readonly TIMEFRAMES_ENUM = Timeframes;
  public readonly DISPLAY_ENUM = DisplayEnum;

  public loaded = false;
  public error = false;

  constructor(private ordersService: OrdersService, private store: Store<AppState>) { }

  ngOnInit(): void {

    if (this.display === DisplayEnum.Top10) {

      this.data = this.ordersService.getTopCustomersSummary(undefined, this.timeframe);

    } else if (this.display === DisplayEnum.Graph) {

      this.data = this.ordersService.getOrderVolumeSummary(this.customer, undefined, this.timeframe);

    }

    this.data.subscribe(next => {
      this.loaded = true;
      this.error = false;
    }, err => {
      this.loaded = true;
      this.error = true;
    });

  }

  onCustomerChanged(customer: string) {
    this.customerSelected.emit(customer);
  }

}

