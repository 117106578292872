/**
 * src/app/modules/wsockets/services/ws/ws.service.ts
 *
 * @since 1/21
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Observable} from 'rxjs';
import {VpEvent} from '../../../notifications/models/VpEvent';
import {ChatMessages} from '../../../notifications/models/ChatMessages';
import {Orders} from "../../../orders/models/orders";

@Injectable({providedIn: 'root'})
export class WsService {
  public $events: Observable<any>;
  public $messages: Observable<VpEvent<ChatMessages>>;
  public $systemErrors: Observable<VpEvent<ChatMessages>>;
  public $statuses: Observable<VpEvent<Partial<Orders>>>;
  public $orderUpdates: Observable<VpEvent<{id: number}>>;
  public $logoutEvents: Observable<VpEvent<any>>;
  constructor(private socket: Socket) {
    this.$events = socket.fromEvent('events');
    this.$messages = socket.fromEvent('messages');
    this.$systemErrors = socket.fromEvent('system_errors');
    this.$logoutEvents = socket.fromEvent('logout');
    this.$statuses = socket.fromEvent('statuses');
    this.$orderUpdates = socket.fromEvent('order_updates');
  }

}
