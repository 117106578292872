/**
 * src/app/modules/organizations/components/profile/email-settings/email-settings.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'underscore';

enum triggers {

  // A new RFQ is received
  ON_NEW_RFQ = 'on_new_rfq',
  // A new Order is received
  ON_NEW_ORDER = 'on_new_order',
  // An Order is updated by a Customer
  ON_ORDER_EXTERNAL_UPDATE = 'on_order_external_update',
  // I receive a message
  ON_MESSAGE_RECEIVED = 'on_message_received',
  //   An Order has not been completed or cancelled within 5 days of receipt
  ON_5DAYS_IDLE = 'on_5days_idle',
  //   An Order has not been opened 24 hours after receipt
  ON_1DAYS_IDLE = 'on_1days_idle'
}

@Component({
  selector: 'vp-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

  // /**
  //   @TODO:
  //   I am assigned an Order
  //   on_order_assigned
  //  */
  // this seems like an account based item.
  //

  @Input() vendorForm: FormGroup;
  public triggers = triggers;

  constructor() { }

  ngOnInit(): void {
  }

  get f() {
    return this.vendorForm;
  }

  get ns() {
    return this.f.get('notification_triggers');
  }

  /**
   * Check if an item is in the string array.
   */
  has(s: string): boolean {
    return this.ns.value.indexOf(s) !== -1;
  }

  allChecked(): boolean {
    return _.isEqual(Object.values(this.triggers), this.ns.value); // `true`
  }

  // Check or uncheck all.
  flipAll($event) {

    let picks = [];

    if ($event.currentTarget.checked) {
      picks = Object.values(this.triggers);
    }

    this.ns.setValue(picks);

  }

  /**
   * Add and remove notification triggers.
   */
  flip(s: string) {

    const c = Object.assign([], this.ns.value);

    if (c.indexOf(s) !== -1) {
      c.splice(c.indexOf(s), 1);
    } else {
      c.push(s);
    }

    this.ns.setValue(c);

  }

}
