/**
 * src/app/modules/shared/models/locations.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

export class Locations {
  id: number;
  name: string | null;
  address: string | null;
  city: string | null;
  province: string | null;
  postal_code: string | null;
  website: string | null;
  email: string | null;
  phone: string | null;
  fax: string | null;
  country: string;
  logo_url: string | null;
  cover_photo_url: string | null;
}


