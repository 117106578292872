/**
 * src/app/modules/system-admin/components/edit-organization/edit-organization.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {OrganizationsService} from '../../services/organizations.service';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {Organizations} from '../../../organizations/models/organizations';
import {Location} from '@angular/common';
import {FormsService} from '../../../shared/services/forms.service';
import {MapToolsService} from '../../../shared/services/map-tools.service';
import {Locations} from '../../../shared/models/locations';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {
  selectSystemAdminOrganizationEdit,
  selectSystemAdminOrganizationEditOrganization, selectSystemAdminOrganizationHasOrganization
} from '../../state/systemAdmin.selector';
import {OrganizationEdit} from '../../state/systemAdmin.reducer';
import {
  loginEditLoginAsUserAction, organizationEditLogoUrlSubjectUpdateAction,
  organizationEditSubjectUpdateAction, organizationEditSubjectUpdateFailedAction,
  organizationEditTabChangeAction
} from '../../state/systemAdmin.actions';
import {FileService} from '../../../orders/services/file.service';
import {PlansService} from '../../services/plans.service';
import {Plans} from '../../../organizations/models/plans';
import {PhoneMasks} from '../../../shared/models/phoneMasks.enum';
import {UploadResult} from '../../../orders/models/upload-result';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {HttpErrorResponse} from '@angular/common/http';
import {UsersService} from 'app/modules/organizations/services/users.service';


@Component({
  selector: 'vp-vendor-form',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss']
})
export class EditOrganizationComponent implements OnInit, OnDestroy {

  public vendorForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public organization = new BehaviorSubject<Organizations>(new Organizations());
  public loading = false;
  public organizationEdit: Observable<OrganizationEdit>;
  public plans: Observable<Plans[]>;
  public selectedPlan: Plans;
  public hasOrganization: Observable<boolean>;
  private readonly destroy$ = new Subject();
  public logoUploading = false;
  public coverPhotoUploading = false;

  public mask = PhoneMasks.Default;
  private lastEmail: string;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private organizationsService: OrganizationsService,
    private plansService: PlansService,
    private formsService: FormsService,
    private fileService: FileService,
    private location: Location,
    private store: Store<AppState>,
    private router: Router,
    private mapTools: MapToolsService,
    private userService: UsersService,
  ) {
  }

  ngOnInit(): void {

    this.vendorForm = this.createForm();

    this.plans = this.plansService.getCollection().pipe(take(1), map((r) => {
      return r.data;
    }));

    this.organizationEdit = this.store.select(selectSystemAdminOrganizationEdit);

    // If creating a New Organization, set the default tab to 'Details'
    this.store.select(selectSystemAdminOrganizationEditOrganization).pipe(filter(o => !o?.id), takeUntil(this.destroy$))
              .subscribe((o => {
                this.store.dispatch(organizationEditTabChangeAction({tab: 'details'}));
              }));

    this.hasOrganization = this.store.select(selectSystemAdminOrganizationHasOrganization).pipe(takeUntil(this.destroy$));

    this.organizationEdit.subscribe(oe => this.organization.next(oe.organization));

    this.organization.pipe(filter((o) => this.vendorForm !== undefined && o !== null),
      takeUntil(this.destroy$)).
    subscribe((organization) => {
      // reset and populate form.
      this.lastEmail = organization.contact_email;
      this.vendorForm.reset(organization);

      if (organization?.plan) {
        this.onPlanChanged(organization.plan);
      }

      // check if the url doesn't have our id in it, if missing, update with out id, this handles 'new'.
      if (!isNaN(organization.id)) {

        this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
          if (params.org_id && params.org_id === 'new') {
            this.location.replaceState(`/system-admin/organizations/${organization.id}`);
          }
        });
      }

    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createForm() {

    const emailExistsValidator = async (control: FormControl): Promise<ValidationErrors> => {
      return await this.userService.emailExistsValidator(control.value, this.lastEmail);
    }

    const f = new FormGroup({
      id: new FormControl(undefined, []),
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      logo_url: new FormControl('', []),
      cover_photo_url: new FormControl('', []),
      contact_first_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      contact_last_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      contact_website: new FormControl('', [Validators.maxLength(255)]),
      contact_email: new FormControl('', [Validators.required, Validators.maxLength(255)], [emailExistsValidator]),
      contact_number: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      kind: new FormControl('', [Validators.required]),
      active: new FormControl(true, []),
      locations: new FormControl('', []),
      primaryLocation: new FormGroup({
        id: new FormControl(undefined, []),
        address: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        province: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        postal_code: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        country: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        website: new FormControl('', []),
      }, ),
      plan: new FormControl('', [Validators.required])
    });

    f.get('contact_number').valueChanges.subscribe((phone) => {
      const oldMask = this.mask;
      const newMask = (phone && !phone.match(/[^$,.\d]/) && phone.replace(/\D/g, '').length <= 10) ? PhoneMasks.UsaFormatted : PhoneMasks.Default;
      if (oldMask === PhoneMasks.UsaFormatted && newMask === PhoneMasks.Default && (!this.f.get('contact_number').value || this.f.get('contact_number').value.length === 0)) {
        this.f.get('contact_number').setValue('', {emitEvent: false});
      }
      this.mask = newMask;
      return phone;
    });

    return f;

  }

  get f() {
    return this.vendorForm;
  }

  /**
   * Get a populated model to push to backend.
   */
  getPopulatedModel(): Organizations {

    let website;

    const org = new Organizations();

    org.id = this.f.get('id').value;
    org.name = this.f.get('name').value;
    org.logo_url = this.f.get('logo_url').value;
    org.cover_photo_url = this.f.get('cover_photo_url').value;
    org.contact_first_name = this.f.get('contact_first_name').value;
    org.contact_last_name = this.f.get('contact_last_name').value;
    website = this.f.get('contact_website').value?.trim();
    org.contact_website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';
    org.contact_email = this.f.get('contact_email').value;
    org.contact_number = this.f.get('contact_number').value;
    org.kind = this.f.get('kind').value;
    org.active = this.f.get('active').value;
    org.plan = this.f.get('plan').value;

    org.primaryLocation = new Locations();

    const locationId = this.f.get('primaryLocation.address').value;

    org.primaryLocation.id  = this.f.get('primaryLocation.id').value;
    org.primaryLocation.address  = this.f.get('primaryLocation.address').value;
    org.primaryLocation.city  = this.f.get('primaryLocation.city').value;
    org.primaryLocation.province  = this.f.get('primaryLocation.province').value;
    org.primaryLocation.postal_code  = this.f.get('primaryLocation.postal_code').value;
    org.primaryLocation.country = this.f.get('primaryLocation.country').value;
    website = this.f.get('primaryLocation.website').value?.trim();
    org.primaryLocation.website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';

    if (org.active === null) {
      org.active = false;
    }

    return org;

  }

  // onFileUpload(event: any) {
  //   const eventObj: MSInputMethodContext = event as MSInputMethodContext;
  //   const target: HTMLInputElement = eventObj.target as HTMLInputElement;
  //   const files: FileList = target.files;
  //
  //   this.fileService.uploadFile(files[0]).subscribe((v) => {
  //     this.vendorForm.patchValue({logo_url: v.url});
  //     this.vendorForm.get('logo_url').updateValueAndValidity();
  //   });
  // }

  onFormSubmit() {

    if (!this.f.valid) {
      if (!(this.f.get('plan').value)) {
        this.toastrService.error('Please select a plan for this organization.');
      }
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    this.store.dispatch(organizationEditSubjectUpdateAction(this.getPopulatedModel()));
  }

  onPlanChanged(e: Plans) {
    this.f.get('plan').setValue(e);
    this.selectedPlan = e;
  }

  /**
   * Called when a tab is changed.
   */
  onTab(tab: any) {
    this.store.dispatch(organizationEditTabChangeAction({tab: tab.title.toLowerCase()}));
  }

  onCancel() {
    this.router.navigate(['/system-admin/organizations']);
  }

  public loginAs(userId: number) {
    this.store.dispatch(loginEditLoginAsUserAction({ userId }));
  }

  /**
   * Remove a single image file.
   */
  removeLogo(formField: string) {
    this.f.get(formField).setValue('');
    this.saveLogo('');
  }

  /**
   * Upload a single image file
   * @param event
   */
  uploadFile(event: any): Observable<UploadResult> {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    return this.fileService.uploadFile(files[0]);
  }

  /**
   * Upload logo
   * @param event
   */
  onLogoUpload(event: any): void {
    this.logoUploading = true;
    this.uploadFile(event).subscribe((v) => {
      this.f.get('logo_url').setValue(v.url);
      this.saveLogo(v.url);
      this.logoUploading = false;
      event.target.value = '';
    }, err => {
      this.logoUploading = false;
      if (err.error?.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(err.message);
      }
      event.target.value = '';
    });
  }

  /**
   * Upload logo
   * @param event
   */
  onCoverPhotoUpload(event: any): void {
    this.coverPhotoUploading = true;
    this.uploadFile(event).subscribe((v) => {
      this.f.get('cover_photo_url').setValue(v.url);
      this.coverPhotoUploading = false;
    }, err => {
      this.coverPhotoUploading = false;
    });
  }

  handleAddressChange(address: Address) {

    const parsedAddress = this.mapTools.parseGooglePlacesResults(address);

    this.f.get('primaryLocation.address').setValue(`${parsedAddress.street_number} ${parsedAddress.route}`);
    this.f.get('primaryLocation.city').setValue(parsedAddress.locality);
    this.f.get('primaryLocation.province').setValue(parsedAddress.state_abbr);
    this.f.get('primaryLocation.postal_code').setValue(parsedAddress.postal_code);
    this.f.get('primaryLocation.country').setValue(parsedAddress.country_code);
  }

  /**
   * Save logo only
   * @param url
   */
  private saveLogo(url: string): void {
    this.organizationEdit.pipe(take(1)).subscribe(orgEdit => {
      if (!orgEdit) {
        return;
      }
      const org = orgEdit.organization;
      if (org && org.id && org.id > 0) {
        this.store.dispatch(organizationEditLogoUrlSubjectUpdateAction(Object.assign({}, org, { logo_url: url }))); // we can safely save the org
      }
    });
  }

}
