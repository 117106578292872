/**
 * src/app/modules/authentication/authentication-routing.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 9/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
