/**
 * src/app/modules/authentication/services/is-authenticated-guard.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 2/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Store} from "@ngrx/store";
import {AppState} from "../../../app.module";
import {selectIsAuthenticated} from "../state/authentication.selector";
import {Observable} from "rxjs";

@Injectable()
export class IsAuthenticatedGuardService implements CanActivate {

  constructor(private store: Store<AppState>, public router: Router) {
  }

  canActivate(): Observable<boolean> {

    return this.store.select(selectIsAuthenticated)

  }

}
