import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'extractFileName'
})
export class ExtractFilenamePipe implements PipeTransform {
  transform(url: string): string {
    if (!url) {
      return;
    }
    const arr = url.split('/');
    return decodeURIComponent(arr[arr.length - 1]);
  }
}
