/**
 * src/app/modules/notifications/state/notifications.effects.ts
 *
 * @author John Eubank <John@studiodesigner.com>
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {
  markAllNotificationsAsReadAction,
  markNotificationsAsReadAction,
  markNotificationsAsUnreadAction,
  previewNotificationsListGetAction,
  previewNotificationsListSubjectChangeAction,
  unreadNotificationCountGetAction,
  unreadNotificationCountSubjectChangeAction
} from "./notifications.actions";
import {State} from "@progress/kendo-data-query";
import {NotificationsService} from "../services/notifications.service";
import {ChatMessages} from "../models/ChatMessages";
import {HandleErrorService} from "../../shared/services/handle-error.service";

@Injectable()
export class NotificationsEffects {

  constructor(
    private actions: Actions,
    private notificationsService: NotificationsService,
    private toastrService: ToastrService,
    private errorHandler: HandleErrorService
  ) {}

  getPreviewNotifications$ = createEffect(() =>
    this.actions.pipe(
      ofType(previewNotificationsListGetAction.type),
      switchMap(() => {
        const state: State = {
          sort: [{dir: 'desc', field: 'created_at'}],
          filter: null,
          take: 5,
          skip: 0,
        };
        return this.notificationsService.getNotifications(state, 'unread').pipe(take(1), map(o => previewNotificationsListSubjectChangeAction({previewNotificationsList: o.data})),
          catchError((e) => {
            this.errorHandler.handleError(e, 'There was an error retrieving preview notifications');
            return of(previewNotificationsListSubjectChangeAction({previewNotificationsList: []}));
          }))
      })
    )
  );

  // preview notifications list changing should trigger an action that gets the count of unread messages
  getUnreadNotificationCount$ = createEffect(() =>
    this.actions.pipe(
      ofType(unreadNotificationCountGetAction.type),
      switchMap(() => {
        return this.notificationsService.getUnreadNotificationCount().pipe(take(1), map(c => unreadNotificationCountSubjectChangeAction({count: c})),
        catchError((e) => {
          this.errorHandler.handleError(e, 'There was an error retrieving notification count');
          return of(unreadNotificationCountSubjectChangeAction({count: 0}));
        })
        )
      })
    )
  );

  setCountFromPreviewList$ = createEffect(() =>
    this.actions.pipe(
      ofType(previewNotificationsListSubjectChangeAction.type),
      switchMap(() => {
        return of(unreadNotificationCountGetAction());
      })
    )
  );

  // set preview notifications as read
  setNotificationsAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(markNotificationsAsReadAction.type),
      switchMap((chatMessages: { messages: ChatMessages[] }) => {
        const ids: number[] = [];
        for (const m of chatMessages.messages) {
          ids.push(m.id);
        }
        return this.notificationsService.markAsRead(ids).pipe(map(v => previewNotificationsListGetAction()),
          catchError(() => {
            this.toastrService.error('There was an error setting notifications as read');
            return of(previewNotificationsListGetAction());
          })
        )
      })
    )
  );

  // set preview notifications as read
  setAllNotificationsAsRead$ = createEffect(() =>
    this.actions.pipe(
      ofType(markAllNotificationsAsReadAction.type),
      switchMap(() => {
        return this.notificationsService.markAllAsRead().pipe(map(v => previewNotificationsListGetAction()),
          catchError(() => {
            this.toastrService.error('There was an error setting notifications as read');
            return of(previewNotificationsListGetAction());
          })
        )
      })
    )
  );

  // set notifications as unread
  setNotificationsAsUnread$ = createEffect(() =>
    this.actions.pipe(
      ofType(markNotificationsAsUnreadAction.type),
      switchMap((chatMessages: ChatMessages[]) => {
        const ids: number[] = [];
        for (const m of chatMessages) {
          ids.push(m.id);
        }
        return this.notificationsService.markAsUnread(ids).pipe(map(v => previewNotificationsListGetAction()),
          catchError(() => {
            this.toastrService.error('There was an error setting notifications as read');
            return of(previewNotificationsListGetAction());
          })
        )
      })
    )
  );

}
