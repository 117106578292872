<!--<kendo-tabstrip>-->
<!--  <kendo-tabstrip-tab title="Chat" [selected]="true">-->
<!--    <ng-template kendoTabContent>-->
      <kendo-chat [messages]="chatMessages | async | localizemessages: (vpUser | async)?.timezone | sortbytimestamp"
        [user]="user" (sendMessage)="sendMessage($event)">
        <ng-template kendoChatMessageTemplate let-message>
          <div class="k-bubble-body" [class.k-bubble-image]="message.text | isimage">
            <span *ngIf="!message.containsMedia" [ngClass]="{'system-message': message.isSystemMessage}">{{message.text}}</span>
            <ng-container *ngIf="message.containsMedia">
              <div *ngIf="(message.text | isimage)" class="message-image-container">
                <a [href]="message.text"><span class="k-icon k-i-download message-image-download-icon"></span></a>
                <div class="image-hover-over"></div>
                <div class="message-image-wrapper">
                  <img class="message-image" [src]="message.text" />
                </div>
              </div>
              <div *ngIf="!(message.text | isimage)" class="message-document">
                <kendo-card class="document-download" [width]="'100%'">
                  <span
                    class="k-icon file-type-icon-message icon-style-type-{{message.text | getFileExtension}} k-i-file-{{message.text | getFileExtension}}"></span>
                  <kendo-card-body class="k-hbox chat-file-body message-document-container">
                    <div [title]="message.text | extractFileName" class="document-hover-over"></div>
                    <a [href]="message.text"><span class="k-icon k-i-download message-document-download-icon"></span></a>
                    <p class="file-text" kendoCardSubtitle>{{ message.text | extractFileName | truncateFileNameShort: 7 }}
                    </p>
                  </kendo-card-body>
                </kendo-card>
              </div>
            </ng-container>
            <time class="k-message-time">{{message.timestamp | date: 'h:mm a'}}</time>
          </div>
        </ng-template>
      </kendo-chat>
      <div class="preview-attachment-container" *ngIf="attachments && attachments.length > 0">
        <ng-container *ngFor="let preview of attachments">
          <div class="preview-attachment-items">
            <span class="material-icons remove-attachment-icon" (click)="removeAttachment(preview.url)">cancel</span>
            <kendo-avatar *ngIf="preview.isImage && preview.url" width="80px" height="80px" shape="rounded"
              [imageSrc]="preview.url" class="preview-attachment"></kendo-avatar>
            <kendo-card class="document-preview preview-attachment icon-type-{{preview.name | lowercase | getFileExtension}}"
              *ngIf="!preview.isImage || !preview.url" [ngClass]="{ 'card-loading-attachment': !preview.url }"
              [width]="'80px'">
              <div *ngIf="!preview.url" class="attachment-loading k-icon k-i-loading"></div>
              <span
                class="k-icon file-type-icon icon-style-type-{{preview.name | lowercase | getFileExtension}} k-i-file-{{preview.name | lowercase | getFileExtension}}"></span>
              <kendo-card-body class="k-hbox" class="document-preview-body">
                <p [title]="preview.name" kendoCardSubtitle>
                  {{ preview.name | extractFileName | truncateFileNameShort: 4 }}
                </p>
              </kendo-card-body>
            </kendo-card>
          </div>
        </ng-container>
      </div>

<!--    </ng-template>-->
<!--  </kendo-tabstrip-tab>-->
<!--  <kendo-tabstrip-tab title="Attachments">-->
<!--    <ng-template kendoTabContent>-->
<!--      <div class="preview-attachment-container">-->
<!--        <ng-container *ngFor="let preview of attachments">-->
<!--          <div class="preview-attachment-items">-->
<!--            <span class="material-icons remove-attachment-icon" (click)="removeAttachment(preview.url)">cancel</span>-->
<!--            <kendo-avatar *ngIf="preview.isImage && preview.url" width="80px" height="80px" shape="rounded"-->
<!--              [imageSrc]="preview.url" class="preview-attachment"></kendo-avatar>-->
<!--            <kendo-card class="document-preview preview-attachment icon-type-{{preview.name | getFileExtension}}"-->
<!--              *ngIf="!preview.isImage || !preview.url" [ngClass]="{ 'card-loading-attachment': !preview.url }"-->
<!--              [width]="'80px'">-->
<!--              <div *ngIf="!preview.url" class="attachment-loading k-icon k-i-loading"></div>-->
<!--              <span-->
<!--                class="k-icon file-type-icon icon-style-type-{{preview.name | getFileExtension}} k-i-file-{{preview.name | getFileExtension}}"></span>-->
<!--              <kendo-card-body class="k-hbox" class="document-preview-body">-->
<!--                <p [title]="preview.name" kendoCardSubtitle>-->
<!--                  {{ preview.name | extractFileName | truncateFileNameShort: 4 }}-->
<!--                </p>-->
<!--              </kendo-card-body>-->
<!--            </kendo-card>-->
<!--          </div>-->
<!--        </ng-container>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </kendo-tabstrip-tab>-->
<!--</kendo-tabstrip>-->
