<form class="form-holder" [formGroup]="vendorForm">
    <div class="form-groups-container">
      <div class="layout layout--stretch">
        <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
          <div class="form-group">
            <h2 class="form-group-header">General Info</h2>

            <div class="form-control">
              <kendo-label for="vendor-name" text="Organization Name"></kendo-label>
              <input kendoTextBox id="vendor-name" type="text" formControlName="name" autocomplete="nope">
            </div>
            <div class="form-control">
              <kendo-label for="contact-first-name" text="Contact First Name"></kendo-label>
              <input kendoTextBox id="contact-first-name" type="text" formControlName="contact_first_name" autocomplete="nope">
            </div>
            <div class="form-control">
              <kendo-label for="contact-last-name" text="Contact Last Name"></kendo-label>
              <input kendoTextBox id="contact-last-name" type="text" formControlName="contact_last_name" autocomplete="nope">
            </div>
          </div>
        </div>
        <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
          <ng-container formGroupName="primaryLocation">
          <div class="form-group">
            <h2 class="form-group-header">
              Address
            </h2>

            <div class="form-control">
              <kendo-label for="address" text="Street"></kendo-label>
              <input ngx-google-places-autocomplete name="address"
                class="k-textbox"
                autocomplete="nope"
                formControlName="address"
                placeholder="Street"
                (onAddressChange)="handleAddressChange($event)">
            </div>

            <div class="layout">
              <div class="layout__item u-1/2">
                <div class="form-control">
                  <kendo-label for="city" text="City"></kendo-label>
                  <input kendoTextBox id="city" type="text" formControlName="city" autocomplete="nope">
                </div>
              </div>
              <div class="layout__item u-1/2">
                <div class="form-control">
                  <kendo-label for="state" text="State/Province"></kendo-label>
                  <input kendoTextBox id="state" type="text" formControlName="province" autocomplete="nope">
                </div>
              </div>
            </div>

            <div class="layout">
              <div class="layout__item u-1/2">
                <div class="form-control">
                  <kendo-label for="post-code" text="Zip/Postcode"></kendo-label>
                  <input kendoTextBox id="post-code" type="text" formControlName="postal_code" autocomplete="nope">
                </div>
              </div>
              <div class="layout__item u-1/2">
                <div class="form-control">
                  <kendo-label for="country" text="Country"></kendo-label>
                  <input kendoTextBox id="country" type="text" formControlName="country" autocomplete="nope">
                </div>
              </div>
            </div>
          </div>
          </ng-container>
        </div>
        <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
          <div class="form-group">
            <h2 class="form-group-header">Contact</h2>
            <div class="form-control">
              <kendo-label for="web-site" text="Website"></kendo-label>
              <input kendoTextBox id="web-site" type="text" formControlName="contact_website" autocomplete="nope"
                pattern="([hH][tT][tT][pP][sS]?:\/\/)?[\w-]+(\.[\w-]+)+(\/([^\/]+\/?)*)?"
                placeholder="Website">
            </div>

            <kendo-formfield class="form-control">
              <kendo-label for="email" text="Email"></kendo-label>
              <input kendoTextBox id="email" type="email" formControlName="contact_email" autocomplete="nope">
              <kendo-formerror *ngIf="vendorForm.controls.contact_email.errors?.error">
                {{vendorForm.controls.contact_email.errors?.error}}
              </kendo-formerror>
            </kendo-formfield>

            <div class="form-control">
              <kendo-label for="phone" [optional]="true" text="Phone" ></kendo-label>
<!--              <input kendoTextBox id="phone" type="text" formControlName="contact_number" autocomplete="nope" [mask]="(f.get('contact_number').value | findMask)">-->
              <input kendoTextBox id="phone" type="text" formControlName="contact_number" autocomplete="nope" [mask]="mask">
            </div>
          </div>
        </div>
        <div class="layout__item  u-1/1  u-1/2@sm  u-1/3@lg  u-1/4@xl">
          <div class="form-group">
            <h2 class="form-group-header">Additional Info</h2>

            <div class="form-control">
              <kendo-label for="web-site" text="Organization Type"></kendo-label>
              <ul class="k-radio-list">
                <li class="k-radio-item">
                  <input name="kind" type="radio" #kindVendor kendoRadioButton formControlName="kind" value="vendor">
                  <kendo-label class="k-radio-label" [for]="kindVendor" text="Vendor"></kendo-label>
                </li>
                <li class="k-radio-item">
                  <input name="kind" type="radio" #kindManufacturer kendoRadioButton formControlName="kind" value="manufacturer">
                  <kendo-label class="k-radio-label" [for]="kindManufacturer" text="Manufacturer"></kendo-label>
                </li>
                <li class="k-radio-item">
                  <input name="kind" type="radio" #kindShowroom kendoRadioButton formControlName="kind" value="showroom">
                  <kendo-label class="k-radio-label" [for]="kindShowroom" text="Showroom"></kendo-label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="layout__item  u-1/1  u-1/1@sm  u-2/3@lg  u-1/2@xl">
<!--          <div class="form-group">-->
<!--            <h2 class="form-group-header">Locations</h2>-->
<!--            <div class="form-control">-->
<!--              <div class="k-checkbox-item">-->
<!--                <input type="checkbox" kendoCheckBox [(ngModel)]="locationsChecked"-->
<!--                       [ngModelOptions]="{standalone: true}" />-->
<!--                <kendo-label class="k-checkbox-label"-->
<!--                             text="We have additional locations"></kendo-label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <vp-locations *ngIf="locationsChecked"></vp-locations>-->
<!--          </div>-->
          <div class="form-group" *ngIf="(organizationProfile | async)?.plan?.directoryCoverPhoto">
            <h2 class="form-group-header">Directory Cover Photo</h2>
            <div class="form-control" kendoTooltip filter=".tooltip-enabled" position="bottom">
              <span *ngIf="vendorForm.get('cover_photo_url')?.value?.length > 0" class="material-icons remove-attachment-icon" (click)="removeLogo('cover_photo_url')">cancel</span>
              <label *ngIf="vendorForm" class="details-header__logo-container tooltip-enabled" title="Your cover photo on the Studio Designer Vendor Directory">
                <ng-container [ngSwitch]="vendorForm.get('cover_photo_url')?.value?.length > 0">
                  <input type="file" (change)="onLogoUpload($event)" accept="image/png, image/jpeg">
                  <kendo-avatar *ngSwitchCase="true"
                                width="170px"
                                height="120px"
                                [imageSrc]="vendorForm.get('cover_photo_url')?.value"
                                class="kendo-avatar">
                  </kendo-avatar>
                  <div class="details-header__logo-placeholder cover_photo-placeholder" *ngSwitchDefault>
                    <div *ngIf="logoUploading" class="k-icon k-i-loading k-icon-xl"></div>
                    <ng-container *ngIf="!logoUploading">
                      <div class="plus">+</div>
                      <div>Upload Cover Photo</div>
                    </ng-container>
                  </div>
                </ng-container>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
</form>
