import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";
import {Store} from "@ngrx/store";
import {AppState} from "./app.module";
import {selectAuthenticatedUser, selectIsAuthenticated} from "./modules/authentication/state/authentication.selector";
import {User} from "./modules/shared/models/user";
import {Observable, Subscription} from 'rxjs';
import {selectHeader} from './modules/shared/state/shared.selector';

@Component({
  selector: 'vp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  protected subscriptions: Subscription[] = [];
  public header: Observable<string>;

  constructor(private permissionsService: NgxPermissionsService,
              private store: Store<AppState>) {}


  ngOnInit(): void {

    const authenticatedSubscription = this.store.select(selectAuthenticatedUser).subscribe((user: User) => {

      if (user === null) {
        this.permissionsService.flushPermissions();
        return;
      }

      // load permissions when authenticated user is updated.
      this.permissionsService.loadPermissions(user.role || []);

    });

    this.subscriptions.push(authenticatedSubscription);

    this.header = this.store.select(selectHeader);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
