/**
 * src/app/modules/shared/components/header/unlinked-header.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 7/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {changeHeaderAction} from '../../state/shared.actions';
import {Router} from '@angular/router';
import {selectOrganization} from '../../../organizations/state/organizations.selector';
import {Observable} from 'rxjs';
import {selectOrder, selectUuid} from '../../../orders/state/orders.selector';
import {take} from 'rxjs/operators';
import {SignUpModalComponent} from '../sign-up-modal/sign-up-modal.component';
import {Orders} from "../../../orders/models/orders";

@Component({
  selector: 'vp-unlinked-header',
  templateUrl: './unlinked-header.component.html',
  styleUrls: ['./unlinked-header.component.scss']
})
export class UnlinkedHeaderComponent implements OnInit, OnDestroy {

  public uuid: Observable<string>;
  public order: Observable<Orders>;
  @ViewChild(SignUpModalComponent) signUpModal: SignUpModalComponent;

  constructor(private store: Store<AppState>,
              private router: Router) {}

  ngOnInit(): void {

    this.uuid = this.store.select(selectUuid);
    this.order = this.store.select(selectOrder);

    // determine if there is an organization currently set. if so, change the header
    this.store.select(selectOrganization).subscribe(next => {
      if (next && next.id) {
        this.store.dispatch(changeHeaderAction({header: 'user'}));
      }
    });
  }

  ngOnDestroy() {}

  // we want to hide the header
  login() {
    this.signUpModal.openModal();
  }

  // we want to hide the header
  signUp() {
    this.store.dispatch(changeHeaderAction({header: ''}));

    this.uuid.pipe(take(1)).subscribe(uuid => {
      this.router.navigate(['/sign-up'], { queryParams: { uuid } });
    });
  }

}
