/**
 * src/app/modules/shared/components/header/header.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 11/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {Observable, Subscription} from 'rxjs';
import {selectIsAuthenticated} from '../../../authentication/state/authentication.selector';
import {AuthenticationService} from '../../../authentication/services/authentication.service';
import {take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {logoutSuccessAction} from '../../../authentication/state/authentication.actions';
import {Router} from '@angular/router';
import {changeHeaderAction} from '../../state/shared.actions';
import {selectSystemAdminPlanEdit} from "../../../system-admin/state/systemAdmin.selector";
import {PlanEdit} from "../../../system-admin/state/systemAdmin.reducer";
import {Organizations} from "../../../organizations/models/organizations";
import {selectOrganization} from "../../../organizations/state/organizations.selector";

@Component({
  selector: 'vp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public isVendorActive = true;
  public showMenu = false;
  public subscription: Subscription;
  public showNotificationDropdown = false;
  public org: Observable<Organizations>;

  // public exampleNotifications: any[] = [
  //   {
  //     designer: 'Associated Redifusion',
  //     message: 'This is a notification text',
  //     status: 'new',
  //     date: '16 December 2020',
  //     orderID: '/orders/1'
  //   }
  // ];

  constructor(private store: Store<AppState>,
              private authenticationService: AuthenticationService,
              private toastrService: ToastrService,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.org = this.store.select(selectOrganization);
    this.subscription = this.store.select(selectIsAuthenticated).subscribe((isAuthenticated: boolean) => {
      this.showMenu = isAuthenticated;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  logout() {
    this.authenticationService.logout().pipe(take(1)).subscribe(() => {
      this.store.dispatch(logoutSuccessAction());
    }, (err) => {
      this.toastrService.error('Unable to successfully log you out.');
    });
  }

  changeHeader() {
    this.store.dispatch(changeHeaderAction({header: 'admin'}));
  }


  toggleNotificationDropdown() {
    this.showNotificationDropdown = !this.showNotificationDropdown;

  }

}
