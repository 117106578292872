/**
 * src/app/modules/user-space-admin/services/dashboard.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 9/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultListDTO } from '../../shared/models/resultList.dto';
import { State } from '@progress/kendo-data-query';
import { TransformationsService } from '../../shared/services/transformations.service';
import {DashboardConfiguration} from '../models/dashboardConfiguration';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService) { }

  /**
   * Get paginated collection of dashboard configurations in the vendor portal.
   */
  public getCollection(state?: State): Observable<DashboardConfiguration[]> {

    const params = state ? this.transformationsService.getHttpParamsFromKendoState(state) : {};

    return this.httpClient.get<DashboardConfiguration[]>(`/api/dashboard-configurations/list`, {params});

  }

  /**
   * Get a single dashboard configuration in the vendor portal.
   */
  public getOne(id: number): Observable<DashboardConfiguration> {
    return this.httpClient.get<DashboardConfiguration>(`/api/dashboard-configurations/config/${id}`);
  }

  /**
   * Create or update a dashboard configuration.
   * @var plan
   */
  public save(config: DashboardConfiguration): Observable<DashboardConfiguration> {

    const url = '/api/dashboard-configurations/config';

    if (config.id) {
      return this.httpClient.put<DashboardConfiguration>(`${url}/${config.id}`, config);
    }

    return this.httpClient.post<DashboardConfiguration>(url, config);

  }

}
