<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp">
    <div class="paper small">
      <div *ngIf="!successfullyReset" class="sign-body">
        <form [formGroup]="forgotPasswordForm" (submit)="onSubmit()" class="left-block">
          <h2 class="mb-20">Password Recovery</h2>
          <p class="subtitle mb-38">Did you forget your password? We'll send you an email with instructions</p>

          <div class="custom reset-input-block">
            <label for="email">Email</label>
            <input kendoTextBox id="email" type="email" formControlName="email" autocomplete="email"
              placeholder="your-email@email.com" [pattern]="emailValidationPattern">
          </div>

          <div class="btns-row custom">
            <button routerLink="/login" kendoButton class="btn btn-secondary">Back</button>

            <button kendoButton [primary]="true" kendoButton class="btn btn-primary">Reset Password</button>
          </div>
        </form>
      </div>
      <div *ngIf="successfullyReset" class="success-container custom">
        <span class="k-icon k-i-check mb-4"></span>
        <h2 class="mb-2">
          Success!
        </h2>
        <p class="subtitle mb-2 mb-4">Your password has been reset! Check your email and follow the instructions</p>
        <button routerLink="/login" kendoButton class="btn btn-secondary">Back</button>
      </div>
    </div>
  </div>

</div>
