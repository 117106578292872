<div class="container">
  <div class="page-info  page-info--dashboard papper">
    <h2 class="page-info__title">Organization Dashboard</h2>

    <div class="page-info__data">
      <div class="page-info__data-item"><span>
          {{locationCount | async}}
        </span> Locations</div>
      <div class="page-info__data-item"><span>
          {{employeeCount | async}}
        </span> Employees</div>
    </div>
  </div>

  <div class="content-area  content-area--dashboard">

      <div class="grid-controls">

        <form class="form-holder" [formGroup]="newWidgetForm">

          <div id="data-filter" class="dropdown-filter">
            <kendo-dropdownlist
              id="data-dropdown"
              [data]="dataOptions"
              formControlName="widget">
            </kendo-dropdownlist>
          </div>

          <div id="timeframe-filter" class="dropdown-filter">
            <kendo-dropdownlist
              id="timeframe-dropdown"
              [data]="timeframeOptions"
              formControlName="timeframe">
            </kendo-dropdownlist>
          </div>

          <div id="display-filter" class="dropdown-filter">
            <kendo-dropdownlist
              id="display-dropdown"
              [data]="display"
              formControlName="display">
            </kendo-dropdownlist>
          </div>

        </form>

        <button class="filter-button" kendoButton primary="true"
                (click)="addNewItem()">
          <span class="label-flex-vertical">Add New Widget</span>
        </button>

        <div class="button-group-flex-right">

          <span *ngIf="(dashboard | async)?.id" class="label-flex-vertical k-mr-3">Selected Template: {{ (dashboard | async)?.name }}</span>

          <button class="filter-button" kendoButton primary="true"
                  (click)="openSavePrompt()">
            <span class="label-flex-vertical">Save Template</span>
          </button>

          <button class="filter-button" kendoButton primary="true"
                  (click)="openLoadPrompt()">
            <span class="label-flex-vertical">Load Template</span>
          </button>

        </div>

      </div>

    <kendo-dialog *ngIf="templateSaveOpen | async" #saveDialog title="Save Template"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (close)="closeSavePrompt($event)">
      <div class="dialog-content">
        <form class="form-holder" [formGroup]="saveForm">
          <div class="form-group">
            <div class="form-control">
              <input kendoTextBox id="name" type="text" formControlName="name" [autofocus] (keyup.enter)="closeSavePrompt(saveForm.get('name').value)">
            </div>
          </div>
        </form>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closeSavePrompt()">Cancel</button>
        <button *ngIf="(dashboard | async)?.id" kendoButton [disabled]="s.invalid"
                (click)="closeSavePrompt(saveForm.get('name').value)">
          Save
        </button>
        <button kendoButton primary="true" [disabled]="s.invalid"
                (click)="closeSavePrompt(saveForm.get('name').value, true)">
          Save As New
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>

    <kendo-dialog *ngIf="templateLoadOpen | async" #loadDialog title="Load Template"
                  [width]="500" [minWidth]="450"
                  [maxHeight]="'70vh'"
                  (close)="closeLoadPrompt()">
      <div class="dialog-content">
        <form class="form-holder" [formGroup]="loadForm">
          <div class="form-group">
            <div class="form-control">
              <kendo-dropdownlist
                id="plan-dropdown"
                *ngIf="(user | async)"
                [data]="(user | async).dashboardConfigurations"
                [textField]="'name'"
                [valueField]="'id'"
                [defaultItem]="{'name': 'Select...'}"
                formControlName="configuration">
              </kendo-dropdownlist>
            </div>
          </div>
        </form>
      </div>
      <kendo-dialog-actions layout="stretched">
        <button kendoButton (click)="closeLoadPrompt()">Cancel</button>
        <button kendoButton primary="true"
                (click)="closeLoadPrompt(loadForm.get('configuration').value)">
          Load Template
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>

    <gridster class="gridster-container" *ngIf="(dashboard | async)" [options]="options">
      <gridster-item [item]="item | removeReadonly" *ngFor="let item of (dashboard | async).configuration">
        <kendo-floatingactionbutton
          icon="trash"
          [align]="{ vertical: 'top', horizontal: 'end' }"
          themeColor="warning"
          shape="circle"
          (mousedown)="removeItem($event, item)"
          (touchstart)="removeItem($event, item)"
        ></kendo-floatingactionbutton>
        <div class="button-holder">
          <vp-orders-over-time [display]="(item | removeReadonly).display" [timeframe]="(item | removeReadonly).timeframe" [widget]="(item | removeReadonly).widget" [customer]="(item | removeReadonly).customer" (customerSelected)="onCustomerSelected($event, item)"></vp-orders-over-time>
        </div>
      </gridster-item>
    </gridster>
  </div>

</div>
