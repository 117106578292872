/**
 * src/app/modules/user-space-admin/user-space-admin-routing.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {
  countEmployeesGetAction,
  countLocationsGetAction
} from "./state/userSpaceAdmin.actions";
import {StateResolver} from "../shared/services/state.resolver";
import {IsAuthenticatedGuardService} from "../authentication/services/is-authenticated-guard.service";
import {
  previewNotificationsListGetAction
} from "../notifications/state/notifications.actions";
import {DashboardGuardService} from "../authentication/services/featureGuards/dashboard-guard.service";

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [IsAuthenticatedGuardService, DashboardGuardService],
    component: DashboardComponent,
    resolve: { state: StateResolver },
    data: {
      actions: [
        { action: countLocationsGetAction },
        { action: countEmployeesGetAction },
        { action: previewNotificationsListGetAction }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserSpaceAdminRoutingModule { }
