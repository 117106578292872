import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../../../app.module';
import {logoutSuccessAction} from '../state/authentication.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe( tap(() => {}, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        const url = location.toString();
        if (err.status !== 401 || url.includes('login')) {
          return;
        }

        this.store.dispatch(logoutSuccessAction());

        this.router.navigate(['/login']);
      }
    }));

  }
}
