/**
 * src/modules/shared/pipes/remove-selected.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/11/2021
 */
import {Pipe, PipeTransform} from '@angular/core';
import {OrdersSummary} from '../../orders/models/orders-summary';
import {Widget} from '../models/widget.enum';
import * as currency from 'currency.js';

@Pipe({
  name: 'customerDataPoint'
})
export class CustomerDataPointPipe implements PipeTransform {

  transform(v: OrdersSummary, widget: Widget): string {

    const opts = {symbol: '$', precision: 2};

    if (!v || !widget) {
      return v ? v.value.format(opts) : currency(0).format(opts);
    }

    switch (widget) {
      case Widget.AverageValueOfOrders:
        return currency(v.value).format(opts);
      case Widget.TotalValueOfOrders:
        return currency(v.value).format(opts);
      case Widget.QuantityOfOrders:
        return v.value.value.toString();
      default:
        return v.value.value.toString();
    }

  }

}
