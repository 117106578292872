import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {FormsService} from '../../../shared/services/forms.service';
import {take} from 'rxjs/operators';
import {TextBoxComponent} from "@progress/kendo-angular-inputs";

@Component({
  selector: 'vp-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {
  @ViewChild('password') public textbox: TextBoxComponent;

  protected subscriptions: Subscription[] = [];
  public token: string;
  public passwordForm: FormGroup;
  public loading = false;
  public err: string;
  public passwordValidationPattern: any = /^(?=\D*\d)(?=[^A-Z]*[A-Z]).{8,}$/;

  constructor(private activatedRoute: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private formsService: FormsService,
              private cd: ChangeDetectorRef,
              private router: Router) { }


  public ngAfterViewInit(): void {
    this.textbox.input.nativeElement.type = "password";
  }

  ngOnInit(): void {
    const routeSubscriber = this.activatedRoute.queryParams.subscribe(async params => {

      if (params['token'] && params['token'].length > 0) {
        this.token = params['token'];
      } else {
        this.token = '';
      }

    });

    this.subscriptions.push(routeSubscriber);

    this.passwordForm = this.createForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get f() {
    return this.passwordForm;
  }

  createForm() {
    return new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ])
    });
  }

  onSubmit() {
    if (!this.f.valid || !this.token) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    this.authenticationService
      .setPassword(this.token, this.f.get('password').value)
      .pipe(take(1))
      .subscribe(
        r => {
          this.router.navigate(['/login']);
        },
        err => {
          this.err = err.message;
        }
      );
  }

  public togglePass(visible: boolean): void {
    this.textbox.input.nativeElement.type = visible ? 'text' : 'password';
  }
}
