/**
 * src/app/modules/authentication/components/sign-up-success/sign-up-success.component.ts
 *
 * @author Peter Korotkiy <darkfoxs96@gmail.com>
 * @since 08/04
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';

@Component({
  selector: 'vp-sign-up-success',
  templateUrl: './sign-up-success.component.html',
  styleUrls: ['./sign-up-success.component.scss'],
})
export class SignUpSuccessComponent implements OnInit {

  email = this.activatedRoute.params.pipe(map(p => p?.uuid as string));

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

}
