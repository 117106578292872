/**
 * src/app/modules/shared/services/transformations.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {State as KendoState} from "@progress/kendo-data-query";

@Injectable({
  providedIn: 'root'
})
export class TransformationsService {

  constructor() { }


  /**
   * Transform kendo data state parameters into http parameters.
   * @param state
   */
  getHttpParamsFromKendoState(state: KendoState): HttpParams | null {

    let params = new HttpParams();

    if (!state) {
      return params;
    }

     if (state.take) {
       params = params.append('take', String(state.take))
     }

     if (state.skip) {
      params = params.append('skip', String(state.skip));
     } else {
       params = params.append('skip', '0');
     }

    if (state.sort && state.sort.length > 0) {
      for (const s of state.sort) {

        // skip undefined sorts.
        if (s.dir === undefined) {
          continue;
        }

        params = params.append('sortFields[]', s.field)
          .append('sortOrder[]', s.dir.toUpperCase());

      }

      if (state.filter && state.filter.filters && state.filter.filters.length > 0) {

        for(const f of <any>state.filter.filters) {
          params = params.append('filterFields[]', String(f.field))
            .append('filterValues[]', String(f.value))
            .append('filterOperators[]', String(f.operator))
        }

      }

    }

    return params;

  }

}
