/**
 * src/app/modules/organizations/state/organizations.effects.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {OrganizationsService} from '../services/organizations.service';
import {
  countOrganizationGetAction,
  countOrganizationSubjectChangeAction,
  countActiveOrganizationGetAction,
  countActiveOrganizationSubjectChangeAction,
  countInactiveOrganizationGetAction,
  countInactiveOrganizationSubjectChangeAction,
  userLoginEditSubjectChangeAction,
  userLoginEditSubjectGetAction,
  userLoginEditSubjectUpdateAction,
  userLoginEditSubjectUpdateFailedAction,
  userOrganizationEditSubjectChangeAction,
  userOrganizationEditSubjectGetAction,
  userOrganizationEditSubjectGetFailedAction,
  userOrganizationEditSubjectUpdateAction,
  userOrganizationEditSubjectUpdateFailedAction,
  countUserGetAction,
  countUserSubjectChangeAction,
  userOrganizationEditLogoUrlSubjectUpdateAction,
  userOrganizationEditLogoUrlSubjectUpdateFailedAction,
} from './organizations.actions';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {of} from 'rxjs';
import {User} from '../../shared/models/user';
import {UsersService} from '../services/users.service';
import {Organizations} from '../models/organizations';
import {CountService} from '../services/count.service';
import {HandleErrorService} from "../../shared/services/handle-error.service";

@Injectable()
export class OrganizationsEffects {

  constructor(
    private actions: Actions,
    private router: Router,
    private organizationsService: OrganizationsService,
    private toastrService: ToastrService,
    private userService: UsersService,
    private countService: CountService,
    private errorHandler: HandleErrorService
  ) {}

  getOrganization$ = createEffect(() =>
    this.actions.pipe(
      ofType(userOrganizationEditSubjectGetAction.type),
      switchMap((id: number) => {
          return this.organizationsService.getOne().pipe(
            map(org => userOrganizationEditSubjectChangeAction(org)),
            catchError((error: HttpErrorResponse) => {
              this.errorHandler.handleError(error, 'Sorry! We could not retrieve your profile.');
              return of(userOrganizationEditSubjectGetFailedAction({ error }));
        }));
        })
    )
  );

  updateOrganization$ = createEffect(() =>
    this.actions.pipe(
      ofType(userOrganizationEditSubjectUpdateAction.type),
      switchMap((updatedProfile: Organizations) => {

          return this.organizationsService.save(updatedProfile).pipe(map(org => {

              this.toastrService.success(`Profile updated!`);

              return userOrganizationEditSubjectChangeAction(org);

            }),
            catchError((error: HttpErrorResponse) => {
              this.toastrService.error('There was a server error saving your profile.');
              return of(userOrganizationEditSubjectUpdateFailedAction({ error }));
            }));

        }

      )
    ));

  updateLogoOfOrganization$ = createEffect(() =>
    this.actions.pipe(
      ofType(userOrganizationEditLogoUrlSubjectUpdateAction.type),
      switchMap((updatedProfile: Organizations) => {
          return this.organizationsService.save(updatedProfile).pipe(switchMap(o => {
              this.toastrService.success(`Logo updated`);
              return of();
            }),
            catchError((err) => {
              this.toastrService.error(`Could not update logo`);
              return of(userOrganizationEditLogoUrlSubjectUpdateFailedAction(err));
            })
          );

        }
      )
    ));

  getLogin = createEffect(() =>
    this.actions.pipe(
      ofType(userLoginEditSubjectGetAction.type),
      switchMap(({id}) => {

          if (id === 'new') {
            return of(userLoginEditSubjectChangeAction(new User()));
          }

          return this.userService.getOne(id).pipe(map(user => userLoginEditSubjectChangeAction(user)),
            catchError((e) => {
              this.errorHandler.handleError(e, 'There was a server error retrieving this login.');
              return of(userLoginEditSubjectChangeAction(new User()));
            }));
        }

      )
    )
  );

  updateLogin$ = createEffect(() =>
    this.actions.pipe(
      ofType(userLoginEditSubjectUpdateAction.type),
      switchMap((user: User) => {

          return this.userService.save(user).pipe(switchMap(u => {

              let msg;

              if (!user.id) {
                msg = `${u.first_name} ${u.last_name} has been successfully added.`;
              } else {
                msg = `${u.first_name} ${u.last_name} has been successfully updated.`;
              }

              this.router.navigate(['/profile']);
              this.toastrService.success(msg);

              return of(userLoginEditSubjectChangeAction(u));

            }),
            catchError((err) => {

              let msg = 'There was a server error saving this user';
              if (err.error && err.error.hasOwnProperty('statusCode') && err.error.statusCode === 409 ) {
                msg = err.error.message;
              }

              this.toastrService.error(msg);
              return of(userLoginEditSubjectUpdateFailedAction(err));

            })
            );

        }
      )
    )
  );

  getOrganizationCount = createEffect(() =>
    this.actions.pipe(
      ofType(countOrganizationGetAction.type),
      switchMap((props: any) => {
        return this.countService.getOrganizationCount().pipe(take(1), map(c => countOrganizationSubjectChangeAction({organizationCount: c})),
          catchError((e) => {
            this.errorHandler.handleError(e, 'There was a server error retrieving the count of organizations')
            return of(countOrganizationSubjectChangeAction({organizationCount: 0}));
          }));
      })
    ));

  getActiveOrganizationCount = createEffect(() =>
  this.actions.pipe(
    ofType(countActiveOrganizationGetAction.type),
    switchMap((props: any) => {
      return this.countService.getActiveOrganizationCount().pipe(take(1), map(c => countActiveOrganizationSubjectChangeAction({activeOrganizationCount: c})),
        catchError((e) => {
          this.errorHandler.handleError(e, 'There was a server error retrieving the count of active organizations');
          return of(countActiveOrganizationSubjectChangeAction({activeOrganizationCount: 0}));
        }));
    })
  ));

  getInactiveOrganizationCount = createEffect(() =>
    this.actions.pipe(
      ofType(countInactiveOrganizationGetAction.type),
      switchMap((props: any) => {
        return this.countService.getInactiveOrganizationCount().pipe(take(1), map(c => countInactiveOrganizationSubjectChangeAction({inactiveOrganizationCount: c})),
          catchError((e) => {
            this.errorHandler.handleError(e, 'There was a server error retrieving the count of inactive organizations');
            return of(countInactiveOrganizationSubjectChangeAction({inactiveOrganizationCount: 0}));
          }));
      })
    ));

  getUserCount = createEffect(() =>
    this.actions.pipe(
      ofType(countUserGetAction.type),
      switchMap((props: any) => {
        return this.countService.getUserCount().pipe(take(1), map(c => countUserSubjectChangeAction({userCount: c})),
          catchError((e) => {
            this.errorHandler.handleError(e, 'There was a server error retrieving the count of users');
            return of(countUserSubjectChangeAction({userCount: 0}));
          }));
      })
    ));
}
