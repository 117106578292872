/**
 * src/app/modules/orders/pipes/item-total.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 01/21
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';
import {Orders} from '../models/orders';
import {Items} from "../models/items";

@Pipe({
  name: 'imageOccurrences'
})
export class ImageOccurrencesPipe implements PipeTransform {
  transform(item: Items): number {
    let imageCount = 0;
    if (item.primary_image !== null) {
      imageCount = item.files.filter((doc) => (doc.type === 'image')).length + 1;
    } else {
      imageCount = item.files.filter((doc) => (doc.type === 'image')).length;
    }
    return imageCount;
  }
}
