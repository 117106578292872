/**
 * src/app/modules/organizations/models/organizations.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import { User } from '../../shared/models/user';
import { OrganizationKind } from './organizationKind';
import { Locations } from '../../shared/models/locations';
import {Plans} from './plans';
import {StripeStatusEnum} from './stripeStatus.enum';

export class Organizations {
  id: number;
  users: User[] | null;
  name: string | null;
  logo_url: string | null;
  cover_photo_url: string | null;
  contact_first_name: string | null;
  contact_last_name: string | null;
  contact_website: string | null;
  contact_email: string | null;
  contact_number: string | null;
  kind: OrganizationKind;
  active: boolean;
  locations: Locations[] | null;
  primaryLocation: Locations | null;
  notification_triggers: string[];
  plan: Plans | null;
  allow_credit_card: boolean;
  allow_ach: boolean;
  stripe_status: StripeStatusEnum;

  uuid?: string; // we can use this in the case of submitting an associated order with a new organization signing up

  constructor() {
  }

}
