/**
 * src/app/modules/orders/components/order-details/order-details.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @author Alan Moore <alan@studiodesigner.com>
 *
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '@progress/kendo-angular-conversational-ui';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {
  selectDefaultPlan,
  selectOrder,
  selectOrderAcceptProcessing,
  selectOrderDetails,
  selectOrderFreightTrackingProcessing,
  selectOrderRejectProcessing, selectRequestPaymentProcessing,
  selectUuid
} from '../../state/orders.selector';
import {Observable, Subscription} from 'rxjs';
import {Orders} from '../../models/orders';
import {filter, map, take} from 'rxjs/operators';
import {OrdersService} from '../../services/orders.service';
import {OrderStatus} from '../../models/order-status.enum';
import {selectAuthenticatedUser} from '../../../authentication/state/authentication.selector';
import {User as VpUser} from '../../../shared/models/user';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {ToastrService} from 'ngx-toastr';
import {
  orderAccept,
  orderAcceptDialog,
  orderFreightTrackingDialog,
  orderReject,
  orderRejectDialog,
  orderUpdateFreightTracking, requestPaymentDialog, sendPaymentOptions
} from '../../state/orders.actions';
import {FileService} from '../../services/file.service';
import {Items} from '../../models/items';
import {yyyymmddFromDate, yyyymmddToDate} from '../../../../tools/time.tools';
import {selectOrganization} from '../../../organizations/state/organizations.selector';
import {Organizations} from '../../../organizations/models/organizations';
import {Plans} from '../../../organizations/models/plans';
import {ActivatedRoute, Router} from '@angular/router';
import {changeHeaderAction} from '../../../shared/state/shared.actions';
import {selectHeader} from '../../../shared/state/shared.selector';
import {SignUpModalComponent} from '../../../shared/components/sign-up-modal/sign-up-modal.component';
import {CurrencyPipe, Location} from '@angular/common';

const bot: User = {
  id: 0
};

const user: User = {
  id: 1,
};

@Component({
  selector: 'vp-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDetailsComponent implements OnInit, OnDestroy {

  public attachmentsDialogOpen = false;
  public adjustDialogOpen = false;
  public freightTrackingDialogOpen: Observable<boolean>;
  public rejectDialogOpen: Observable<boolean>;
  public acceptDialogOpen: Observable<boolean>;
  public requestPaymentDialogOpen: Observable<boolean>;
  public showChat = false; // hide chat window
  public order: Observable<Orders>;
  public vpUser: Observable<VpUser>;
  public orderStatuses = OrderStatus;
  public getOrderStatus: boolean;
  public freightTrackingForm: FormGroup;
  public orderAcceptForm: FormGroup;
  public requestPaymentForm: FormGroup;
  public orderRejectForm: FormGroup;
  public viewItem: Items;
  public acceptOrderDateValue: Date = new Date();
  public rejectOrderDateValue: Date = new Date();
  public organization: Observable<Organizations>;
  public defaultPlan: Observable<Plans>;
  public uuid: Observable<string>;
  public header: Observable<string>;
  public isUnlinkedShare = false;
  public dateFormat = 'MM/dd/yyyy';
  public calendarType = 'classic';
  public isOrderShown = true;
  public innerWidth: number;
  public freightTrackingProcessing = this.store.select(selectOrderFreightTrackingProcessing);
  public rejectProcessing = this.store.select(selectOrderRejectProcessing);
  public acceptProcessing = this.store.select(selectOrderAcceptProcessing);
  public requestPaymentProcessing = this.store.select(selectRequestPaymentProcessing);
  public inputCounter = 0;
  public selectedPaymentACH: boolean;
  public selectedPaymentCreditCard: boolean;
  // public paymentButtonFocusCCard = false;
  public paymentAllowedOrderStatuses = [
    OrderStatus.Approved,
    OrderStatus.Delivered,
    OrderStatus.New,
    OrderStatus.Shipped,
  ];

  protected subscriptions: Subscription[] = [];

  // the default minimum date for ALL date forms. anything before this would not be submittable
  public minDate = new Date(1970, 1, 1);

  // accept max date
  public acceptMaxDate: Date;

  public readonly user: User = user;
  public readonly bot: User = bot;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @ViewChild(SignUpModalComponent) signUpModal: SignUpModalComponent;
  @ViewChild('send_accept_btn') send_accept_btn: ElementRef;
  @ViewChild('send_reject_btn') reject_btn: ElementRef;
  @ViewChild('update_freight_tracking_btn') update_freight_tracking_btn: ElementRef;
  @ViewChild('reject_order_date') reject_order_date: ElementRef;
  @ViewChild('reject_order_name_btn') reject_order_name_btn: ElementRef;


  constructor(private store: Store<AppState>,
              private ordersService: OrdersService,
              private http: HttpClient,
              private toastrService: ToastrService,
              private fileInput: ElementRef,
              private fileService: FileService,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              private _el: ElementRef,
              private router: Router) { }

  ngOnInit(): void {

    this.header = this.store.select(selectHeader);
    this.innerWidth = window.innerWidth;

    this.defaultPlan = this.store.select(selectDefaultPlan);
    this.uuid = this.store.select(selectUuid);

    this.organization = this.store.select(selectOrganization);

    // take one org, then get the order, with which we can effectively determine on next if order.org_id === org.id
    this.organization.pipe(take(1)).subscribe(org => {
      this.order = this.store.select(selectOrder);
      this.order.subscribe(order => {
        this.selectedPaymentACH = (typeof order?.allow_ach === 'boolean') ? order.allow_ach : org.allow_ach;
        this.selectedPaymentCreditCard = (typeof order?.allow_credit_card === 'boolean') ? order.allow_credit_card : org.allow_credit_card;

        // use this for matching
        const sharedUrl = 'shared/';

        // check if the path in the address bar matches /shared/*
        const pathMatches = this.location.path().substr(0, `/${sharedUrl}`.length) === `/${sharedUrl}`;

        // check if the route being used by angular is /shared/:uuid
        const angularPathMatches = this.activatedRoute.routeConfig.path === `${sharedUrl}:uuid`;

        if (pathMatches && angularPathMatches && order && order.organization
          && order.organization.id && org && order.organization.id === org.id) {
          // change the path in the address bar without reloading this component
          this.location.go(`/orders/${order.id}`);
        }

        // our max date should be at least 14 days in the future. if current value is more than 14 days, use that as max
        const d = new Date();
        d.setDate(d.getDate() + 14);
        this.acceptMaxDate = order && order.accepted_at && order.accepted_at > d ? order.accepted_at : d;

        this.showChat = !(!order || order.status === this.orderStatuses.Canceled); // hide chat if order is canceled; otherwise, show chat

      });

    });


    this.freightTrackingDialogOpen = this.store.select(selectOrderDetails)
      .pipe(map(d => d && d.freightTrackingDialogOpen));
    this.acceptDialogOpen = this.store.select(selectOrderDetails)
      .pipe(map(d => d && d.acceptDialogOpen));
    this.rejectDialogOpen = this.store.select(selectOrderDetails)
      .pipe(map(d => d && d.rejectDialogOpen));
    this.requestPaymentDialogOpen = this.store.select(selectOrderDetails)
      .pipe(map(d => d && d.requestPaymentDialogOpen));

    this.vpUser = this.store.select(selectAuthenticatedUser).pipe(map((u: VpUser) => {
      return u;
    }));

    this.subscriptions.push(this.order.subscribe());
    this.subscriptions.push(this.defaultPlan.subscribe());
    this.subscriptions.push(this.uuid.subscribe());
    this.subscriptions.push(this.freightTrackingDialogOpen.subscribe());
    this.subscriptions.push(this.acceptDialogOpen.subscribe());
    this.subscriptions.push(this.rejectDialogOpen.subscribe());
    this.subscriptions.push(this.requestPaymentDialogOpen.subscribe());
    this.subscriptions.push(this.vpUser.subscribe());
    this.subscriptions.push(this.order.pipe(filter(o => !!o))
      .subscribe(o => this.freightTrackingForm = this.createFreightTrackingForm(o)));
    this.subscriptions.push(this.order.pipe(filter(o => !!o))
      .subscribe(o => this.orderAcceptForm = this.createAcceptOrderForm(o)));
    this.subscriptions.push(this.order.pipe(filter(o => !!o))
      .subscribe(o => this.orderRejectForm = this.createRejectOrderForm(o)));
    this.subscriptions.push(this.order.pipe(filter(o => !!o))
      .subscribe(o => this.requestPaymentForm = this.createRequestPaymentOptionForm(o)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Utility function to navigate through Accept/Update Freight Tracking Fields using Enter key
   * @param event { Event }
   * @param currentInputElement { number }
   */
  autoFocusFormEnter(event: any, currentInputElement: number) {
    const targetFormSize = event.target.form.length;
    // Input Counter value will change depending on the field the enter key was pressed
    this.inputCounter = currentInputElement;
    if (!this.orderAcceptForm.valid || !this.freightTrackingForm.valid) {
      return;
    } else {
      event.target.form[this.inputCounter++]?.focus();
    }
    // Checking if anymore input fields exist in the present form
    // If not, the end key will focus on the specified submit button
    const checkInputGroupSize = this.inputCounter > targetFormSize;
    // If the enter key is pressed on the last field in form, then set focus on the submit button
    if (checkInputGroupSize) {
      this.send_accept_btn?.nativeElement?.focus();
      this.update_freight_tracking_btn?.nativeElement?.focus();
    }
  }

  /**
   * Utility function to navigate through Reject Fields using Enter key
   * @param currentInputElement { number }
   */
  autoFocusEnterReject(currentInputElement: number) {
    const rejectOrderFields = {
        reject_order_date: this.reject_order_date,
        reject_order_name: this.reject_order_name_btn
    };

    const rejectOrderFieldsSize = Object.keys(rejectOrderFields).length;

    this.inputCounter = currentInputElement;

    const checkObjectSize = this.inputCounter === rejectOrderFieldsSize;

    const currentField = Object.keys(rejectOrderFields)[this.inputCounter++];
    const getValue = rejectOrderFields[currentField];

    if (!this.orderRejectForm.valid) {
      return;
    } else {
      // Only focus element if it exist
      getValue?.nativeElement?.focus();
    }

    if (checkObjectSize) {
      this.reject_btn?.nativeElement?.focus();
    }
  }

  autoFocusPaymentOptions(paymentOption: string) {
    if (paymentOption === 'ACH') {
      this.selectedPaymentACH = !this.selectedPaymentACH;
    }
    if (paymentOption === 'Credit Card') {
      this.selectedPaymentCreditCard = !this.selectedPaymentCreditCard;
    }
  }

  async routeToOrders() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.router.navigate(['/orders']);
    }
  }

  async requestChanges() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.showChat = !this.showChat; // toggle chat window
    }
  }

  checkInProgressOrderStatus($event): void {
    this.order.pipe(take(1)).subscribe(o => {
      // If an order does not have a status of 'New', do not show the inProgress badge
      if (o.status !== OrderStatus.New) {
        this.getOrderStatus = !$event;
      } else {
        this.getOrderStatus = $event;
      }
    });
  }

  private createFreightTrackingForm(order: Orders): FormGroup {
    return new FormGroup({
      tracking_number: new FormControl(order.tracking_number),
      est_ship_date : new FormControl(yyyymmddToDate(order.est_ship_date)),
      date_shipped : new FormControl(yyyymmddToDate(order.date_shipped)),
      delivered : new FormControl(yyyymmddToDate(order.delivered)),
    });
  }

  private createAcceptOrderForm(order: Orders): FormGroup {
    return new FormGroup({
      vendor_invoice_id: new FormControl(order.vendor_invoice_id, [Validators.required, Validators.maxLength(20)]),
      order_accepted_name: new FormControl(''),
      accept_order_date: new FormControl(this.acceptOrderDateValue, [Validators.required]),
    });
  }

  private createRequestPaymentOptionForm(o: Orders): FormGroup {
    return new FormGroup({
      requested_payment_amount: new FormControl(o.requested_amount, [Validators.required])
    });
  }

  private createRejectOrderForm(order: Orders): FormGroup {
    return new FormGroup({
      reject_order_date: new FormControl(this.rejectOrderDateValue, [Validators.required]),
      order_rejected_name: new FormControl(''),
      reason: new FormControl('')
    });
  }

  async isLinkedOrder(): Promise<boolean> {
    const header = await this.header.pipe(take(1)).toPromise();
    if (header !== 'unlinked') {
      const linkedOrder = await this.doesAccountOwnThisOrder();
      if (linkedOrder) {
        return true;
      }
      this.toastrService.info(`This order is not linked to your account.`);
      return false;
    }
    this.signUpModal.openModal();
    return false;
  }

  async doesAccountOwnThisOrder(): Promise<boolean> {
    const order = await this.order.pipe(take(1)).toPromise();
    const organization = await this.organization.pipe(take(1)).toPromise();
    return order.organization?.id === organization?.id;
  }

  async acceptOrder() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.getOrderStatus = false;
      document.body.style.overflow = 'hidden';
      this.store.dispatch(orderAcceptDialog({isOpen: true}));
    }
  }

  async rejectOrder() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.getOrderStatus = false;
      document.body.style.overflow = 'hidden';
      this.store.dispatch(orderRejectDialog({isOpen: true}));
    }
  }

  async requestPaymentOpen() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.getOrderStatus = false;
      document.body.style.overflow = 'hidden';
      this.store.dispatch(requestPaymentDialog({isOpen: true}));
    }
  }

  async openFreightTrackingDialog() {
    const isLinkedOrder = await this.isLinkedOrder();
    if (isLinkedOrder) {
      this.order.pipe(take(1)).subscribe(o => {
        if (o.status !== OrderStatus.Approved && o.status !== OrderStatus.Delivered && o.status !== OrderStatus.Shipped) {
          return this.toastrService.error(`You need to accept this order before entering any tracking information.`);
        }
        if (o.items.length === 0) {
          return this.toastrService.error(`You can't accept a voided order.`);
        }

        this.store.dispatch(orderFreightTrackingDialog({isOpen: true}));
      });
    }
  }
  viewAttachments(item: Items) {
    this.viewItem = item;
    this.attachmentsDialogOpen = true;
  }
  closeAttachmentsDialog() {
      this.attachmentsDialogOpen = false;
  }

  /**
   * Utility function to exit Kendo Dialog when clicking outside of dialog
   * @param event { Event }
   * @param dialogType { string }
   */
  determineCloseModal(event, dialogType?: string) {
    if (event.target.className.indexOf('k-overlay') !== -1) {
      if (dialogType === 'attachments') {
        this.closeAttachmentsDialog();
      }
      if (dialogType === 'payment_options') {
        this.closePaymentOptionsDialog();
      }
    }
  }

  closeAcceptDialog() {
    document.body.style.overflow = '';
    this.store.dispatch(orderAcceptDialog({isOpen: false}));
  }

  closePaymentOptionsDialog() {
    document.body.style.overflow = '';
    this.store.dispatch(requestPaymentDialog({isOpen: false}));
  }

  closeRejectDialog() {
    document.body.style.overflow = '';
    this.store.dispatch(orderRejectDialog({isOpen: false}));
  }

  closeAdjustDialog() {
    this.adjustDialogOpen = false;
  }

  closeFreightTrackingDialog() {
    this.store.dispatch(orderFreightTrackingDialog({isOpen: false}));
  }

  updateFreightTracking() {
    if (!this.freightTrackingForm.valid) {
      return;
    }
    this.order.pipe(take(1)).subscribe(o => {
      this.organization.pipe(take(1)).subscribe(org => {
        this.uuid.pipe(take(1)).subscribe(uuid => {
          const props = {
            order_id: o.id,
            uuid,
            tracking_number: this.freightTrackingForm.get('tracking_number').value,
            est_ship_date: yyyymmddFromDate(this.freightTrackingForm.get('est_ship_date').value),
            date_shipped: yyyymmddFromDate(this.freightTrackingForm.get('date_shipped').value),
            delivered: yyyymmddFromDate(this.freightTrackingForm.get('delivered').value),
          };

          this.store.dispatch(orderUpdateFreightTracking(props));
        });
      });

    });
  }

  /**
   * Utility function to extract fileName out of FileUrl
   * @param fileUrl { string }
   * @param fileName { string }
   */
  getFileName(fileName: string) {
    return fileName.split('\\').pop().split('/').pop();
  }

  /**
   * Download selected file
   * @param fileUrl { string }
   * @param fileName { string }
   */
  download(fileUrl: string, fileName?: string) {
    // Will extract FileName from Primary Image if selected
    if (!fileName) {
      fileName = this.getFileName(fileUrl);
    }

    const arr = fileUrl.split('/');
    arr[2] = 'cdn.studiodesigner.com';

    // files downloaded include a key in index 3, remove it
    arr.splice(3, 1);
    fileUrl = arr.join('/');

    this.fileService.getFile(fileUrl).subscribe((blob) => {
      saveAs(blob, fileName);
    });

  }

  sendPaymentOptions() {
    if (!this.requestPaymentForm.valid) {
      return;
    }
    if (!this.selectedPaymentCreditCard && !this.selectedPaymentACH) {
      return;
    }
    this.order.pipe(take(1)).subscribe(o => {
      this.store.dispatch(sendPaymentOptions({
        order_id: o.id,
        requestAmount: this.requestPaymentForm.get('requested_payment_amount').value,
        allowStripeCreditCard: this.selectedPaymentCreditCard,
        allowStripeACH: this.selectedPaymentACH,
      }));
    });
    document.body.style.overflow = '';
  }

  sendAccept() {
    if (!this.orderAcceptForm.valid) {
      return;
    }
    this.order.pipe(take(1)).subscribe(o => {
      this.organization.pipe(take(1)).subscribe(org => {
        this.uuid.pipe(take(1)).subscribe(uuid => {
          this.store.dispatch(orderAccept({
            order_id: o.id,
            uuid,
            date: this.orderAcceptForm.get('accept_order_date').value,
            order_number: this.orderAcceptForm.get('vendor_invoice_id').value,
            name: this.orderAcceptForm.get('order_accepted_name').value,
          }));
        });
      });
    });
    document.body.style.overflow = '';
  }

  sendRejection() {
    if (!this.orderRejectForm.valid) {
      return;
    }
    this.order.pipe(take(1)).subscribe(o => {
      this.uuid.pipe(take(1)).subscribe(uuid => {
        this.store.dispatch(orderReject({
          order_id: o.id,
          uuid,
          date: this.orderRejectForm.get('reject_order_date').value,
          reason: this.orderRejectForm.get('reason').value,
          name: this.orderRejectForm.get('order_rejected_name').value,
        }));
      });
    });
    document.body.style.overflow = '';
  }

    // Toggle between chat and order on mobile devices
  responsiveBlocksToggle() {
    this.isOrderShown = !this.isOrderShown;
  }

  isMobile() {
    return this.innerWidth <= 900;
  }

  // we want to hide the header
  login() {
    this.store.dispatch(changeHeaderAction({header: ''}));

    this.uuid.pipe(take(1)).subscribe(uuid => {
      this.router.navigate(['/login'], { queryParams: { uuid } });
    });
  }

  // we want to hide the header
  signUp() {
    this.store.dispatch(changeHeaderAction({header: ''}));

    this.uuid.pipe(take(1)).subscribe(uuid => {
      this.router.navigate(['/sign-up'], { queryParams: { uuid } });
    });
  }

}
