<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp sign-up-success">
    <div class="paper small">
      <div class="success-container custom">
        <ng-container *ngIf="!errorVerifiedEmail && !successVerifiedEmail">
          <span class="k-icon k-i-clock mb-4"></span>
          <h2 class="mb-2">Processing...</h2>
        </ng-container>
        <ng-container *ngIf="successVerifiedEmail">
          <span class="k-icon k-i-check mb-4"></span>
          <h2 class="mb-2">Success!</h2>
        </ng-container>
        <ng-container *ngIf="errorVerifiedEmail">
          <span class="k-icon k-i-close mb-4"></span>
          <h2 class="mb-2">Error!</h2>
        </ng-container>
        <p class="subtitle mb-2 mb-4">
          <ng-container *ngIf="successVerifiedEmail">
            Your email has been verified!
          </ng-container>
          <ng-container *ngIf="errorVerifiedEmail">
            Could not verify your email.
          </ng-container>
        </p>
        <button *ngIf="successVerifiedEmail"
                kendoButton class="btn btn-primary"
                routerLink="/login">Login</button>


        <form *ngIf="errorVerifiedEmail"
              [formGroup]="form" (submit)="onSubmit()" class="left-block">
          <p class="subtitle mb-38">Do you want to resend the verification link? Please enter your email</p>

          <div class="custom reset-input-block">
            <label for="email">Email</label>
            <input kendoTextBox id="email" type="email" formControlName="email" autocomplete="email"
                   placeholder="your-email@email.com" [pattern]="emailValidationPattern">
          </div>

          <div class="btns-row custom">
            <button kendoButton
                    [disabled]="form.invalid"
                    [primary]="true"
                    kendoButton
                    class="btn btn-primary">
              Resend verification email
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
