/**
 * src/modules/shared/pipes/remove-selected.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/11/2021
 */
import {Pipe, PipeTransform} from '@angular/core';
import {OrdersSummary} from '../../orders/models/orders-summary';
import {Widget} from '../models/widget.enum';
import * as currency from 'currency.js';

@Pipe({
  name: 'filterCustomers'
})
export class FilterCustomersPipe implements PipeTransform {

  transform(v: OrdersSummary[], widget: Widget): OrdersSummary[] {
    if (!v) {
      return [];
    }
    if (!widget) {
      return v;
    }
    const output: OrdersSummary[] = [];
    for (const o of v) {
      switch (widget) {
        case Widget.AverageValueOfOrders:
          output.push({ ...o, value: currency(o.value).divide(o.quantity) });
          break;
        case Widget.TotalValueOfOrders:
          output.push({ ...o, value: currency(o.value) });
          break;
        case Widget.QuantityOfOrders:
          output.push({ ...o, value: currency(o.quantity) });
          break;
        default:
          output.push(o);
          break;
      }
    }

    output.sort((a, b) => b.value.value - a.value.value);

    return output;

  }

}
