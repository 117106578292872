  <form class="form-holder">
    <ul class="k-checkbox-list">
      <li class="k-checkbox-item">
        <input type="checkbox" kendoCheckBox name="selectAll" [checked]="allChecked()" (change)="flipAll($event)"/>&nbsp;
        <kendo-label class="k-checkbox-label" for="selectAll" text="Send all emails to me"></kendo-label>
      </li>
    </ul>
    <fieldset>
      <legend>Email me when...</legend>
      <ul class="k-checkbox-list">
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_NEW_RFQ)" (change)="flip(triggers.ON_NEW_RFQ)"/>
          <kendo-label class="k-checkbox-label"
                       text="A new RFQ is received"></kendo-label>
        </li>
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_NEW_ORDER)" (change)="flip(triggers.ON_NEW_ORDER)"/>
          <kendo-label class="k-checkbox-label"
                       text="A new Order is received"></kendo-label>
        </li>
<!--        <li class="k-checkbox-item">-->
<!--          <input type="checkbox" kendoCheckBox [checked]="has(triggers.)" (change)="flip(triggers.)"/>-->
<!--          <kendo-label class="k-checkbox-label"-->
<!--                       text="I am assigned an Order"></kendo-label>-->
<!--        </li>-->
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_ORDER_EXTERNAL_UPDATE)" (change)="flip(triggers.ON_ORDER_EXTERNAL_UPDATE)"/>
          <kendo-label class="k-checkbox-label"
                       text="An Order is updated by a Customer"></kendo-label>
        </li>
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_1DAYS_IDLE)" (change)="flip(triggers.ON_1DAYS_IDLE)"/>
          <kendo-label class="k-checkbox-label"
                       text="An Order has not been opened 24 hours after receipt"></kendo-label>
        </li>
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_5DAYS_IDLE)" (change)="flip(triggers.ON_5DAYS_IDLE)"/>
          <kendo-label class="k-checkbox-label"
                       text="An Order has not been completed or cancelled within 5 days of receipt"></kendo-label>
        </li>
        <li class="k-checkbox-item">
          <input type="checkbox" kendoCheckBox [checked]="has(triggers.ON_MESSAGE_RECEIVED)" (change)="flip(triggers.ON_MESSAGE_RECEIVED)"/>
          <kendo-label class="k-checkbox-label" text="I receive a message"></kendo-label>
        </li>
      </ul>
    </fieldset>



  </form>
