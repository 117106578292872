/**
 * src/app/modules/orders/orders.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrdersRoutingModule} from './orders-routing.module';
import {ListComponent} from './components/list/list.component';
import {OrderContainerComponent} from './components/order-container/order-container.component';
import {OrderDetailsComponent} from './components/order-details/order-details.component';
import {OrderFilesComponent} from './components/order-files/order-files.component';
import {OrderHistoryComponent} from './components/order-history/order-history.component';
import {OrderItemsComponent} from './components/order-items/order-items.component';
import {DialogModule, WindowModule} from '@progress/kendo-angular-dialog';
import {CheckBoxModule, FormFieldModule, TextBoxModule} from '@progress/kendo-angular-inputs';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {GridModule} from '@progress/kendo-angular-grid';
import {ButtonGroupModule, ButtonModule} from '@progress/kendo-angular-buttons';
import {BadgeModule} from '@progress/kendo-angular-indicators';
import {LayoutModule} from '@progress/kendo-angular-layout';
import {LabelModule} from '@progress/kendo-angular-label';
import {CalendarCommonModule, DateInputModule, DatePickerModule} from '@progress/kendo-angular-dateinputs';
import {ReactiveFormsModule} from '@angular/forms';
import {Store, StoreModule} from '@ngrx/store';
import * as fromOrders from './state/orders.reducer';
import * as fromAuthentication from '../authentication/state/authentication.reducer';
import {OrdersEffects} from './state/orders.effects';
import {AuthenticationEffects} from '../authentication/state/authentication.effects';
import {EffectsModule} from '@ngrx/effects';
import {ChatModule} from '@progress/kendo-angular-conversational-ui';
import {OrdersService} from './services/orders.service';
import {SharedModule} from '../shared/shared.module';
import {ItemTotalPipe} from './pipes/item-total.pipe';
import {WsService} from '../wsockets/services/ws/ws.service';
import {AppState} from '../../app.module';
import {filter, take} from 'rxjs/operators';
import {orderAddChatMessage, orderStatusUpdateAction, orderSubjectGetAction} from './state/orders.actions';
import {CountService} from '../organizations/services/count.service';
import {DocumentOccurrencesPipe} from './pipes/document-occurrances.pipe';
import {ImageOccurrencesPipe} from './pipes/image-occurrances.pipe';
import {
  previewNotificationsListGetAction
} from '../notifications/state/notifications.actions';
import {UploadsModule} from '@progress/kendo-angular-upload';
import {IsImagePipe} from './pipes/is-image.pipe';
import {OrderChatComponent} from './components/order-chat/order-chat.component';
import {selectOrganization} from '../organizations/state/organizations.selector';
import {Organizations} from '../organizations/models/organizations';
import {DefaultPlanService} from './services/default-plan.service';
import {UnlinkedVendorsService} from './services/unlinked-vendors.service';
import {IsUnlinkedPipe} from './pipes/unlinked-orders/is-unlinked.pipe';
import {IsLoggedInPipe} from './pipes/unlinked-orders/is-logged-in.pipe';
import {DoesOrderBelongToOrganizationPipe} from './pipes/unlinked-orders/does-order-belong-to-organization.pipe';
import {AcceptOrderPipe} from './pipes/plan-permissions/accept-order.pipe';
import {RejectOrderPipe} from './pipes/plan-permissions/reject-order.pipe';
import {RequestChangesPipe} from './pipes/plan-permissions/request-changes.pipe';
import {UpdateTrackingPipe} from './pipes/plan-permissions/update-tracking.pipe';
import {SignUpModalComponent} from '../shared/components/sign-up-modal/sign-up-modal.component';
import {selectOrder} from './state/orders.selector';
import {AutofocusDirective} from '../shared/directives/autofocus.directive';


@NgModule({
  declarations: [ListComponent, OrderContainerComponent, OrderDetailsComponent,
    OrderFilesComponent, OrderHistoryComponent, OrderItemsComponent, ItemTotalPipe, ImageOccurrencesPipe,
    DocumentOccurrencesPipe, IsImagePipe, OrderChatComponent, IsUnlinkedPipe, IsLoggedInPipe,
    DoesOrderBelongToOrganizationPipe, AcceptOrderPipe, RejectOrderPipe, RequestChangesPipe, UpdateTrackingPipe,
    AutofocusDirective,
  ],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    DialogModule,
    TextBoxModule,
    DropDownListModule,
    GridModule,
    ButtonModule,
    CheckBoxModule,
    ButtonGroupModule,
    BadgeModule,
    LayoutModule,
    LabelModule,
    CalendarCommonModule,
    DatePickerModule,
    FormFieldModule,
    DateInputModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromOrders.ordersFeatureKey, fromOrders.reducer),
    StoreModule.forFeature(fromAuthentication.authenticationFeatureKey, fromAuthentication.reducer),
    EffectsModule.forFeature([OrdersEffects, AuthenticationEffects]),
    ChatModule,
    SharedModule,
    UploadsModule,
    WindowModule
  ],
  providers: [OrdersService, ItemTotalPipe, DocumentOccurrencesPipe, ImageOccurrencesPipe, CountService,
    IsImagePipe, DefaultPlanService, UnlinkedVendorsService, IsUnlinkedPipe, IsLoggedInPipe,
    DoesOrderBelongToOrganizationPipe, AcceptOrderPipe, RejectOrderPipe, RequestChangesPipe, UpdateTrackingPipe,
    SignUpModalComponent
  ]
})
export class OrdersModule {
  constructor(private wsService: WsService, private store: Store<AppState>) {


    wsService.$messages.pipe(filter(m => m.name === 'ORDER_CHAT')).subscribe(
      x => {
        this.store.dispatch(orderAddChatMessage(x.payload));
        this.store.select(selectOrganization).subscribe((next: Organizations) => {
          // only attempt to get notifications if the plan has notifications enabled
          if (next?.plan?.notifications) {
            this.store.dispatch(previewNotificationsListGetAction());
          }
        });
      }
    );

    wsService.$systemErrors.pipe(filter(m => m.name === 'SYSTEM_ERROR')).subscribe(
      x => {
        this.store.dispatch(orderAddChatMessage(x.payload));
      }
    );

    wsService.$statuses.pipe(filter(m => m.name === 'ORDER_STATUS')).subscribe(
      x => {
        this.store.dispatch(orderStatusUpdateAction({orderId: x.payload.id, status: x.payload.status}));
      }
    );

    wsService.$orderUpdates.pipe(filter(m => m.name === 'ORDER_UPDATED')).subscribe(async x => {
      this.store.select(selectOrder).pipe(take(1)).subscribe(currentOrder => {
        if (!currentOrder || currentOrder.id !== x.payload.id) {
          return;
        }

        this.store.dispatch(orderSubjectGetAction({id: x.payload.id}));
      });
    });

  }

}
