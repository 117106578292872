/**
 * src/app/modules/system-admin/system-admin-routing.module.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 10/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {ListOrganizationsComponent} from './components/list-organization/list-organizations.component';
import {EditOrganizationComponent} from './components/edit-organization/edit-organization.component';
import {EditUserComponent} from './components/edit-user/edit-user.component';
import {
  loginEditSubjectGetAction,
  organizationEditSubjectGetAction, planEditSubjectGetAction,
} from './state/systemAdmin.actions';
import {StateResolver} from '../shared/services/state.resolver';
import {IsAuthenticatedGuardService} from '../authentication/services/is-authenticated-guard.service';
import {
  countOrganizationGetAction,
  countActiveOrganizationGetAction,
  countInactiveOrganizationGetAction, countUserGetAction
} from '../organizations/state/organizations.actions';
import {AllNotificationsComponent} from "../shared/components/all-notifications/all-notifications.component";
import {ListPlansComponent} from "./components/list-plan/list-plans.component";
import {EditPlanComponent} from "./components/edit-plan/edit-plan.component";
import {NotificationsGuardService} from "../authentication/services/featureGuards/notifications-guard.service";


const routes: Routes = [
  {
    path: 'system-admin',
    canActivate: [IsAuthenticatedGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        resolve: { state: StateResolver },
        data: {
          actions: [
            { action: countOrganizationGetAction },
            { action: countUserGetAction },
          ]
        }
      },
      {
        path: 'notifications',
        canActivate: [NotificationsGuardService],
        component: AllNotificationsComponent
      },
      {
        path: 'organizations',
        children: [
          {
            path: '',
            component: ListOrganizationsComponent,
            resolve: { state: StateResolver },
            data: {
              actions: [
                { action: countActiveOrganizationGetAction },
                { action: countInactiveOrganizationGetAction },
              ]
            }
          },
          {
            path: ':org_id',
            component: EditOrganizationComponent,
            resolve: { state: StateResolver },
            data: {
              actions: [
                { action: organizationEditSubjectGetAction, keys: { id: 'org_id'} }
              ]
            }
          },
          {
            path: ':org_id/users/:user_id',
            component: EditUserComponent,
            resolve: { state: StateResolver },
            data: {
              actions: [
                { action: organizationEditSubjectGetAction, keys: { id: 'org_id'} },
                { action: loginEditSubjectGetAction, keys: { org_id: 'org_id', user_id: 'user_id'} }
              ]
            }
          }
        ]
      },
      {
        path: 'plans',
        children: [
          {
            path: '',
            component: ListPlansComponent,
            resolve: { state: StateResolver },
            data: {
              actions: []
            }
          },
          {
            path: ':plan_id',
            component: EditPlanComponent,
            resolve: { state: StateResolver },
            data: {
              actions: [
                { action: planEditSubjectGetAction, keys: { id: 'plan_id'} }
              ]
            }
          },
        ]
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemAdminRoutingModule { }
