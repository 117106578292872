/**
 * src/app/modules/notifications/state/notifications.actions.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {createAction, props} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query/dist/npm/state';
import {FilterDescriptor} from '@progress/kendo-data-query';
import {ChatMessages} from '../../notifications/models/ChatMessages';

// preview notifications list
export const previewNotificationsListGetAction = createAction('[Preview Notifications] Preview List Subject Get');
export const previewNotificationsListSubjectChangeAction = createAction('[Preview Notifications] Preview List Subject Change', props<{ previewNotificationsList: ChatMessages[] }>());

// notifications list
export const notificationsListPagerChangeAction = createAction('[Notifications] List Pager Change', props<KendoDataState>());
// tslint:disable-next-line:max-line-length
export const notificationsPagerAddNameFilterAction = createAction('[Notifications] List Pager Add Text Filter', props<FilterDescriptor>());
export const notificationsPagerResetNameFilterAction = createAction('[Notifications] List Pager Reset Name Filter');

// change tab
export const notificationsTabChangeAction = createAction('[Notifications] Tab Change', props<{ tab: 'all' | 'read' | 'unread' }>());

// mark notifications as read
export const markNotificationsAsReadAction = createAction('[Update Notifications] Mark notifications as read', props<{ messages: ChatMessages[] }>());

// mark all notifications as read
export const markAllNotificationsAsReadAction = createAction('[Update Notifications] Mark all notifications as read')

// mark notifications as unread
export const markNotificationsAsUnreadAction = createAction('[Update Notifications] Mark notifications as unread', props<{ messages: ChatMessages[] }>());

// set the current number of unread notifications
export const unreadNotificationCountGetAction = createAction('[Unread Notifications Count] Get unread notification count');
export const unreadNotificationCountSubjectChangeAction = createAction('[Unread Notifications Count] Set unread notification count', props<{count: number}>());


