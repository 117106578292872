/**
 * src/modules/user-space-admin/pipes/convert-chat-messages-to-messages.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 03/21
 */
import {Pipe, PipeTransform} from "@angular/core";
import {Message} from "@progress/kendo-angular-conversational-ui";
import {ChatMessages} from "../../notifications/models/ChatMessages";

@Pipe({
  name: 'convertChatMessagesToMessages'
})
export class ConvertChatMessagesToMessagesPipe implements PipeTransform {

  transform(chatMessages: ChatMessages[]): Message[] {

      if (!chatMessages) {
        return [];
      }

      const messages = [];

      for (const m of chatMessages) {
        messages.push({text: m.message, author: {id: (m.originator === true) ? 1 : 0}, timestamp: m.created_at});
      }

      return messages;

  }

}
