/**
 * src/app/modules/organizations/services/users.service.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../../shared/models/user';
import {Successful} from 'app/modules/shared/models/successful';
import {FormControl, ValidationErrors} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private timer: NodeJS.Timeout;

  constructor(private httpClient: HttpClient,
              private toastrService: ToastrService) { }

  /**
   * Get a user.
   */
  public getOne(user_id: number): Observable<User> {
    return this.httpClient.get<User>(`/api/users/${user_id}`);
  }

  /**
   * Create or update an organizations user..
   */
  public save(user: User): Observable<User> {

    const url = `/api/users`;
    let r: Observable<User>;

    if (user.id) {
      r =  this.httpClient.put<User>(`${url}/${user.id}`, user);
    } else {
      r = this.httpClient.post<User>(url, user);
    }

    return r;

  }

  /**
   * Checking exists email.
   */
  public checkEmailExists(email: string): Observable<Successful> {
   return this.httpClient.post<Successful>('/api/users/check/email', {email: email});
 }

  /**
   * email validator, checks for email presence, show message about error.
   */
  public async emailExistsValidator(email: string, lastEmail: string): Promise<ValidationErrors> {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (!email) {
      return null;
    }
    if (email === lastEmail) {
      return null;
    }
    return new Promise((resolve) => {
      this.timer = setTimeout(async () => {
        this.timer = null;
        await this.checkEmailExists(email).toPromise().then((data) => {
          if (data.successful) {
            resolve(null);
          }
        }).catch((err: HttpErrorResponse) => {
          resolve({error: err.error?.message ?? err.message});
        });
        resolve(null);
      }, 400);
    });
  }

}
