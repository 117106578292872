/**
 * src/modules/shared/pipes/graph-average-value-per-quantity.pipe.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/11/2021
 */
import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';
import {OrdersSummary} from "../../orders/models/orders-summary";

@Pipe({
  name: 'graphAverageValuePerQuantity'
})
export class GraphAverageValuePerQuantityPipe implements PipeTransform {

  transform(summary: OrdersSummary[]): currency[] {
    if (!summary) {
      return [];
    }
    const output: currency[] = [];
    for (const o of summary) {
      const q = currency(o.quantity);
      const v = currency(o.value);
      output.push((!q || q.intValue === 0) ? currency(0) : v.divide(q));
    }
    return output;
  }

}
