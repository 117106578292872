/**
 * src/app/modules/notifications/services/notifications.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 03/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ResultListDTO} from '../../shared/models/resultList.dto';
import {State} from '@progress/kendo-data-query';
import {TransformationsService} from '../../shared/services/transformations.service';
import {ChatMessages} from "../models/ChatMessages";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService) { }

  /**
   * Get paginated collection of notifications in the vendor portal.
   *
   * @param state
   * @param type
   *
   * @returns Observable<ResultListDTO<ChatMessages[]>>
   */
  public getNotifications(state: State, type: 'all' | 'unread' | 'read'): Observable<ResultListDTO<ChatMessages[]>> {

    const params = this.transformationsService.getHttpParamsFromKendoState(state);

    return this.httpClient.get<ResultListDTO<ChatMessages[]>>(`/api/notifications/${type}`, {params});

  }

  /**
   * Get a count of unread notifications in the vendor portal.
   *
   * @param state
   * @param type
   *
   * @returns Observable<number>
   */
  public getUnreadNotificationCount(): Observable<number> {

    return this.httpClient.get<number>(`/api/notifications/countunread`);

  }

  /**
   * Marks notifications as read
   *
   * @param ids
   *
   * @returns Observable<ChatMessages[]>
   */
  public markAsRead(ids: number[]): Observable<ChatMessages[]> {
    return this.httpClient.put<ChatMessages[]>(`/api/notifications/markasread`, {ids: ids});
  }

  /**
   * Marks all notifications as read
   *
   * @returns Observable<ChatMessages[]>
   */
  public markAllAsRead(): Observable<ChatMessages[]> {
    return this.httpClient.put<ChatMessages[]>(`/api/notifications/markallasread`, {});
  }

  /**
   * Marks notifications as unread
   *
   * @param ids
   *
   * @returns Observable<ChatMessages[]>
   */
  public markAsUnread(ids: number[]): Observable<ChatMessages[]> {
    return this.httpClient.put<ChatMessages[]>(`/api/notifications/markasunread`, {ids: ids});
  }

}
