/**
 * src/app/modules/authentication/state/authentication.reducer.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC 2020
 * @since 9/20
 */

import {Action, createReducer, on} from '@ngrx/store';
import {User} from '../../shared/models/user';
import {
  configurationListSetAction,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  loginUpdateAction,
  logoutSuccessAction
} from './authentication.actions';
import {DashboardConfiguration} from "../../user-space-admin/models/dashboardConfiguration";

export const authenticationFeatureKey = 'authentication';

export interface AuthenticationState  {
  authenticated_at: number;
  isAuthenticated: boolean;
  errorMessage: any;
  user: User;
  headerView: string;
}

export const initialState: AuthenticationState = {
  authenticated_at: 0,
  isAuthenticated: false,
  errorMessage: null,
  user: null,
  headerView: 'user'
};

const authenticationReducer = createReducer(
  initialState,
  on(loginAction, (state: AuthenticationState, credentials) => ({...state, errorMessage: null})),
  on(loginSuccessAction, (state: AuthenticationState, user) => ({ ...state, user, isAuthenticated: true})),
  on(loginFailureAction, (state: AuthenticationState, err: any) => ({...state, user: null, errorMessage: err})),
  on(logoutSuccessAction, (state: AuthenticationState) => ({...state, ...initialState})),
  on(loginUpdateAction, (state: AuthenticationState, user: User) => ({ ...state, user: user })),
  on(configurationListSetAction, (state: AuthenticationState, configurations: { configurations: DashboardConfiguration[] }) => ({ ...state, user: { ...state.user, dashboardConfigurations: configurations.configurations } })),
);

export function reducer(state: AuthenticationState | undefined, action: Action) {
  return authenticationReducer(state, action);

}
