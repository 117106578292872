/**
 * src/app/modules/organizations/components/profile/details/details.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../app.module';
import {FormsService} from '../../../../shared/services/forms.service';
import {MapToolsService} from '../../../../shared/services/map-tools.service';
import {FormGroup} from '@angular/forms';
import {FileService} from '../../../../orders/services/file.service';
import {map, take} from 'rxjs/operators';
import {PhoneMasks} from '../../../../shared/models/phoneMasks.enum';
import {Observable} from 'rxjs';
import {Organizations} from '../../../models/organizations';
import {selectOrganization} from '../../../state/organizations.selector';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {UploadResult} from "../../../../orders/models/upload-result";

@Component({
  selector: 'vp-organization-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {

  public locationsChecked = false;
  public mask = PhoneMasks.Default;
  public organizationProfile: Observable<Organizations>;
  public logoUploading = false;

  @Input() vendorForm: FormGroup;

  constructor(
    private store: Store<AppState>,
    private fileService: FileService,
    private formsService: FormsService,
    private mapTools: MapToolsService,
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.organizationProfile = this.store.select(selectOrganization);

    this.vendorForm.get('contact_number').valueChanges.subscribe((phone) => {
      const oldMask = this.mask;
      const newMask = (phone && !phone.match(/[^$,.\d]/) && phone.replace(/\D/g, '').length <= 10) ? PhoneMasks.UsaFormatted : PhoneMasks.Default;
      if (oldMask === PhoneMasks.UsaFormatted && newMask === PhoneMasks.Default && (!this.vendorForm.get('contact_number').value || this.vendorForm.get('contact_number').value.length === 0)) {
        this.vendorForm.get('contact_number').setValue('', {emitEvent: false});
      }
      this.mask = newMask;
      return phone;
    });
  }

  /**
   * Remove a single image file.
   */
  removeLogo(formField: string) {
      this.vendorForm.get(formField).setValue('');
  }

  /**
   * Upload a single image file
   * @param event { any }
   */
  uploadFile(event: any): Observable<UploadResult> {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    return this.fileService.uploadFile(files[0]);
  }

  /**
   * Upload logo
   * @param event
   */
  onLogoUpload(event: any): void {
    this.logoUploading = true;
    this.uploadFile(event).subscribe((v) => {
      this.vendorForm.get('cover_photo_url').setValue(v.url);
      this.logoUploading = false;
    }, err => {
      this.logoUploading = false;
    });
  }

  handleAddressChange(address: Address) {
    const form = this.vendorForm;
    const parsedAddress = this.mapTools.parseGooglePlacesResults(address);

    form.get('primaryLocation.address').setValue(`${parsedAddress.street_number} ${parsedAddress.route}`);
    form.get('primaryLocation.city').setValue(parsedAddress.locality);
    form.get('primaryLocation.province').setValue(parsedAddress.state_abbr);
    form.get('primaryLocation.postal_code').setValue(parsedAddress.postal_code);
    form.get('primaryLocation.country').setValue(parsedAddress.country_code);
  }

}
