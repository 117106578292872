/**
 * src/app/modules/shared/pipes/currencyjs.pipe.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @copyright DesignersAxis, LLC
 * @since 05/07/2019
 */

import {Pipe, PipeTransform} from '@angular/core';
import * as currency from 'currency.js';

@Pipe({
  name: 'currencyjs'
})
export class CurrencyjsPipe implements PipeTransform {
  transform(value: any, showSymbol?: boolean, precision?: number): string {


    if (showSymbol === undefined) {
      showSymbol = false;
    }

    let opts = {};

    if (precision) {
      opts = { precision };
    }

    if (value === undefined) {
      return currency(0, opts).format();
    }

    if (value.format === undefined) {
     return currency(value, opts).format();
    }

    return value.format(showSymbol);

  }
}
