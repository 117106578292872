<div class="sign-container">
  <div class="left-image">
    <img src="../../../../../assets/Images/sign-illustration.png" alt="">
  </div>

  <div class="right-container animated animatedFadeInUp fadeInUp">
    <div class="paper">

      <div class="sign-body">
        <form class="left-block form-holder u-1/2@sm u-1/1" [formGroup]="loginForm" (submit)="onSubmit()">
          <h1 class="mb-2">Log In</h1>
          <p class="subtitle mb-50">To fully engage with our design community</p>
          <div class="mb-50">

            <div class="custom mb-4">
              <label for="email">Email</label>
              <input kendoTextBox id="email" type="email" formControlName="username" autocomplete="nope"
                placeholder="Email" [pattern]="emailValidationPattern">
              <p *ngIf="loginForm.dirty && (f.controls.email?.errors?.required || f.controls.email?.errors?.email)"
                class="error-message">Invalid email address</p>
            </div>

            <div class="custom mb-4">
              <label for="password">Password</label>

              <kendo-textbox #password kendoTextBox id="password" formControlName="password" autocomplete="nope"
                placeholder="Password">
                <ng-template kendoTextBoxSuffixTemplate>
                  <button kendoButton look="clear" [icon]="'eye'" [type]="'button'"
                    (mousedown)="togglePass(true)"
                    (mouseup)="togglePass(false)"
                    (mouseleave)="togglePass(false)">
                  </button>
                </ng-template>
              </kendo-textbox>
            </div>

            <div class="additional-login-row custom">
              <label class="checkbox-label flex-col-center">
                <input kendoCheckBox type="checkbox" formControlName="remember_me">
                <span class="label">Keep me signed in</span>
              </label>
              <a routerLink="/forgot-password" class="label">Forgot Password?</a>
            </div>
          </div>


          <div class="btns-row custom mb-50">
            <button [disabled]="loginForm.invalid || loading" kendoButton [primary]="true" class="btn btn-primary">Log
              In</button>
          </div>

          <div class="footer custom">
            <span class="label">Do not have an account?</span> <a routerLink="/sign-up">Sign Up</a>
          </div>
        </form>

        <div class="w-100 d-flex flex-column right-block layout__item u-1/2">
          <h5 class="right-block__header">Welcome to The Studio Designer Vendor Portal!</h5>
          <div class="body-1">
            The fast and easy way to collaborate with designers on orders, gain insights, and discover new partners.
            Get started in
            minutes with our simple Sign Up.</div>
        </div>
      </div>
    </div>
  </div>
</div>
