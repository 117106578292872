/**
 * src/app/modules/orders/state/orders.selector.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {createSelector} from '@ngrx/store';
import {State as KendoDataState} from '@progress/kendo-data-query';
import {ordersFeatureKey, OrdersState} from './orders.reducer';

export const selectOrdersState = (state: any) => state[ordersFeatureKey];

export const selectOrder = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.currentOrder
);

export const selectOrderDetails = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.orderDetails
);

export const selectOrderChat = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.currentOrder ? state.currentOrder.chatMessages : []
);

export const selectOrderFreightTrackingProcessing = createSelector(
  selectOrdersState,
  (state: OrdersState) => !!state.orderDetails?.freightTrackingProcessing,
);

export const selectOrderRejectProcessing = createSelector(
  selectOrdersState,
  (state: OrdersState) => !!state.orderDetails?.rejectProcessing,
);

export const selectOrderAcceptProcessing = createSelector(
  selectOrdersState,
  (state: OrdersState) => !!state.orderDetails?.acceptProcessing,
);
export const selectRequestPaymentProcessing = createSelector(
  selectOrdersState,
  (state: OrdersState) => !!state.orderDetails?.requestPaymentProcessing,
);

export const selectOrdersList = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.list
);

export const selectOrdersListPager = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.list.pager
);

export const selectOrdersListSearchFilter = createSelector(
   selectOrdersState,
  (state: OrdersState) => state.list.searchFilter
);

export const selectOrdersListStatusFilter = createSelector(
   selectOrdersState,
  (state: OrdersState) => state.list.statusFilter
);

export const selectOrdersListPagerWithFilters = createSelector(
   selectOrdersState,
  (state: OrdersState) => {
    const pager: KendoDataState = {...state.list.pager };

    if (state.list.statusFilter !== null || state.list.searchFilter !== null) {
      pager.filter = {
        logic: 'and',
        filters: []};
    }

    if (state.list.statusFilter !== null) {
      pager.filter.filters.push(state.list.statusFilter);
    }

    if (state.list.searchFilter !== null) {
      pager.filter.filters.push(state.list.searchFilter);
    }

    return pager;
  }
);

export const selectNewOrderCount = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.newOrderCount
);

export const selectApprovedOrderCount = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.approvedOrderCount
);

export const selectRejectedOrderCount = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.rejectedOrderCount
);

export const selectDefaultPlan = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.defaultPlan
);

export const selectUuid = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.uuid
);
