/**
 * src/app/modules/organizations/components/profile/profile.component.ts
 *
 * @author Bryan Henry <bryan@studiodesigner.com>
 * @since 12/20
 * @copyright DesignersAxis, LLC, 2020
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Organizations} from '../../models/organizations';
import {FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Locations} from '../../../shared/models/locations';
import {selectOrganization, selectOrganizationProfileTab} from '../../state/organizations.selector';
import {FormsService} from '../../../shared/services/forms.service';
import {
  userOrganizationEditLogoUrlSubjectUpdateAction,
  userOrganizationEditSubjectUpdateAction,
  userOrganizationEditTabChangeAction
} from '../../state/organizations.actions';
import {Location} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {filter, take, takeUntil} from 'rxjs/operators';
import {organizationEditTabChangeAction} from '../../../system-admin/state/systemAdmin.actions';
import {FileService} from '../../../orders/services/file.service';
import {Router} from '@angular/router';
import {UploadResult} from "../../../orders/models/upload-result";
import {UsersService} from 'app/modules/organizations/services/users.service';

@Component({
  selector: 'vp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public vendorForm: FormGroup;
  public organizationProfile: Observable<Organizations>;
  private subscriptions: Subscription[] = [];
  public profileTab: Observable<string>;
  public organization = new BehaviorSubject<Organizations>(new Organizations());
  public avatarLoading = false;
  private lastEmail: string;

  constructor(private store: Store<AppState>,
              private formsService: FormsService,
              private fileService: FileService,
              private router: Router,
              private location: Location,
              private userService: UsersService,
              private toastrService: ToastrService) { }


  ngOnInit(): void {

    this.profileTab = this.store.select(selectOrganizationProfileTab);
    this.vendorForm = this.createForm();

    this.organizationProfile = this.store.select(selectOrganization);

    // If email settings become disabled, set the default tab to 'Details'
    this.organizationProfile.pipe(filter(o => !o?.plan?.email_notifications))
      .subscribe((o => {
        this.store.dispatch(userOrganizationEditTabChangeAction({tab: 'details'}));
      }));

    const storeOrganizationSubscription = this.organizationProfile
      .subscribe((o) => {
        if (o && this.vendorForm) {
          this.lastEmail = o.contact_email;
          this.vendorForm.reset(o);
        }
      });

    this.subscriptions.push(storeOrganizationSubscription);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createForm() {

    const emailExistsValidator = async (control: FormControl): Promise<ValidationErrors> => {
      return await this.userService.emailExistsValidator(control.value, this.lastEmail);
    }

    return new FormGroup({
      id: new FormControl(undefined, []),
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      logo_url: new FormControl('', []),
      cover_photo_url: new FormControl('', []),
      contact_first_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      contact_last_name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      contact_website: new FormControl('', [Validators.maxLength(255)]),
      contact_email: new FormControl('', [Validators.required, Validators.maxLength(255)], [emailExistsValidator]),
      contact_number: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      kind: new FormControl('', [Validators.required]),
      active: new FormControl(true, []),
      locations: new FormControl('', []),
      primaryLocation: new FormGroup({
        id: new FormControl(undefined, []),
        address: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        province: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        postal_code: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        country: new FormControl('', [Validators.required, Validators.maxLength(255)]),
        website: new FormControl('', []),
      }),
      notification_triggers: new FormControl([], []),
      allow_credit_card: new FormControl(false, []),
      allow_ach: new FormControl(false, []),
    });


  }

  get f() {
    return this.vendorForm;
  }

  /**
   * Get a populated model to push to backend.
   */
  getPopulatedModel(): Organizations {

    let website;

    const org = new Organizations();

    org.id = this.f.get('id').value;
    org.name = this.f.get('name').value;
    org.allow_credit_card = this.f.get('allow_credit_card').value;
    org.allow_ach = this.f.get('allow_ach').value;
    org.logo_url = this.f.get('logo_url').value;
    org.cover_photo_url = this.f.get('cover_photo_url').value;
    org.contact_first_name = this.f.get('contact_first_name').value;
    org.contact_last_name = this.f.get('contact_last_name').value;
    website = this.f.get('contact_website').value?.trim();
    org.contact_website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';
    org.contact_email = this.f.get('contact_email').value;
    org.contact_number = this.f.get('contact_number').value;
    org.kind = this.f.get('kind').value;
    org.active = this.f.get('active').value;
    org.notification_triggers = this.f.get('notification_triggers').value;

    org.primaryLocation = new Locations();

    const locationId = this.f.get('primaryLocation.address').value;

    org.primaryLocation.id = this.f.get('primaryLocation.id').value;
    org.primaryLocation.address = this.f.get('primaryLocation.address').value;
    org.primaryLocation.city = this.f.get('primaryLocation.city').value;
    org.primaryLocation.province = this.f.get('primaryLocation.province').value;
    org.primaryLocation.postal_code = this.f.get('primaryLocation.postal_code').value;
    org.primaryLocation.country = this.f.get('primaryLocation.country').value;
    website = this.f.get('primaryLocation.website').value?.trim();
    org.primaryLocation.website = website ? website.match(/^https?:\/\//i)?.input ?? `http://${website}` : '';

    if (org.active === null) {
      org.active = false;
    }

    return org;

  }

  onFormSubmit() {
    if (!this.f.valid) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    this.store.dispatch(userOrganizationEditSubjectUpdateAction(this.getPopulatedModel()));
  }

  /**
   * Called when a tab is changed.
   */
  onTab(tab: any) {
    this.store.dispatch(userOrganizationEditTabChangeAction({tab: tab.title.toLowerCase()}));
  }

  onCancel(): void {
    this.organizationProfile.pipe(take(1)).subscribe((op) => {
      if (op?.plan?.dashboard) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/orders']);
      }
    });
  }

  /**
   * Remove a single image file.
   */
  removeLogo(formField: string) {
    this.saveLogo('');
    this.vendorForm.get(formField).setValue('');
  }

  /**
   * Upload a single image file
   * @param event
   */
  uploadFile(event: any): Observable<UploadResult> {
    const eventObj: MSInputMethodContext = event as MSInputMethodContext;
    const target: HTMLInputElement = eventObj.target as HTMLInputElement;
    const files: FileList = target.files;
    return this.fileService.uploadFile(files[0]);
  }

  /**
   * Upload logo
   * @param event
   */
  onLogoUpload(event: any): void {
    this.avatarLoading = true;
    this.uploadFile(event).subscribe((v) => {
      this.saveLogo(v.url); // this autosaves the logo
      this.vendorForm.get('logo_url').setValue(v.url);
      this.avatarLoading = false;
      event.target.value = '';
    }, err => {
      this.avatarLoading = false;
      if (err.error?.message) {
        this.toastrService.error(err.error.message);
      } else {
        this.toastrService.error(err.message);
      }
      event.target.value = '';
    });
  }

  /**
   * Save logo only
   * @param url
   */
  private saveLogo(url: string): void {
    this.organizationProfile.pipe(take(1)).subscribe(org => {
      if (org && org.id && org.id > 0) {
        this.store.dispatch(userOrganizationEditLogoUrlSubjectUpdateAction(Object.assign({}, org, { logo_url: url }))); // we can safely save the org
      }
    });
  }

}
