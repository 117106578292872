/**
 * src/app/modules/system-admin/services/plans.service.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultListDTO } from '../../shared/models/resultList.dto';
import { State } from '@progress/kendo-data-query';
import { TransformationsService } from '../../shared/services/transformations.service';
import { Plans } from '../../organizations/models/plans';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private httpClient: HttpClient, private transformationsService: TransformationsService) { }

  /**
   * Get paginated collection of plans in the vendor portal.
   */
  public getCollection(state?: State): Observable<ResultListDTO<Plans[]>> {

    const params = state ? this.transformationsService.getHttpParamsFromKendoState(state) : {};

    return this.httpClient.get<ResultListDTO<Plans[]>>(`/api/system-admin/plans`, {params});

  }

  /**
   * Get a single plan in the vendor portal.
   */
  public getOne(id: number): Observable<Plans> {
    return this.httpClient.get<Plans>(`/api/system-admin/plans/${id}`);
  }

  /**
   * Create or update an plan.
   * @var plan
   */
  public save(plan: Plans): Observable<Plans> {

    const url = '/api/system-admin/plans';
    let r: Observable<Plans>;

    if (plan.id) {
      r =  this.httpClient.put<Plans>(`${url}/${plan.id}`, plan);
    } else {
      r = this.httpClient.post<Plans>(url, plan);
    }

    return r;

  }

}
