/**
 * src/app/modules/system-admin/components/list-plan/list-plans.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {ToastrService} from 'ngx-toastr';
import {State} from '@progress/kendo-data-query';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {ResultListDTO} from '../../../shared/models/resultList.dto';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.module';
import {
  selectSystemAdminPlanListPagerWithFilters
} from '../../state/systemAdmin.selector';
import {
  planEditSubjectChangeAction,
  planEditSubjectGetAction,
  planListPagerChangeAction
} from '../../state/systemAdmin.actions';
import {Router} from '@angular/router';
import {selectIsAuthenticated} from "../../../authentication/state/authentication.selector";
import {Plans} from "../../../organizations/models/plans";
import {PlansService} from "../../services/plans.service";
import {HandleErrorService} from "../../../shared/services/handle-error.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'vp-system-admin-plans-list',
  templateUrl: './list-plans.component.html',
  styleUrls: ['./list-plans.component.scss']
})
export class ListPlansComponent implements OnInit, OnDestroy {

  public isLoadingPlans: boolean;
  public isLoadingGridData: boolean;
  public gridData: GridDataResult | ResultListDTO<Plans>;

  // list-organization page statistics.
  public statistics = { total: 0 };

  // default page sizes.
  public pageSizes = [10, 25, 50];

  // sort settings for kendo grid.
  public sortableSettings = { allowUnsort: true,  mode: 'multi' };

  // the state of the kendo pager.
  public pagerState: State;

  private subscriptions: Subscription[] = [];

  constructor(
    private plansService: PlansService,
    private toastrService: ToastrService,
    private store: Store<AppState>,
    private router: Router,
    private errorHandler: HandleErrorService
  ) {

  }

  ngOnInit(): void {

    const pagerSubscription = this.store.select(selectSystemAdminPlanListPagerWithFilters)
      .pipe(filter(err => err !== null)).subscribe((pagerState: State) => {

        this.store.select(selectIsAuthenticated).pipe(take(1), filter(v => v)).subscribe(() => {
          this.plansService.getCollection(pagerState).pipe(take(1)).subscribe(r => {
              this.gridData = r;
              this.statistics.total = r.total;
            },
            (error: HttpErrorResponse) => {
              this.errorHandler.handleError(error, 'Unable to get plans from the server.');
              this.gridData = null;
              this.statistics.total = 0;
            });


          this.pagerState = {...pagerState, filter: null};
          this.isLoadingGridData = false;
          this.isLoadingPlans = false;
        });

      });

    this.subscriptions.push(pagerSubscription);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addPlan() {
    this.store.dispatch(planEditSubjectChangeAction(new Plans()));
    this.router.navigate(['/system-admin/plans/details']);
  }

  editPlan(id: any) {
    this.store.dispatch(planEditSubjectGetAction({id}));
    this.router.navigate(['/system-admin/plans/details']);
  }

  /**
   * Dispatch a data state change.
   * @var state
   */
  dataStateChange(state: DataStateChangeEvent) {
    this.store.dispatch(planListPagerChangeAction(state));
  }

}
