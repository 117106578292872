/**
 * src/app/modules/user-space-admin/state/userSpaceAdmin.selector.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 02/17/2021
 * @copyright DesignersAxis, LLC, 2020
 *
 */
import {userSpaceAdminFeatureKey, UserSpaceAdminState} from "./userSpaceAdmin.reducer";
import {createSelector} from "@ngrx/store";
import {OrdersState} from "../../orders/state/orders.reducer";
import {selectOrdersState} from "../../orders/state/orders.selector";

export const selectUserSpaceAdminState = (state: any) => state[userSpaceAdminFeatureKey];

export const selectOrdersSummary = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.ordersSummary
);

export const selectTopCustomers = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.topCustomers
);

export const selectLocationCount = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.locationCount
);

export const selectEmployeeCount = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.employeeCount
);

export const selectCustomers = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.customers
);

export const selectSalesCodes = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.salesCodes
);

export const selectFilters = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.filters;
  }
);

export const selectCustomer = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.filters.customer
);

export const selectSalescode = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.filters.salesCode
);

export const selectTimeframe = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.filters.timeframe
);

export const selectTimeframes = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => state.timeframes
);

export const selectWidgets = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.widgets;
  }
);

export const selectDashboardSaveOpen = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.dashboard.templateSaveModalOpen;
  }
);

export const selectDashboardLoadOpen = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.dashboard.templateLoadModalOpen;
  }
);

export const selectDashboardConfiguration = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.dashboard.configuration;
  }
);

export const selectDashboardDetails = createSelector(
  selectUserSpaceAdminState,
  (state: UserSpaceAdminState) => {
    return state.dashboard;
  }
);

