/**
 * src/app/modules/system-admin/components/edit-plan/edit-plan.component.ts
 *
 * @author John Eubank <john@studiodesigner.com>
 * @since 6/21
 * @copyright DesignersAxis, LLC, 2021
 *
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';
import {filter, skip, take} from 'rxjs/operators';
import {Location} from '@angular/common';
import {FormsService} from '../../../shared/services/forms.service';
import {AppState} from '../../../../app.module';
import {Store} from '@ngrx/store';
import {PlanEdit} from '../../state/systemAdmin.reducer';
import {
  planEditSubjectUpdateAction
} from '../../state/systemAdmin.actions';
import {FileService} from '../../../orders/services/file.service';
import {Plans} from '../../../organizations/models/plans';
import {PlansService} from '../../services/plans.service';
import {selectSystemAdminOrganizationEdit, selectSystemAdminPlanEdit} from "../../state/systemAdmin.selector";

@Component({
  selector: 'vp-plan-form',
  templateUrl: './edit-plan.component.html',
  styleUrls: ['./edit-plan.component.scss']
})
export class EditPlanComponent implements OnInit, OnDestroy {

  public planForm: FormGroup;
  private subscriptions: Subscription[] = [];
  public plan = new BehaviorSubject<Plans>(new Plans());
  public loading = false;
  public planEdit: Observable<PlanEdit>;
  public formSet = false;

  // used for on change to prevent circular interactions in form when manually changing the value of a field
  private readonly NO_EMIT_OPTIONS = { emitEvent: false, emitModelToViewChange: true, emitViewToModelChange: false };

  constructor(private formBuilder: FormBuilder,
              private toastrService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private plansService: PlansService,
              private formsService: FormsService,
              private fileService: FileService,
              private location: Location,
              private store: Store<AppState>) {
  }

  ngOnInit(): void {

    this.planForm = this.createForm();

    this.planEdit = this.store.select(selectSystemAdminPlanEdit);

    const storePlanSubscription = this.planEdit
      .subscribe((oe) => {
        this.plan.next(oe?.plan);
      });

    this.subscriptions.push(storePlanSubscription);

    const planSubscriber = this.plan.pipe(filter((o) => this.planForm !== undefined && o !== null)).
    subscribe((plan) => {

      // reset and populate form.
      this.planForm.reset(plan);

      // check if the url doesn't have our id in it, if missing, update with out id, this handles 'new'.
      if (!isNaN(plan.id)) {

        this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
          if (params.plan_id && params.plan_id === 'new') {
            this.location.replaceState(`/system-admin/plans/${plan.id}`);
          }
          this.setFormEvents(1); // for edit form
        });

      } else {

        this.setFormEvents(0); // for new Form

      }

    });

    this.subscriptions.push(planSubscriber);

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createForm() {
    return new FormGroup({
      id: new FormControl(undefined, []),
      defaultPlan: new FormControl(false, []),
      name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
      email_notifications: new FormControl(false, []),
      acknowledge_dates: new FormControl(false, []),
      estimated_shipped_dates: new FormControl(false, []),
      date_shipped: new FormControl(false, []),
      vendor_order_numbers: new FormControl(false, []),
      order_manager: new FormControl(false, []),
      accepting_orders: new FormControl(false, []),
      rejecting_orders: new FormControl(false, []),
      update_tracking_number: new FormControl(false, []),
      request_changes: new FormControl(false, []),
      multiple_locations: new FormControl(false, []),
      payments: new FormControl(false, []),
      dashboard: new FormControl(false, []),
      notifications: new FormControl(false, []),
      vendor_directory: new FormControl(false, []),
      permissions: new FormControl(false, []),
      oneClick: new FormControl(false, []),
      directoryCoverPhoto: new FormControl(false, []),
    });

  }

  get f() {
    return this.planForm;
  }

  /**
   * Set the form events, this establishes behaviours that link between different form fields
   * @param   skipCount   when editing an existing form, we need to add a skip count, since valueChanges will trigger once on load
   */
  setFormEvents(skipCount = 0): void {

    if (this.formSet === true) {
      return;
    }

    this.f.get('order_manager').valueChanges.pipe(skip(skipCount)).subscribe(o => {
      // turning off the order manager disables all of the subgroup options;
      if (!o) {
        this.f.get('accepting_orders').setValue(false, this.NO_EMIT_OPTIONS);
        this.f.get('rejecting_orders').setValue(false, this.NO_EMIT_OPTIONS);
        this.f.get('update_tracking_number').setValue(false, this.NO_EMIT_OPTIONS);
        this.f.get('request_changes').setValue(false, this.NO_EMIT_OPTIONS);
      }
    });

    const enableOrderManagerFn = o => {
      if (o && !this.f.get('order_manager').value) {
        this.f.get('order_manager').setValue(true, this.NO_EMIT_OPTIONS);
      }
    };

    // if any of these values are set to true, enable order manager as well
    this.f.get('accepting_orders').valueChanges.pipe(skip(skipCount)).subscribe(enableOrderManagerFn);
    this.f.get('rejecting_orders').valueChanges.pipe(skip(skipCount)).subscribe(enableOrderManagerFn);
    this.f.get('update_tracking_number').valueChanges.pipe(skip(skipCount)).subscribe(enableOrderManagerFn);
    this.f.get('request_changes').valueChanges.pipe(skip(skipCount)).subscribe(enableOrderManagerFn);

    this.formSet = true;

    return;

  }

  /**
   * Get a populated model to push to backend.
   */
  getPopulatedModel(): Plans {

    const plan = new Plans();

    plan.id = this.f.get('id').value;
    plan.defaultPlan = this.f.get('defaultPlan').value ? this.f.get('defaultPlan').value : false;
    plan.name = this.f.get('name').value;
    plan.accepting_orders = this.f.get('accepting_orders').value ? this.f.get('accepting_orders').value : false;
    plan.dashboard = this.f.get('dashboard').value ? this.f.get('dashboard').value : false;
    plan.email_notifications = this.f.get('email_notifications').value ? this.f.get('email_notifications').value : false;
    plan.multiple_locations = this.f.get('multiple_locations').value ? this.f.get('multiple_locations').value : false;
    plan.notifications = this.f.get('notifications').value ? this.f.get('notifications').value : false;
    plan.oneClick = this.f.get('oneClick').value ? this.f.get('oneClick').value : false;
    plan.directoryCoverPhoto = this.f.get('directoryCoverPhoto').value ? this.f.get('directoryCoverPhoto').value : false;
    plan.order_manager = this.f.get('order_manager').value ? this.f.get('order_manager').value : false;
    plan.payments = this.f.get('payments').value ? this.f.get('payments').value : false;
    plan.permissions = this.f.get('permissions').value ? this.f.get('permissions').value : false;
    plan.rejecting_orders = this.f.get('rejecting_orders').value ? this.f.get('rejecting_orders').value : false;
    plan.request_changes = this.f.get('request_changes').value ? this.f.get('request_changes').value : false;
    plan.update_tracking_number = this.f.get('update_tracking_number').value ? this.f.get('update_tracking_number').value : false;
    plan.vendor_directory = this.f.get('vendor_directory').value ? this.f.get('vendor_directory').value : false;

    // unused permissions that do not show up on page -- always set their values as true for now
    plan.estimated_shipped_dates = true;
    plan.date_shipped = true;
    plan.acknowledge_dates = true;
    plan.vendor_order_numbers = true;

    return plan;

  }

  onFormSubmit() {

    if (!this.f.valid) {
      this.formsService.markFormGroupAsTouched(this.f);
      return;
    }

    this.store.dispatch(planEditSubjectUpdateAction(this.getPopulatedModel()));

  }

  onCancel() {
    this.location.back();
  }

}
