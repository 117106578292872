<div class="container">
  <div class="page-info  page-info--list">
    <h2 class="page-info__title">Organizations ({{statistics.total}})</h2>

    <div class="page-info__data" *ngIf="!isLoadingVendors && statistics.total>0">
      <div class="page-info__data-item"><span>{{activeOrganizationCount | async}}</span> Active</div>
      <div class="page-info__data-item"><span>{{inactiveOrganizationCount | async}}</span> Inactive</div>
      <button kendoButton
              primary="true"
              routerLink="new">Add New Organization</button>
    </div>
  </div>

  <div *ngIf="isLoadingVendors" class="k-loading-image"></div>

  <div *ngIf="!isLoadingVendors" class="content-area">
    <div class="grid-controls">
      <div class="search-items">
        <kendo-textbox id="search-organizations-textbox" (valueChange)="onSearchInput($event)"
                      placeholder="Search Organizations"
                      [value]="(searchFilterSelect | async)?.value"
                      [clearButton]="true">
          <ng-template kendoTextBoxSuffixTemplate>
            <span class="k-icon k-i-zoom"></span>
          </ng-template>
        </kendo-textbox>
      </div>

        <div class="filter-items">
          <kendo-dropdownlist [data]="changeStatusMenuItems"
                              [textField]="'text'"
                              [valueField]="'status'"
                              [value]="selectedDropdownStatus"
                              (selectionChange)="onStatusFiltersListChanged($event)">
          </kendo-dropdownlist>
        </div>
      </div>

    <div class="grid-content">
        <kendo-grid [loading]="isLoadingGridData"
                    [data]="gridData"
                    [resizable]="true"
                    [sortable]="sortableSettings"
                    scrollable="none"
                    [filter]="pagerState.filter"
                    [pageable]="true"
                    [sort]="pagerState.sort"
                    [pageSize]="pagerState.take"
                    [skip]="pagerState.skip"
                    reorderable="true"
                    [columnMenu]="{ filter: false }"
                    (dataStateChange)="dataStateChange($event)"
                    >

          <kendo-grid-column field="logo" title=""
                            width="60" [filterable]="false" [sortable]="false"
                            media="(min-width: 48rem)">
            <ng-template kendoGridCellTemplate let-organization>
              <kendo-avatar *ngIf="organization.logo_url" [shape]="'circle'"
                          [imageSrc]="organization.logo_url"></kendo-avatar>
              <kendo-avatar *ngIf="!organization.logo_url" [shape]="'circle'"
                            [initials]="organization.name"></kendo-avatar>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="name" title="Name">
            <ng-template kendoGridCellTemplate let-organization>
              {{organization.name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="contact_full_name" title="Contact Name"
                            media="(min-width: 500px)"
                            reorderable="true">
            <ng-template kendoGridCellTemplate let-organization>
              {{organization.contact_first_name}} {{organization.contact_last_name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="contact_email" title="Email"
                            media="(min-width: 48rem)"
                            [filterable]="'false'"></kendo-grid-column>

          <kendo-grid-column field="phone" title="Phone"
                            media="(min-width: 55rem)"
                            [sortable]="false" [filterable]="false">
            <ng-template kendoGridCellTemplate let-organization>{{ organization.contact_number | mask: (organization.contact_number | findMask)}}</ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="active" title="Status" width="110"
                            [minResizableWidth]="110"
                            [sortable]="true"
                            [filterable]="false">
            <ng-template kendoGridCellTemplate let-organization>
              <kendo-badge-container>
                <kendo-badge [themeColor]="(organization.active) ? 'success' : 'light'"
                            [shape]="'rectangle'"
                            [size]="'medium'"
                            [position]="'inside'"
                            [align]="{ horizontal: 'start', vertical: 'top' }">
                  {{(organization.active) ? 'ACTIVE' : 'INACTIVE'}}
                </kendo-badge>
              </kendo-badge-container>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Actions" width="120"
                            [minResizableWidth]="120"
                            [filterable]="'false'" [sortable]="false"
                            class="k-grid-column--center">
            <ng-template kendoGridCellTemplate let-organization>

              <kendo-buttongroup>
                <button kendoButton
                        (click)="editOrganization(organization.id)"
                        [primary]="true">Edit</button>
              </kendo-buttongroup>

            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
          </ng-template>

          <ng-template kendoGridNoRecordsTemplate>
            <div class="content-area  content-area--no-content">
              <p>No Organizations. Why not create one?</p>
              <button kendoButton
                      primary="true"
                      (click)="addOrganization()">Add New Organization</button>
            </div>
          </ng-template>
        </kendo-grid>
      </div>
  </div>

<!--<kendo-dialog *ngIf="confirmChangeVendorStatusDialogOpened" [width]="700" [minWidth]="370">-->
<!--  <div class="dialog-content">-->
<!--    <h2 *ngIf="switchToVendorStatus === VendorStatus.active">Activate Vendor</h2>-->
<!--    <h2 *ngIf="switchToVendorStatus === VendorStatus.inactive">Deactivate Vendor</h2>-->

<!--    <p *ngIf="switchToVendorStatus === VendorStatus.active">-->
<!--      <b>{{vendorForChangeActiveStatus.vendor_name}}</b> will be activated.-->
<!--    </p>-->

<!--    <p *ngIf="switchToVendorStatus === VendorStatus.inactive">-->
<!--      <b>{{vendorForChangeActiveStatus.vendor_name}}</b>-->
<!--      will be deactivated. You can activate it later again.-->
<!--    </p>-->
<!--  </div>-->

<!--  <kendo-dialog-actions layout="normal">-->
<!--    <button (click)="onDialogClose()" class="custom-button white">Cancel</button>-->
<!--    <button (click)="changeVendorStatus()" class="custom-button yellow">-->
<!--      <span *ngIf="switchToVendorStatus === VendorStatus.active">Activate</span>-->
<!--      <span *ngIf="switchToVendorStatus === VendorStatus.inactive">Deactivate</span>-->
<!--    </button>-->

<!--    &lt;!&ndash;<button kendoButton look="flat" (click)="onDialogClose()">Cancel</button>-->
<!--    <button kendoButton (click)="changeVendorStatus()" primary="true">-->
<!--      <span *ngIf="switchToVendorStatus === VendorStatus.active">Activate</span>-->
<!--      <span *ngIf="switchToVendorStatus === VendorStatus.inactive">Deactivate</span>-->
<!--    </button>&ndash;&gt;-->
<!--  </kendo-dialog-actions>-->
<!--</kendo-dialog>-->
</div>
